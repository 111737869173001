import LogoSvg from "../img/axiom-logo-2023.png";
import LoginSrc from "../img/iss037e017041_orig.jpg";
const LogoPng = LogoSvg;

const HTML_TITLE = "Axiom Space Wallet Viewer";
const TEAM_CITY = "Axiom Space";
const TEAM_NAME = "Axiom Space";
const COPYRIGHT = "Axiom Space";
const LOGIN_SRC = LoginSrc;
const LOGO_SVG_SRC = LogoSvg;
const LOGO_PNG_SRC = LogoPng;

export default {
  HTML_TITLE,
  TEAM_CITY,
  TEAM_NAME,
  COPYRIGHT,
  LOGIN_SRC,
  LOGO_SVG_SRC,
  LOGO_PNG_SRC,
  HIDE_PRINTING_LIMIT: true,
  REDEMPTION_SHOW_REMAINING: false,
  REDEMPTION_SHOW_REDEEMED: true,
};
