import { FunctionComponent } from "react";
import { CLIENT } from "../../../../../config";
import { ClaimButtonProps, DefaultClaimButtonUI } from "./default";
import { PickemClaimButtonUI } from "./pickem";

export const ClaimButtonUI: FunctionComponent<ClaimButtonProps> = (props) => {
  switch (CLIENT) {
    case "pickem":
    case "pickem-marketplace":
    case "pickem-test":
      return <PickemClaimButtonUI {...props} />;
    default:
      return <DefaultClaimButtonUI {...props} />;
  }
};
