import { getStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import { formatUsdWithSymbol } from "utils/utils";
import styles from "./style.module.scss";

type Props = {
  currentListedPrice?: number;
  lowestMarketPrice?: number | null;
};

export function PriceUI(props: Props) {
  const localizedStrings = getStrings("Pages", "Details")[useLanguage()];

  if (props.currentListedPrice) {
    return (
      <>
        <h2>{formatUsdWithSymbol(props.currentListedPrice)}</h2>
        <p className={styles.marketplacePrice}>
          {localizedStrings.listedOnMarketplace}
        </p>
        <hr />
      </>
    );
  } else if (props.lowestMarketPrice) {
    return (
      <>
        <h2>{formatUsdWithSymbol(props.lowestMarketPrice)}</h2>
        <p>{localizedStrings.currentLowestPrice}</p>
        <hr />
      </>
    );
  } else {
    return null;
  }
}
