import Checkbox from "../Checkbox";
import styles from "./styles.module.scss";

type Props = { count: number };

export default function SkeletonTableRow({ count }: Props) {
  const list = Array.from(Array(count).keys());

  return (
    <table className={`${styles.table} ${styles.skeleton}`}>
      <tbody>
        {list.map((_, i) => (
          <tr key={i}>
            <td>
              <label className={styles.checkbox}>
                <Checkbox readOnly />
              </label>
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        ))}
        <tr>
          <td />
        </tr>
      </tbody>
    </table>
  );
}
