import { PropsWithChildren } from "react";
import { Blockchain, Config } from "config";
import { MetaTags } from "components";
import { NftShowFlowConnector } from "./connectors/NftShowFlowConnector";
import { Navigate, useParams } from "react-router-dom";
import { NFT_LIST_ROUTE } from "config/routes";
import { NftShowEthConnector } from "./connectors/NftShowEthConnector";

export type ModalVisible =
  | "claim"
  | "transfer"
  | "sell"
  | "purchase"
  | "withdraw"
  | "login";

const Page = ({ children }: PropsWithChildren<unknown>) => (
  <>
    <MetaTags
      title={Config.Metatags.NFT_DETAILS_META_TAGS.TITLE}
      description={Config.Metatags.NFT_DETAILS_META_TAGS.DESCRIPTION}
    />
    {children}
  </>
);

type Params = { id: string; contractAddress?: string; contractName?: string };

export default function NftShowPage() {
  const blockchain = Blockchain.BLOCKCHAIN_NAME;

  const { id, contractAddress, contractName } = useParams<Params>();
  switch (true) {
    case blockchain === "flow":
      if (id == null || contractAddress == null || contractName == null) {
        // This is an error, go back to the nft list
        return <Navigate replace to={NFT_LIST_ROUTE} />;
      }
      return (
        <Page>
          <NftShowFlowConnector
            itemFID={id}
            contractAddress={contractAddress}
            contractName={contractName}
          />
        </Page>
      );
    case blockchain === "polygon":
      if (id == null || contractAddress == null) {
        // This is an error, go back to the nft list
        return <Navigate replace to={NFT_LIST_ROUTE} />;
      }
      return (
        <Page>
          <NftShowEthConnector itemEID={id} contractAddress={contractAddress} />
        </Page>
      );
    default:
      return null;
  }
}
