import { Dispatch, SetStateAction } from "react";
import { ModalVisible } from "../NftShowPage";
import { useAppSelector } from "../../../../lib/store/hooks";
import { selectAppUser } from "../../../../lib/store/slices/user-slice";
import { useLocation } from "react-router-dom";
import { LoginButtonUI } from "../ui/LoginButtonUI";

type Props = {
  setModalIsVisible: Dispatch<SetStateAction<ModalVisible | null>>;
};
export function LoginButtonFlowConnector({ setModalIsVisible }: Props) {
  const user = useAppSelector(selectAppUser);
  const isPublic = useLocation().pathname.includes("public");
  const displayBtn = !user && isPublic;

  return displayBtn ? (
    <LoginButtonUI onClick={() => setModalIsVisible("login")} />
  ) : null;
}
