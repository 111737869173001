import { hasUniqueWalletType } from "config";
import { authenticationAPI } from "lib/store/api/authentication-api";
import { flowAPI } from "lib/store/api/flow";
import {
  useCurrentWallet,
  useRegister,
  useWalletSetupStatus,
} from "lib/store/api/flow/hooks";
import { useAppSelector } from "lib/store/hooks";
import { selectAppUser, selectFlowUser } from "lib/store/slices/user-slice";
import { useLocation } from "react-router-dom";
import { SmartContract } from "types";
import { SetupWizardUI, Step } from "../ui/SetupWizardUI";

type Props = {
  showAlert?: boolean;
};

export type SetupStatus = {
  originalArgs?: { smartContract: SmartContract };
  isLoading: boolean;
  endpoint?: string;
};

export function SetupWizardFlowConnector({ showAlert }: Props) {
  const completedSteps: Step[] = [];

  const user = useAppSelector(selectAppUser);
  const flowUser = useAppSelector(selectFlowUser);
  const { pathname } = useLocation();
  const userId = user ? String(user.id) : undefined;
  const { data: wallets } = flowAPI.useGetWalletListQuery({ userId });
  const wallet = useCurrentWallet();
  const walletStatusQuery = useWalletSetupStatus();
  const isOnSingleNftPage = pathname === "/nft";
  const isCurrentlyConnected = Boolean(flowUser?.addr);
  const hasRegisteredWallet = Boolean(wallets?.length);

  if (isCurrentlyConnected && hasRegisteredWallet) {
    completedSteps.push("register");
  }
  if (
    walletStatusQuery?.isSuccess &&
    walletStatusQuery.currentData?.every((contract) => contract.isSetup)
  ) {
    completedSteps.push("smartContracts");
  }

  if (user?.profileName != null) {
    completedSteps.push("profile");
  }

  const [onRegister, registerStatus] = useRegister();
  const [onSetup, setupStatus] =
    flowAPI.useSetupAccountForSmartContractMutation();
  const [onAddFunds, fundsStatus] = flowAPI.useAddFundsMutation();
  const [onSkipAddingFunds] = flowAPI.useSkipAddingFundsMutation();
  const [onCompleteProfile, profileStatus] =
    authenticationAPI.useSetUsernameMutation();

  const isLoading =
    registerStatus.isLoading ||
    setupStatus.isLoading ||
    fundsStatus.isLoading ||
    profileStatus.isLoading;

  const isProfileStatusError = profileStatus.isError;

  return (
    <SetupWizardUI
      showAlert={
        (isCurrentlyConnected || !hasRegisteredWallet) &&
        showAlert &&
        isOnSingleNftPage
      }
      steps={["register", "smartContracts", "profile"]}
      completedSteps={completedSteps}
      wallet={wallet}
      isLoading={isLoading}
      walletType={hasUniqueWalletType("flow", "Blocto") ? "Blocto" : "Dapper"}
      onRegister={() => onRegister()}
      requiredSmartContracts={walletStatusQuery.data}
      isError={isProfileStatusError}
      onSetup={(smartContract) => {
        // The setup step will not render if the wallet is null anyway
        if (wallet !== null) {
          onSetup({ wallet, smartContract });
        }
      }}
      onAddFunds={(source) =>
        // The funds step will not render if the wallet is null anyway
        wallet != null && onAddFunds({ source, wallet })
      }
      onSkipAddingFunds={() =>
        // The funds step will not render if the wallet is null anyway
        wallet != null && onSkipAddingFunds({ wallet })
      }
      onCompleteProfile={(profileName) =>
        // The profile step will not render if the user is null anyway
        user != null && onCompleteProfile({ id: user.id, profileName })
      }
      setupStatus={setupStatus}
    />
  );
}
