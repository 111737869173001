import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Config, getStrings } from "config";
import Button from "components/Widjets/Button";
import Modal from "components/Widjets/Modal";
import Chip from "components/Widjets/Chip";
import styles from "./custom.module.scss";
import { useLanguage } from "lib/contexts/LanguageContext";
import DataTable, { TableHeader } from "../../../Widjets/Table";
import SkeletonTableRow from "../../../Widjets/SkeletonTableRow";
import { Sort, SortHeader } from "./SortHeader";
import { formatUsdWithSymbol } from "utils/utils";

export interface ItemPreview {
  itemFID: string;
  editionNumber: string | number | null;
  price: number | null;
  profile: { profileName: string | null };
}

type Props<T> = {
  list: T[];
  total: number;
  isLoading: boolean;
  onLoadMore: () => void;
  onSort: (sort: Sort) => void;
  onSelect: (item: T) => void;
  onCancel: () => void;
};

const DUMMY_COUNT = 3;

// FIXME: This feels a bit Bulls-specific. Should we have a section in the client config where
// this kind of behavior can be defined?
type Scarcity = "rare" | "legendary" | "iconic";
function getScarcityFromEditionNumber(editionNumber: string | null): Scarcity {
  if (editionNumber == null) return "rare";
  const editionNumberInt = Number.parseInt(editionNumber);
  if (editionNumberInt <= 6) return "legendary";
  else if (editionNumberInt < 30) return "iconic";
  else return "rare";
}

export function SelectEditionModal<T extends ItemPreview>(props: Props<T>) {
  const localizedStrings = getStrings("Modals", "SelectEdition")[useLanguage()];
  const [selected, setSelected] = useState<T>();

  return (
    <Modal
      isVisible
      onClose={props.onCancel}
      backdropClassName={styles.selectModalBackdrop}
      modalClassName={styles.selectModal}
      contentClassName={styles.selectModalContent}
    >
      <>
        <SortHeader total={props.total} onSort={props.onSort} />
        <>
          <main id={styles.editionsScroll}>
            <InfiniteScroll
              next={props.onLoadMore}
              hasMore={props.total > props.list.length}
              dataLength={props.list.length}
              loader={<SkeletonTableRow count={DUMMY_COUNT} />}
              scrollableTarget={styles.editionsScroll}
            >
              <DataTable
                showSelect
                singleSelection
                noDataMsg={localizedStrings.noNftForSale}
                onSelection={(s) => {
                  const nft = props.list.find((nft) => nft.itemFID === s[0]);
                  if (nft) {
                    setSelected(nft);
                  }
                }}
                itemKey={"itemFID"}
                items={props.list}
                loading={props.isLoading}
                headers={
                  [
                    {
                      text: localizedStrings.price,
                      value: "price",
                      display: (price: number | null) => {
                        return price ? formatUsdWithSymbol(price) : "";
                      },
                    },
                    {
                      text: localizedStrings.edition,
                      value: "editionNumber",
                      display: (editionNumber: string | null) =>
                        `# ${editionNumber}`,
                    },
                    Config.MarketPlace.scarcityType !== "unique" && {
                      text: localizedStrings.scarcityTh,
                      value: "editionNumber",
                      display: (number: string | number | null) => {
                        const scarcity = getScarcityFromEditionNumber(
                          number?.toString() ?? null
                        );
                        return (
                          <Chip className={scarcity} type="secondary">
                            {localizedStrings[scarcity]}
                          </Chip>
                        );
                      },
                      cellClass: "pl-0",
                    },
                    { text: localizedStrings.ownerTh, value: "profileName" },
                  ] as TableHeader<string | number | null>[]
                }
              />
            </InfiniteScroll>
          </main>
        </>
        <footer>
          <Button
            disabled={selected == null}
            className={"mt-2"}
            onClick={() => selected != null && props.onSelect(selected)}
          >
            {localizedStrings.continue}
          </Button>
        </footer>
      </>
    </Modal>
  );
}
