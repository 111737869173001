import { NFT_SHOW } from "config/routes";
import { useNavigate, generatePath } from "react-router-dom";
import { withPrefix } from "@onflow/fcl";
import { useAppSelector } from "../lib/store/hooks";
import { selectAppUser } from "../lib/store/slices/user-slice";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppNavigation = () => {
  const navigate = useNavigate();
  const user = useAppSelector(selectAppUser);
  const path = `${!user ? "/public" : ""}${NFT_SHOW}`;

  return {
    goToFlowNftPage(nft: {
      itemFID: string;
      smartContractAddress: string;
      smartContractName: string;
    }) {
      const { smartContractName, smartContractAddress, itemFID } = nft;
      navigate(
        generatePath(path, {
          smartContractAddress: withPrefix(smartContractAddress),
          smartContractName,
          itemFID,
        })
      );
    },
  } as const;
};
