import {
  CSSProperties,
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from "react";
import {
  AlertCircle,
  AlertOctagon,
  AlertTriangle,
  Info,
  XCircle,
} from "react-feather";
import styles from "./styles.module.scss";
type Type = "blank" | "info" | "warning" | "danger";
type TextAlign = "center" | "left";
type Props = {
  children: ReactNode;
  textAlign?: TextAlign;
  type?: Type;
  style?: CSSProperties;
  dismissible?: boolean;
  isVisible?: boolean;
  className?: string;
};
const Alert: FunctionComponent<Props> = ({
  children,
  type = "info",
  textAlign = "left",
  dismissible = false,
  isVisible = true,
  style,
  className,
}: Props) => {
  const [_isVisible, setIsVisible] = useState(isVisible);

  useEffect(() => {
    setIsVisible(isVisible);
  }, [isVisible]);

  const renderIcon = () => {
    let icon;
    switch (type) {
      case "blank":
        break;
      case "info":
        icon = <Info className={styles.icon} />;
        break;
      case "warning":
        icon = <AlertTriangle />;
        break;
      case "danger":
        icon = <AlertOctagon className={styles.icon} />;
        break;
      default:
        icon = <AlertCircle className={styles.icon} />;
        break;
    }
    return <div className={styles.icon}>{icon}</div>;
  };

  const rootClasses = [styles.alertContainer];
  rootClasses.push(styles[type]);
  rootClasses.push(styles[textAlign]);
  if (_isVisible) rootClasses.push(styles.show);
  else rootClasses.push(styles.hide);
  if (className) rootClasses.push(className);

  return (
    <div className={rootClasses.join(" ")} style={style}>
      <div className={styles.alertWrapper}>
        {textAlign === "left" && renderIcon()}
        <div className={styles.alertContent}>
          {textAlign === "center" && renderIcon()}
          {children}
        </div>

        {dismissible && (
          <div className={`${styles.icon} ml-2`}>
            <XCircle
              className={`${styles.icon} ${styles.disposable}`}
              onClick={() => setIsVisible(false)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Alert;
