import styles from "./styles.module.scss";
import { FunctionComponent } from "react";
import { useLanguage } from "lib/contexts/LanguageContext";
import { getStrings } from "config";
const strings = getStrings("Component", "StatusChip");
type PossibleSuffix = "Inner" | "Outer" | "Text";
type WalletStatus =
  | "main-wallet"
  | "connected"
  | "disconnected"
  | "setup-required"
  | "setup-done"
  | "unknown";
interface ChipProps {
  status?: WalletStatus;
  className?: string;
}
const StatusChip: FunctionComponent<ChipProps> = ({
  status = "unknown",
  className,
}: ChipProps) => {
  const lang = useLanguage();
  const renderCircleColor = (suffix: PossibleSuffix): string => {
    switch (status) {
      case "connected":
      case "setup-done":
        return styles[`green${suffix}`];
      case "disconnected":
        return styles[`red${suffix}`];
      case "setup-required":
        return styles[`yellow${suffix}`];
      case "main-wallet":
        return styles[`grey${suffix}`];
      default:
        return styles[`black${suffix}`];
    }
  };
  const renderText = () => {
    switch (status) {
      case "connected":
      case "disconnected":
        return strings[lang].connectedDisconnectedStatusTxt;
      case "setup-required":
      case "setup-done":
        return strings[lang].setupDoneRequiredTxt;
      case "main-wallet":
        return strings[lang].mainWalletTxt;
      default:
        return strings[lang].unknownStatusTxt;
    }
  };
  let rootClass = styles.dFlex;
  if (className) rootClass += ` ${className}`;
  return (
    <div className={rootClass}>
      <div
        className={`${styles.statusCircleOuter} ${renderCircleColor("Outer")}`}
      >
        <div
          className={`${styles.statusCircleInner} ${renderCircleColor(
            "Inner"
          )}`}
        />
      </div>
      <span className={`${renderCircleColor("Text")} ml-1`}>
        {renderText()}
      </span>
    </div>
  );
};

export default StatusChip;
