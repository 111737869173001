import { getStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import { FunctionComponent, ReactNode } from "react";
import styles from "./styles.module.scss";

type Props = {
  index?: string | number;
  title?: string;
  description?: string;
  imageSrc?: string;
  label?: string | ReactNode;
  footer?: ReactNode;
  onClick?: () => void;
  displayUnclaimedStatus?: boolean;
  hideDescription?: boolean;
};

const Collectible: FunctionComponent<Props> = ({
  index,
  title,
  description,
  imageSrc,
  label,
  footer,
  onClick,
  displayUnclaimedStatus,
  hideDescription,
}) => {
  const lang = useLanguage();
  const strings = getStrings("Component", "NftList");
  const imgClass = displayUnclaimedStatus
    ? `${styles.imgContainer} ${styles.unclaimedContainer}`
    : styles.imgContainer;

  return (
    <div key={index} className={styles.collectibleItem} onClick={onClick}>
      <div className={imgClass}>
        <div
          className={styles.imgTag}
          style={{
            backgroundImage: `url(${imageSrc ? new URL(imageSrc).href : ""})`,
          }}
        />
        {displayUnclaimedStatus && (
          <span className={styles.unclaimedButton}>
            {strings[lang].clickToClaim}
          </span>
        )}
        {!!label && (
          <div className={styles.imgOverlay}>
            <div className={styles.imgLabel}>{label}</div>
          </div>
        )}
      </div>
      <h5>{title}</h5>
      {!hideDescription && <p>{description}</p>}
      {footer}
    </div>
  );
};

export default Collectible;
