const MERCHANT_LIST = {
  Testnet: [{ id: "1", address: "0x28492e97618732eb" }],
  Mainnet: [{ id: "71", address: "0x3744f2f82b3c0a79" }],
};

const MINT_STORE_MARKET = {
  Testnet: { id: 1, address: "0x28492e97618732eb" },
  Mainnet: { id: 11, address: "0x3744f2f82b3c0a79" },
};

const WALLET_VIEWER_ID = {
  Testnet: 1,
  Mainnet: 5,
};
const KYC_MERCHANT_ID = {
  Testnet: 1,
  Mainnet: 3,
};

export { MERCHANT_LIST, MINT_STORE_MARKET, WALLET_VIEWER_ID, KYC_MERCHANT_ID };
