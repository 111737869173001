import feathersClient from "lib/feathers";
import { Flow, WalletPreview } from "types";
import { isValidAddress } from "utils/utils";
import { Builder } from "./builder";
import { flowAPI } from ".";
import { selectAppUser } from "lib/store/slices/user-slice";
import { RootState } from "lib/store";
import { toast } from "react-toastify";
import { current } from "@reduxjs/toolkit";

export const flowWalletEndpoints = (
  builder: Builder,
  walletType: FlowWalletType
) => ({
  getWalletList: builder.query<Flow.Wallet[], { userId: string | undefined }>({
    async queryFn({ userId }): Promise<{ data: Flow.Wallet[] }> {
      if (userId == null) return { data: [] };
      const { data } = await feathersClient
        .service("flow-client-wallet")
        .find({ query: { userId, walletType } });
      const validWallets = data.filter((wallet) =>
        isValidAddress(wallet.address)
      );
      return { data: validWallets };
    },
    providesTags: (result) =>
      result
        ? [
            ...result.map(({ id }) => ({ type: "FlowWallet" as const, id })),
            "FlowWallet",
          ]
        : ["FlowWallet"],
  }),

  removeWallet: builder.mutation<true, WalletPreview>({
    async queryFn(wallet): Promise<{ data: true }> {
      await feathersClient
        .service("flow-client-wallet")
        .remove(String(wallet.id), {});
      return { data: true };
    },
    async onQueryStarted({ id }, { dispatch, getState, queryFulfilled }) {
      const user = selectAppUser(getState() as RootState);
      let wallet: Flow.Wallet | undefined;

      try {
        dispatch(
          flowAPI.util.updateQueryData(
            "getWalletList",
            { userId: String(user?.id) },
            (draft) => {
              const walletIndex = draft.findIndex((wallet) => wallet.id === id);
              if (walletIndex !== -1) {
                const [proxyWallet] = draft.splice(walletIndex, 1);
                wallet = current(proxyWallet);
              }
            }
          )
        );
        await queryFulfilled;
      } catch (error) {
        console.error(error);
        // TODO put that in the string file
        toast.error("Wallet removal failed");
        dispatch(
          flowAPI.util.updateQueryData(
            "getWalletList",
            { userId: String(user?.id) },
            (draft) => {
              if (wallet) {
                draft.push(wallet);
              }
            }
          )
        );
      }
    },
  }),

  updateWallet: builder.mutation<
    Flow.Wallet,
    { id: number; walletName?: string }
  >({
    async queryFn(wallet) {
      const { id, ...data } = wallet;
      const updatedWallet = await feathersClient
        .service("flow-client-wallet")
        .patch(id.toString(), data);
      return { data: updatedWallet };
    },
    async onQueryStarted({ id, walletName }, { dispatch, getState }) {
      const user = selectAppUser(getState() as RootState);
      dispatch(
        flowAPI.util.updateQueryData(
          "getWalletList",
          { userId: String(user?.id) },
          (draft) => {
            const existingWallet = draft.find((wallet) => wallet.id === id);
            if (existingWallet) {
              existingWallet.walletName = walletName ?? null;
            }
          }
        )
      );
    },
  }),

  registerWallet: builder.mutation<Flow.Wallet, { address: string }>({
    async queryFn({ address }) {
      const wallet = await feathersClient
        .service("register-wallet")
        .create({ flowAddress: address, walletType });

      return { data: wallet };
    },
    async onQueryStarted({ address }, { queryFulfilled, dispatch, getState }) {
      const user = selectAppUser(getState() as RootState);
      const id = Date.now();
      dispatch(
        flowAPI.util.updateQueryData(
          "getWalletList",
          { userId: String(user?.id) },
          (draft) => {
            const existingWallet = draft.find(
              (wallet) => wallet.address === address
            );
            if (!existingWallet) {
              draft.push({
                id,
                walletType,
                address,
                marketSetup: [],
                walletName: null,
                wizard: {
                  id: 1,
                  register: false,
                  setup: false,
                  profile: false,
                },
                balance: null,
              });
            }
          }
        )
      );

      const { data: backEndWallet } = await queryFulfilled;
      dispatch(
        flowAPI.util.updateQueryData(
          "getWalletList",
          { userId: String(user?.id) },
          (draft) => {
            const existingWallet = draft.find((wallet) => wallet.id === id);
            if (existingWallet) {
              existingWallet.id = backEndWallet.id;
              existingWallet.balance = backEndWallet.balance;
            }
          }
        )
      );
    },
  }),

  getRecipient: builder.mutation<
    { name: string | null; address: string | null },
    { nameOrAddress: string }
  >({
    async queryFn({ nameOrAddress }) {
      if (!nameOrAddress || nameOrAddress.length === 0) {
        return { data: { name: null, address: null } };
      }

      const isRecipientAddress = isValidAddress(nameOrAddress);

      const query = isRecipientAddress
        ? { address: nameOrAddress }
        : { profileName: nameOrAddress };

      try {
        const { data } = await feathersClient
          .service("primary-wallet")
          .find({ query });

        const recipient = data.length > 0 ? data[0] : null;

        const name = isRecipientAddress
          ? recipient?.profileName ?? null
          : nameOrAddress;

        const address = isRecipientAddress
          ? nameOrAddress
          : recipient?.address ?? null;

        return { data: { name, address } };
      } catch (err) {
        if (isRecipientAddress) {
          return { data: { name: null, address: nameOrAddress } };
        } else {
          return { data: { name: nameOrAddress, address: null } };
        }
      }
    },
  }),

  addFunds: builder.mutation<
    null,
    {
      source: "moonpay";
      wallet: { address: string; wizard: { id: number } };
      amount?: number;
    }
  >({
    async queryFn({ source, wallet, amount }) {
      switch (source) {
        case "moonpay":
          window
            ?.open("https://buy.moonpay.com/?currencyCode=fusd", "_blank")
            ?.focus();
          break;
      }
      await feathersClient
        .service("wizard")
        .patch(wallet.wizard.id.toString(), { hasAddFunds: true });
      return { data: null };
    },
    invalidatesTags: ["FlowWallet"],
  }),

  skipAddingFunds: builder.mutation<
    null,
    { wallet: { wizard: { id: number } } }
  >({
    async queryFn({ wallet }) {
      await feathersClient
        .service("wizard")
        .patch(wallet.wizard.id.toString(), { hasAddFunds: true });
      return { data: null };
    },
    invalidatesTags: ["FlowWallet"],
  }),
});
