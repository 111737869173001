import { NET } from ".";

const CHAIN_ID = {
  Testnet: "80001",
  Mainnet: "137",
};

const RPC_URL = {
  Testnet: "https://rpc-mumbai.matic.today",
  Mainnet: "https://rpc-mainnet.matic.network",
};

const WEB3_PROVIDER_URL = {
  Testnet:
    "https://polygon-mumbai.g.alchemy.com/v2/Py_tP7yRn0-GBlBOvU1nbbImArfIcGTG",
  Mainnet:
    "https://polygon-mainnet.g.alchemy.com/v2/rDJIxaZB8xGo5FS1RyrBlkwhylYZPOEy",
};

//Polygon Config
const MAINNET: Chain = {
  name: "Polygon Mainnet",
  chain: "Polygon",
  icon: "",
  rpc: [
    "https://rpc-mainnet.matic.network",
    "https://polygon-rpc.com/",
    "https://matic-mainnet.chainstacklabs.com",
    "https://rpc-mainnet.maticvigil.com",
    "https://rpc-mainnet.matic.quiknode.pro",
    "https://matic-mainnet-full-rpc.bwarelabs.com",
  ],
  nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
  infoURL: "https://polygon.technology/",
  chainId: 137,
  explorers: [
    {
      url: "https://polygonscan.com",
    },
  ],
  web3ProviderURL:
    "https://polygon-mainnet.g.alchemy.com/v2/rDJIxaZB8xGo5FS1RyrBlkwhylYZPOEy",
};

//Mumbai Config
const TESTNET: Chain = {
  name: "Mumbai",
  chain: "Polygon",
  icon: "",
  rpc: [
    "https://matic-mumbai.chainstacklabs.com",
    "https://rpc-mumbai.matic.today",
    "https://rpc-mumbai.maticvigil.com",
    "https://matic-testnet-archive-rpc.bwarelabs.com",
  ],
  nativeCurrency: {
    name: "MATIC",
    symbol: "MATIC",
    decimals: 18,
  },
  infoURL: "https://polygon.technology/",
  chainId: 80001,
  explorers: [
    {
      url: "https://mumbai.polygonscan.com",
    },
  ],
  web3ProviderURL:
    "https://polygon-mumbai.g.alchemy.com/v2/Py_tP7yRn0-GBlBOvU1nbbImArfIcGTG",
};

function getChainConfig(): Chain {
  switch (NET) {
    case "Testnet":
      return TESTNET;
    case "Mainnet":
      return MAINNET;
  }
}

export {
  CHAIN_ID,
  RPC_URL,
  WEB3_PROVIDER_URL,
  MAINNET,
  TESTNET,
  getChainConfig,
};
