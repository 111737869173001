import * as fcl from "@onflow/fcl";
import {
  Blockchain,
  Project,
  hasUniqueWalletType,
  getFlowUniqueWalletType,
  Config,
} from "config";
import { BlockchainConfig } from "types/blockchain";
import { AppDispatch } from "lib/store";
import { setFlowUser } from "lib/store/slices/user-slice";
import { flowAuthenticationEndpoints } from "./authentication-endpoints";
import { flowWalletEndpoints } from "./wallet-endpoints";
import { flowNftEndpoints } from "./nft-endpoints";
import { flowBlockchainEndpoints } from "./blockchain-endpoints";
import { flowOnchainActionsEndpoints } from "./onchain-actions-endpoints";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

// FIXME: Change the way blockchain config works, so we don't have to resort to `as`
const flowConfig = Blockchain.NET_CONFIG as BlockchainConfig<"flow">;

//Set default fallback value
const walletType: FlowWalletType = getFlowUniqueWalletType() || "Blocto";

const LOCAL_STORAGE = {
  can: true,
  get: async (key: string) => {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  },

  put: async (key: string, value: string) =>
    localStorage.setItem(key, JSON.stringify(value)),
};

fcl
  .config()
  .put("accessNode.api", flowConfig.ACCESS_NODE)
  .put("0xMintStoreItem", flowConfig.MINT_STORE_ITEM_ADDRESS)
  .put("0xMintStoreMarketFactory", flowConfig.MINT_STORE_MARKET_FACTORY_ADDRESS)
  .put("0xNonFungibleToken", flowConfig.NON_FUNGIBLE_TOKEN_ADDRESS)
  .put("0xMetadataViews", flowConfig.METADATA_VIEWS_ADDRESS)
  .put("0xDapperUtilityCoin", flowConfig.DUC_ADDRESS)
  .put("0xNFTStorefront", flowConfig.NFT_STORE_FRONT_ADDRESS)
  .put("0xFungibleToken", flowConfig.FUNGIBLE_TOKEN_ADDRESS)
  .put("0xFUSD", flowConfig.FUSD_ADDRESS);

if (Project.USE_OPENID) {
  fcl.config().put("service.OpenID.scopes", "email preferred_username");
}

if (hasUniqueWalletType("flow", "Blocto")) {
  fcl
    .config()
    .put("challenge.handshake", flowConfig.WALLET)
    .put("discovery.wallet.method", "HTTP/POST");
}

if (hasUniqueWalletType("flow", "Dapper")) {
  fcl
    .config()
    .put(
      "fcl.storage",
      Config.Client.PERSIST_FLOW_USER_IN_LOCAL_STORAGE
        ? LOCAL_STORAGE
        : undefined
    )
    .put("discovery.wallet", Project.DISCOVERY_WALLET[Blockchain.NET])
    .put("discovery.wallet.method", "POP/RPC");
}

export function setupFlowListeners(dispatch: AppDispatch): void {
  fcl.currentUser().subscribe((user: FlowUser) => {
    dispatch(setFlowUser(user));
    if (user?.addr) {
      dispatch(
        flowAPI.endpoints.checkWalletSetup.initiate(
          { address: user.addr },
          { forceRefetch: true }
        )
      );
    }
  });
}

export const flowAPI = createApi({
  reducerPath: "flowAPI",
  baseQuery: fetchBaseQuery(),
  tagTypes: [
    "FlowWallet",
    "FlowMintStoreNft",
    "ChallengeWinner",
    "FlowMerchant",
    "FlowEdition",
    "FlowNftState",
    "SortedFlowNftForEdition",
    "ItemForSaleList",
  ],
  endpoints: (builder) => ({
    ...flowAuthenticationEndpoints(builder),
    ...flowWalletEndpoints(builder, walletType),
    ...flowNftEndpoints(builder),
    ...flowBlockchainEndpoints(builder),
    ...flowOnchainActionsEndpoints(builder),
  }),
});
