import Button from "components/Widjets/Button";

import { useState } from "react";
import { getStrings, Config } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import styles from "./style.module.scss";
import { FAQ_ZENDESK } from "config/routes";
import { AlertCircle } from "react-feather";
import { formatUsdWithSymbol } from "utils/utils";
import { HasConsentBeforePurchase } from "../components";

interface NftPreview {
  id?: number;
  thumbnail?: string | null;
  name?: string | null;
  editionNumber: string | number | null;
  printingLimit: number | null;
  price?: number | null;
}

interface WalletPreview {
  balance: string | null;
}

type Props = {
  disabled: boolean;
  loggedIn: boolean;
  nft: NftPreview;
  wallet: WalletPreview | null;
  onClick: () => void;
  hasInsufficientFunds: boolean;
  customDisabledReason?: string | null;
};

export function PurchaseButtonUI(props: Props) {
  const localizedStrings = getStrings("Pages", "Details")[useLanguage()];
  const [hasConsented, setHasConsented] = useState(
    !Config.Client.REQUIRE_CONSENT_BEFORE_PURCHASE
  );

  return (
    <>
      {!props.disabled && (
        <HasConsentBeforePurchase onCheckClick={setHasConsented} />
      )}
      <Button
        type="primary"
        disabled={
          (props.disabled && props.loggedIn) ||
          props.hasInsufficientFunds ||
          (!hasConsented && props.loggedIn)
        }
        onClick={props.onClick}
      >
        {localizedStrings.purchase}
      </Button>
      {props.customDisabledReason && <p>{props.customDisabledReason}</p>}
      <div className={`${styles.errorContainer} d-flex align-center`}>
        {props.hasInsufficientFunds && <AlertCircle className={"mr-2"} />}
        {props.hasInsufficientFunds && (
          <span>
            <p className="my-0">{localizedStrings.insufficientFunds}</p>
            <p className="my-0">
              {`${localizedStrings.walletBalance}: ${formatUsdWithSymbol(
                Number(props.wallet?.balance)
              )}`}
            </p>
          </span>
        )}
      </div>
      {props.hasInsufficientFunds && (
        <a href={`${FAQ_ZENDESK}/articles/4408653521553`} target="_blank">
          {localizedStrings.learnMoreLinkTxt}
        </a>
      )}
    </>
  );
}
