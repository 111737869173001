import {
  ClaimModal,
  ClaimModalProps,
} from "../../../../../components/AppComponent/ActionModals/Claim/ClaimModal";
import { FC, PropsWithChildren, useEffect } from "react";
import { Button } from "../../../../../components";
import { useNftList } from "../../../../../lib/store/api/flow/hooks";
import { useNavigate } from "react-router-dom";
import { NFT_LIST_ROUTE } from "../../../../routes";
import { getStrings } from "../../../../index";
import { useLanguage } from "../../../../../lib/contexts/LanguageContext";
import { Config } from "config";

const GAME_LINK = `${Config.MarketPlace.redirectMarketplaceLink}/collection`;
const _strings = getStrings("Modals", "Claim");
const CustomClaimModal: FC<PropsWithChildren<ClaimModalProps>> = (props) => {
  const navigate = useNavigate();
  const lang = useLanguage();
  const { totalUnclaimed, isLoading } = useNftList();
  const strings = _strings[lang];

  const shouldRedirect = totalUnclaimed === 0 && !isLoading;

  useEffect(() => {
    const id = setTimeout(() => {
      if (shouldRedirect && GAME_LINK) {
        window.location.href = GAME_LINK;
      }
    }, 2000);

    return () => {
      clearTimeout(id);
    };
  }, [shouldRedirect]);

  const additionalProps = { ...props, shouldRedirect };

  return (
    <ClaimModal {...additionalProps}>
      {totalUnclaimed > 0 && !isLoading && (
        <>
          <Button
            onClick={() => navigate(NFT_LIST_ROUTE)}
          >{`${strings.claimBtnTxt} (${totalUnclaimed})`}</Button>
          <a href={GAME_LINK ?? ""}>{strings.gameLinkTxt}</a>
        </>
      )}
    </ClaimModal>
  );
};
export default CustomClaimModal;
