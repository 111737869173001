import { SmartContract } from "types";
import * as Blockchain from "../../global-blockchain-config";

const MERCHANT_LIST = {
  Testnet: [{ id: "17", address: "0x28492e97618732eb" }],
  Mainnet: [{ id: "17", address: "0x28492e97618732eb" }],
};

const MINT_STORE_MARKET = {
  Testnet: { id: 1, address: "0x28492e97618732eb" },
  Mainnet: { id: 1, address: "0x28492e97618732eb" },
};

const WALLET_VIEWER_ID = {
  Testnet: 1,
  Mainnet: 1,
};

const KYC_MERCHANT_ID = {
  Testnet: 1,
  Mainnet: 1,
};

const DISCOVERY_WALLET: {
  Testnet?: string;
  Mainnet?: string;
} = {
  Testnet: "https://staging.accounts.meetdapper.com/fcl/authn-restricted",
  Mainnet: "https://accounts.meetdapper.com/fcl/authn-restricted",
};

const DAPPER_WALLET_URL = {
  Testnet: "https://staging.accounts.meetdapper.com",
  Mainnet: "https://accounts.meetdapper.com",
};

const APP_IDENTIFIER = {
  Testnet: "mintTestnetAPI",
  Mainnet: "mintMainnetAPI",
};
const USE_OPENID = false;

const SMART_CONTRACTS: SmartContract[] = [
  {
    id:
      Blockchain.NET === "Testnet"
        ? "786aa074-64d0-493a-823b-e7d36fa53bff"
        : "",
    //TODO - add mainnet id when we have it
    name: "MintStoreItem",
    type: "Edition",
    address:
      Blockchain.NET === "Testnet"
        ? "0x985d410b577fd4a1"
        : "0x20187093790b9aef",
    publicCollectionStoragePath: "MintStoreItemCollectionPublic",
    providerPath: "MintStoreItemCollection",
  },
];

const IS_AGE_GATED = false;
const IS_NFT_TEXTUAL_HEADING = false;

export {
  MERCHANT_LIST,
  MINT_STORE_MARKET,
  WALLET_VIEWER_ID,
  KYC_MERCHANT_ID,
  DISCOVERY_WALLET,
  DAPPER_WALLET_URL,
  APP_IDENTIFIER,
  USE_OPENID,
  SMART_CONTRACTS,
  IS_AGE_GATED,
  IS_NFT_TEXTUAL_HEADING,
};
