import React, { FunctionComponent } from "react";
import { Config } from "config";
import { useNavigate } from "react-router-dom";
import { LOGIN_ROUTE } from "../../../config/routes";
import { authenticationAPI } from "lib/store/api/authentication-api";
import { flowAPI } from "lib/store/api/flow";
import { ethAPI } from "lib/store/api/eth";
import { DefaultFooter } from "./DefaultFooter";
import { PickemFooter } from "./PickemFooter";
import { ShebaFooter } from "./ShebaFooter";

export const AppFooter: FunctionComponent = () => {
  const [authLogout] = authenticationAPI.useLogoutMutation();
  const [flowLogout] = flowAPI.useLogoutMutation();
  const [ethLogout] = ethAPI.useLogoutMutation();

  const navigate = useNavigate();

  const logout = React.useCallback(() => {
    authLogout();
    flowLogout();
    ethLogout({ isDisconnecting: true });
    navigate(LOGIN_ROUTE);
  }, [authLogout, flowLogout, ethLogout, navigate]);

  switch (Config.Client.CUSTOM_FOOTER) {
    case "pickem":
      return <PickemFooter onLogout={logout} />;
    case "sheba":
      return <ShebaFooter onLogout={logout} />;
    default:
      return <DefaultFooter logout={logout} />;
  }
};
