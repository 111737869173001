import { PriceUI } from "../ui/PriceUI";

export function PriceFlowConnector(props: {
  currentListedPrice?: number;
  lowestMarketPrice?: number | null;
}) {
  return (
    <PriceUI
      currentListedPrice={props.currentListedPrice}
      lowestMarketPrice={props.lowestMarketPrice}
    />
  );
}
