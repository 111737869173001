import feathersClient from "lib/feathers";
import { RootState } from "lib/store";
import { selectAppUser, setAppUser } from "lib/store/slices/user-slice";
import { Eth } from "types";
import { isValidAddress } from "utils/utils";
import { Builder } from "./builder";

export const ethWalletEndpoints = (builder: Builder) => ({
  getWalletList: builder.query<
    Eth.Wallet[],
    { userId: string | undefined; address?: string | undefined }
  >({
    async queryFn({ userId, address }) {
      if (userId == null) return { data: [] } as { data: Eth.Wallet[] };
      const { data } = await feathersClient
        .service("eth-client-wallet")
        .find({ query: { userId, address } });
      const validWallets = data.filter((wallet) =>
        isValidAddress(wallet.address)
      );
      return { data: validWallets };
    },
    providesTags: (result) =>
      result
        ? [
            ...result.map(({ id }) => ({ type: "EthWallet" as const, id })),
            "EthWallet",
          ]
        : ["EthWallet"],
  }),

  updateWallet: builder.mutation<
    Eth.Wallet,
    { id: number; walletName?: string }
  >({
    async queryFn(wallet) {
      const { id, ...data } = wallet;
      const updatedWallet = await feathersClient
        .service("eth-client-wallet")
        .patch(id.toString(), data);
      return { data: updatedWallet };
    },
    invalidatesTags: (_, __, { id }) => [{ type: "EthWallet", id }],
  }),

  getRecipient: builder.mutation<
    { name: string | null; address: string | null },
    { nameOrAddress: string }
  >({
    async queryFn({ nameOrAddress }) {
      if (!nameOrAddress || nameOrAddress.length === 0) {
        return { data: { name: null, address: null } };
      }

      const isRecipientAddress = isValidAddress(nameOrAddress);

      const query = isRecipientAddress
        ? { address: nameOrAddress }
        : { profileName: nameOrAddress };

      try {
        const { data } = await feathersClient
          .service("primary-wallet")
          .find({ query });

        const recipient = data.length > 0 ? data[0] : null;

        const name = isRecipientAddress
          ? recipient?.profileName ?? null
          : nameOrAddress;

        const address = isRecipientAddress
          ? nameOrAddress
          : recipient?.address ?? null;

        return { data: { name, address } };
      } catch (err) {
        if (isRecipientAddress) {
          return { data: { name: null, address: nameOrAddress } };
        } else {
          return { data: { name: nameOrAddress, address: null } };
        }
      }
    },
  }),

  registerWallet: builder.mutation<
    Eth.Wallet,
    { address: string; walletType: EthWalletType }
  >({
    async queryFn({ address, walletType }, { dispatch, getState }) {
      const wallet = await feathersClient
        .service("register-eth-wallet")
        .create({ ethAddress: address, walletType });

      // Reset the user to get its new primary wallet ID
      const user = selectAppUser(getState() as RootState);
      if (user != null) {
        const nextUser = await feathersClient
          .service("users")
          .get(user.id.toString());
        dispatch(setAppUser(nextUser));
      }

      return { data: wallet };
    },
    invalidatesTags: ["EthWallet"],
  }),
});
