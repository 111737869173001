import { getStrings, hasWalletType } from "config";
import { useContext } from "react";
import { useLanguage } from "lib/contexts/LanguageContext";
import Modal from "components/Widjets/Modal";
import Button from "components/Widjets/Button";
import RoundImage from "components/AppComponent/RoundImage";
import styles from "../common.module.scss";
import { setEthConnectingWalletProvider } from "lib/store/api/eth/web3";
import { ethAPI } from "lib/store/api/eth";

import { AppContext } from "context/AppContext";
import BloctoPng from "config/global/img/blocto.png";
import MetaMaskPng from "config/global/img/metamask.png";

const strings = getStrings("Modals", "SelectWallet");

export function SelectWallet() {
  const appContext = useContext(AppContext) as AppContext;
  const lang = useLanguage();

  const [onBlockchainLogin] = ethAPI.useLoginMutation();
  const [onBlockchainLogout] = ethAPI.useLogoutMutation();

  const ethLogin = () => {
    onBlockchainLogout({ isDisconnecting: false });
    onBlockchainLogin();
  };

  return (
    <>
      <Modal
        clickOutsideToClose={false}
        isVisible={appContext.selectWallet.display.isVisible}
        onClose={() => {
          appContext.selectWallet.display.setIsVisible(false);
          appContext.selectWallet.callbacks.onClose();
        }}
        modalClassName={styles.genericModal}
      >
        <h3>{strings[lang].title}</h3>
        <h5 className="text-center mt-1 mb-3">{strings[lang].body}</h5>
        {hasWalletType("polygon", "Blocto") && (
          <Button
            className="my-2"
            icon={<RoundImage imageSrc={BloctoPng} size={30} />}
            onClick={() => {
              setEthConnectingWalletProvider("Blocto");
              ethLogin();
            }}
            type="secondary"
          >
            Blocto
          </Button>
        )}
        {hasWalletType("polygon", "MetaMask") && (
          <Button
            className="my-2"
            icon={<RoundImage imageSrc={MetaMaskPng} size={30} />}
            onClick={() => {
              setEthConnectingWalletProvider("MetaMask");
              ethLogin();
            }}
            type="secondary"
          >
            MetaMask
          </Button>
        )}
      </Modal>
    </>
  );
}
