import * as Sentry from "@sentry/react";
import type { Middleware } from "@reduxjs/toolkit";
import { captureException } from "@sentry/react";
import { Project } from "../config";

export function initializeSentry(): void {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_KEY,
    environment: process.env.NODE_ENV,
    initialScope: {
      tags: { client: process.env.REACT_APP_WALLET_VIEWER_NAME },
    },
    attachStacktrace: true,
    release: process.env.REACT_APP_VERSION,
    normalizeDepth: 100,
    integrations: [
      new Sentry.GlobalHandlers({ onerror: true, onunhandledrejection: true }),
    ],
  });
}

const rejectionRegEx = /\w*\/(executeQuery|executeMutation)\/rejected/;

export function initializeReduxStoreEnhancer() {
  return Sentry.createReduxEnhancer({
    configureScopeWithState(scope, state) {
      if (state.users.flowUser) {
        scope.setTag(
          `flow.connected.${Project.WALLET_TYPE?.[0]}`,
          state.users.flowUser.addr
        );
      }
      if (state.users.ethUser) {
        scope.setTag(
          `eth.connected.${Project.WALLET_TYPE[0]}`,
          state.users.ethUser.address
        );
      }
    },
  });
}

export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  const isRtkRejection = action.type.match(rejectionRegEx);
  const isInternalRtkRejection = action.meta?.condition;
  const isDapperCancellation = action.error?.message?.includes("Declined");

  if (isRtkRejection && !isInternalRtkRejection && !isDapperCancellation) {
    captureException(action.error, {
      tags: {
        endpoint: action.meta?.arg?.endpointName,
      },
      fingerprint: [action.error?.stack ?? ""],
    });
  }

  return next(action);
};
