import Button from "components/Widjets/Button";
import Modal from "components/Widjets/Modal";
import { getStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import { useState } from "react";
import { formatUsdWithSymbol } from "utils/utils";
import styles from "../styles.module.scss";

interface WalletPreview {
  balance: string | null;
}

export type Source = "moonpay";

type Props = {
  onMainButtonClick: (source: Source) => void;
  onClose: () => void;
  onSkip: () => void;
  wallet: WalletPreview;
};

type Substep = "show-balance" | "select-source";

export function FundsStep(props: Props) {
  const localizedStrings = getStrings("Modals", "Wizard")[useLanguage()];
  const [substep, setSubstep] = useState<Substep>("show-balance");

  if (substep === "show-balance") {
    return (
      <Modal
        clickOutsideToClose={false}
        isVisible
        onClose={props.onClose}
        modalClassName={styles.modal}
        btnTxt={localizedStrings.wyre.step1.actionBtnTxt}
        onActionClick={() => setSubstep("select-source")}
        cancelTxt={localizedStrings.wyre.step1.cancelBtnTxt}
        onCancelClick={() => props.onSkip()}
      >
        <h1>{localizedStrings.wyre.step1.title}</h1>
        <p>{localizedStrings.wyre.step1.subtitle}</p>
        <h4 className={styles.balance}>
          {formatUsdWithSymbol(Number(props.wallet.balance ?? "0"))}
        </h4>
        <h4>{localizedStrings.wyre.step1.currentBalance}</h4>
      </Modal>
    );
  } else if (substep === "select-source") {
    return (
      <Modal
        isVisible
        onClose={props.onClose}
        modalClassName={styles.modal}
        btnTxt={localizedStrings.wyre.step2.cancelBtnTxt}
        onActionClick={() => setSubstep("show-balance")}
      >
        <h1>{localizedStrings.wyre.step2.title}</h1>
        <p>{localizedStrings.wyre.step2.subtitle}</p>
        <Button
          type={"secondary"}
          className="mb-0"
          onClick={() => props.onMainButtonClick("moonpay")}
        >
          {localizedStrings.wyre.sources.moonpay}
        </Button>
      </Modal>
    );
  } else {
    return null;
  }
}
