import * as fcl from "@onflow/fcl";
import { Blockchain, Project } from "config";
import feathersClient from "lib/feathers";
import { Flow } from "types";
import { BlockchainConfig } from "types/blockchain";
import { Builder } from "./builder";

const MARKET = Project.MINT_STORE_MARKET[Blockchain.NET];

// FIXME: Change the way blockchain config works, so we don't have to resort to `as`
const flowConfig = Blockchain.NET_CONFIG as BlockchainConfig<"flow">;

export const flowBlockchainEndpoints = (builder: Builder) => ({
  getBlockchainStatus: builder.query<Flow.BlockchainStatus, void>({
    async queryFn() {
      const { data } = await feathersClient
        .service("marketplace-status")
        .find({ query: { marketAddress: MARKET.address.replace("0x", "") } });

      const status: { tag: string } = await fcl.send(
        await fcl.build([fcl.ping()]),
        {
          node: flowConfig.ACCESS_NODE,
        }
      );

      const isBlockchainUnderMaintenance = status.tag !== "PING";
      const isMarketplaceUnderMaintenance =
        data[0]?.isUnderMaintenance ?? false;
      const maintenanceMessage = data[0]?.maintenanceMessage ?? null;

      return {
        data: {
          isBlockchainUnderMaintenance,
          isMarketplaceUnderMaintenance,
          maintenanceMessage,
        },
      };
    },
  }),
});
