import { FC } from "react";
import CollectiblesInfiniteSection from "components/Widjets/Collectibles/CollectiblesInfiniteSection";
import { Flow } from "../../../types";
import Collectible from "components/Widjets/Collectibles/Collectible";
import { UIProps } from "./types";
import { useAppNavigation } from "hooks/useNavigation";
import { formatUsdWithSymbol } from "utils/utils";

const Items: FC<UIProps<Flow.ItemForSale>> = ({
  items,
  onLoadMore,
  total,
  loading,
  searchTerm,
  strings,
}) => {
  const navigator = useAppNavigation();
  return (
    <CollectiblesInfiniteSection
      hasMore={total > items.length}
      dataLength={items.length}
      title={strings.collectiblesHeading}
      searchTerm={searchTerm}
      onLoadMore={onLoadMore}
      loading={loading}
    >
      {items?.map((item, index) => (
        <Collectible
          key={index}
          index={index}
          title={item.nft.name}
          imageSrc={item.nft.thumbnail ?? undefined}
          footer={
            item.price != null && (
              <p>{`${strings.priceFrom} ${formatUsdWithSymbol(item.price)}`}</p>
            )
          }
          onClick={() => navigator.goToFlowNftPage(item)}
        />
      ))}
    </CollectiblesInfiniteSection>
  );
};

export default Items;
