import { skipToken } from "@reduxjs/toolkit/dist/query";
import { rulesetAPI } from ".";

export function useRuleset(uuid?: string) {
  return rulesetAPI.useGetRulesetByUUIDQuery(uuid ? { uuid } : skipToken);
}

export function useRulesets(rulesets?: string[]) {
  return rulesetAPI.useGetRulesetsQuery(rulesets ? { rulesets } : skipToken);
}
