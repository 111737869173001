const MERCHANT_LIST = {
  Testnet: [{ id: "1", address: "0x28492e97618732eb" }],
  Mainnet: [
    { id: "6", address: "0x3744f2f82b3c0a79" },
    { id: "9", address: "0x3744f2f82b3c0a79" },
    { id: "10", address: "0x3744f2f82b3c0a79" },
    { id: "11", address: "0x3744f2f82b3c0a79" },
    { id: "12", address: "0x3744f2f82b3c0a79" },
    { id: "13", address: "0x3744f2f82b3c0a79" },
    { id: "14", address: "0x3744f2f82b3c0a79" },
    { id: "15", address: "0x3744f2f82b3c0a79" },
    { id: "16", address: "0x3744f2f82b3c0a79" },
    { id: "17", address: "0x3744f2f82b3c0a79" },
    { id: "18", address: "0x3744f2f82b3c0a79" },
    { id: "19", address: "0x3744f2f82b3c0a79" },
    { id: "20", address: "0x3744f2f82b3c0a79" },
    { id: "21", address: "0x3744f2f82b3c0a79" },
    { id: "22", address: "0x3744f2f82b3c0a79" },
    { id: "23", address: "0x3744f2f82b3c0a79" },
    { id: "24", address: "0x3744f2f82b3c0a79" },
    { id: "25", address: "0x3744f2f82b3c0a79" },
    { id: "26", address: "0x3744f2f82b3c0a79" },
  ],
};

// Koenig does not have a marketplace so the values for MINT_STORE_MARKET are placeholders

const MINT_STORE_MARKET = {
  Testnet: { id: 1, address: "0x28492e97618732eb" },
  Mainnet: { id: 12, address: "0x3744f2f82b3c0a79" },
};

const WALLET_VIEWER_ID = {
  Testnet: 1,
  Mainnet: 6,
};
const KYC_MERCHANT_ID = {
  Testnet: 1,
  Mainnet: 3,
};

export { MERCHANT_LIST, MINT_STORE_MARKET, WALLET_VIEWER_ID, KYC_MERCHANT_ID };
