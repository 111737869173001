import LoginSrc from "../img/bulls-wallet-intro.png";
import LogoSvg from "../img/logo.svg";
import LogoPng from "../img/logo16.png";

const HTML_TITLE = "Bulls Wallet Viewer";
const TEAM_CITY = "Chicago";
const TEAM_NAME = "Bulls";
const COPYRIGHT = "Chicago Bulls";
const LOGIN_SRC = LoginSrc;
const LOGO_SVG_SRC = LogoSvg;
const LOGO_PNG_SRC = LogoPng;

export default {
  HTML_TITLE,
  TEAM_CITY,
  TEAM_NAME,
  COPYRIGHT,
  LOGIN_SRC,
  LOGO_SVG_SRC,
  LOGO_PNG_SRC,
};
