import { getStrings } from "config";
import { flowAPI } from "lib/store/api/flow";
import { useCurrentWallet } from "lib/store/api/flow/hooks";
import { PlaceForSaleButtonUI } from "../ui/PlaceForSaleButtonUI";
import { useLanguage } from "lib/contexts/LanguageContext";
import { ModalVisible } from "../NftShowPage";
import { canSell } from "../util";

export function PlaceForSaleButtonFlowConnector({
  itemFID,
  contractAddress,
  contractName,
  setModalVisible,
}: {
  itemFID: string;
  contractAddress: string;
  contractName: string;
  setModalVisible: React.Dispatch<React.SetStateAction<ModalVisible | null>>;
}) {
  const localizedStrings = getStrings("Pages", "Details")[useLanguage()];
  const { data: nft } = flowAPI.useGetFullNftQuery({
    itemFID,
    contractAddress,
    contractName,
  });

  const isStatusTraitActive = nft?.attributes?.status === "Active";

  const { data: status } = flowAPI.useGetBlockchainStatusQuery();
  const wallet = useCurrentWallet();
  const isUnderMaintenance =
    status?.isBlockchainUnderMaintenance ||
    status?.isMarketplaceUnderMaintenance;

  if (!canSell(nft) || nft == null) {
    return null;
  }

  return (
    <PlaceForSaleButtonUI
      disabled={
        wallet?.address !== nft.currentAddress ||
        isUnderMaintenance ||
        isStatusTraitActive
      }
      customDisabledReason={
        isStatusTraitActive ? localizedStrings.unclaimableActiveNfts : null
      }
      setModalVisible={setModalVisible}
    />
  );
}
