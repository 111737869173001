import { useState } from "react";

export function displayPrice(price: string | undefined): string | undefined {
  const priceWithDot = price?.includes(".") ? price : `${price}.`;
  return priceWithDot
    ?.replace(/^\./, "0.") // .99 => 0.99
    ?.replace(/\.$/, ".00") // 12. => 12.00
    ?.replace(/(\.\d$)/, "$10") // 3.5 => 3.50
    ?.replace(/^0*(\d)/, "$1"); // 025.45 => 25.45
}

type UseMoney = {
  display: string | undefined;
  update: (input: string) => void;
  value: number;
};

type Props = {
  useFormatting?: boolean;
};

export default function useMoney({ useFormatting = true }: Props): UseMoney {
  const [price, setPrice] = useState<string | undefined>();
  const usablePrice = displayPrice(
    price?.replace(/,/g, "") // 1,234,567.98 => 1234567.89
  );
  const floatPrice = Number.parseFloat(usablePrice ?? "");
  function updatePrice(input: string) {
    let _input = input;

    // force input to have maximum 2 decimal points (remove commas only for testing)
    const match = _input.replace(/,/g, "").match(/^\d*\.?\d{0,2}/);
    if (match) _input = match[0];
    else _input = "";

    // add commas for each 3 digits if visualizing price (should not use that for decimal field inputs)
    if (useFormatting)
      _input = _input.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    setPrice(_input);
  }
  return {
    display: price,
    update: updatePrice,
    value: floatPrice,
  };
}
