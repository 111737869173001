import LoginSrc from "../img/banner.png";
import LogoSvg from "../img/valtech.svg";
const LogoPng = LogoSvg;

const HTML_TITLE = "Valtech Innovation Lab";
const TEAM_CITY = "Valtech Innovation Lab";
const TEAM_NAME = "";
const COPYRIGHT = "Valtech Innovation Lab";
const LOGIN_SRC = LoginSrc;
const LOGO_SVG_SRC = LogoSvg;
const LOGO_PNG_SRC = LogoPng;

export default {
  HTML_TITLE,
  TEAM_CITY,
  TEAM_NAME,
  COPYRIGHT,
  LOGIN_SRC,
  LOGO_SVG_SRC,
  LOGO_PNG_SRC,
};
