import { useContext } from "react";
import { getStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import { useAppDispatch } from "lib/store/hooks";
import { ethAPI } from "lib/store/api/eth";
import commonStyles from "../common.module.scss";
import styles from "./style.module.scss";
import Modal from "components/Widjets/Modal";
import Button from "components/Widjets/Button";
import RoundImage from "components/AppComponent/RoundImage";
import MetaMaskPng from "config/global/img/metamask.png";
import { AppContext } from "context/AppContext";

const strings = getStrings("Modals", "ChangeChain");

export function ChangeChain() {
  const appContext = useContext(AppContext) as AppContext;
  const dispatch = useAppDispatch();
  const lang = useLanguage();

  return (
    <>
      <Modal
        clickOutsideToClose={false}
        isVisible={appContext.changeChain.display.isVisible}
        onClose={() => {
          appContext.changeChain.display.setIsVisible(false);
          appContext.changeChain.callbacks.onClose();
        }}
        modalClassName={commonStyles.genericModal}
        className={styles.overlay}
      >
        <h3>{strings[lang].title}</h3>
        <h5 className="text-center mt-1 mb-3">{strings[lang].body}</h5>

        <Button
          icon={<RoundImage imageSrc={MetaMaskPng} size={30} />}
          onClick={() => {
            dispatch(
              ethAPI.endpoints.switchChains.initiate(
                appContext.changeChain.otherBlockchain.info
              )
            );
          }}
          type="secondary"
        >
          {strings[lang].btnTxt}
          {<br />}
          {`(${appContext.changeChain.otherBlockchain.info.name})`}
        </Button>
      </Modal>
    </>
  );
}
