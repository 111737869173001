type Props = {
  text: string;
  className?: string;
};

export default function NewLineText({ text, className }: Props) {
  const newText = text
    .split("\\n")
    .map((lineOfText, index) => <p key={index}>{lineOfText}</p>);
  return <div className={className}>{newText}</div>;
}
