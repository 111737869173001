export default {
  en: {
    title: "Frequently Asked Questions",
    questions: {
      nft: {
        question: "What is an NFT?",
        answer: `
          <p>
            NFTs ("Non-Fungible Tokens") are one-of-a-kind tokens that represent a unique good or
            asset, like digital art. They can be sold in to collectors and the sale of NFTs. NFTs
            created on Mint are hosted on the Flow blockchain.
          </p>
        `,
      },
      "no-nft": {
        question: "I am logging in to my wallet but I do not see my NFT?",
        answer: `
          <p>
            Please make sure you use the same email you used at checkout when purchasing your NFT.
          </p>
        `,
      },
      locked: {
        question: "The transfer / sale button is locked. What does this mean?",
        answer: `
          <p>
            This means your NFT is currently not transferrable. A hold period is required from your 
            merchant before an NFT can be transferred. For more information on the hold period 
            please refer to your merchants terms of use.
          </p>
        `,
      },
      "place-for-sale": {
        question: "Can I place my NFT for sale?",
        answer: `
          <p>
            Yes, if your Merchant has enabled the secondary marketplace you can place the NFT for 
            sale from your wallet.
          </p>
        `,
      },
      gift: {
        question: "Can I gift or transfer my NFT to someone?",
        answer: `
          <p>
            Yes you can transfer your NFT from your wallet to any other FLOW enabled wallet such as 
            <a href="https://blocto.portto.io/en/" target="_blank">Blocto</a>.
          </p>
        `,
      },
      opensea: {
        question: "Can I sell my NFT on OpenSea?",
        answer: `
          <p>
            Not yet. OpenSea, the largest marketplace for blockchain-enabled non-fungible tokens 
            (NFTs) is in the process of building support for Flow. 
            (<a href="https://news.bitcoin.com/flow-nfts-are-coming-to-opensea/">link</a>)
          </p>
        `,
      },
      metamask: {
        question: "Can I transfer my NFT to my Metamask Wallet?",
        answer: `
          <p>
            No, the NFT you purchased is on the Flow blockchain and is not compatible with Ethereum 
            wallets. Once interoperability between the Ethereum and Flow blockchain is achieved you 
            will be able to transfer it.
          </p>
        `,
      },
      "sold-out": {
        question: "NFT says Sold Out any chance i can still purchase it.",
        answer: `
          <p>
            You can purchase an NFT from the Merchants secondary markeplace.
          </p>
        `,
      },
      refund: {
        question: "Can I get a refund for an NFT I purchased?",
        answer: `
          <p>
            Please refer yourself to the merchants refund policy in their Terms of Service.
          </p>
        `,
      },
      "after-purchase": {
        question: "What can I do with my NFT after purchase?",
        answer: `
          <p>
            You can share it, gift it or place it for sale directly from the wallet.
          </p>
        `,
      },
      "wrong-email": {
        question: "I checked out with the incorrect email what can I do?",
        answer: `
          <p>
            You can transfer your NFT to the appropriate email by accessing your NFT from your 
            wallet and selecting the Transfer Item button. If you can no longer access the email 
            you checked out with please contact <a href="mailto:support@mint.store">support@mint.store</a>.
          </p>
        `,
      },
      blockchain: {
        question: "On which blockchain is my NFT registered?",
        answer: `
          <p>
            Your NFT is registered on the Flow blockchain.
          </p>
        `,
      },
      claim: {
        question: "What is the difference between a Claimed and Unclaimed NFT?",
        answer: `
          <p>
            After your initial purchase, the NFT is stored on a MINT wallet. However, you can claim 
            the NFT onto a Blocto wallet to gain full control over the NFT.
          </p>
        `,
      },
      "third-party-wallet": {
        question: "Can I transfer my NFT to a third party wallet?",
        answer: `
          <p>
            You can transfer your NFT to Flow based wallets. Metamask is an ethereum based wallet 
            and therefore cannot be used to store your NFT. By initializing the Claim process, the 
            NFT is transferred to a non-custodial wallet of your choice.
          </p>
        `,
      },
      "wallet-details": {
        question:
          "What is the information associated with my third-party wallet?",
        answer: `
          <br/>
          <div class="img-container" style=" max-width:300px;">
            <img src="https://dkuxa1i6sgo8h.cloudfront.net/public/faq/wallet-details.png" />
          </div>  
          <hr/>
          <div class="img-container" style=" max-width:100px;">
            <img src="https://dkuxa1i6sgo8h.cloudfront.net/public/faq/blocto-wallet.png" />
          </div>
          <p>
            <b>Wallet type: </b>The type of third-party wallet. The example shown is a <a href="https://blocto.portto.io/en/" target="_blank">Blocto</a> wallet.
          </p>
          <hr/>
          <div class="img-container" style=" max-width:100px;">
            <img src="https://dkuxa1i6sgo8h.cloudfront.net/public/faq/connected-pin.png" />
          </div>
          <p>
            <b>Connected: </b>The connected pin appears if you are currently connected to this wallet.
          </p>
          <hr/>
          <div class="img-container" style=" max-width:70px;">
            <img src="https://dkuxa1i6sgo8h.cloudfront.net/public/faq/setup-pin.png" />
          </div>
          <p>
            <b>Setup: </b>The wallet needs to be initialized in order to receive FUSD and hold NFTs. The setup process only needs to occur one time per third-party wallet. The pin appears yellow if the wallet is not yet setup, and green when ready.
          </p>
          <hr/>
          <div class="img-container" style=" max-width:150px;">
            <img src="https://dkuxa1i6sgo8h.cloudfront.net/public/faq/address.png" />
          </div>
          <p>
            <b>Address: </b>The wallet address. More info regarding each address can be found at https://flowscan.org/account/FLOW_ADDRESS, ex: <a href="https://flowscan.org/account/0xa9e7f237a3262fc2" target="_blank">https://flowscan.org/account/0xa9e7f237a3262fc2</a>
          </p>
          <hr/>
          <div class="img-container" style=" max-width:150px;">
            <img src="https://dkuxa1i6sgo8h.cloudfront.net/public/faq/balance.png" />
          </div>
          <p>
            <b>Balance: </b>The FUSD balance associated to the wallet. The wallet needs to be setup in order to read the balance.
          </p>
          <hr/>
          <div class="img-container" style=" max-width:80px;">
            <img src="https://dkuxa1i6sgo8h.cloudfront.net/public/faq/primary-pin.png" />
          </div>
          <p>
            <b>Primary: </b>Accounts can associate multiple third-party wallets, but can select one to be their primary. This will be the wallet of choice to receive NFTs from other users.
          </p>
          
          <br/>
        `,
      },
      environment: {
        question: "What is the environment impact of my NFT?",
        answer: `
          <p>
            Flow blockchain runs on the proof-of-stake model, which dramatically reduces the 
            potential energy consumed by cryptocurrency mining. It runs mostly on nodes operated 
            by carbon free energy. Flow is able to use so much less energy because it's secured by 
            the stake (i.e. financial value) the operators lock up, instead of the energy they burn. 
            Flow uses less than 1 GWh of power/year (less than many stadiums). Ethereum is thousands 
            of times more at ~8TWh/year. NFTs built on Flow have less than 1/1000th the environmental 
            footprint as NFTs on ethereum.
          </p>
        `,
      },
      download: {
        question: "Can I download my NFT?",
        answer: `
          <p>
            You can download your NFT media by right-clicking on the media and selecting Save As.
          </p>
        `,
      },
      fusd: {
        question:
          "I want to buy an NFT on the secondary market, but need some FUSD. How can I load my account?",
        answer: `
          <p>
            FUSD can be purchased via credit card via the Blocto app
          </p>
          <p>
            1. Download the Blocto App on your phone here
          </p>
          <div class="img-container" style="background-color: white; max-width:300px;">
            <img src="https://dkuxa1i6sgo8h.cloudfront.net/public/faq/blocto-link.png" />
          </div>
          <br/>
          <p>
            2. Login with the same email you created your account with
          </p>
          <div class="img-container" style="background-color: white; max-width:300px;">
            <img src="https://dkuxa1i6sgo8h.cloudfront.net/public/faq/blocto-signin.png" />
          </div>
          <br/>
          <p>
            3. Create a FUSD Wallet
          </p>
          <div class="img-container" style="background-color: white; max-width:300px;">
            <img src="https://dkuxa1i6sgo8h.cloudfront.net/public/faq/create-fusd-wallet.jpeg" />
          </div>
          <br/>
          <div class="img-container" style="background-color: white; max-width:300px;">
            <img src="https://dkuxa1i6sgo8h.cloudfront.net/public/faq/create-fusd-wallet-1.jpeg" />
          </div>
          <br/>
          <div class="img-container" style="background-color: white; max-width:300px;">
            <img src="https://dkuxa1i6sgo8h.cloudfront.net/public/faq/create-fusd-wallet-2.jpeg" />
          </div>
          <br/>
          <div class="img-container" style="background-color: white; max-width:300px;">
            <img src="https://dkuxa1i6sgo8h.cloudfront.net/public/faq/create-fusd-wallet-3.jpeg" />
          </div>
          <br/>
          <div class="img-container" style="background-color: white; max-width:300px;">
            <img src="https://dkuxa1i6sgo8h.cloudfront.net/public/faq/create-fusd-wallet-4.jpeg" />
          </div>
          <br/>
          <p>
          4. Purchase FUSD with your Credit Card
          </p>
          
        `,
      },
      "withdraw-funds": {
        question: "I have sold my NFT how can I withdraw my funds?",
        answer: `
          <p>
          We will be releasing an easier withdraw your balance in the coming weeks. 

          However, at the moment the best way to withdraw your funds is to convert FUSD to USDC, then USDC to the fiat currency of your choice using the exchange of your choice. This can be done in the Blocto App.
          </p>
          <p>
            1. Download the Blocto App on your phone here
          </p>
          <div class="img-container" style="background-color: white; max-width:300px;">
            <img src="https://dkuxa1i6sgo8h.cloudfront.net/public/faq/blocto-link.png" />
          </div>
          <br/>
          <p>
            2. Select Blocto Swap from the Discover Tab
          </p>
          <div class="img-container" style="background-color: white; max-width:300px;">
            <img src="https://dkuxa1i6sgo8h.cloudfront.net/public/faq/blocto-swap.png" />
          </div>
          <br/>
          <p>
            3. Convert your FUSD to tUSDT (Flow)
          </p>
          <div class="img-container" style="background-color: white; max-width:300px;">
            <img src="https://dkuxa1i6sgo8h.cloudfront.net/public/faq/swap-tusdt.png" />
          </div>
          <br/>
          <p>
            4. Teleport your tUSDT (Flow) to USDT (Ethereum)
          </p>
          <div class="img-container" style="background-color: white; max-width:300px;">
            <img src="https://dkuxa1i6sgo8h.cloudfront.net/public/faq/swap-usdt.png" />
          </div>
          <br/>
          <p>
            4. Sell your USDT on MoonPay <a href="https://www.moonpay.com/buy/usdt" target="_blank">here</a> or any exchange supporting USDT
          </p>
          <div class="img-container" style="background-color: white; max-width:300px;">
            <img src="https://dkuxa1i6sgo8h.cloudfront.net/public/faq/sell-usdt.png" />
          </div>
          <br/>
        `,
      },
      setup: {
        question: "Why do accounts need to be set up?",
        answer: `
          <p>
            Accounts on FLOW have strict rules about how their data can be stored and accessed.
            Setting up an account means creating an FUSD vault and an NFT collection,
            which enables the storage of FUSD an NFTs respectively.
          </p>
        `,
      },
    },
  },
};
