import LoginSrc from "../img/turismo.jpeg";
import LogoSvg from "../img/lostin.png";
const LogoPng = LogoSvg;

const HTML_TITLE = "LOSTiN Turismo";
const TEAM_CITY = "LOSTiN Turismo";
const TEAM_NAME = "";
const COPYRIGHT = "LOSTiN";
const LOGIN_SRC = LoginSrc;
const LOGO_SVG_SRC = LogoSvg;
const LOGO_PNG_SRC = LogoPng;

export default {
  HTML_TITLE,
  TEAM_CITY,
  TEAM_NAME,
  COPYRIGHT,
  LOGIN_SRC,
  LOGO_SVG_SRC,
  LOGO_PNG_SRC,
};
