import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../index";

export type ErrorType = "login" | "logout" | "transaction" | "chain";
export type Error = {
  type: ErrorType;
  message: string;
  code?: string | null;
  provider?: string | null;
};

interface ErrorState {
  error: Error | null;
}

const initialState: ErrorState = {
  error: null,
};

export const errorSlice = createSlice({
  name: "errors",
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<Error>) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
});

export const { setError, clearError } = errorSlice.actions;

export const selectError = (state: RootState): Error | null =>
  state.errors.error;

export const errorReducer = errorSlice.reducer;
