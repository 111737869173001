const HAS_UTILITY_PAGE = true;

const UTILITY_BANNER_BUTTON_LINK = "/login";

const UTILITY_SHOPIFY_SHOP = "mint-nft-plus.myshopify.com";
const UTILITY_SHOPIFY_COLLECTION_ID = 267445960804;

const UTILITY_BANNER =
  "https://mint-store-metadata.s3.us-east-2.amazonaws.com/lostin/utility-thumbnails/lostin-utility-banner.jpeg";
const UTILITY_BANNER_FILE =
  "https://mint-store-metadata.s3.us-east-2.amazonaws.com/lostin/utility-thumbnails/lostin-utility-banner.jpeg";

export default {
  HAS_UTILITY_PAGE,
  UTILITY_BANNER_BUTTON_LINK,
  UTILITY_SHOPIFY_SHOP,
  UTILITY_SHOPIFY_COLLECTION_ID,
  UTILITY_BANNER,
  UTILITY_BANNER_FILE,
};
