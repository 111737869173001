import { useState, useEffect } from "react";

export const useGetDomElement = (
  id: string,
  pollingDelay = 40
): HTMLElement | null => {
  const [element, setElement] = useState(
    (document && document.getElementById(id)) ?? null
  );
  useEffect(() => {
    if (element) {
      return;
    }
    const intervalId = setInterval(() => {
      const presentElement = (document && document.getElementById(id)) ?? null;
      if (presentElement) {
        setElement(presentElement);
        clearInterval(intervalId);
      }
    }, pollingDelay);

    return () => clearInterval(intervalId);
  }, []);

  return element;
};
