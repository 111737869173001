import { FunctionComponent } from "react";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

const Page: FunctionComponent<Props> = ({
  className,
  style = {},
  children,
}) => {
  return (
    <section className={className} style={style}>
      {children}
    </section>
  );
};

export default Page;
