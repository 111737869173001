import * as fcl from "@onflow/fcl";
import * as t from "@onflow/types";
import { Project, Blockchain, hasWalletType } from "config";
import { BlockchainConfig } from "types/blockchain";
import { FlowTx, FlowScript } from "lib/blockchain/flow/cadence";
import feathersClient from "lib/feathers";
import { Builder } from "./builder";
import { Flow, SmartContract, SmartContractWithSetup } from "types";
import { flowAPI } from ".";
import { AppUser } from "lib/store/slices/user-slice";

const FLOW_MAX_GAS_LIMIT = (Blockchain.NET_CONFIG as BlockchainConfig<"flow">)
  .FLOW_MAX_GAS_LIMIT;

export const flowAuthenticationEndpoints = (builder: Builder) => ({
  getNonce: builder.mutation<Flow.Nonce, { user: AppUser }>({
    async queryFn() {
      const data = await feathersClient.service("flow-nonce").create({});
      return { data };
    },
  }),

  login: builder.mutation<null, void>({
    async queryFn() {
      const auth = await fcl.authenticate();
      const service = auth.services.find(
        (services: { type: string }) => services.type === "account-proof"
      );

      if (service) {
        await feathersClient
          .service("account-proof")
          .create(auth)
          .catch(() => {
            console.warn("Account proof did not succeed.");
          });
      }

      return { data: null };
    },
  }),

  logout: builder.mutation<null, void>({
    async queryFn() {
      await fcl.unauthenticate();
      return { data: null };
    },
  }),

  /**
   * Check the wallet setup using the local client configuration.
   *
   * Also, check if the FUSD setup is required for the specific client.
   */
  checkWalletSetup: builder.query<
    Array<SmartContract & { isSetup: boolean }>,
    { address: string }
  >({
    async queryFn({ address }) {
      const smartContractSetupPromises = Project.SMART_CONTRACTS.map(
        async (smartContract) => {
          const script = FlowScript.isAccountSetup(smartContract);
          return fcl
            .send([
              fcl.script(fcl.cdc`${script}`),
              fcl.args([fcl.arg(address, t.Address)]),
            ])
            .then(fcl.decode) as boolean;
        }
      );

      if (hasWalletType("flow", "Blocto")) {
        smartContractSetupPromises.push(
          fcl
            .send([
              fcl.script(fcl.cdc`${FlowScript.isAccountSetupFUSD}`),
              fcl.args([fcl.arg(address, t.Address)]),
            ])
            .then(fcl.decode)
        );
      }

      const smartContractsSetup = await Promise.allSettled(
        smartContractSetupPromises
      );
      const data: SmartContractWithSetup[] = Project.SMART_CONTRACTS.map(
        (smartContract, index) => {
          const smartContractSetup = smartContractsSetup[index];
          return {
            ...smartContract,
            isSetup:
              smartContractSetup.status === "fulfilled" &&
              smartContractSetup.value,
          };
        }
      );

      if (hasWalletType("flow", "Blocto")) {
        const fusdSetup = smartContractsSetup[smartContractsSetup.length - 1];
        data.push({
          id: "FUSD",
          type: "FUSD",
          name: "FUSD",
          publicCollectionStoragePath: "/public/fusdBalance",
          address: await fcl.config().get("0xFUSD"),
          isSetup: fusdSetup.status === "fulfilled" && fusdSetup.value,
          setupScript: FlowTx.setupFUSD,
          providerPath: "/public/fusdBalance",
        });
      }

      return { data };
    },
    async onQueryStarted({ address }, { dispatch }) {
      try {
        dispatch(flowAPI.endpoints.registerWallet.initiate({ address }));
      } catch (e) {
        console.error(e);
      }
    },
  }),

  setupAccountForSmartContract: builder.mutation<
    null,
    {
      wallet: { id: number; address: string };
      smartContract: SmartContractWithSetup;
    }
  >({
    async queryFn({ smartContract }) {
      const txId = await fcl.send([
        fcl.transaction(
          fcl.cdc`${
            smartContract.setupScript ??
            FlowTx.Dapper.dapperSetupAccountScript(smartContract)
          }`
        ),
        fcl.payer(fcl.authz),
        fcl.proposer(fcl.authz),
        fcl.authorizations([fcl.authz]),
        fcl.limit(FLOW_MAX_GAS_LIMIT),
      ]);
      await fcl.tx(txId).onceSealed();
      return { data: null };
    },
    invalidatesTags: (_, __, { wallet: { id } }) => [
      { type: "FlowWallet", id },
    ],
    async onQueryStarted(
      { wallet, smartContract },
      { dispatch, queryFulfilled }
    ) {
      try {
        await queryFulfilled;
        dispatch(
          flowAPI.util.updateQueryData(
            "checkWalletSetup",
            {
              address: wallet.address,
            },
            (draft) => {
              const newlySetupSmartContract = draft.find(
                (cachedSmartContract) =>
                  cachedSmartContract.name === smartContract.name
              );
              if (newlySetupSmartContract) {
                newlySetupSmartContract.isSetup = true;
              }
            }
          )
        );
      } catch (e) {
        console.error(e);
      }
    },
  }),
});
