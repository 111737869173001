import { flowAPI } from "lib/store/api/flow";
import { ChangeWalletButtonsUI } from "../ui/ChangeWalletButtonsUI";

export function ChangeWalletButtonsFlowConnector({
  displayChangeWalletButton,
  displayConnectWallet,
  currentOwnerAddress,
  currentWalletAddress,
  customPickemInnerText,
}: {
  displayChangeWalletButton: boolean;
  displayConnectWallet: boolean;
  currentOwnerAddress?: string;
  currentWalletAddress?: string;
  customPickemInnerText?: string;
}) {
  const { data: status } = flowAPI.useGetBlockchainStatusQuery();

  const [login] = flowAPI.useLoginMutation();
  const [logout] = flowAPI.useLogoutMutation();

  const onLogin = async () => {
    await logout();
    await login();
  };

  return (
    <ChangeWalletButtonsUI
      isUnderMaintenance={status?.isBlockchainUnderMaintenance ?? false}
      onLogin={onLogin}
      displayChangeWalletButton={displayChangeWalletButton}
      displayConnectWallet={displayConnectWallet}
      currentOwnerAddress={currentOwnerAddress}
      currentWalletAddress={currentWalletAddress}
      customPickemInnerText={customPickemInnerText}
    />
  );
}
