import LoginSrc from "../img/nfg-background-V2.jpeg";
import LogoSvg from "../img/NFT-white.png";
import LogoPng from "../img/gleaguelogo.png";

const HTML_TITLE = "G League Wallet Viewer";
const TEAM_CITY = "NBA G League";
const TEAM_NAME = "NBA G League";
const COPYRIGHT = "NBA G League Ignite NFT Collection";
const LOGIN_SRC = LoginSrc;
const LOGO_SVG_SRC = LogoSvg;
const LOGO_PNG_SRC = LogoPng;

export default {
  HTML_TITLE,
  TEAM_CITY,
  TEAM_NAME,
  COPYRIGHT,
  LOGIN_SRC,
  LOGO_SVG_SRC,
  LOGO_PNG_SRC,
};
