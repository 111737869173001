import {
  Project,
  Blockchain,
  hasWalletType,
  getPolygonUniqueWalletType,
} from "config";
import { useState, useEffect, useContext } from "react";
import { useAppDispatch, useAppSelector } from "lib/store/hooks";
import { selectAppUser, selectEthUser } from "lib/store/slices/user-slice";
import { selectError } from "lib/store/slices/error-slice";
import { ethAPI } from "lib/store/api/eth";
import {
  getMetaMask,
  setEthConnectingWalletProvider,
  walletProvider,
} from "lib/store/api/eth/web3";
import useIsMobile from "hooks/useIsMobile";

import { AppContext } from "context/AppContext";
import { getChainConfig } from "config/global/blockchain/polygon";

export function SessionUpdates() {
  const appContext = useContext(AppContext) as AppContext;
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectEthUser);
  const isMobile = useIsMobile();

  //INITIAL SETUP
  useEffect(() => {
    if (
      Blockchain.BLOCKCHAIN_NAME === "polygon" &&
      Project.WALLET_TYPE.length === 1
    ) {
      setEthConnectingWalletProvider(getPolygonUniqueWalletType());
    }
  }, []);

  //TRY LOGIN WITH SESSION
  const [triedLogin, setTriedLogin] = useState<boolean>(false);
  const [userAuthenticated, setUserAuthenticated] = useState<boolean>(
    user?.address != null || user?.address !== ""
  );
  const userId = useAppSelector(selectAppUser)?.id?.toString();
  const [onGetWalletList] = ethAPI.useLazyGetWalletListQuery();

  useEffect(() => {
    if (userId && !triedLogin) {
      const getWallets = async () => {
        setTriedLogin(true);
        const wallets = await onGetWalletList({ userId }).unwrap();
        if (wallets === undefined || wallets.length === 0) return;
        switch (localStorage.getItem("walletProvider")) {
          case "blocto":
            dispatch(ethAPI.endpoints.tryLoginWithBlocto.initiate());
            break;
          case "metamask":
            dispatch(
              ethAPI.endpoints.tryLoginWithMetaMask.initiate({ wallets })
            );
            break;
          default:
            break;
        }
      };

      getWallets();
    }
  }, [userId]);

  //GET METAMASK UPDATES
  if (hasWalletType("polygon", "MetaMask")) {
    const [ethLogin] = ethAPI.useLoginMutation();
    const [ethLogout] = ethAPI.useLogoutMutation();

    //account specific updates
    useEffect(() => {
      const initializeBlockchainConnections = async () => {
        const { metamaskProvider, metamaskWeb3 } = await getMetaMask();
        if (!metamaskProvider || !metamaskWeb3) return;

        metamaskProvider.on("accountsChanged", async (_accounts: unknown) => {
          const accounts = _accounts as Array<string>;
          if (user != null && walletProvider === "MetaMask") {
            if (accounts.length === 0) {
              ethLogout({ isDisconnecting: true });
            } else {
              if (user.address !== accounts[0]) {
                ethLogout({ isDisconnecting: true });
                setEthConnectingWalletProvider("MetaMask");
                ethLogin();
              }
            }
          }
        });

        metamaskProvider.on("chainChanged", (chainId) => {
          if (chainId == metamaskWeb3.utils.toHex(getChainConfig().chainId)) {
            appContext.changeChain.callbacks.onSuccess();
            appContext.changeChain.callbacks.setOnSuccess(() => () => {});
            appContext.changeChain.display.setIsVisible(false);
          }
        });
      };

      initializeBlockchainConnections().catch();

      return () => {
        const uninitializeBlockchainConnections = async () => {
          const { metamaskProvider } = await getMetaMask();
          if (metamaskProvider == null) return;
          metamaskProvider.removeAllListeners("accountsChanged");
          metamaskProvider.removeAllListeners("chainChanged");
        };

        uninitializeBlockchainConnections().catch();
      };
    }, [user, appContext.changeChain.callbacks.onSuccess]);

    //user specific updates
    useEffect(() => {
      if (user?.loading) {
        appContext.alert.content.setData({
          title: "Connecting to Metamask",
          body: "Please confirm the request in your Metamask wallet",
          type: "info",
          isLoading: true,
        });
        appContext.alert.display.setIsVisible(true);
      } else {
        if (
          appContext.alert.display.isVisible &&
          appContext.alert.content.data?.isLoading
        ) {
          appContext.alert.display.setIsVisible(false);
        }
      }

      if (
        (user?.address === null || user?.address === "") &&
        userAuthenticated
      ) {
        //user has been disconnected from wallet
        //let the user know next time they are connected
        setUserAuthenticated(false);
      }

      //show wallet connected screen upon user wallet update
      if (user?.address && !userAuthenticated) {
        setUserAuthenticated(true);
        appContext.alert.content.setData({
          type: "success",
          title: "Wallet Connected",
          body: `You are now connected to your ${Project.WALLET_TYPE[0]} wallet: ${user.address}`,
        });
        appContext.alert.display.setIsVisible(true);
      }
    }, [user, userAuthenticated]);
  }

  //USER UPDATES FOR BLOCTO AND METAMASK
  useEffect(() => {
    if (Project.WALLET_TYPE?.length > 1) {
      if (user != null && walletProvider != null) {
        appContext.selectWallet.callbacks.onSuccess();
        appContext.selectWallet.callbacks.setOnSuccess(() => () => {});
      }
    }
  }, [user]);

  //METMASK ERROR UPDATES
  const error = useAppSelector(selectError);
  useEffect(() => {
    if (error === null) return;

    if (error.provider !== "MetaMask") return;

    const errorCode = error.code ? error.code.replace("-", "") : 0;

    switch (error.type) {
      case "login":
        switch (errorCode) {
          //tried to login with metamask but metamask extension is not installed
          case "32603":
            if (isMobile) {
              const url = `https://metamask.app.link/dapp/${window.location.host}`;
              appContext.alert.content.setData({
                type: "danger",
                title: "Login with MetaMask on the MetaMask app",
                body: `To login with MetaMask, please open the <a target='_blank' href='${url}'>MetaMask app</a> and login.`,
              });
            } else {
              appContext.alert.content.setData({
                type: "danger",
                title: "MetaMask extension not installed",
                body: "Please install the MetaMask extension to login. You can download it <a target='_blank' href='https://metamask.io/download'>here</a>.",
                btnTxt: "Refresh",
                onClick: () => {
                  //refresh the page to reload the metamask extension
                  //possible improvement: reload the page with a query param to immediately check for metamask extension
                  window.location.reload();
                },
              });
            }
            appContext.alert.display.setIsVisible(true);
            appContext.alert.callbacks.onOpen();
            break;
          //tried to login before but the metamask extension was closed
          case "32002":
            appContext.alert.content.setData({
              type: "danger",
              title: "Login by opening MetaMask extension",
              body: "The MetaMask extension window may already be open, but may not be visible.",
            });
            appContext.alert.display.setIsVisible(true);
            appContext.alert.callbacks.onOpen();
            break;
          //user denied login
          case "4001":
            appContext.alert.content.setData({
              type: "danger",
              title: "Login Denied",
              body: "You have denied the login request. Please try again and accept the request to login.",
            });
            appContext.alert.display.setIsVisible(true);
            appContext.alert.callbacks.onOpen();
            break;
          default:
            break;
        }
        break;
      case "transaction":
        switch (errorCode) {
          // user denied transaction
          case "4001":
            appContext.alert.content.setData({
              type: "danger",
              title: "Transaction Denied",
              body: "You have denied the transaction request. Please try again and confirm the transaction to continue.",
            });
            appContext.alert.display.setIsVisible(true);
            appContext.alert.callbacks.onOpen();
            break;
          // user was on the wrong chain
          case "4902":
            appContext.changeChain.otherBlockchain.setInfo(getChainConfig());
            appContext.changeChain.display.setIsVisible(true);
            break;
          default:
            break;
        }
        break;
      case "chain":
        switch (errorCode) {
          // user denied chain change
          case "4001":
            appContext.alert.content.setData({
              type: "danger",
              title: "Chain Change Denied",
              body: "You have denied the chain change request. Please try again and confirm the chain change to continue.",
            });
            appContext.alert.display.setIsVisible(true);
            appContext.alert.callbacks.onOpen();
            break;
          //user closed the change chain window
          case "32002":
            appContext.alert.content.setData({
              type: "danger",
              title: "Change chain by opening MetaMask extension",
              body: "The MetaMask extension window may already be open, but may not be visible.",
            });
            appContext.alert.display.setIsVisible(true);
            appContext.alert.callbacks.onOpen();
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }, [error]);

  return <></>;
}
