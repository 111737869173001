import React, {
  ChangeEvent,
  KeyboardEvent,
  RefObject,
  useState,
  useRef,
  cloneElement,
  useEffect,
  ReactElement,
} from "react";
import {
  ChevronDown,
  ChevronUp,
  MoreHorizontal,
  Search,
  XCircle,
} from "react-feather";

import styles from "./styles.module.scss";
import Field from "../FieldInput";

export type Selection = {
  value: string;
  key: number | string | null;
  display?: string;
  data?: unknown;
};

type SelectProps = {
  className?: string;
  optionListClassName?: string;
  children?: (React.ReactElement | false)[];
  selection?: Selection | null;
  defaultSelection?: string;
  value?: string;
  onSelect?: (selection: Selection | null) => void;
  placeholder?: string;
  search?: boolean;
  disabled?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  iconOnly?: boolean;
  menuPosition?: "right" | "left";
  maxWidth?: number | string;
  [x: string]: any;
  fieldInputClassname?: string;
};

const Dropdown: React.FunctionComponent<SelectProps> = ({
  iconOnly = false,
  className,
  optionListClassName,
  children,
  value,
  onChange,
  defaultSelection,
  onSelect,
  selection = null,
  disabled = false,
  search = false,
  placeholder,
  maxWidth,
  fieldInputClassname,
  ...rest
}: SelectProps) => {
  // States
  const [isFocused, setIsFocused] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [_value, setValue] = useState(value || "");
  const [searchTerm, setSearchTerm] = useState<string>("");

  const mapElementToSelection = (
    child: ReactElement | false
  ): Selection | null => {
    if (child === false) return null;
    return {
      key: child.key,
      value: child.props.value,
      display: child.props.display,
      data: child.props.data,
    };
  };

  const [_singleSelection, setSingleSelection] = useState<Selection | null>(
    children && Array.isArray(children)
      ? mapElementToSelection(
          children.find((x) => x && x.props.value === defaultSelection) ?? false
        )
      : null
  );
  // Refs
  const fieldRef: RefObject<HTMLInputElement> = useRef(null);
  const containerRef: RefObject<HTMLDivElement> = useRef(null);

  // Effects
  useEffect(() => {
    if (_singleSelection)
      setValue(_singleSelection.display || _singleSelection.value);
    if (onSelect) onSelect(_singleSelection);
  }, [_singleSelection]);

  useEffect(() => {
    if (value) setValue(value);
  }, [value]);

  useEffect(() => {
    if (selection === null) {
      setSingleSelection(null);
      return;
    }

    if (selection?.value !== _singleSelection?.value) {
      setSingleSelection(selection);
    }
  }, [selection]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsFocused(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef]);

  const incrementActiveIndex = () => {
    if (!children) return;

    const nextIndex = (activeIndex + 1) % children.length;
    setActiveIndex(nextIndex);
  };

  const decrementActiveIndex = () => {
    if (!children) return;

    let nextIndex = (activeIndex - 1) % children.length;
    if (nextIndex < 0) nextIndex = children.length - 1;
    setActiveIndex(nextIndex);
  };
  // Handlers

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      incrementActiveIndex();
    }
    if (event.key === "ArrowUp") {
      event.preventDefault();
      decrementActiveIndex();
    }
    if (event.key === "Enter") {
      event.preventDefault();
      onSelectOption(activeIndex);
    }

    if (!search) event.preventDefault();
  };

  const _onAppendIconClick = () => {
    if (search) {
      setSearchTerm("");
      setValue("");
      setActiveIndex(0);
      setSingleSelection(null);
    } else {
      setIsFocused(!isFocused);
    }
  };
  const _onInputClick = () => {
    if (search) {
      setIsFocused(true);
    } else {
      setIsFocused(!isFocused);
    }
  };

  const _onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setValue("");
    if (onChange) onChange(event);
  };

  const onSelectOption = (index: number) => {
    if (!children || !children[index]) return;

    // @ts-ignore
    if (children[index].props.onClick) {
      // @ts-ignore
      children[index].props.onClick();
      if (iconOnly) setIsFocused(false);
    } else {
      setSingleSelection(mapElementToSelection(children[index]));
    }
    if (fieldRef && fieldRef.current) fieldRef.current.blur();
  };

  // Rendering

  let rootClass = styles.holder;
  if (isFocused) rootClass += ` ${styles.focused}`;
  if (className) rootClass += ` ${className}`;

  let dataHolderClassName = styles.dataHolder;
  if (optionListClassName) dataHolderClassName += ` ${optionListClassName}`;
  if (iconOnly) dataHolderClassName += ` ${styles.iconOnly}`;

  const isOptionSelected = (value: string) => {
    return _singleSelection && _singleSelection.value === value;
  };
  const renderOption = (child: React.ReactElement | false, index: number) => {
    if (!child) return null;
    if (
      !search ||
      (search && searchTerm === "") ||
      child.props.display.toLowerCase().search(searchTerm.toLowerCase()) > -1
    ) {
      const augmentedProps = {
        key: child.key ?? index,
        onHover: () => setActiveIndex(index),
        active: activeIndex === index,
        selected: isOptionSelected(child.props.value),
        onClick: () => onSelectOption(index),
      };
      return cloneElement(child, augmentedProps);
    }
    return null;
  };
  const appendIcon =
    search && searchTerm !== null ? (
      <XCircle />
    ) : !isFocused ? (
      <ChevronDown onClick={() => setIsFocused(!isFocused)} />
    ) : (
      <ChevronUp onClick={() => setIsFocused(!isFocused)} />
    );

  const preprendIcon = search ? <Search /> : null;
  return (
    <div
      className={rootClass}
      ref={containerRef}
      onClick={() => (iconOnly ? setIsFocused(!isFocused) : null)}
      style={{
        maxWidth: maxWidth ?? "inherit",
        width: "100%",
      }}
    >
      {!iconOnly ? (
        <Field
          inputRef={fieldRef}
          placeHolder={placeholder}
          autoComplete="off"
          readOnly={!search}
          onClick={_onInputClick}
          disabled={disabled}
          value={_value || searchTerm}
          appendIcon={appendIcon}
          prependIcon={preprendIcon}
          onAppendIconClick={_onAppendIconClick}
          onChange={_onChange}
          onKeyDown={onKeyDown}
          onBlur={() => setIsFocused(false)}
          data-form-type="other"
          className={fieldInputClassname}
          {...rest}
        />
      ) : (
        <MoreHorizontal className={styles.icon} />
      )}
      {/*I really don't like this Dropdown/select really need a rework*/}
      {children &&
      children.length > 0 &&
      children.map(renderOption).filter((x) => x).length > 0 ? (
        <div className={dataHolderClassName}>{children.map(renderOption)}</div>
      ) : (
        <div className={styles.noData}> No Data</div>
      )}
    </div>
  );
};

export default Dropdown;
