import { getStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import styles from "../styles.module.scss";
import CollectiblesSection from "components/Widjets/Collectibles/CollectiblesSection";
import Collectible from "components/Widjets/Collectibles/Collectible";
import { FunctionComponent } from "react";
import { Ruleset, Utility } from "types";
import { useNavigate } from "react-router-dom";
import { GATED_ITEM_ROUTE, UTILITY_ROUTES } from "config/routes";
import { Lock } from "react-feather";
import { formatUsdWithSymbol } from "utils/utils";

type Props = {
  products: Utility.Product[];
  rulesets: Ruleset.Validation[] | undefined;
};

const getMinPrice = (product: Utility.Product) => {
  return parseFloat(product.priceRangeV2.minVariantPrice.amount);
};

const Products: FunctionComponent<Props> = ({ products, rulesets }: Props) => {
  const strings = getStrings("Pages", "Utility")[useLanguage()];
  const navigate = useNavigate();

  return (
    <section className={styles.sectionContainer}>
      <CollectiblesSection
        title={strings.merchandise.title}
        description={strings.merchandise.description}
        displayShowAll={true}
        onShowAll={() => navigate(UTILITY_ROUTES.PRODUCTS)}
      >
        {products.map((product, i) => {
          const ruleset =
            rulesets?.filter((rs) => rs.id === product.ruleSetId)[0] ?? null;
          const isAvailable = ruleset ? ruleset.ruleSetResult.isValid : false;

          return (
            <Collectible
              key={i}
              title={product.title ?? ""}
              description={formatUsdWithSymbol(getMinPrice(product))}
              imageSrc={product.featuredImage?.url ?? ""}
              onClick={() =>
                navigate(GATED_ITEM_ROUTE.replace(":handle", product.handle))
              }
              label={
                !isAvailable ? (
                  <>
                    <Lock size={10} /> {strings.locked}
                  </>
                ) : undefined
              }
            />
          );
        })}
      </CollectiblesSection>
    </section>
  );
};

export default Products;
