import Modal from "components/Widjets/Modal";
import { getStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import styles from "../styles.module.scss";
import { Check } from "react-feather";
import { SetupStatus } from "../connectors/SetupWizardFlowConnector";
import SmartContractDataTable from "./SmartContractDataTable";
import { SmartContractWithSetup } from "types";
import { ConnectSmartContractChip } from "./SmartContractChip";

type Props = {
  onSetup: (smartContract: SmartContractWithSetup) => void;
  onClose: () => void;
  walletType: string | null;
  insideSetupModal: boolean;
  onNextStepButtonClick?: () => void;
  setupStatus?: SetupStatus;
  requiredSmartContracts?: SmartContractWithSetup[];
};

export function SmartContractStep(props: Props) {
  const loading = props.setupStatus?.isLoading;
  const localizedStrings = getStrings("Modals", "Wizard")[useLanguage()]
    .smartContract;

  const handleActionClick = loading
    ? undefined
    : props.insideSetupModal
    ? props.onNextStepButtonClick
    : undefined;

  return (
    <Modal
      clickOutsideToClose={false}
      isVisible
      onClose={loading ? undefined : props.onClose}
      modalClassName={styles.genericModal}
      btnTxt={
        props.insideSetupModal
          ? localizedStrings.continueBtn
          : localizedStrings.closeBtn
      }
      onActionClick={handleActionClick}
    >
      <h1>{localizedStrings.title}</h1>
      <p>{localizedStrings.subtitle}</p>

      {props.requiredSmartContracts &&
      props.requiredSmartContracts.length > 0 ? (
        <SmartContractDataTable
          hoverEffect={false}
          items={props.requiredSmartContracts ?? []}
          headers={[
            {
              text: localizedStrings.nameHeader,
              value: "name",
              display: (smartContract) => smartContract.name,
            },
            {
              text: localizedStrings.statusHeader,
              value: "isSetup",
              connectChip: {
                setup: (smartContract) => props.onSetup(smartContract),
                setupStatus: props.setupStatus!,
                text: {
                  loadingText: localizedStrings.connecting,
                  notLoadingText: localizedStrings.notConnected,
                },
              },
              display: (wallet) => {
                return (
                  <>
                    {wallet.isSetup && (
                      <div className="d-flex">
                        <span className="mr-2">
                          {localizedStrings.connected}
                        </span>
                        <Check />
                      </div>
                    )}
                  </>
                );
              },
            },
          ]}
        />
      ) : (
        <ConnectSmartContractChip
          onSetup={props.onSetup}
          contract={props.requiredSmartContracts?.[0]}
          setupStatus={props.setupStatus}
          onClose={props.onClose}
        />
      )}
    </Modal>
  );
}
