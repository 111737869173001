import { ItemOption, PanelHeader } from "./index";
import PanelOption from "./options";
import styles from "./style.module.scss";

const Panel = <T extends unknown>({
  options,
  onSelect,
  selectedItems,
  isOpen = false,
}: PanelHeader<T> & { selectedItems: ItemOption[] }) => {
  return (
    <div className={styles.content}>
      {isOpen &&
        options.map((option, idx) => (
          <div
            key={`${option.key}_${option.value}`}
            className={styles.optionWrapper}
          >
            <PanelOption
              onSelect={onSelect}
              option={option}
              selectedItems={selectedItems}
            />
            {idx !== options.length - 1 && <hr className={styles.separator} />}
          </div>
        ))}
    </div>
  );
};
export default Panel;
