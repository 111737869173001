import Modal from "components/Widjets/Modal";
import { getStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import styles from "../styles.module.scss";
import parse from "html-react-parser";

type Props = {
  isLoading?: boolean;
  onMainButtonClick: () => void;
  onClose: () => void;
  walletType: WalletType | null;
};

export function RegisterStep(props: Props) {
  const localizedStrings = getStrings("Modals", "Wizard")[useLanguage()];
  return (
    <Modal
      clickOutsideToClose={false}
      isVisible
      onClose={props.onClose}
      modalClassName={styles.modal}
      btnTxt={localizedStrings.register.actionBtnTxt}
      onActionClick={() => props.onMainButtonClick()}
      btnLoading={props.isLoading}
    >
      {props.walletType && (
        <>
          <h1>
            {localizedStrings.register.title.replaceAll(
              "$WalletType",
              props.walletType
            )}
          </h1>
          <p>
            {parse(
              localizedStrings.register.subheader.replaceAll(
                "$WalletType",
                props.walletType
              )
            )}
          </p>
        </>
      )}
    </Modal>
  );
}
