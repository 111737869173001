import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import { createLogger } from "redux-logger";

import {
  authenticationAPI,
  setupAuthenticationListeners,
} from "./api/authentication-api";
import { userReducer } from "./slices/user-slice";
import { errorReducer } from "./slices/error-slice";

import { setupFlowListeners, flowAPI } from "./api/flow";
import { ethAPI } from "./api/eth";
import { rulesetAPI } from "./api/ruleset";
import { utilityAPI } from "./api/utility";
import {
  initializeReduxStoreEnhancer,
  rtkQueryErrorLogger,
} from "../../sentry";

// Colors for the logger
const RED = "\u001b[31m";
const GREEN = "\u001b[32m";
const YELLOW = "\u001b[33m";
const BLUE = "\u001b[34m";
const CYAN = "\u001b[36m";
const RESET = "\u001b[0m";

export const logger = createLogger({
  predicate: (_, { type, error }) => {
    const filteredOutTypes = ["__rtkq/focused", "__rtkq/unfocused"];

    // rtk-query outputs "rejected" logs when a query is skipped (usually because it is already being done)
    // We don't need to polute the console with these internal non-errors.
    const isSkipped = error?.name === "ConditionError";

    return !isSkipped && !filteredOutTypes.includes(type);
  },
  titleFormatter: ({ type, meta }) => {
    const endpoint = meta?.arg?.endpointName;

    const endpointColor = type.includes("pending")
      ? YELLOW
      : type.includes("fulfilled")
      ? GREEN
      : type.includes("rejected")
      ? RED
      : BLUE;

    return (
      `${CYAN}action` +
      `${endpoint ? `:${endpointColor}${endpoint} ` : " "}` +
      `${RESET}${type}`
    );
  },
  collapsed: true,
  diff: true,
});

export const store = configureStore({
  reducer: {
    users: userReducer,
    errors: errorReducer,
    [authenticationAPI.reducerPath]: authenticationAPI.reducer,
    [flowAPI.reducerPath]: flowAPI.reducer,
    [ethAPI.reducerPath]: ethAPI.reducer,
    [rulesetAPI.reducerPath]: rulesetAPI.reducer,
    [utilityAPI.reducerPath]: utilityAPI.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware()
      .concat(authenticationAPI.middleware)
      .concat(flowAPI.middleware)
      .concat(ethAPI.middleware)
      .concat(rulesetAPI.middleware)
      .concat(utilityAPI.middleware)
      .concat(rtkQueryErrorLogger);

    if (process.env.NODE_ENV === "development") {
      // return middleware.concat(logger);
      return middleware;
    } else {
      return middleware;
    }
  },
  enhancers: [initializeReduxStoreEnhancer()],
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
setupAuthenticationListeners(store.dispatch);
setupFlowListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
