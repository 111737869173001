import { useState, createContext } from "react";
import { getChainConfig } from "config/global/blockchain/polygon";

type AlertType = "success" | "info" | "warning" | "danger";

interface Alert {
  type: AlertType;
  title: string;
  body: string;
  isLoading?: boolean;
  btnTxt?: string;
  onClick?: () => void;
}

interface AppContext {
  wizard: {
    display: {
      isVisible: boolean;
      setIsVisible: (val: boolean) => void;
    };
  };
  selectWallet: {
    display: {
      isVisible: boolean;
      setIsVisible: (val: boolean) => void;
    };
    callbacks: {
      onClose: () => void;
      setOnClose: (val: () => void) => void;
      onSuccess: () => void;
      setOnSuccess: (val: () => void) => void;
    };
  };
  changeChain: {
    display: {
      isVisible: boolean;
      setIsVisible: (val: boolean) => void;
    };
    otherBlockchain: {
      info: Chain;
      setInfo: (val: Chain) => void;
    };
    callbacks: {
      onClose: () => void;
      setOnClose: (val: () => void) => void;
      onSuccess: () => void;
      setOnSuccess: (val: () => void) => void;
    };
  };
  alert: {
    display: {
      isVisible: boolean;
      setIsVisible: (val: boolean) => void;
    };
    content: {
      data: Alert | undefined;
      setData: (val: Alert | undefined) => void;
    };
    callbacks: {
      onClose: () => void;
      setOnClose: (val: () => void) => void;
      onOpen: () => void;
      setOnOpen: (val: () => void) => void;
    };
  };
}

const AppContext = createContext<AppContext | null>(null);

const AppProvider = ({ children }: { children: JSX.Element }) => {
  // WIZARD
  const [displayWizardModal, setDisplayWizardModal] = useState<boolean>(false);

  // SELECT WALLET
  const [displaySelectWalletModal, setDisplaySelectWalletModal] =
    useState<boolean>(false);
  const [onSelectWalletSuccess, setOnSelectWalletSuccess] = useState<
    () => void
  >(() => () => {});
  const [onChangeChainClose, setOnChangeChainClose] = useState<() => void>(
    () => () => {}
  );
  const [onChangeChainSuccess, setOnChangeChainSuccess] = useState<() => void>(
    () => () => {}
  );

  // CHANGE BLOCKCHAIN
  const [displaychangeChain, setDisplaychangeChain] = useState<boolean>(false);
  const [otherBlockchainInfo, setOtherBlockchainInfo] = useState<Chain>(
    getChainConfig()
  );
  const [onSelectWalletClose, setOnSelectWalletClose] = useState<() => void>(
    () => () => {}
  );

  // ALERT
  const [displayAlert, setDisplayAlert] = useState<boolean>(false);
  const [alert, SetAlert] = useState<Alert | undefined>();
  const [onAlertClose, setOnAlertClose] = useState<() => void>(() => () => {});
  const [onAlertOpen, setOnAlertOpen] = useState<() => void>(() => () => {});

  const data = {
    wizard: {
      display: {
        isVisible: displayWizardModal,
        setIsVisible: setDisplayWizardModal,
      },
    },
    selectWallet: {
      display: {
        isVisible: displaySelectWalletModal,
        setIsVisible: setDisplaySelectWalletModal,
      },
      callbacks: {
        onClose: onSelectWalletClose,
        setOnClose: setOnSelectWalletClose,
        onSuccess: onSelectWalletSuccess,
        setOnSuccess: setOnSelectWalletSuccess,
      },
    },
    changeChain: {
      display: {
        isVisible: displaychangeChain,
        setIsVisible: setDisplaychangeChain,
      },
      otherBlockchain: {
        info: otherBlockchainInfo,
        setInfo: setOtherBlockchainInfo,
      },
      callbacks: {
        onClose: onChangeChainClose,
        setOnClose: setOnChangeChainClose,
        onSuccess: onChangeChainSuccess,
        setOnSuccess: setOnChangeChainSuccess,
      },
    },
    alert: {
      display: {
        isVisible: displayAlert,
        setIsVisible: setDisplayAlert,
      },
      content: {
        data: alert,
        setData: SetAlert,
      },
      callbacks: {
        onClose: onAlertClose,
        setOnClose: setOnAlertClose,
        onOpen: onAlertOpen,
        setOnOpen: setOnAlertOpen,
      },
    },
  };

  return <AppContext.Provider value={data}>{children}</AppContext.Provider>;
};

export { AppContext, AppProvider };
