import GoBackBtn from "components/AppComponent/GoBackBtn";
import Collectible from "components/Widjets/Collectibles/Collectible";
import CollectiblesInfiniteSection from "components/Widjets/Collectibles/CollectiblesInfiniteSection";
import { getStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import ErrorPage from "pages/ErrorPage/ErrorPage";
import { FunctionComponent } from "react";
import { Lock } from "react-feather";
import styles from "../styles.module.scss";

type Props = {
  title: string;
  description: string;
  hideDescription?: boolean;
  isFetching: boolean;
  isError: boolean;
  data?: Item[];
  dataLength: number;
  hasMore: boolean;
  onLoadMore: () => void;
};

export type Item = {
  title: string;
  description?: string;
  imageSrc?: string;
  onClick?: () => void;
  isAvailable: boolean;
};

const UtilitySubpageUI: FunctionComponent<Props> = ({
  title,
  description,
  hideDescription,
  isFetching,
  isError,
  data,
  dataLength,
  hasMore,
  onLoadMore,
}) => {
  const strings = getStrings("Pages", "Utility")[useLanguage()];

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div className={styles.utilityPage}>
      <div className={styles.container}>
        <GoBackBtn className="mt-5 mb-4" />
        <hr className="mb-4" />
        <section className={styles.sectionContainer}>
          <CollectiblesInfiniteSection
            hasMore={hasMore}
            dataLength={dataLength}
            loading={isFetching}
            title={title}
            description={description}
            onLoadMore={onLoadMore}
          >
            {data?.map((item, i) => {
              return (
                <Collectible
                  key={i}
                  title={item.title}
                  description={item.description}
                  imageSrc={item.imageSrc}
                  onClick={() => item.onClick?.()}
                  hideDescription={hideDescription}
                  label={
                    !item.isAvailable && (
                      <>
                        <Lock size={10} /> {strings.locked}
                      </>
                    )
                  }
                />
              );
            })}
          </CollectiblesInfiniteSection>
        </section>
      </div>
    </div>
  );
};

export default UtilitySubpageUI;
