import { ClientConfig } from "config";
import { AvailableLanguage } from "lib/contexts/LanguageContext";
import { FunctionComponent } from "react";
import { Flow } from "types";
import MarketplaceUI from "../../ui";

type Props = {
  strings: ClientConfig["Strings"]["Pages"]["ArtistMarketplace"][AvailableLanguage];
  editions: Flow.EditionForSale[];
  onSearchChange: () => void;
  total: number;
  loading: boolean;
  onLoadMore: () => void;
  searchTerm: string;
};

const MerchantUI: FunctionComponent<Props> = ({
  strings,
  editions = [],
  total,
  onLoadMore,
  onSearchChange,
  searchTerm,
  loading,
}) => {
  return (
    <MarketplaceUI.Editions
      loading={loading}
      onSearchChange={onSearchChange}
      strings={strings}
      items={editions}
      total={total}
      onLoadMore={onLoadMore}
      searchTerm={searchTerm}
    />
  );
};

export default MerchantUI;
