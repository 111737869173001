import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { Provider as StoreProvider } from "react-redux";
import { LanguageProvider } from "./lib/contexts/LanguageContext";
import App from "./App";
import "./index.scss";
import "./utilities.scss";
import { store } from "lib/store";
import { initializeSentry } from "./sentry";

// Start the mocking conditionally.
if (process.env.NODE_ENV === "development") {
  const { worker } = require("./network-mocks");
  worker.start({ waitUntilReady: true, onUnhandledRequest: "bypass" });
}

initializeSentry();
ReactDOM.render(
  <StrictMode>
    <StoreProvider store={store}>
      <LanguageProvider>
        <App />
      </LanguageProvider>
    </StoreProvider>
  </StrictMode>,
  document.getElementById("root")
);
