import Modal from "components/Widjets/Modal";
import {
  AlertCircle,
  AlertOctagon,
  AlertTriangle,
  Info,
  CheckCircle,
} from "react-feather";
import LoaderCircle from "components/Widjets/LoaderCircle";
import commonStyles from "../common.module.scss";
import styles from "./style.module.scss";
import { useContext } from "react";
import { AppContext } from "context/AppContext";
import parse from "html-react-parser";

export function AlertModal() {
  const appContext = useContext(AppContext) as AppContext;

  const renderIcon = () => {
    let icon;
    switch (appContext.alert.content.data?.type) {
      case "success":
        icon = <CheckCircle size={50} />;
        break;
      case "info":
        icon = <Info size={50} />;
        break;
      case "warning":
        icon = <AlertTriangle size={50} />;
        break;
      case "danger":
        icon = <AlertOctagon size={50} />;
        break;
      default:
        icon = <AlertCircle size={50} />;
        break;
    }
    return <div>{icon}</div>;
  };

  return (
    <Modal
      clickOutsideToClose={false}
      isVisible={appContext.alert.display.isVisible}
      onClose={() => {
        appContext.alert.display.setIsVisible(false);
        appContext.alert.callbacks.onClose();
      }}
      modalClassName={commonStyles.genericModal}
      className={styles.overlay}
      btnTxt={appContext.alert.content.data?.btnTxt}
      onActionClick={
        appContext.alert.content.data?.onClick &&
        appContext.alert.content.data.onClick
      }
    >
      {appContext.alert.content.data?.isLoading ? (
        <LoaderCircle size={50} />
      ) : (
        renderIcon()
      )}

      <h3 className="mt-3 mb-2">{appContext.alert.content.data?.title}</h3>
      <h5 className="ma-0 text-center">
        {appContext.alert.content.data?.body &&
          parse(appContext.alert.content.data?.body)}
      </h5>
    </Modal>
  );
}
