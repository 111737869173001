import * as Blockchain from "../../../global-blockchain-config";
import { SmartContract } from "types";

const MERCHANT_LIST = {
  Testnet: [{ id: "29", address: "0x8c0c210d007efa25" }],
  Mainnet: [{ id: "3", address: "0xdac587e8f257a6f2" }],
};

const MINT_STORE_MARKET = {
  Testnet: { id: 1, address: "0x8c0c210d007efa25" },
  Mainnet: { id: 1, address: "0xdac587e8f257a6f2" },
};

const WALLET_VIEWER_ID = {
  Testnet: 15,
  Mainnet: 10,
};
const KYC_MERCHANT_ID = {
  Testnet: 1,
  Mainnet: 1,
};

const SMART_CONTRACTS: SmartContract[] = [
  {
    id: "6a291653-a05e-4778-9d4a-a1f898413f3e",
    name: "MUMGJ",
    type: "Edition",
    address:
      Blockchain.NET === "Testnet"
        ? "0x985d410b577fd4a1"
        : "0x20187093790b9aef",
    publicCollectionStoragePath: "MUMGJCollectionPublic",
    providerPath: "MUMGJCollection",
  },
];

export {
  MERCHANT_LIST,
  MINT_STORE_MARKET,
  WALLET_VIEWER_ID,
  KYC_MERCHANT_ID,
  SMART_CONTRACTS,
};
