const HAS_UTILITY_PAGE = true;

const UTILITY_SHOPIFY_SHOP = "mint-nft-plus.myshopify.com";
const UTILITY_SHOPIFY_COLLECTION_ID = 267445960804;

const UTILITY_BANNER = "https://source.unsplash.com/random/1280x720/?gate";
const UTILITY_BANNER_PLAYLIST =
  "https://source.unsplash.com/random/1280x720/?music";
const UTILITY_BANNER_VIDEO =
  "https://source.unsplash.com/random/1280x720/?cinema";
const UTILITY_BANNER_FILE =
  "https://source.unsplash.com/random/1280x720/?folders";

export default {
  HAS_UTILITY_PAGE,
  UTILITY_SHOPIFY_SHOP,
  UTILITY_SHOPIFY_COLLECTION_ID,
  UTILITY_BANNER,
  UTILITY_BANNER_PLAYLIST,
  UTILITY_BANNER_VIDEO,
  UTILITY_BANNER_FILE,
};
