import { NET } from "../blockchain";

const SERVER_API_URL =
  NET === "Testnet"
    ? "https://dev-testnet-api.mint.store"
    : "https://v2.api-wallet.mint.store";

const CLAIM_API_URL =
  NET === "Testnet"
    ? "https://dev.api.mint.store"
    : "https://prod.api.mint.store";

export { SERVER_API_URL, CLAIM_API_URL };
