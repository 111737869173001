import { FC } from "react";
import { Config } from "../../../../config";
import { NftPreview } from "../types";
import styles from "../ui/style.module.scss";

type Props = {
  nft?: NftPreview;
  restrictionBasedOnEditionName?: boolean;
};

const DisplayPfpTraits: FC<Props> = ({
  nft,
  restrictionBasedOnEditionName,
}) => {
  if (restrictionBasedOnEditionName) {
    return (
      <div className={styles.pfpTraits}>
        {Object.entries(nft?.attributes ?? {})
          .filter(
            (attribute) =>
              attribute[0] === "Points" || attribute[0] === "PrizeEligibility"
          )
          .map(([name, value], index) => (
            <div key={index} className={styles.pfpTrait}>
              <p>
                {name === "PrizeEligibility" ? "Prize Eligibility" : name}
                <br />
                <span>{value}</span>
              </p>
            </div>
          ))}
      </div>
    );
  } else {
    return (
      <div className={styles.pfpTraits}>
        {Object.entries(nft?.attributes ?? {})
          .sort(([attributeName1], [attributeName2]) =>
            attributeName1 > attributeName2 ? 1 : -1
          )
          .map(([name, value], index) => (
            <div key={index} className={styles.pfpTrait}>
              <p>
                {name}
                <br />
                <span>{value}</span>
              </p>
            </div>
          ))}
      </div>
    );
  }
};

export const PfpTraits: FC<Props> = ({
  nft,
  restrictionBasedOnEditionName,
}) => {
  if (nft?.contractType !== "PFP") return null;
  switch (Config.Client.HIDE_PFP_TRAITS) {
    case true:
      return restrictionBasedOnEditionName ? (
        <DisplayPfpTraits
          nft={nft}
          restrictionBasedOnEditionName={restrictionBasedOnEditionName}
        />
      ) : null;
    default:
      return <DisplayPfpTraits nft={nft} />;
  }
};
