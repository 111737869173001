import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from "react";
import styles from "./styles.module.scss";
import { Check } from "react-feather";
import { LoaderCircle } from "../../index";

type Props = {
  className?: string;
  children?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  selected?: boolean;
  readOnly?: boolean;
  naked?: boolean;
  onChange?: (selected: boolean) => void;
};
const Checkbox: FunctionComponent<Props> = ({
  className,
  children,
  disabled = false,
  loading = false,
  selected = false,
  readOnly = false,
  naked = true,
  onChange,
}: Props) => {
  const [_selected, setSelected] = useState(selected);

  // Effects

  useEffect(() => {
    setSelected(selected);
  }, [selected]);

  // Handlers

  const onClick = () => {
    if (readOnly) return;

    setSelected(!_selected);
    if (onChange) onChange(!_selected);
  };

  // Rendering

  let rootClass = styles.checkbox;
  if (naked) rootClass += ` ${styles.naked}`;
  if (_selected) rootClass += ` ${styles.selected}`;
  if (className) rootClass += ` ${className}`;

  return (
    <button
      style={{ cursor: readOnly ? "default" : "pointer" }}
      disabled={disabled || loading}
      className={rootClass}
      onClick={onClick}
    >
      {loading ? (
        <LoaderCircle className={`loader ${styles.loader}`} />
      ) : (
        <span>
          <Check />
        </span>
      )}
      {children}
    </button>
  );
};

export default Checkbox;
