const HAS_UTILITY_PAGE = true;

const UTILITY_SHOPIFY_SHOP = "mint-nft-plus.myshopify.com";
const UTILITY_SHOPIFY_COLLECTION_ID = 267445960804;

export default {
  HAS_UTILITY_PAGE,
  UTILITY_SHOPIFY_SHOP,
  UTILITY_SHOPIFY_COLLECTION_ID,
};
