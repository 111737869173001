import { Button } from "../../../../components";
import { getStrings } from "../../../../config";
import { useLanguage } from "../../../../lib/contexts/LanguageContext";

type Props = {
  onClick: () => void;
};
export function LoginButtonUI({ onClick }: Props) {
  const localizedStrings = getStrings("Pages", "Details")[useLanguage()];

  return <Button onClick={onClick}>{localizedStrings.button.login}</Button>;
}
