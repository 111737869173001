import LoginSrc from "../img/login-photo-white.png";
import Logo from "../img/logo.svg";
import { yearNow } from "utils/utils";

const HTML_TITLE = "UMG Wallet Viewer";
const TEAM_CITY = "Universal Music";
const TEAM_NAME = "Universal Music";
const COPYRIGHT_YEAR = "";
const COPYRIGHT = `Copyright ${yearNow} Universal Music Group N.V. All rights reserved.`;
const LOGIN_SRC = LoginSrc;
const LOGO_SVG_SRC = Logo;
const LOGO_PNG_SRC = Logo;

export default {
  HTML_TITLE,
  TEAM_CITY,
  COPYRIGHT_YEAR,
  TEAM_NAME,
  COPYRIGHT,
  LOGIN_SRC,
  LOGO_SVG_SRC,
  LOGO_PNG_SRC,
};
