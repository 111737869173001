import { getStrings } from "config";
import { GATED_VIDEO_ROUTE } from "config/routes";
import { useLanguage } from "lib/contexts/LanguageContext";
import { useRulesets } from "lib/store/api/ruleset/hooks";
import { useUtilityVideos } from "lib/store/api/utility/hooks";
import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import UtilitySubpageUI, { Item } from "./ui";

const FilesPage: FunctionComponent = () => {
  const strings = getStrings("Pages", "Utility")[useLanguage()];
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(0);
  const { data, total, isFetching, isError, rulesetIds } =
    useUtilityVideos(page);
  const { data: rulesets, isFetching: isRulesetsFetching } =
    useRulesets(rulesetIds);

  const items: Item[] | undefined = data.map((video) => {
    const ruleset = rulesets?.find((rs) => rs.id === video.rulesetId);
    const isAvailable = !!ruleset?.ruleSetResult.isValid;

    return {
      title: video.name ?? "",
      description: video.description ?? "",
      imageSrc: video.thumbnail ?? "",
      onClick: () => {
        navigate(GATED_VIDEO_ROUTE.replace(":id", video.id));
      },
      isAvailable,
    };
  });

  return (
    <UtilitySubpageUI
      title={strings.videos.title}
      description={strings.videos.description}
      isFetching={isFetching || isRulesetsFetching}
      isError={isError}
      data={items}
      dataLength={data.length ?? 0}
      hasMore={total > (data.length ?? 0)}
      onLoadMore={() => setPage((p) => p + 1)}
    />
  );
};

export default FilesPage;
