import LoginSrc from "../img/NBPA_PlayerCon_Abstract_Rotate.png";
import LogoSvg from "../img/NBPA_PlayerCon_Lockup_White.png";
const LogoPng = LogoSvg;

const HTML_TITLE = "NBPA PlayerCon Wallet Viewer";
const TEAM_CITY = "NBPA PlayerCon";
const TEAM_NAME = "NBPA PlayerCon";
const COPYRIGHT = "NBPA PlayerCon";
const LOGIN_SRC = LoginSrc;
const LOGO_SVG_SRC = LogoSvg;
const LOGO_PNG_SRC = LogoPng;

export default {
  HTML_TITLE,
  TEAM_CITY,
  TEAM_NAME,
  COPYRIGHT,
  LOGIN_SRC,
  LOGO_SVG_SRC,
  LOGO_PNG_SRC,
};
