import { FullNft } from "../../../../../../types/app-model/flow";
import { Blockchain, Config, Project, CLIENT } from "config";
import feathersClient from "../../../../../feathers";
const MERCHANT_IDS = Project.MERCHANT_LIST[Blockchain.NET].map((x) => x.id);

async function getDefaultLowestPriceForEdition(
  nft?: FullNft
): Promise<number | null> {
  if (!Config.MarketPlace.hasMarketplace || !nft || !nft.editionFID) {
    return null;
  }

  const { data } = await feathersClient.service("item-for-sale").find({
    query: {
      editionFID: nft.editionFID,
      $sort: { price: 1 },
      $limit: 1,
      merchantFID: MERCHANT_IDS,
    },
  });
  const item = data.length > 0 ? data[0] : null;
  return item?.price ?? null;
}

async function getPickemLowestPrice(nft?: FullNft): Promise<number | null> {
  if (!Config.MarketPlace.hasMarketplace || !nft || !nft.attributes) {
    return null;
  }

  const attribute = nft?.attributes?.streak;
  if (!attribute) return null;

  const { minimumPrice } = await feathersClient
    .service(`item-for-sale/streak`)
    .get(attribute);

  return minimumPrice;
}

export function getLowestPriceForEdition(
  nft?: FullNft
): Promise<number | null> {
  switch (CLIENT) {
    case "pickem":
    case "pickem-marketplace":
      return getPickemLowestPrice(nft);
    default:
      return getDefaultLowestPriceForEdition(nft);
  }
}
