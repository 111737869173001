import { useAppSelector } from "lib/store/hooks";
import { selectAppUser } from "lib/store/slices/user-slice";
import { FunctionComponent } from "react";
import { NotFoundUI } from "./ui/NotFoundUI";

const NotFoundPage: FunctionComponent = () => {
  const user = useAppSelector(selectAppUser);
  return <NotFoundUI isLoggedIn={user != null} />;
};

export default NotFoundPage;
