import { FC } from "react";
import styles from "./styles.module.scss";

type Props = {
  src?: string;
  label?: string;
  name?: string;
  hasBorder?: boolean;
  className?: string;
  onClick?: () => void;
};

const RoundImgCard: FC<Props> = ({
  src,
  label,
  name,
  className,
  hasBorder = false,
  onClick,
}) => {
  const cardClasses = [styles.card];
  if (hasBorder) cardClasses.push(styles.hasBorder);
  if (onClick) cardClasses.push(styles.hasPointer);
  if (className) cardClasses.push(className);

  return (
    <div onClick={onClick} className={cardClasses.join(" ")}>
      <div
        className={styles.img}
        style={{
          backgroundImage: `url(${src})`,
        }}
      >
        {!src && <span>{name?.charAt(0).toUpperCase()}</span>}
      </div>
      <div className={styles.info}>
        <div className={styles.infoContainer}>
          <p className={styles.label}>{label}</p>
          <h4 className={styles.name}>{name}</h4>
        </div>
      </div>
    </div>
  );
};

export default RoundImgCard;
