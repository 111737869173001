import style from "./style.module.scss";
import React, { FunctionComponent, ReactNode, useState } from "react";
import { Volume2, VolumeX } from "react-feather";
type Props = {
  src?: string;
  onIconClick?: () => void;
  icon?: ReactNode;
  fullscreen?: boolean; // It's not really a full screen thing, for position of built-in close Icon
  fileType?: "Video" | "3D Object" | "Image" | "Audio";
  thumbnail?: string;
  audioSrc?: string;
  name?: string;
  className?: string;
  children?: ReactNode;
};

const AnimatedNFT: FunctionComponent<Props> = ({
  src,
  onIconClick,
  icon,
  fullscreen = false,
  fileType = "Video",
  thumbnail,
  audioSrc,
  name,
  className,
  children,
}: Props) => {
  const [isMuted, setIsMuted] = useState<boolean>(true);
  const _onIconClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (onIconClick) {
      onIconClick();
      e.stopPropagation();
    }
  };

  let rootClass = style.container;

  if (fileType === "3D Object") rootClass += ` ${style.glbContainer}`;
  if (fullscreen) rootClass += ` ${style.fullscreen}`;
  if (className) rootClass += ` ${className}`;

  const renderNFTFileType = () => {
    if (fileType === "3D Object") {
      // @ts-ignore
      return (
        <div className={style.glp}>
          {/*@ts-ignore */}
          <model-viewer
            ar
            ar-modes="scene-viewer quick-look"
            shadow-intensity="1"
            exposure="1"
            poster={thumbnail}
            src={src} // AR Android/Web
            autoplay
            auto-rotate
            camera-controls
            style={{ backgroundColor: "unset" }}
            muted
          >
            {fullscreen && (
              <div className={style.audioPlayer}>
                {isMuted ? (
                  <VolumeX onClick={() => setIsMuted(!isMuted)} />
                ) : (
                  <Volume2 onClick={() => setIsMuted(!isMuted)} />
                )}
                <video muted={isMuted} autoPlay>
                  <source src={audioSrc} type="audio/mp3" />
                </video>
              </div>
            )}
            {/*@ts-ignore */}
          </model-viewer>
        </div>
      );
    } else if (fileType === "Video") {
      return (
        <video
          className={style.videoTag}
          autoPlay
          loop
          muted
          playsInline
          poster={thumbnail}
        >
          <source placeholder={thumbnail} src={src} type="video/mp4" />
        </video>
      );
    } else if (fileType === "Audio") {
      return (
        <>
          <img className={style.thumbnail} src={thumbnail} alt={name} />
          <audio className={style.audioContainer} src={audioSrc} controls />
        </>
      );
    } else {
      return <img className={style.videoTag} src={src} />;
    }
  };

  return (
    <div className={rootClass}>
      {renderNFTFileType()}
      {children}
      {icon && (
        <div className={style.icon} onClick={_onIconClick}>
          {icon}
        </div>
      )}
    </div>
  );
};
export default AnimatedNFT;
