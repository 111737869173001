import LoginSrc from "../img/elf-wallet-intro.png";
import LogoSvg from "../img/European_League_of_Football_Logo.png";
const LogoPng = LogoSvg;

const HTML_TITLE = "ELF Wallet Viewer";
const TEAM_CITY = "European";
const TEAM_NAME = "European League Football";
const COPYRIGHT = "European League Football";
const LOGIN_SRC = LoginSrc;
const LOGO_SVG_SRC = LogoSvg;
const LOGO_PNG_SRC = LogoPng;

export default {
  HTML_TITLE,
  TEAM_CITY,
  TEAM_NAME,
  COPYRIGHT,
  LOGIN_SRC,
  LOGO_SVG_SRC,
  LOGO_PNG_SRC,
};
