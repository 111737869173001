const PRIVACY_POLICY = `
<p>
Hornets.Mint.Store Privacy Policy
Last Updated: November 9, 2021
</p>
<p>
The site hornets.mint.store is an application that provides users with the opportunity to collect and showcase digital blockchain collectibles containing exclusive content from the Hornets Basketball, LLC (“Hornets”), a member team of the National Basketball Association (the “NBA”) (collectively, the “App”).   Minting Services Ou ("Mint", "we", or "us") is making the App available to you. Before you use the App, however, you will need to agree to these Terms of Use and any terms and conditions incorporated herein by reference (collectively, these “Terms"). 
</p>
<p>
Mint is committed to protecting and respecting your privacy. This privacy policy (this “Policy”) sets out how we collect and process personal information about you when you visit our website at https://hornets.mint.store, when you sign up for or use the App, or when you otherwise do business or make contact with us.
</p>
<p>
1. What information do we collect?
</p>
<p>
A. SUMMARY
</p>
<p>
Mint collects data to enable us to operate the App effectively, and to provide you with the best experience on the App. You provide some of this data to us directly, such as when you register to use the App, subscribe to a newsletter, respond to a survey, make an enquiry through our website, contact us for support, or contact us as a prospective user, vendor, supplier, or consultant. We get some of your data by recording how you interact with the App by, for example, using technologies like cookies. We also obtain and process data in the context of making the App available to you.
</p>
<p>
B. LEARN MORE
</p>
<p>
You have choices about the data we collect. When you are asked to provide personal data, you may decline. But if you choose not to provide data that is necessary to enable us to make the App available to you, you may not be able to sign up for or use the App. The data we collect depends on the context of your interactions with Mint, and the choices you make (including your privacy settings). The data we collect can include the following:
Email and Electronic Wallet Address. We may collect your email address and your electronic wallet address.
Device and Usage information. We may collect data about your device and how you and your device interact with Mint and our App. For example, we may collect your interactions on our website, your feature usage patterns, location data, and your interactions with us. We may also collect data about your device and the network you use to connect to our App; this may include data such as your IP address, browser type, operating system, and referring URLs.
</p>
<p>
2. What do we use your information for?
</p>
<p>
A. SUMMARY
</p>
<p>
We use the data we collect to operate our business and to make the App available to you. This includes using the data to improve our App and to personalize your experiences. We may also use the data to communicate with you to, among other things, inform you about your account, provide security updates, and give you information about the App. We may also use the data to manage your email subscriptions, improve the relevance and security of our website, respond to user enquiries, and send you periodic marketing communications about our App.
</p>
<p>
B. LEARN MORE
</p>
<p>
We use data to provide the App to you, to improve the App, and to perform essential business operations. This includes operating the App, maintaining and improving the performance of the App, developing new features, conducting research, providing customer support, and to enforce our Terms of Use. Examples of such uses include the following:
Providing the App. We use data to carry out your transactions with us and to make the App available to you. In certain cases, the App may include personalized features and recommendations to enhance your enjoyment, and automatically tailor your experience based on the data we have about you.
Technical support. We use data to diagnose problems, and to provide customer care and other support services.
Improving the App. We use data to continually improve the App, including system administration, system security, and adding new features or capabilities.
Business Operations. We use data to develop aggregate analyses and business intelligence that enable us to operate, protect, make informed decisions, and report on the performance of our business.
Promotions. We may use your data to administer contests, promotions, surveys, or other site features.
Improving Advertising Campaigns. We may use your data to improve our advertising campaigns, primarily in an effort to prevent targeting you with advertisements that are not relevant to you.
Sending Periodic Emails. We may use your data to send you periodic emails. Depending on the marketing preferences you select on your privacy dashboard, we may send you occasional marketing emails about our App, which you can unsubscribe from at any time using the link provided in the message.
Communications. We use data we collect to communicate with you, and to personalize our communications with you. For example, we may contact you to discuss your account, to remind you about features of the App that are available for your use, to update you about a support request, or to invite you to participate in a survey. Additionally, you can sign up for email subscriptions, and choose whether you want to receive marketing communications from us.
</p>
<p>
3. How do we protect your information?
</p>
<p>
We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information. We offer the use of a secure server. All supplied sensitive information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our gateway providers database only to be accessible by those authorized with special access rights to such systems, and are required to keep the information confidential.
</p>
<p>
4. How do we ensure that our processing systems remain confidential, resilient, and available?
</p>
<p>
A. SUMMARY
</p>
<p>
We implement a variety of measures to ensure that our processing systems remain confidential, resilient, and available. Specifically, we have implemented processes to help ensure high availability, business continuity, and prompt disaster recovery. We commit to maintaining strong physical and logical access controls, and conduct regular penetration testing to identify and address potential vulnerabilities.
</p>
<p>
B. LEARN MORE
</p>
<p>
High Availability. Every part of the App utilizes properly-provisioned, redundant servers (e.g., multiple load balancers, web servers, replica databases) in case of failure. We take servers out of operation as part of regular maintenance, without impacting availability.
Business Continuity. We keep encrypted backups of data daily in multiple regions on Google Cloud Platform. While never expected, in the case of production data loss (i.e., primary data stores loss), we will restore organizational data from these backups.
Disaster Recovery. In the event of a region-wide outage, we will bring up a duplicate environment in a different Google Cloud Platform region. Our operations team has extensive experience performing full region migrations.
Physical Access Controls. The App is hosted on Google Cloud Platform, whose data centers feature a layered security model, including extensive safeguards such as custom-designed electronic access cards, alarms, vehicle access barriers, perimeter fencing, metal detectors, and biometrics. Mint employees do not have physical access to these data centers, servers, network equipment, or storage.
Logical Access Controls. Only designated, authorized operations team members have access to configure the infrastructure on an as-needed basis behind a two-factor authenticated virtual private network. Specific private keys are required for individual servers, and keys are stored in a secure and encrypted location.
</p>
<p>
5. Do we use cookies?
</p>
<p>
A. SUMMARY
</p>
<p>
Yes. Cookies are small files that a site or its service provider transfers to your computers hard drive through your Web browser (if you allow) that enables the sites or service providers systems to recognize your browser and capture and remember certain information. You can choose to disable cookies, but if you do, your ability to use or access certain parts of the App may be affected.
</p>
<p>
B. LEARN MORE
</p>
<p>
We use cookies and other similar identifiers to understand and save your preferences for future visits, to advertise to you on other sites, and to compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.
You may refuse to accept cookies by activating the setting on your browser that allows you to refuse the setting of cookies. You can find information on popular browsers and how to adjust your cookie preferences at the following websites:
</p>
<p>
Microsoft Internet Explorer
</p>
<p>
Mozilla Firefox
</p>
<p>
Google Chrome
</p>
<p>
Apple Safari
</p>
<p>
However, if you choose to disable cookies, you may be unable to access certain parts of our website. A banner asking you to accept our cookies policy will be displayed upon the first visit to our website (or the first visit after you delete your cookies). Unless you have adjusted your browser setting so that it will refuse cookies and/or you have not accepted our cookies policy, our system will issue cookies when you log on to our site.
Our web pages may contain electronic images known as web beacons (also called single-pixel gifs) that we use to help deliver cookies on our websites and to count users who have visited those websites. We may also include web beacons in our promotional email messages or newsletters, to determine whether and when you open and act on them.
In addition to placing web beacons on our own websites, we sometimes work with other companies to place our web beacons on their websites or in their advertisements. This helps us to develop statistics on how often clicking on an advertisement on a Mint website results in a purchase or other action on the advertiser's website.
Finally, our App may contain web beacons or similar technologies from third-party analytics providers that help us compile aggregated statistics about the effectiveness of our promotional campaigns or other operations. These technologies enable the analytics providers to set or read their own cookies or other identifiers on your device, through which they can collect information about your online activities across applications, websites or other products.
</p>
<p>
6. Do we disclose any information to outside parties?
</p>
<p>
A. SUMMARY
</p>
<p>
We share your personal data with your consent, or as necessary to make the App available to you. We also share your data with the NBA and the Hornets; vendors working on our behalf; when required by law or to respond to legal process; to protect our customers; to protect lives; to maintain the security and integrity of our App; and to protect our rights or our property.
</p>
<p>
B. LEARN MORE
</p>
<p>
We share your personal data with your consent, or as necessary to make the App available to you. We share your data with the NBA and the Hornets, since they are some of the major content providers for the App. You will be asked to agree to their terms of service and privacy policies as well when you sign up to use the App. You have the right to refuse, but if you do refuse, you may not be able to complete your registration or use the App.
We also share personal data with vendors or agents working on our behalf for the purposes described in this Policy. For example, companies we have hired to provide cloud hosting services, off-site backups, and customer support may need access to personal data to provide those functions. In such cases, these companies are required to abide by our data privacy and security requirements and are not allowed to use personal data they receive from us for any other purpose. If you have questions or concerns about any of our vendors, feel free to contact us at support@mint.store
</p>
<p>
We may disclose your personal data as part of a corporate transaction such as a corporate sale, merger, reorganization, dissolution, or similar event. Finally, we will access, transfer, disclose, and/or preserve personal data, when we have a good faith belief that doing so is necessary to:
(1) comply with applicable law or respond to valid legal process, judicial orders, or subpoenas;
(2) respond to requests from public or governmental authorities, including for national security or law enforcement purposes;
(3) protect the vital interests of our users, customers, or other third parties (including, for example, to prevent spam or attempts to defraud users of our products, or to help prevent the loss of life or serious injury of anyone);
(4) operate and maintain the security or integrity of our App, including to prevent or stop an attack on our computer systems or networks;
(5) protect the rights, interests or property of Mint or third parties;
(6) prevent or investigate possible wrongdoing in connection with the App; or
(7) enforce our Terms of Use.
</p>
<p>
We may use and share aggregated non-personal information with third parties for marketing, advertising, and analytics purposes. We do not sell or trade your personal information to third parties.
</p>
<p>
7. How to Access and Control Your Personal Data
</p>
<p>
A. SUMMARY
</p>
<p>
You can view, access, edit, delete, or request a copy of your personal data for many aspects of the App via your user dashboard. You can also make choices about Mint’s collection and use of your data. You can always choose whether you want to receive marketing communications from us. You can also opt out from receiving marketing communications from us by using the opt-out link on the communication, or by visiting your user dashboard.
</p>
<p>
B. LEARN MORE
</p>
<p>
Data Access. You can access your personal data on your account’s user dashboard.
Data Portability. You can request a copy of your personal data by submitting an email to us at support@mint.store and including “Please send me a copy of my personal data” in the “Subject” line. Mint will verify your ability to access that email, then send you a digital export of the data we hold that is associated with your email address. We will use reasonable efforts to respond to your request within 30 days of our receipt of verification of the request. Mint may be limited in its ability to send certain personal data to you (e.g., the identification of your electronic wallet).
Data Erasure. You may request that Mint delete your personal data by submitting an email to us at support@mint.store and including “Please delete my personal data” in the “Subject” line. Mint will verify your ability to access that email, then delete the personal data associated with your email address. We will use reasonable efforts to respond to your request within 30 days of our receipt of verification of the request.
Data Correction. You can modify your personal data via your account’s user dashboard. Note that since some of the data we collect is specific to you – for example, your electronic wallet address – you may not be able to modify this data without needing to create a new user profile.
Your Communications Preferences. You can choose whether you wish to receive marketing communications from us. If you receive marketing communications from us and would like to opt out, you can do so by following the directions in that communication. You can also make choices about your receipt of marketing communications by signing into your account, and viewing and managing your communication permissions in your account’s user dashboard, where you can update contact information, manage your contact preferences, opt out of email subscriptions, and choose whether to share your contact information with Mint and our partners. Alternatively, you can request that we withdraw your consent to use your personal data by submitting an email to us at support@mint.store and including “Please withdraw my consent for marketing communications” in the “Subject” line. Mint will verify your ability to access that email, then update our systems to remove your email address from the system we use to send marketing communications. We will use reasonable efforts to respond to your request within 30 days of our receipt of verification of the request. Please note that these choices do not apply to mandatory communications that are part of the App, or to surveys or other informational communications that have their own unsubscribe method.
</p>
<p>
8. Third Party Links
</p>
<p>
Occasionally, at our discretion, we may include or offer third party products or services on our website or through our App. If you access other websites using the links provided, the operators of these websites may collect information from you that will be used by them in accordance with their privacy policies. These third party sites have separate and independent privacy policies. We, therefore, have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.
</p>
<p>
9. Where we Store and Process Personal Data; International Transfers
</p>
<p>
Personal data collected by Mint may be stored and processed in the United States, Canada, or in any other country where Mint or its affiliates, subsidiaries or service providers maintain facilities. The storage location(s) are chosen in order to operate efficiently, to improve performance, and to create redundancies in order to protect the data in the event of an outage or other problem. We take steps to ensure that the data we collect is processed according to the provisions of this Policy and the requirements of applicable law wherever the data is located.
We transfer personal data from the European Economic Area and Switzerland to other countries, some of which have not been determined by the European Commission to have an adequate level of data protection. When we engage in such transfers, we use a variety of legal mechanisms, including contracts, to help ensure your rights and protections travel with your data. To learn more about the European Commission’s decisions on the adequacy of the protection of personal data in the countries where Mint processes personal data, please visit: ec.europa.eu/justice/data-protection/international-transfers/adequacy/index_en.htm
</p>
<p>
10. Data Retention
</p>
<p>
A. SUMMARY
</p>
<p>
We may retain your personal information as long as you continue to use the App, have an account with us, or for as long as is necessary to fulfill the purposes outlined in this Policy. You can ask to close your account by contacting us as described above, and we will delete your personal information on request. We may, however, retain personal information for an additional period as is permitted or required under applicable laws, for legal, tax, or regulatory reasons, or for legitimate and lawful business purposes.
</p>
<p>
B. LEARN MORE
</p>
<p>
We will retain your personal data for as long as necessary to make the App available to you, or for other essential purposes such as complying with our legal obligations, resolving disputes, and enforcing our agreements. Because these needs can vary for different types of data, actual retention periods can vary significantly. The criteria we use to determine the retention periods include:
How long is the personal data needed to make the App available to you and/or operate our business? This includes such things such as maintaining and improving the performance of the App, keeping our systems secure, and maintaining appropriate business and financial records. This is the general rule that establishes the baseline for most data retention periods.
Is there an automated control, such as in your user dashboard, that enables you to access and delete the personal data at any time? If there is not, a shortened data retention time will generally be adopted.
Is the personal data of a sensitive type? If so, a shortened retention time would generally be appropriate.
Has the user provided consent for a longer retention period? If so, we will retain the data in accordance with your consent.
Is Mint subject to a legal, contractual, or similar obligation to retain the data? Examples can include mandatory data retention laws in the applicable jurisdiction, government orders to preserve data relevant to an investigation, or data that must be retained for the purposes of litigation.
</p>
<p>
11. Changes to our Privacy Policy
</p>
<p>
We will update this Policy when necessary to reflect customer feedback and changes to our App. When we post changes to this statement, we will revise the "last updated" date at the top of the statement. If there are material changes to the Policy or in how Mint will use your personal data, we will notify you either by prominently posting a notice of such changes before they take effect or by sending you a notification directly. We encourage you to periodically review this privacy statement to learn how Mint is protecting your information.
</p>
<p>
12. California Resident Rights
</p>
<p>
If you are a California resident, you have the rights set forth in this section. Please see the “Exercising Your Rights” section below for instructions regarding how to exercise these rights. If there are any conflicts between this section and any other provision of this Privacy Policy and you are a California resident, the portion that is more protective of your personally-identifiable data will control to the extent of such conflict. If you have any questions about this section or whether any of the following rights apply to you, please contact us at dpo@nbatopshot.com.
</p>
<p>
Exercising Your Rights
</p>
<p>
Please follow the instructions and requirements described below when submitting your requests. Requests that fail to comply with any of these instructions and requirements may result in delayed or no response.
To exercise the rights described below as a California resident, you must send us a request (1) that provides sufficient information to allow us to verify that (i) you are the person about whom we have collected personal data, (ii) you, as the requester, are the same person as the data subject for whose information you’re requesting (or such person’s parent/guardian), (2) that describes your request in sufficient detail to allow us to understand, evaluate and respond to it, (3) that declares, under the penalty of perjury, that you’re exercising your rights under the CCPA as a California resident solely for lawful purposes, and (4) in a way that does not and would not unduly burden or otherwise abuse our data request system and/or our App. Each request that meets all of these criteria, and which is confirmed via email verification, will be considered a “Valid Request.” We may not respond to requests that do not meet these criteria. We will use commercially reasonable efforts to determine whether a request may be for harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise objectionable purposes, and we reserve the right not to respond to such request. We will only use your personal data provided in a Valid Request to verify your identity and complete your request. You do not need an account on the App to submit a Valid Request.
We will work to respond to your Valid Request within 45 days of receipt. We will not charge you a fee for making a Valid Request unless your Valid Request(s) is excessive, repetitive or manifestly unfounded. If we determine that your Valid Request warrants a fee, we will notify you of the fee and explain that decision before completing your request.
You may submit a Valid Request by sending us an email at : support@mint.store
You may also authorize an agent (an “Authorized Agent”) to exercise your rights on your behalf. To do this, you must provide your Authorized Agent with written permission to exercise your rights on your behalf, and we may request a copy of this written permission from your Authorized Agent when they make a request on your behalf.
</p>
<p>
Access
</p>
<p>
You have the right to request certain information about our collection and use of your personal data over the past 12 months. In response to a Valid Request, we will provide you with the following information:
The categories of personal data that you requested and that we have collected about you.
The categories of sources from which that personal data was collected.
The business or commercial purpose for collecting your personal data.
The categories of third parties with whom we have shared your personal data.
The specific pieces of Personal Data that you explicitly requested and that we have collected about you.
If we have disclosed your personal data to any third parties for a business purpose over the past 12 months, we will identify the categories of personal data shared with each category of third party recipient.
</p>
<p>
Deletion
</p>
<p>
You have the right to request that we delete the personal data that we have collected about you. Under the CCPA, this right is subject to certain exceptions: for example, we may need to retain your personal data to provide you with access to the App or complete a transaction or other action you have requested. If your deletion request is subject to one of these exceptions, we may deny your deletion request.
</p>
<p>
We Do Not Sell Your Personal Data
</p>
<p>
In this section, we use the term ‘sell’ as it is defined in the CCPA. We do not sell your personal data.
</p>
<p>
We Will Not Discriminate Against You for Exercising Your Rights Under the CCPA
We will not discriminate against you for exercising your rights under the CCPA. We will not deny you our goods or services, charge you different prices or rates, or provide you a lower quality of goods and services if you exercise your rights under the CCPA. However, we may offer different tiers of our products or services as allowed by applicable data privacy laws (including the CCPA) with varying prices, rates or levels of quality of the goods or services you receive related to the value of personal data that we receive from you.
</p>
<p>
13. How to Contact Us
</p>
<p>
If you have a technical or support question, please contact support@mint.store
If you have a privacy concern, complaint, or a question for the Data Protection Officer of Mint, please contact us by sending us an email at support@mint.store .We will respond to questions or concerns within 30 days.
Unless otherwise stated, Mint is a data controller for personal data we collect through the App subject to this statement. Our address is 3433 Stanley St, Montreal, Quebec H3A 1S2.
</p>
`;

const TERMS_OF_SERVICE = `

<p>
Hornets.Mint.Store Terms of Use 
Last Updated: November 19, 2021
</p>
<p>
The site hornets.mint.store is an application that provides users with the opportunity to collect and showcase digital blockchain collectibles containing content solely for entertainment purposes from the Hornets Basketball, LLC (“Hornets”), a member team of the National Basketball Association (the “NBA”), (collectively, the “App”).   Minting Services Ou ("Mint", "we", or "us") is making the App available to you. Before you use the App, however, you will need to agree to these Terms of Use and any terms and conditions incorporated herein by reference (collectively, these “Terms"). 
</p>
<p>
PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE APP. THESE TERMS GOVERN YOUR USE OF THE APP, UNLESS WE HAVE EXECUTED A SEPARATE WRITTEN AGREEMENT WITH YOU FOR THAT PURPOSE. WE ARE ONLY WILLING TO MAKE THE APP AVAILABLE TO YOU IF YOU ACCEPT ALL OF THESE TERMS. BY USING THE APP OR ANY PART OF IT, YOU ARE CONFIRMING THAT YOU UNDERSTAND AND AGREE TO BE BOUND BY ALL OF THESE TERMS. IF YOU ARE ACCEPTING THESE TERMS ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE LEGAL AUTHORITY TO ACCEPT THESE TERMS ON THAT ENTITY’S BEHALF, IN WHICH CASE “YOU” WILL MEAN THAT ENTITY. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT ACCEPT ALL OF THESE TERMS, THEN WE ARE UNWILLING TO MAKE THE APP AVAILABLE TO YOU. IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY NOT ACCESS OR USE THE APP.
THIS AGREEMENT CONTAINS AN ARBITRATION PROVISION (SEE SECTION 15).  PLEASE REVIEW THE ARBITRATION PROVISION CAREFULLY BECAUSE IT AFFECTS YOUR RIGHTS.  BY USING THE APP OR ANY PART OF IT, YOU UNDERSTAND AND AGREE TO BE BOUND BY THE ARBITRATION PROVISION.
</p>
<p>
ANY PURCHASE OR SALE YOU MAKE, ACCEPT OR FACILITATE OUTSIDE OF THIS APP (AS DEFINED BELOW) OF A COLLECTIBLE (AS DEFINED BELOW) WILL BE ENTIRELY AT YOUR RISK. WE DO NOT CONTROL OR ENDORSE PURCHASES OR SALES OF COLLECTIBLES OUTSIDE OF THIS APP. WE EXPRESSLY DENY ANY OBLIGATION TO INDEMNIFY YOU OR HOLD YOU HARMLESS FOR ANY LOSSES YOU MAY INCUR BY TRANSACTING, OR FACILITATING TRANSACTIONS, IN COLLECTIBLES OUTSIDE OF THIS APP.
</p>
<p>
BY USING THE APP OR ANY PART OF IT, OR BY CLICKING “I ACCEPT” BELOW OR INDICATING YOUR ACCEPTANCE IN AN ADJOINING BOX, YOU ARE CONFIRMING THAT YOU UNDERSTAND AND AGREE TO BE BOUND BY ALL OF THESE TERMS.
</p>
<p>
Any changes to these Terms will be in effect as of the “Last Updated Date” referred to at the top of this page. You should review these Terms before using the App or purchasing any product or using any services that are available through this App.
</p>
<p>
Your continued use of this App after the “Last Updated Date” will constitute your acceptance of and agreement to such changes.
</p>
<p>
By using this App, you affirm that you are of legal age to enter into these Terms, and you accept and are bound by these Terns. You affirm that if you are using this App on behalf of an organization or company, you have the legal authority to bind any such organization or company to these Terms.
</p>
<p>
You may not use this App if you: (i) do not agree to these Terms; (ii) are not of the age of majority in your jurisdiction of residence; or (iii) are prohibited from accessing or using this App or any of this App’s contents, products or services by applicable law.
</p>
<p>
1. USE OF THE APP; ACCOUNT SET-UP AND SECURITY
</p>
<p>
(i) Account and Wallet Set-Up. To most easily use the App, you should first install a web browser (such as the Google Chrome web browser).  You will also need register to establish an account affiliated with an electronic wallet, which will enable you to purchase and store collectibles that you collect or purchase via the App.  Each collectible is a Non-Fungible Token (an “NFT”) on the Flow™ blockchain network (the “Flow Network”). 
</p>
<p>
(ii) Account Registration. You must provide accurate and complete registration information when you create an account for the App. By creating an account, you agree to provide accurate, current and complete account information about yourself, and to maintain and promptly update your account information as necessary.  We reserve the right reclaim usernames without liability to you.
</p>
<p>
(iii) Account Security. You are responsible for the security of your account for the App and for your electronic wallets. If you become aware of any unauthorized use of your password or of your account with us, you agree to notify us immediately at support@mint.store.
</p>
<p>

(iv) Account Transactions.  You can use your electronic wallet to purchase, store, and engage in transactions using your credit card, or via one or more cryptocurrencies that we may elect to accept from time to time. Transactions that take place on the App are managed and confirmed via the Flow Network.  You understand that your Flow Network public address will be made publicly visible whenever you engage in a transaction on the App.
</p>
<p>
 2. PURCHASING AND EARNING YOUR COLLECTIBLES
 </p>
 <p>
(i) Acquiring Collectibles. The App allows you to collect and showcase Collectibles (each, a “Collectible”) from the Hornets.  Each Collectible is an NFT on the Flow Network. 
</p>
<p>
(ii) Characteristics of Collectibles. Collectibles are comprised of an artistic design created by the in-house graphics design team of the Hornets.  Each Collectible has a defined set of attributes – including scarcity – which help determine the value of the Collectible. 
</p>
<p>
(iii) Subjectivity of Collectibles. The value of each Collectible is inherently subjective, in the same way the value of other collectibles is inherently subjective. Each Collectible has no inherent or intrinsic value. Some collectors might prefer to have a Collectible featuring a certain Hornets game, while another might prefer an equivalent Collectible featuring a different Hornets match-up or game.  
</p>
<p>
(iv) Showcasing Collectibles. The “Showcase” feature of the App allows to you organize your Collectibles into collections, and show them to your friends. 
</p>
<p>
3.  PAYMENT, GAS FEES, AND TAXES
</p>
<p>
(i) Financial Transactions on App. Any payments or financial transactions that you engage in via the App will be conducted solely through the Flow Network.  We have no control over these payments or transactions, nor do we have the ability to reverse any payments or transactions. We have no liability to you or to any third party for any claims or damages that may arise as a result of any payments or transactions that you engage in via the App, or any other payment or transactions that you conduct via the Flow Network. We do not provide refunds for any purchases that you might make on or through the App – whether for Collectibles or anything else.
</p>
<p>
(ii) Transaction fees. Transactions in Collectibles on the Flow Network may be subject to additional fees, including fees payable to some or all of the Collectible Parties, which fees will be deducted from transaction proceeds before payment to the selling party.
</p>
<p>
(iii) Responsibility for Taxes. You will be solely responsible to pay any and all sales, use, value-added and other taxes, duties, and assessments (except taxes on our net income) now or hereafter claimed or imposed by any governmental authority (collectively, the “Taxes”) associated with your use of the App. Except for income taxes levied on us, you: (a) will pay or reimburse us for all national, federal, state, local or other taxes and assessments of any jurisdiction, including value added taxes and taxes as required by international tax treaties, customs or other import or export taxes, and amounts levied in lieu thereof based on charges set, services performed or payments made hereunder, as are now or hereafter may be imposed under the authority of any national, state, local or any other taxing jurisdiction; and (b) will not be entitled to deduct the amount of any such taxes, duties or assessments from payments (including Gas Fees) made to us pursuant to these Terms. To allow us to determine our tax obligations, unless you otherwise notify us in writing, you confirm that you are not a resident in Canada nor are you registered for Goods and services tax / Harmonized sales tax (GST / HST) or Provincial sales taxes (PST) in Canada, and will inform us if your status changes in the future.
</p>
<p>
4.  OWNERSHIP, LICENSE, AND OWNERSHIP RESTRICTIONS
</p>
<p>
YOUR OWNERSHIP OF COLLECTIBLES WILL ONLY BE RECOGNIZED BY US IF YOU HAVE PURCHASED OR OTHERWISE RIGHTFULLY ACQUIRED SUCH COLLECTIBLES FROM A LEGITIMATE SOURCE AND NOT THROUGH ANY OF THE CATEGORY B PROHIBITED ACTIVITIES (AS DEFINED BELOW).
</p>
<p>
For the purposes of this Section 4, the following capitalized terms will have the following meanings: 
</p>
<p>
“Art” means any art, design, and drawings (in any form or media, including, without limitation, video or photographs) that may be associated with a Collectible that you Own.
</p>
<p>
“Own” means, with respect to a Collectible, a Collectible that you have purchased or otherwise rightfully acquired from a legitimate source (and not through any of the Category B Prohibited Activities (as defined below)), where proof of such purchase is recorded on the Flow Network.
</p>
<p>
“Purchased Collectible” means a Collectible that you Own. “Third Party IP” means any third party patent rights (including, without limitation, patent applications and disclosures), copyrights, trade secrets, trademarks, know-how or any other intellectual property rights recognized in any country or jurisdiction in the world.
</p>
<p>
(i) Ownership of Collectible.  Because each Collectible is an NFT on the Flow Network,  when you purchase a Collectible in accordance with these Terms (and not through any of the Category B Prohibited Activities), you own the underlying NFT completely.  This means that you have the right to swap your Collectible, sell it, or give it away. Ownership of the Collectible is mediated entirely by the Flow Network. 
</p>
<p>
(ii) We Own the App.  You acknowledge and agree that we (or, as applicable, our licensors) owns all legal right, title and interest in and to all other elements of the App, and all intellectual property rights therein (including, without limitation, all Art, designs, systems, methods, information, computer code, software, services, “look and feel”, organization, compilation of the content, code, data, and all other elements of the App (collectively, the “App Materials”)).  You acknowledge that the App Materials are protected by copyright, trade dress, patent, and trademark laws, international conventions, other relevant intellectual property and proprietary rights, and applicable laws. All App Materials are the copyrighted property of us or our licensors, and all trademarks, service marks, and trade names associated with the App or otherwise contained in the App Materials are proprietary to us or our licensors.
</p>
<p>
(iii) No User License or Ownership of App Materials. Except as expressly set forth herein, your use of the App does not grant you ownership of or any other rights with respect to any content, code, data, or other App Materials that you may access on or through the App. We reserve all rights in and to the App Materials that are not expressly granted to you in these Terms.
</p>
<p>
(iv) Further User Ownership Acknowledgements. For the sake of clarity, you understand and agree: (a) that your purchase of a Collectible, whether via the App or otherwise, does not give you any rights or licenses in or to the App Materials (including, without limitation, our copyright in and to the associated Art) other than those expressly contained in these Terms; (b) that you do not have the right, except as otherwise set forth in these Terms, to reproduce, distribute, or otherwise commercialize any elements of the App Materials (including, without limitation, any Art) without our prior written consent in each case, which consent we may withhold in our sole and absolute discretion; and (c) that you will not apply for, register, or otherwise use or attempt to use any of our trademarks or service marks, or any confusingly similar marks, anywhere in the world without our prior written consent in each case, which consent we may withhold at our sole and absolute discretion.
</p>
<p>
(v)  User License to Art.  Subject to your continued compliance with these Terms, we grant you non-exclusive, non-transferable, royalty-free license to use and display the Art for your Purchased Collectibles, solely for the following purposes: (a) for your own personal, non-commercial use or(b) as part of a marketplace that permits the purchase and sale of your Purchased Collectibles, provided that the marketplace cryptographically verifies each Collectible owner’s rights to display the Art for their Purchased Collectible to ensure that only the actual owner can display the Art.
</p>
<p>
(vi) Restrictions on Ownership.  You agree that you may not, nor permit any third party to do or attempt to do any of the foregoing without our (or, as applicable, our licensors’) express prior written consent in each case: (a) modify the Art for your Purchased Collectible in any way, including, without limitation, the shapes, designs, drawings, attributes, or color schemes; (b) use the Art for your Purchased Collectible to advertise, market, or sell any third party product or service; (c) use the Art for your Purchased Collectible in connection with images, videos, or other forms of media that depict hatred, intolerance, violence, cruelty, or anything else that could reasonably be found to constitute hate speech or otherwise infringe upon the rights of others; (d) use the Art for your Purchased Collectible in movies, videos, or any other forms of media, except to the limited extent that such use is expressly permitted in these Terms or solely for your own personal, non-commercial use; (e) sell, distribute for commercial gain (including, without limitation, giving away in the hopes of eventual commercial gain), or otherwise commercialize merchandise that includes, contains, or consists of the Art for your Purchased Collectible; (f) attempt to trademark, copyright, or otherwise acquire additional intellectual property rights in or to the Art for your Purchased Collectible; or (g) otherwise utilize the Art for your Purchased Collectible for your or any third party’s commercial benefit.
</p>
<p>
(vii) Third Party IP. If the Art associated with your Purchased Collectible contains Third Party IP (e.g., licensed intellectual property from the NBA or an NBA player), you understand and agree as follows: (a) that you will not have the right to use such Third Party IP in any way except as incorporated in the Art, and subject to the license and restrictions contained herein; (b) that, depending on the nature of the license granted from the owner of the Third Party IP, we may need to (and reserve every right to) pass through additional restrictions on your ability to use the Art; and (c) to the extent that we inform you of such additional restrictions in writing, you will be responsible for complying with all such restrictions from the date that you receive the notice, and that failure to do so will be deemed a breach of the license contained in this Section 4.
</p>
<p>
(viii) Other Terms of License.  The license granted in Section 4(v) above applies only to the extent that you continue to Own the applicable Purchased Collectible. If at any time you sell, swap, donate, give away, transfer, or otherwise dispose of your Purchased Collectible for any reason, the license granted in Section 4(v) will immediately expire with respect to that Collectible without the requirement of notice, and you will have no further rights in or to the Art for that Collectible. The restrictions in Sections 4(vii) and 4(viii) will survive the expiration or termination of these Terms.
</p>
<p>
(ix) User  Feedback.  You may choose to submit comments, bug reports, ideas or other feedback about the App, including without limitation about how to improve the App (collectively, “Feedback”). By submitting any Feedback, you agree that we are free to use such Feedback at our discretion and without additional compensation to you, and to disclose such Feedback to third parties (whether on a non-confidential basis, or otherwise). You hereby grant us a perpetual, irrevocable, nonexclusive, worldwide license under all rights necessary for us to incorporate and use your Feedback for any purpose.
</p>
<p>
5. CONDITIONS OF USE AND PROHIBITED ACTIVITIES
</p>
<p>
YOU AGREE THAT YOU ARE RESPONSIBLE FOR YOUR OWN CONDUCT WHILE ACCESSING OR USING THE APP, AND FOR ANY CONSEQUENCES THEREOF. YOU AGREE TO USE THE APP ONLY FOR PURPOSES THAT ARE LEGAL, PROPER AND IN ACCORDANCE WITH THESE TERMS AND ANY APPLICABLE LAWS OR REGULATIONS.
</p>
<p>
(i) User Warranties.  Without limiting the foregoing, you warrant and agree that your use of the App will not (and will not allow any third party to):(a) in any manner:
</p>
<p>
(1)  involve the sending, uploading, distributing or disseminating any unlawful, defamatory, harassing, abusive, fraudulent, obscene, or otherwise objectionable content;
</p>
<p>
(2)  involve the distribution of any viruses, worms, defects, Trojan horses, corrupted files, hoaxes, or any other items of a destructive or deceptive nature;
</p>
<p>
(3)  involve the uploading, posting, transmitting or otherwise making available through the App any content that infringes the intellectual proprietary rights of any party;
</p>
<p>
(4)  involve using the App to violate the legal rights (such as rights of privacy and publicity) of others; 
</p>
<p>
(5)  involve engaging in, promoting, or encouraging illegal activity (including, without limitation, money laundering);
</p>
<p>
(6)  involve interfering with other users’ enjoyment of the App;
</p>
<p>
(7)  involve exploiting the App for any unauthorized commercial purpose;
</p>
<p>
(8)  involve modifying, adapting, translating, or reverse engineering any portion of the App;
</p>
<p>
(9) involve removing any copyright, trademark or other proprietary rights notices contained in or on the App or any part of it;
</p>
<p>
(10) involve reformatting or framing any portion of the App;
</p>
<p>
(11) involve displaying any content on the App that contains any hate-related or violent content or contains any other material, products or services that violate or encourage conduct that would violate any criminal laws, any other applicable laws, or any third party rights;
</p>
<p>
(12) involve using any spider, site search/retrieval application, or other device to retrieve or index any portion of the App or the content posted on the App, or to collect information about its users for any unauthorized purpose;
</p>
<p>
(13) involve accessing or using the App for the purpose of creating a product or service that is competitive with any of our products or services;
</p>
<p>
(14) involve abusing, harassing, or threatening another user of the App or any of our authorized representatives, customer service personnel, chat board moderators, or volunteers (including, without limitation, filing support tickets with false information, sending excessive emails or support tickets, obstructing our employees from doing their jobs, refusing to follow the instructions of our employees, or publicly disparaging us by implying favoritism by a our employees or otherwise); or
</p>
<p>
(15) involve using any abusive, defamatory, ethnically or racially offensive, harassing, harmful, hateful, obscene, offensive, sexually explicit, threatening or vulgar language when communicating with another user of the App or any of our authorized representatives, customer service personnel, chat board moderators, or volunteers
</p>
<p>
(each, a “Category A Prohibited Activity”); and/or
</p>
<p>
(b) in any manner:
</p>
<p>
(1)  involve creating user accounts by automated means or under false or fraudulent pretenses;
</p>
<p>
(2)  involve the impersonation of another person (via the use of an email address or otherwise);
</p>
<p>
(3)  involve using, employing, operating, or creating a computer program to simulate the human behavior of a user (“Bots”);
</p>
<p>
(4)  involve using, employing, or operating Bots or other similar forms of automation to engage in any activity or transaction on the App (including, without limitation, purchases of Packs, or of Collectibles on the Marketplace);
</p>
<p>
(5)  involve acquiring Collectibles through inappropriate or illegal means (including, among other things, using a stolen credit card, or a payment mechanism that you do not have the right to use, or purchasing a Collectible and then attempting to charge the cost back to your payment method while still maintaining ownership or control of the Collectible or selling, gifting or trading the Collectible to someone else); or
</p>
<p>
(6)  involve the purchasing, selling or facilitating the purchase and sale of any user’s account(s) to other users or third parties for cash or cryptocurrency consideration outside of the App; or
</p>
<p>
(7)  otherwise involve or result in the wrongful seizure or receipt of any Collectibles or other digital assets (each, a “Category B Prohibited Activity” and, together with Category A Prohibited Activity, the “Prohibited Activities”).
</p>
<p>
(ii) Effect of Your Breaches. If you engage in any of the Prohibited Activities, we may, at our sole and absolute discretion, without notice or liability to you, and without limiting any of our other rights or remedies at law or in equity, immediately suspend or terminate your user account and/or delete your Collectibles’ images and descriptions from the App. If we delete your Collectibles’ images and descriptions from the App, such deletion will not affect your ownership rights in any NFTs that you already Own, but you will not receive a refund of any amounts you paid for those Collectibles. 
</p>
<p>
NOTWITHSTANDING THE FOREGOING, HOWEVER, IF WE REASONABLY BELIEVE THAT YOU ARE ENGAGED IN ANY OF THE CATEGORY B PROHIBITED ACTIVITIES, IN ADDITION TO OUR RIGHT TO IMMEDIATELY SUSPEND OR TERMINATE YOUR USER ACCOUNT AND/OR DELETE YOUR COLLECTIBLES’ IMAGES AND DESCRIPTIONS FROM THE APP, WE ALSO RESERVE THE RIGHT, AT OUR SOLE AND ABSOLUTE DISCRETION, WITHOUT NOTICE OR LIABILITY TO YOU, TO TAKE ANY OR ALL OF THE FOLLOWING ACTIONS: (A) TO DEEM ANY TRANSACTION THAT TOOK PLACE VIA OR AS THE RESULT OF SUCH ACTIVITIES TO BE VOID AB INITIO; AND/OR (B) TO IMMEDIATELY CONFISCATE ANY COLLECTIBLES (INCLUDING THEIR UNDERLYING NFTS) THAT WERE PURCHASED OR ACQUIRED AS THE RESULT OF SUCH ACTIVITIES.
</p>
<p>
6. TERMINATION
</p>
<p>
(i) You Terminate. You may terminate these Terms at any time by canceling your account on the App and discontinuing your access to and use of the App. If you cancel your account, or otherwise terminate these Terms, you will not receive any refunds for any purchases that you might make on or through the App – whether for Collectibles or anything else.
</p>
<p>
(ii) We Terminate. You agree that we, in our sole discretion and for any or no reason, may terminate these Terms and suspend and/or terminate your account(s) for the App without the provision of prior notice. You agree that any suspension or termination of your access to the App may be without prior notice, and that we will not be liable to you or to any third party for any such suspension or termination.
</p>
<p>
(iii)Other Remedies Available. If we terminate these Terms or suspend or terminate your access to or use of the App due to your breach of these Terms or any suspected fraudulent, abusive, or illegal activity (including, without limitation, if you engage in any of the Prohibited Activities), then termination of these Terms will be in addition to any other remedies we may have at law or in equity.
</p>
<p>
(iv) Referral to Governmental Authority. We have the right, without provision of prior notice, to take appropriate legal action, including, without limitation, referral to law enforcement or regulatory authority, or notifying the harmed party of any illegal or unauthorized use of the App. Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone using the App.
</p>
<p>
(v) Effect of Termination. Upon any termination or expiration of these Terms, whether by you or us, you may no longer have access to information that you have posted on the App or that is related to your account, and you acknowledge that we will have no obligation to maintain any such information in our databases or to forward any such information to you or to any third party. Sections 1 and 3 through 17 will survive the termination or expiration of these Terms for any reason.
</p>
<p>
YOU WAIVE AND HOLD US, THE HORNETS,THE NBA AND EACH OF OUR RESPECTIVE PARENTS, SUBSIDIARIES, AFFILIATES AND OUR AND THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, AND SUCCESSORS HARMLESS FROM ANY AND ALL CLAIMS RESULTING FROM ANY ACTION TAKEN BY US AND ANY OF THE FOREGOING PARTIES RELATING TO ANY INVESTIGATIONS BY EITHER US OR SUCH PARTIES OR BY LAW ENFORCEMENT AUTHORITIES.
</p>
<p>

7. DISCLAIMERS
</p>
<p>
YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR ACCESS TO AND USE OF THE APP IS AT YOUR SOLE RISK, AND THAT THE APP IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, WE, THE HORNETS, THE NBA, AND OUR RESPECTIVE PARENTS, SUBSIDIARIES, AFFILIATES, AND LICENSORS MAKE NO EXPRESS WARRANTIES AND HEREBY DISCLAIM ALL IMPLIED WARRANTIES REGARDING THE APP AND ANY PART OF IT, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, CORRECTNESS, ACCURACY, OR RELIABILITY. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE, THE HORNETS, THE NBA, AND OUR PARENTS, SUBSIDIARIES, AFFILIATES, AND LICENSORS DO NOT REPRESENT OR WARRANT TO YOU THAT: (I) YOUR ACCESS TO OR USE OF THE APP WILL MEET YOUR REQUIREMENTS; (II) YOUR ACCESS TO OR USE OF THE APP WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR; (III) USAGE DATA PROVIDED THROUGH THE APP WILL BE ACCURATE; (IV) THE APP OR ANY CONTENT, SERVICES, OR FEATURES MADE AVAILABLE ON OR THROUGH THE APP ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR (V) THAT ANY DATA THAT YOU DISCLOSE WHEN YOU USE THE APP WILL BE SECURE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES IN CONTRACTS WITH CONSUMERS, SO SOME OR ALL OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
</p>
<p>
YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET, AND AGREE THAT WE HAVE NO LIABILITY OR RESPONSIBILITY FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO OUR GROSS NEGLIGENCE.
</p>
<p>
WE, THE HORNETS AND THE NBA WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSSES YOU INCUR AS THE RESULT OF YOUR USE OF THE FLOW NETWORK, OR YOUR ELECTRONIC WALLET, INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES OR CLAIMS ARISING FROM: (I) USER ERROR, SUCH AS FORGOTTEN PASSWORDS OR INCORRECTLY CONSTRUED SMART CONTRACTS OR OTHER TRANSACTIONS; (II) SERVER FAILURE OR DATA LOSS; (III) CORRUPTED WALLET FILES; OR (IV) UNAUTHORIZED ACCESS OR ACTIVITIES BY THIRD PARTIES, INCLUDING, BUT NOT LIMITED TO, THE USE OF VIRUSES, PHISHING, BRUTE-FORCING OR OTHER MEANS OF ATTACK AGAINST THE APP, THE FLOW NETWORK, OR ANY ELECTRONIC WALLET.
</p>
<p>
COLLECTIBLES ARE INTANGIBLE DIGITAL ASSETS THAT EXIST ONLY BY VIRTUE OF THE OWNERSHIP RECORD MAINTAINED IN THE FLOW NETWORK. ALL SMART CONTRACTS ARE CONDUCTED AND OCCUR ON THE DECENTRALIZED LEDGER WITHIN THE FLOW NETWORK. WE HAVE NO CONTROL OVER AND MAKE NO GUARANTEES OR PROMISES WITH RESPECT TO SMART CONTRACTS.
</p>
<p>
WE, THE HORNETS AND THE NBA ARE NOT RESPONSIBLE FOR LOSSES DUE TO BLOCKCHAINS OR ANY OTHER FEATURES OF THE FLOW NETWORK, OR ANY ELECTRONIC WALLET, INCLUDING BUT NOT LIMITED TO LATE REPORT BY DEVELOPERS OR REPRESENTATIVES (OR NO REPORT AT ALL) OF ANY ISSUES WITH THE BLOCKCHAIN SUPPORTING THE FLOW NETWORK, INCLUDING FORKS, TECHNICAL NODE ISSUES, OR ANY OTHER ISSUES HAVING FUND LOSSES AS A RESULT.
</p>
<p>
8. LIMITATION OF LIABILITY
</p>
<p>
YOU UNDERSTAND AND AGREE THAT WE, THE HORNETS, THE NBA, AND EACH OF OUR PARENTS, SUBSIDIARIES, AFFILIATES AND LICENSORS WILL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES WHICH YOU MAY INCUR, HOWSOEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, INCLUDING, WITHOUT LIMITATION, ANY LOSS OF PROFITS (WHETHER INCURRED DIRECTLY OR INDIRECTLY), LOSS OF GOODWILL OR BUSINESS REPUTATION, LOSS OF DATA, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, DIMINUTION OF VALUE OR ANY OTHER INTANGIBLE LOSS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
</p>
<p>
YOU AGREE THAT THE TOTAL, AGGREGATE LIABILITY TO YOU FOR ANY AND ALL CLAIMS ARISING OUT OF OR RELATING TO THESE TERMS OR YOUR ACCESS TO OR USE OF (OR YOUR INABILITY TO ACCESS OR USE) ANY PORTION OF THE APP, WHETHER IN CONTRACT, TORT, STRICT LIABILITY, OR ANY OTHER LEGAL THEORY, IS LIMITED TO THE GREATER OF THE AMOUNTS YOU HAVE ACTUALLY AND LAWFULLY PAID US UNDER THESE TERMS IN THE TWO (2) MONTH PERIOD PRECEDING THE DATE THE CLAIM AROSE.
</p>
<p>
YOU ACKNOWLEDGE AND AGREE THAT WE HAVE MADE THE APP AVAILABLE TO YOU AND ENTERED INTO THESE TERMS IN RELIANCE UPON THE REPRESENTATIONS AND WARRANTIES,  DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH HEREIN, WHICH REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN US AND YOU AND FORM AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN US AND YOU. WE WOULD NOT BE ABLE TO PROVIDE THE APP TO YOU WITHOUT THESE LIMITATIONS.
</p>
<p>
9. ASSUMPTION OF RISK
</p>
<p>
(i) Value and Volatility.  The rights provided to you are for entertainment purposes only.   Without limiting the foregoing, the prices of collectible blockchain assets are extremely volatile and subjective and collectible blockchain assets have no inherent or intrinsic value. Fluctuations in the price of other digital assets could materially and adversely affect the value of your Collectibles, which may also be subject to significant price volatility. Each Collectible has no inherent or intrinsic value. We cannot (and do not) guarantee that any Collectibles purchased will retain their original value, as the value of collectibles is inherently subjective and, among other things, factors occurring outside of the Hornets NFT ecosystem may materially impact the value and desirability of any particular Collectible.
</p>
<p>
(ii) Tax Calculations. You are solely responsible for determining what, if any, taxes apply to your Collectible-related transactions. We are not responsible for determining the taxes that apply to your transactions on the App.
</p>
<p>
(iii) Use of Blockchain. The App does not store, send, or receive Collectibles. This is because Collectibles exist only by virtue of the ownership record maintained on the App’s supporting blockchain in the Flow network. Any transfer of Collectibles occurs within the supporting blockchain in the Flow network, and not on the App.
</p>
<p>
(iv) Inherent Risks with Internet Currency. There are risks associated with using an Internet-based currency, including, but not limited to, the risk of hardware, software and Internet connections, the risk of malicious software introduction, and the risk that third parties may obtain unauthorized access to information stored within your electronic wallet. You accept and acknowledge that we will not be responsible for any communication failures, disruptions, errors, distortions or delays you may experience when using the Flow Network, however caused.
</p>
<p>
(v) Regulatory Uncertainty. The regulatory regime governing blockchain technologies, cryptocurrencies and tokens is uncertain, and new regulations or policies may materially adversely affect the development of the Hornets NFT ecosystem, and therefore the potential utility or value of your Collectibles.
</p>
<p>
(vi) Software Risks. Upgrades to the Flow Network, a hard fork in the Flow Network, or a change in how transactions are confirmed on the Flow Network may have unintended, adverse effects on all blockchains using the Flow Network’s NFT standard, including the Hornets NFT ecosystem.  
</p>
<p>
10. INDEMNIFICATION
</p>
<p>
You agree to hold harmless and indemnify us, the Hornets, the NBA and each of our parents, subsidiaries, affiliates, officers, agents, employees, advertisers, licensors, suppliers or partners from and against any claim, liability, loss, damage (actual and consequential) of any kind or nature, suit, judgment, litigation cost and attorneys' fees arising out of or in any way related to: (i) your breach of these Terms; (ii) your misuse of the App; or (iii) your violation of applicable laws, rules or regulations in connection with your access to or use of the App. You agree that we will have control of the defense or settlement of any such claims.
</p>
<p>
11. EXTERNAL SITES
</p>
<p>
The App may include hyperlinks to other websites or resources (collectively, the “External Sites”), which are provided solely as a convenience to our users. We have no control over any External Sites. You acknowledge and agree that we are not responsible for the availability of any External Sites, and that we do not endorse any advertising, products or other materials on or made available from or through any External Sites. Furthermore, you acknowledge and agree that we are not liable for any loss or damage which may be incurred as a result of the availability or unavailability of the External Sites, or as a result of any reliance placed by you upon the completeness, accuracy or existence of any advertising, products or other materials on, or made available from, any External Sites.
</p>
<p>
12. FORCE MAJEURE
</p>
<p>
(i) Force Majeure Events. We will not be liable or responsible to the you, nor be deemed to have defaulted under or breached these Terms, for any failure or delay in fulfilling or performing any of these Terms, when and to the extent such failure or delay is caused by or results from the following force majeure events (“Force Majeure Event(s)”): (a) acts of God; (b) flood, fire, earthquake, epidemics, pandemics, including the 2019 novel coronavirus pandemic (COVID-19), tsunami, explosion; (c) war, invasion, hostilities (whether war is declared or not), terrorist threats or acts, riot or other civil unrest; (d) government order, law, or action; (e) embargoes or blockades in effect on or after the date of this agreement; (f) strikes, labor stoppages or slowdowns or other industrial disturbances; (g) shortage of adequate or suitable Internet connectivity, telecommunication breakdown or shortage of adequate power or electricity; and (h) other similar events beyond our control.
</p>
<p>
(ii) Performance During Force Majeure Events. If we suffer a Force Majeure Event, we will use reasonable efforts to promptly notify you of the Force Majeure Event, stating the period of time the occurrence is expected to continue. We will use diligent efforts to end the failure or delay and ensure the effects of such Force Majeure Event are minimized. We will resume the performance of our obligations as soon as reasonably practicable after the removal of the cause. In the event that our failure or delay remains uncured for a period of forty-five (45) consecutive days following written notice given by us under this Section 12, we may thereafter terminate these Terms upon fifteen (15) days' written notice.
</p>
<p>
13. CHANGES TO THE APP
</p>
<p>
We are constantly innovating the App to help provide the best possible experience. You acknowledge and agree that the form and nature of the App, and any part of it, may change from time to time without prior notice to you, and that we may add new features and change any part of the App at any time without notice.
</p>
<p>

14. CHILDREN
</p>
<p>
You affirm that you are over the age of 18.  The App is not intended for children under 18.  If you are under the age of 18, you may not use the App.  We do not knowingly collect information from or direct any of our content specifically to children under the age of 18.  If we learn or have reason to suspect that you are a user who is under the age of 18, we will unfortunately have to close your account.  Other countries may have different minimum age limits, and if you are below the minimum age for providing consent for data collection in your country, you may not use the App. 
</p>
<p>

15. PRIVACY POLICY
</p>
<p>
Our Privacy Policy describes the ways we collect, use, store and disclose your personal information, and is hereby incorporated by this reference into these Terms. You agree to the collection, use, storage, and disclosure of your data in accordance with our Privacy Policy.
</p>
<p>

16. DISPUTE RESOLUTION; BINDING ARBITRATION
</p>
<p>
YOU ARE AGREEING TO GIVE UP ANY RIGHTS TO LITIGATE CLAIMS IN A COURT. OTHER RIGHTS THAT YOU WOULD HAVE IF YOU WENT TO COURT MAY ALSO BE UNAVAILABLE OR MAY BE LIMITED IN ARBITRATION.
</p>
<p>
YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO HAVE A TRIAL BY JURY.
</p>
<p>
YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS IN ANY LAWSUIT, INCLUDING, BUT NOT LIMITED TO, CLASS ACTION LAWSUITS INVOLVING ANY SUCH DISPUTE.
</p>
<p>
(i) Binding Arbitration. All disputes arising out of or in connection with this contract, or in respect of any defined legal relationship associated therewith or derived therefrom, shall be referred to and finally resolved by arbitration under the International Commercial Arbitration Rules of Procedure of the Vancouver International Arbitration Centre.
</p>
<p>
The appointing authority shall be the Vancouver International Arbitration Centre.
</p>
<p>
The case shall be administered by the Vancouver International Arbitration Centre in accordance with its Rules.
</p>
<p>
The place of arbitration shall be Vancouver, British Columbia, Canada.
</p>
<p>
(ii) Arbitration Fees. Each party will cover its own fees and costs associated with the arbitration proceedings; however, if the arbitrator finds that you cannot afford to pay the fees and costs reasonably associated with the arbitration proceedings, we will pay them for you.
</p>
<p>
(iii) Award Enforcement. The award of the arbitrator will be final and binding, and any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction. The parties agree that they will not appeal any arbitration decision to any court.
</p>
<p>
(iv) Our Equitable Remedies. Notwithstanding the foregoing, we may seek and obtain injunctive relief in any jurisdiction in any court of competent jurisdiction, and you agree that these Terms are specifically enforceable by us through injunctive relief and other equitable remedies without proof of monetary damages.

</p>
<p>
17. GENERAL
</p>
<p>
(i) Entire Agreement.  These Terms and our Privacy Policy constitute the entire legal agreement between you and us and will be deemed to be the final and integrated agreement between you and us, and govern your access to and use of the App, and completely replace any prior or contemporaneous agreements between you and us related to your access to or use of the App, whether oral or written.
</p>
<p>
(ii) No Third-Party Beneficiaries. These Terms do not and are not intended to confer any rights or remedies upon any person or entity other than you
</p>
<p>
(iii) Interpretation. The language in these Terms will be interpreted as to its fair meaning, and not strictly for or against any party.
</p>
<p>
(iv) Severability. Should any part of these Terms be held invalid, illegal, void or unenforceable, that portion will deemed severed from these Terms and will not affect the validity or enforceability of the remaining provisions of these Terms.
</p>
<p>
(v) No Waivers. Our failure or delay to exercise or enforce any right or provision of these Terms will not constitute or be deemed a waiver of future exercise or enforcement of such right or provision. The waiver of any right or provision of these Terms will be effective only if in writing and signed for and on behalf of us by a duly authorized representative.
</p>
<p>
(vi) Governing Law. All matters arising out of or relating to these Terms will be governed by and construed in accordance with the laws of the Province of British Columbia and the federal laws of Canada applicable therein without giving effect to any choice or conflict of law provision or rule (whether of the Province of British Columbia or any other jurisdiction).
</p>
<p>
(vii) Venue. Subject to Section 16 of these Terms, any legal action or proceeding arising under these Terms will be brought exclusively in the Supreme Court of the Province of British Columbia located in Vancouver, British Columbia, and we and you irrevocably consent and attorn to the personal jurisdiction and venue there.
</p>
<p>
(viii) Notices. We may provide you with any notices (including, without limitation those regarding changes to these Terms) by email or postings on the App. By providing us with your email address, you consent to our using the email address to send you any notices. Notices sent by email will be effective when we send the email, and notices we provide by posting will be effective upon posting. It is your responsibility to keep your email address current.
</p>
<p>
(ix) Assignment. You may not assign any of your rights or obligations under these Terms, whether by operation of law or otherwise, without our prior written consent. We may assign our rights and obligations under these Terms in our sole discretion to an affiliate, or in connection with an acquisition, sale or merger.
</p>
14. CHILDREN You affirm that you are over the age of 18. The App is not intended for children under 18. If you are under the age of 18, you may not use the App. We do not knowingly collect information from or direct any of our content specifically to children under the age of 18. If we learn or have reason to suspect that you are a user who is under the age of 18, we will unfortunately have to close your account. Other countries may have different minimum age limits, and if you are below the minimum age for providing consent for data collection in your country, you may not use the App.
`;

export default { PRIVACY_POLICY, TERMS_OF_SERVICE };
