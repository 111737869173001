import SmallNFTCard from "components/AppComponent/SmallNFTCard";
import Modal from "components/Widjets/Modal";
import { ClientConfig } from "config";
import { AvailableLanguage } from "lib/contexts/LanguageContext";
import { Info } from "react-feather";
import { NftPreview } from ".";
import styles from "../common.module.scss";
import customStyles from "./custom.module.scss";
import { Recipient } from "./RecipientStep";
import TransferStepLoading from "./TransferStepLoading";

export type TransferQueryInfo = {
  isTransferLoading: boolean;
  isTransferSuccess: boolean;
  transferErrorMessage?: string | null;
  transferReset?: () => void;
  transfer: () => void;
};

type Props = {
  transferStep: TransferQueryInfo;
  setRecipientValue: React.Dispatch<React.SetStateAction<string>>;
  recipient?: Recipient;
  recipientReset?: () => void;
  nft: NftPreview;
  onClose: () => void;
  strings: ClientConfig["Strings"]["Modals"]["Sell"][AvailableLanguage];
};

export default function TransferStep(props: Props) {
  const localizedStrings = props.strings.transferModal.transferStep;

  if (props.transferStep.isTransferLoading) {
    return (
      <TransferStepLoading
        nft={props.nft}
        strings={props.strings}
        recipient={props.recipient}
      />
    );
  } else if (props.transferStep.isTransferSuccess) {
    return (
      <Modal
        clickOutsideToClose={false}
        isVisible
        modalClassName={`${styles.genericModal} ${customStyles.transferModal}`}
        onClose={() => {
          props.recipientReset?.();
          props.transferStep.transferReset?.();
          props.onClose();
        }}
      >
        <h3>
          {props.transferStep.isTransferSuccess
            ? localizedStrings.successTitle
            : localizedStrings.title}
        </h3>
        <h5 className="mt-2 mb-3">{localizedStrings.successSubtitle}</h5>
        {props.recipient?.name && (
          <div className={customStyles.confimUserInfo}>
            <span className="mr-2">{localizedStrings.profileName}:</span>
            <span>{props.recipient.name}</span>
          </div>
        )}
        {props.recipient?.address && (
          <div className={customStyles.confimUserInfo}>
            <span className="mr-2">{localizedStrings.address}:</span>
            <span>{props.recipient.address}</span>
          </div>
        )}
        <SmallNFTCard nft={props.nft as NftPreview} success />
      </Modal>
    );
  } else {
    return (
      <Modal
        clickOutsideToClose={false}
        isVisible
        modalClassName={`${styles.genericModal} ${customStyles.transferModal}`}
        onClose={() => {
          props.recipientReset?.();
          props.transferStep.transferReset?.();
          props.onClose();
        }}
        onActionClick={props.transferStep.transfer}
        btnTxt={localizedStrings.transferBtn}
        cancelTxt={localizedStrings.changeInput}
        onCancelClick={() => {
          props.transferStep.transferReset?.();
          props.recipientReset?.();
        }}
      >
        <h3>
          {props.transferStep.transferErrorMessage
            ? localizedStrings.errorTitle
            : localizedStrings.title}
        </h3>
        <h5 className="mt-2 mb-3">
          {props.transferStep.transferErrorMessage
            ? localizedStrings.errorSubtitle
            : localizedStrings.confirmSubtitleWithUser}
        </h5>
        {props.recipient?.name && (
          <div className={customStyles.confimUserInfo}>
            <span className="mr-2">{localizedStrings.profileName}:</span>
            <span>{props.recipient.name}</span>
          </div>
        )}
        {props.recipient?.address && (
          <div className={customStyles.confimUserInfo}>
            <span className="mr-2">{localizedStrings.address}:</span>
            <span>{props.recipient.address}</span>
          </div>
        )}
        <SmallNFTCard nft={props.nft as NftPreview} />
        {props.transferStep.transferErrorMessage && (
          <span className={`${customStyles.error} d-flex`}>
            <Info style={{ marginRight: "0.5rem" }} />
            {props.transferStep.transferErrorMessage}
          </span>
        )}
      </Modal>
    );
  }
}
