const cancelSaleScript = `
    import MintStoreMarketFactory from 0xMintStoreMarketFactory

    // This transaction is for a user to remove a token from a sale collection in their account
    //
    // Parameters
    //
    // tokenID: the ID of the token whose sale is to be delisted
    // marketAddress: the address of the market from which the token is to be delisted

    transaction(tokenID: UInt64, marketAddress: Address) {

        prepare(acct: AuthAccount) {

            let marketRef = getAccount(marketAddress).getCapability(MintStoreMarketFactory.MarketPublicPath).borrow<&{MintStoreMarketFactory.MarketPublic}>()
                ?? panic("Cannot borrow a reference to the account's Market")

            // borrow a reference to the owner's sale collection
            let saleCollection = acct.borrow<&MintStoreMarketFactory.SaleCollection>(from: marketRef.SaleCollectionStoragePath)
                ?? panic("Could not borrow from sale in storage")

            // cancel the token from the sale, thereby de-listing it
            saleCollection.cancelSale(tokenID: tokenID)
        }
    }
`;
export default cancelSaleScript;
