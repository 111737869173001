import React from "react";
import styles from "./style.module.scss";
interface Props {
  show?: boolean;
}
const Loader: React.FunctionComponent<Props> = ({ show = false }: Props) => {
  const loader = (
    <div className={styles.loaderContainer}>
      <div className={styles.loader} />
    </div>
  );
  return show ? loader : null;
};

export default Loader;
