import { ethAPI } from "lib/store/api/eth";
import { useCurrentWallet } from "lib/store/api/eth/hooks";
import { useAppSelector } from "lib/store/hooks";
import { selectEthUser } from "lib/store/slices/user-slice";
import { getStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import { ModalVisible } from "../NftShowPage";
import { ClaimButtonUI } from "../ui/claim-button";

export function ClaimButtonEthConnector({
  itemEID,
  contractAddress,
  claim,
  setDisplayUnclaimableMessage,
  setModalVisible,
}: {
  itemEID: string;
  contractAddress: string;
  claim: (walletAddress: string, nftID: number) => void;
  setDisplayUnclaimableMessage: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  setModalVisible: React.Dispatch<React.SetStateAction<ModalVisible | null>>;
}) {
  const localizedStrings = getStrings("Pages", "Details")[useLanguage()];

  const user = useAppSelector(selectEthUser);

  const { data: nft } = ethAPI.useGetNftQuery({ itemEID, contractAddress });
  const { data: nftState } = ethAPI.useGetNftStateQuery({
    itemEID,
    contractAddress,
    user,
  });

  // TODO: find a way to monitor the blockchain status
  const isUnderMaintenance = false;

  const wallet = useCurrentWallet();

  const canClaim =
    nftState != null &&
    ((nftState.isOwner && !nftState.isClaimed) || nftState.isClaimProcessing);

  if (!canClaim || nft == null) {
    return null;
  }

  let disabledReason: string | undefined;

  if (nftState?.isClaimProcessing) {
    disabledReason = localizedStrings.isClaimProcessing;
  } else if (wallet == null) {
    disabledReason = localizedStrings.noWalletDetected;
  } else if (isUnderMaintenance) {
    disabledReason = localizedStrings.blockchainMaintenance;
  }

  const onClaimClick = () => {
    if (wallet != null && nft.id != null) {
      claim(wallet.address, nft.id);
      setModalVisible("claim");
    }
  };

  const isClaimable = () => {
    return wallet != null && nft.id != null;
  };

  return (
    <ClaimButtonUI
      disabledReason={disabledReason}
      nft={nft}
      onClaimClick={onClaimClick}
      isClaimable={isClaimable()}
      setDisplayUnclaimableMessage={setDisplayUnclaimableMessage}
    />
  );
}
