import { SmartContract } from "types";
import * as Blockchain from "../../../global-blockchain-config";

const MERCHANT_LIST = {
  Testnet: [{ id: "31", address: "0x00007869636b656d" }],
  Mainnet: [{ id: "89", address: "0x00007069636b656d" }],
};

const MINT_STORE_MARKET = {
  Testnet: { id: 4, address: "0x00007869636b656d" }, //TBD
  Mainnet: { id: 0, address: "0x10007069636b656d" }, //TBD
};

const WALLET_VIEWER_ID = {
  Testnet: 17,
  Mainnet: 32,
};

const KYC_MERCHANT_ID = {
  Testnet: 1,
  Mainnet: 1,
};

const DISCOVERY_WALLET = {
  Testnet: "https://staging.accounts.meetdapper.com/fcl/authn-restricted",
  Mainnet: "https://accounts.meetdapper.com/fcl/authn-restricted",
};

const SMART_CONTRACTS: SmartContract[] = [
  {
    id:
      Blockchain.NET === "Testnet"
        ? "787d606b-50b0-4834-a3b0-d5451f6f6dd3"
        : "93302dd9-5a61-4ea2-9ea3-060989477c49",
    name: "Lufthaus",
    type: "PFP",
    scarcity: null,
    attributes: [],
    address:
      Blockchain.NET === "Testnet"
        ? "0x985d410b577fd4a1"
        : "0x20187093790b9aef",
    publicCollectionStoragePath: "LufthausCollectionPublic",
    providerPath: "LufthausCollection",
  },
];

export {
  MERCHANT_LIST,
  MINT_STORE_MARKET,
  WALLET_VIEWER_ID,
  KYC_MERCHANT_ID,
  DISCOVERY_WALLET,
  SMART_CONTRACTS,
};
