import { Blockchain } from "config";
import EthConnector from "./connectors/EthConnector";
import FlowConnector from "./connectors/FlowConnector";

export default function RedemptionNFT() {
  switch (Blockchain.BLOCKCHAIN_NAME) {
    case "flow":
      return <FlowConnector />;
    case "polygon":
      return <EthConnector />;
  }
}
