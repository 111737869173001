import { ClientConfig } from "config";
import { AvailableLanguage } from "lib/contexts/LanguageContext";
import { FC, ReactNode } from "react";
import { Flow } from "types";
import styles from "./styles.module.scss";
import { Outlet, useOutletContext } from "react-router-dom";
import Editions from "./template/editions";
import { HeadingProps, Merchants, Header } from "./template/index";
import Items from "./template/items";
import { UIProps } from "./template/types";

type UiChildrenComponents = {
  Header: FC<HeadingProps>;
  Merchants: FC<UIProps<Flow.Merchant>>;
  Editions: FC<UIProps<Flow.EditionForSale>>;
  Items: FC<UIProps<Flow.ItemForSale>>;
};
export type MarketplaceProps = {
  children?: ReactNode;
  onSearchClear: () => void;
  onSearchChange?: () => void;
  sorting: NftSortingOptions | null;
  strings: ClientConfig["Strings"]["Pages"]["ArtistMarketplace"][AvailableLanguage];
  searchTerm: string;
};

const MarketplaceUI: FC<MarketplaceProps> & UiChildrenComponents = (props) => {
  return (
    <div className={styles.marketplacePage}>
      <div className={styles.container}>
        {props.children}
        <Outlet context={{ ...props }}></Outlet>
      </div>
    </div>
  );
};
MarketplaceUI.Header = Header;
MarketplaceUI.Merchants = Merchants;
MarketplaceUI.Editions = Editions;
MarketplaceUI.Items = Items;

export function useMarketplaceContext() {
  return useOutletContext<MarketplaceProps>();
}
export default MarketplaceUI;
