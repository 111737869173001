import { ReactNode, useState } from "react";
import styles from "./style.module.scss";
import Checkbox from "../Checkbox";
import useIsMobile from "hooks/useIsMobile";
import SkeletonTableRow from "../SkeletonTableRow";
import { getStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import Chip from "../Chip";
import { SetupStatus } from "components/AppComponent/ActionModals/SetupWizard/connectors/SetupWizardFlowConnector";

type ConnectButtonText = {
  loadingText: string;
  notLoadingText: string;
};

export type TableHeader<T> = {
  text: string;
  value: string;
  align?: "start" | "center" | "end";
  display?: (value: T) => ReactNode | JSX.Element;
  connectChip?: {
    setupStatus: SetupStatus;
    text: ConnectButtonText;
    setup: (smartContract: string) => void;
  };
  cellClass?: string;
  // Use this to dynamically show/hide a column
};

export type TableProps<T> = {
  headers: TableHeader<T>[];
  footer?: boolean;
  items: Record<string, any>[] | [];
  itemKey?: string;
  showSelect?: boolean;
  selected?: string[];
  singleSelection?: boolean;
  loading?: boolean;
  noDataMsg?: string;
  onSelection?: (_selected: string[]) => void;
  onRowClick?: () => void;
  hoverEffect?: boolean;
};
function DataTable<T>({
  headers,
  items,
  onSelection,
  selected = [],
  itemKey = "id",
  showSelect = false,
  singleSelection = true,
  footer = false,
  loading = false,
  onRowClick,
  noDataMsg,
  hoverEffect = true,
}: TableProps<T>) {
  const localizedStrings = getStrings("Component", "Table")[useLanguage()];
  const [_selected, setSelected] = useState<string[]>(selected);
  const _isMobile = useIsMobile();

  const connectChipLoading = (
    header: TableHeader<T>,
    item: Record<string, any>
  ) => {
    const setupStatus = header?.connectChip?.setupStatus;
    const isLoading = setupStatus?.isLoading;
    if (isLoading && setupStatus) {
      const smartContractLoading =
        setupStatus?.originalArgs?.smartContract.name === item.name;

      if (smartContractLoading) return true;
    }
  };

  const connectChipText = (header: TableHeader<T>, item: Record<string, any>) =>
    connectChipLoading(header, item)
      ? header.connectChip?.text.loadingText
      : header.connectChip?.text.notLoadingText;

  const _onSelection = (selected: boolean, itemUniqueKey: string) => {
    let selectedCopy = [..._selected];
    if (selected && singleSelection) selectedCopy = [itemUniqueKey];
    else if (!selected && singleSelection) selectedCopy = [];
    else if (selected && !singleSelection) selectedCopy.push(itemUniqueKey);
    else {
      const removeIdx = selectedCopy.indexOf(itemUniqueKey);
      if (removeIdx > -1) selectedCopy.splice(removeIdx, 1);
    }

    if (onSelection) onSelection(selectedCopy);
    setSelected(selectedCopy);
  };

  const _onRowClick = (itemUniqueKey: string) => {
    if (onRowClick) onRowClick();
    else if (showSelect) {
      const selectedIdx = _selected.indexOf(itemUniqueKey);
      const selected = selectedIdx === -1;
      _onSelection(selected, itemUniqueKey);
    }
  };

  const renderCellClassed = (header: TableHeader<T>) => {
    let cellClasses = ``;
    cellClasses += `text-${header?.align ?? "start"}`;
    if (header?.cellClass) cellClasses += ` ${header.cellClass}`;

    return cellClasses;
  };

  return (
    <div
      className={`${styles.dataTable} ${
        _isMobile ? styles.dataTableMobile : ""
      } ${hoverEffect && styles.hoverEffect}`}
    >
      <div className={`${styles.tableWrapper}`}>
        <table className={styles.table}>
          <colgroup>
            {headers.map((header, colGroupIdx) => (
              <col key={`colgroup-${colGroupIdx}`} />
            ))}
          </colgroup>
          <thead>
            {!_isMobile && (
              <tr>
                {showSelect && <col />}

                {headers.map((header, idx) => (
                  <th
                    key={`header_${header.value}_${idx}`}
                    className={renderCellClassed(header)}
                    role="column_header"
                    scope="col"
                    aria-label={header.text}
                  >
                    <span>{header.text}</span>
                  </th>
                ))}
              </tr>
            )}
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr
                key={`item-${item[itemKey]}-${index}`}
                className={`${_isMobile ? styles.mobileTableRow : ""}`}
                onClick={() => _onRowClick(item[itemKey])}
              >
                {showSelect && (
                  <td
                    className={`${
                      _isMobile
                        ? `${styles.mobileRow} ${styles.selectable}`
                        : ""
                    }`}
                  >
                    <label className={styles.checkbox}>
                      <Checkbox
                        selected={_selected.includes(item[itemKey])}
                        onChange={(isSelected) =>
                          _onSelection(isSelected, item[itemKey])
                        }
                      />
                    </label>
                  </td>
                )}
                {headers.map((header, headerIdx) =>
                  !_isMobile ? (
                    <td
                      className={renderCellClassed(headers[headerIdx])}
                      key={`data-${index}-${headerIdx}-${header.value}`}
                    >
                      <span>
                        {header.display
                          ? header.display(item[header.value])
                          : item[header.value]}
                      </span>
                      {!item.isSetup && header.connectChip && (
                        <Chip
                          disabled={
                            header.connectChip.setupStatus.isLoading &&
                            !connectChipLoading(header, item)
                          }
                          loading={connectChipLoading(header, item)}
                          type="primary"
                          onClick={() => {
                            header.connectChip?.setup(item.name);
                          }}
                        >
                          {connectChipText(header, item)}
                        </Chip>
                      )}
                    </td>
                  ) : (
                    <td
                      className={styles.mobileRow}
                      key={`data-${index}-${headerIdx}-${header.value}`}
                    >
                      <div className={styles.mobileRowHeader}>
                        {header.text}
                      </div>
                      <div className={styles.mobileRowData}>
                        <div className={styles.mobileRowDataContainer}>
                          <div>
                            {header.display
                              ? header.display(item[header.value])
                              : item[header.value]}
                          </div>
                        </div>
                      </div>
                    </td>
                  )
                )}
              </tr>
            ))}

            <tr>
              <td />
            </tr>
          </tbody>
        </table>
        {loading && <SkeletonTableRow count={3} />}
        {items.length === 0 && !loading && (
          <div className={styles.noData}>
            <span>{noDataMsg ? noDataMsg : localizedStrings.noData}</span>
          </div>
        )}
      </div>
      {footer && <div className={styles.footer}>this is a footer</div>}
    </div>
  );
}

export default DataTable;
