import { useEditions, useNftList } from "lib/store/api/flow/hooks";
import NFTCollections from "./ui/nft-collections";
import styles from "./styles.module.scss";
import Playlists from "./ui/playlists";
import Marketplace from "./ui/marketplace";
import { useEffect, useState } from "react";
import { LoaderCircle } from "components";
import { Config, getStrings } from "config";
import Archive from "./ui/archive";
import Discord from "./ui/discord";
import Products from "./ui/products";
import Videos from "./ui/videos";
import { useUtility } from "lib/store/api/utility/hooks";
import ErrorPage from "pages/ErrorPage/ErrorPage";
import { useLanguage } from "lib/contexts/LanguageContext";
import { useRulesets } from "lib/store/api/ruleset/hooks";

export type UtilityItem = {
  name: string | null;
  description: string | null;
  thumbnail: string | null;
  itemFID?: string | null;
  link?: string | null;
};

export default function Utility() {
  const strings = getStrings("Pages", "Utility")[useLanguage()];
  const { data: nftCollection } = useNftList(0, 4);
  const { data, isFetching: isUtilityFetching, isError } = useUtility();
  const { data: rulesets, isFetching: isRulesetsFetching } = useRulesets(
    data?.rulesetIds
  );

  const gatedProducts = data?.utilities.products ?? [];
  const gatedPlaylists = data?.utilities.playlists ?? [];
  const gatedVideos = data?.utilities.videos ?? [];
  const gatedFiles = data?.utilities.files ?? [];
  const gatedDiscord = Config.Utility.UTILITY_DISCORD ?? [];

  const [editionsPage, setEditionsPage] = useState<number>(0);
  const { data: editions, total: totalEditions } = useEditions(
    editionsPage,
    {}
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const TIMEOUT_DELAY = 0;

  useEffect(() => {
    const loadingTimeout = setTimeout(() => setIsLoading(false), TIMEOUT_DELAY);

    return () => {
      clearTimeout(loadingTimeout);
    };
  }, []);

  if (isError) {
    return <ErrorPage />;
  }

  if (isLoading || isUtilityFetching || isRulesetsFetching) {
    return (
      <div className={styles.utilityPage}>
        <div className={styles.loaderContainer}>
          <LoaderCircle size={75} />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.utilityPage}>
      <div className={styles.container}>
        <h1>{Config.Client.TEAM_NAME}</h1>
        <div className={styles.headingImage}>
          <div className={styles.background}>
            <img src={Config.Utility.UTILITY_BANNER} />
          </div>
          <div className={styles.content}>
            <h1>{strings.heading.title}</h1>
            <p>{strings.heading.description}</p>
            {Config.Utility.UTILITY_BANNER_BUTTON_LINK && (
              <a href={Config.Utility.UTILITY_BANNER_BUTTON_LINK}>
                {strings.heading.buttonText}
              </a>
            )}
          </div>
        </div>
        <p className={styles.description}>{strings.introText}</p>

        {Config.Utility.UTILITY_SECTIONS.map((section: UtilitySection, i) => {
          switch (section) {
            case "archives":
              return (
                gatedFiles.length > 0 && (
                  <Archive
                    key={`utility_${i}`}
                    archives={gatedFiles}
                    rulesets={rulesets}
                  />
                )
              );
            case "collection":
              return (
                nftCollection.length > 0 && (
                  <NFTCollections
                    key={`utility_${i}`}
                    nftCollection={nftCollection}
                  />
                )
              );
            case "discord":
              return (
                gatedDiscord.length > 0 && (
                  <Discord key={`utility_${i}`} streams={gatedDiscord} />
                )
              );
            case "marketplace":
              return (
                editions.length > 0 && (
                  <Marketplace
                    key={i}
                    editions={editions}
                    totalEditions={totalEditions}
                    setEditionsPage={setEditionsPage}
                  />
                )
              );
            case "merchandise":
              return (
                gatedProducts.length > 0 && (
                  <Products
                    key={`utility_${i}`}
                    products={gatedProducts}
                    rulesets={rulesets}
                  />
                )
              );
            case "playlists":
              return (
                gatedPlaylists.length > 0 && (
                  <Playlists
                    key={`utility_${i}`}
                    playlists={gatedPlaylists}
                    rulesets={rulesets}
                  />
                )
              );
            case "videos":
              return (
                gatedVideos.length > 0 && (
                  <Videos key={i} videos={gatedVideos} rulesets={rulesets} />
                )
              );
            default:
              return <></>;
          }
        })}
      </div>
    </div>
  );
}
