import { Config, Project } from "config";
import { NET } from "config/global/blockchain";
import {
  EDITION_DETAILS,
  MARKETPLACE_MERCHANT_ROUTE,
  MARKETPLACE_PFP_ROUTE,
  MARKETPLACE_ROUTE,
  NFT_SHOW,
  PUBLIC_NFT_ROUTE,
  SHOW,
} from "config/routes";
import { Link, useLocation } from "react-router-dom";
import styles from "./styles.module.scss";
import { withPrefix } from "@onflow/fcl";
import { useAppSelector } from "lib/store/hooks";
import { selectAppUser } from "lib/store/slices/user-slice";

type Breadcrumb = {
  name?: string | null;
  route?: string;
};

type Props = {
  itemName?: string | null;
  itemFID?: string | null;
  merchantFID?: string | null;
  smartContractName?: string | null;
  smartContractAddress?: string | null;
  profileName?: string | null;
  editionFID?: string | null;
};

export default function Breadcrumbs({
  itemName,
  itemFID,
  merchantFID,
  smartContractName,
  smartContractAddress,
  profileName,
  editionFID,
}: Props) {
  const user = useAppSelector(selectAppUser);
  const location = useLocation();
  const hasMultiMerchants = Project.MERCHANT_LIST[NET].length > 1;

  const multiMarketplaceHome = {
    name: Config.Client.TEAM_NAME,
    route: MARKETPLACE_ROUTE,
  };

  const merchantMarketplaceBreadcrumb = {
    name: profileName,
    route: MARKETPLACE_MERCHANT_ROUTE.replace(":id", merchantFID ?? "").replace(
      ":profileName",
      profileName ?? ""
    ),
  };

  const breadcrumbs: Breadcrumb[] = hasMultiMerchants
    ? [multiMarketplaceHome]
    : [];

  const editionsBreadcrumb = {
    name: itemName,
    route: `${EDITION_DETAILS}/${withPrefix(
      smartContractAddress
    )}/${smartContractName}/${editionFID}`,
  };

  const pfpListBreadcrumb = {
    name: smartContractName,
    route: merchantMarketplaceBreadcrumb.route.concat(
      `/${MARKETPLACE_PFP_ROUTE}`
        .replace(
          ":smartContractAddress",
          withPrefix(smartContractAddress) ?? ""
        )
        .replace(":smartContractName", smartContractName ?? "")
    ),
  };

  const pfpDetailsBreadcrumb = {
    name: `${smartContractName} - ${itemFID}`,
    route: user
      ? NFT_SHOW.replace(
          ":smartContractAddress",
          withPrefix(smartContractAddress) ?? ""
        )
          .replace(":smartContractName", smartContractName ?? "")
          .replace(":itemFID", itemFID ?? "")
      : `${PUBLIC_NFT_ROUTE}/${SHOW}`
          .replace(":contractAddress", withPrefix(smartContractAddress) ?? "")
          .replace(":contractName", smartContractName ?? "")
          .replace(":id", itemFID ?? ""),
  };

  switch (decodeURI(location.pathname)) {
    case merchantMarketplaceBreadcrumb.route:
      {
        if (hasMultiMerchants) {
          breadcrumbs.push(merchantMarketplaceBreadcrumb);
        }
      }
      break;
    case editionsBreadcrumb.route:
      {
        breadcrumbs.push(merchantMarketplaceBreadcrumb, editionsBreadcrumb);
      }
      break;
    case pfpListBreadcrumb.route:
      {
        breadcrumbs.push(merchantMarketplaceBreadcrumb, pfpListBreadcrumb);
      }
      break;
    case pfpDetailsBreadcrumb.route: {
      breadcrumbs.push(
        merchantMarketplaceBreadcrumb,
        pfpListBreadcrumb,
        pfpDetailsBreadcrumb
      );
    }
  }

  if (breadcrumbs.length > 1) {
    return (
      <ol className={styles.container}>
        {breadcrumbs.map((breadcrumb, index) => (
          <li>
            {index === breadcrumbs.length - 1 ? (
              <span>{breadcrumb.name}</span>
            ) : (
              <>
                <Link to={breadcrumb.route ?? ""}>{breadcrumb.name}</Link>
                <span className="mx-1">{">"}</span>
              </>
            )}
          </li>
        ))}
      </ol>
    );
  } else {
    return null;
  }
}
