import { Blockchain, Project } from "config";
import { Builder } from "./builder";
import feathersClient from "lib/feathers";
import axios from "axios";
import { setError } from "lib/store/slices/error-slice";
import {
  getMetaMask,
  getMintStoreContract,
  getMintStoreContractMetamask,
  walletProvider,
} from "./web3";
import { getChainConfig } from "config/global/blockchain/polygon";
import { EthereumRpcError, serializeError } from "eth-rpc-errors";
import { setEthUserLoading } from "lib/store/slices/user-slice";

export const ethOnchainActionsEndpoints = (builder: Builder) => ({
  transferNft: builder.mutation<
    null,
    {
      senderAddress: string;
      recipientAddress: string;
      itemEID: string;
      contractAddress: string;
    }
  >({
    async queryFn(
      { senderAddress, recipientAddress, itemEID, contractAddress },
      { dispatch }
    ) {
      switch (walletProvider) {
        case "Blocto": {
          await getMintStoreContract(contractAddress, { needAuth: true })
            .methods.transferFrom(senderAddress, recipientAddress, itemEID)
            .send({ from: senderAddress });
          break;
        }
        case "MetaMask": {
          try {
            //check chain
            const { metamaskProvider } = await getMetaMask();
            if (!metamaskProvider) throw "MetaMask not found";
            const chain = metamaskProvider.networkVersion;

            if (chain != getChainConfig().chainId) {
              const error = new EthereumRpcError(
                4902,
                `Please switch to ${getChainConfig().name} network.`
              );
              throw error;
            }

            dispatch(setEthUserLoading(true));
            await (await getMintStoreContractMetamask(contractAddress)).methods
              .transferFrom(senderAddress, recipientAddress, itemEID)
              .send({
                from: senderAddress,
                maxPriorityFeePerGas: null,
                maxFeePerGas: null,
              });
          } catch (error) {
            dispatch(setEthUserLoading(false));
            const serializedError = serializeError(error);
            if (serializedError) {
              dispatch(
                setError({
                  type: "transaction",
                  code: serializedError.code.toString(),
                  message: serializedError.message,
                  provider: "MetaMask",
                })
              );
            }
            throw error;
          }
        }
      }

      dispatch(setEthUserLoading(false));
      return { data: null };
    },
    invalidatesTags: (_, __, { itemEID, contractAddress }) => [
      { type: "EthNft", id: `${contractAddress}-${itemEID}` },
      "EthNft",
    ],
  }),
  claimNft: builder.mutation<
    null,
    {
      toAddress: string;
      nft: { id: number; itemEID: string; contractAddress: string };
    }
  >({
    async queryFn({ toAddress, nft }) {
      const { accessToken } = await feathersClient.get("authentication");
      await axios.post(
        `${Project.CLAIM_API_URL}/claim-nft`,
        {
          blockchain: Blockchain.BLOCKCHAIN_NAME,
          address: toAddress,
          purchasedNftId: nft.id,
        },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      return { data: null };
    },
    invalidatesTags: (_, __, { nft: { itemEID, contractAddress } }) => [
      { type: "EthNft", id: `${contractAddress}-${itemEID}` },
      "EthNft",
    ],
  }),
});
