import { ethAPI } from "lib/store/api/eth";
import { useCurrentWallet } from "lib/store/api/eth/hooks";
import { useAppSelector } from "lib/store/hooks";
import { selectEthUser } from "lib/store/slices/user-slice";
import { TransferButtonUI } from "../ui/TransferButtonUI";
import { ModalVisible } from "../NftShowPage";

export function TransferButtonEthConnector({
  itemEID,
  contractAddress,
  setModalVisible,
}: {
  itemEID: string;
  contractAddress: string;
  setModalVisible: React.Dispatch<React.SetStateAction<ModalVisible | null>>;
}) {
  const user = useAppSelector(selectEthUser);
  const wallet = useCurrentWallet();

  const { data: nft } = ethAPI.useGetNftQuery({ itemEID, contractAddress });
  const { data: nftState } = ethAPI.useGetNftStateQuery({
    itemEID,
    contractAddress,
    user,
  });

  // TODO: find a way to monitor the blockchain status
  const isUnderMaintenance = false;

  const canTransfer =
    nftState != null &&
    nftState.isClaimed &&
    !nftState.isForSale &&
    nftState.isOwner;

  if (!canTransfer || nft == null) {
    return null;
  }

  return (
    <TransferButtonUI
      disabled={
        wallet?.address !== nft.currentAddress ||
        isUnderMaintenance ||
        wallet == null
      }
      nft={nft}
      setModalVisible={setModalVisible}
    />
  );
}
