import { FunctionComponent } from "react";
import styles from "../styles.module.scss";
import { Props } from "..";
import { Utility } from "types";
import { Download, Lock } from "react-feather";
import { Config } from "config";
import { AnimatedNFT } from "components";
import LockedAlert from "components/AppComponent/LockedAlert";

type UIProps = {
  file: Utility.File;
} & Props;

const FileUI: FunctionComponent<UIProps> = ({ file, ruleset, strings }) => {
  return (
    <>
      {!file?.isAvailable && <LockedAlert message={ruleset?.description} />}
      <div className={styles.container}>
        <div className={styles.headingImage}>
          <div className={styles.background}>
            <img src={Config.Utility.UTILITY_BANNER_FILE} />
          </div>
          <div className={styles.content}>
            <h1>{file.name}</h1>
          </div>
        </div>
        <div className={styles.grid}>
          <div className={styles.gridItem}>
            <div className={styles.card}>
              {file.isAvailable && (
                <div className={styles.downloadBtn}>
                  <a href={file.url} target="_blank">
                    <Download size={32} />
                    {strings.downloadFile}
                    <small>
                      {strings.type}: {file.type}
                    </small>
                  </a>
                </div>
              )}
              {!file.isAvailable && (
                <AnimatedNFT
                  src={file?.thumbnail}
                  thumbnail={file?.thumbnail}
                  name={file?.name}
                  fileType={"Image"}
                  className={styles.imageLocked}
                >
                  <div className={styles.imgScrim}></div>
                  <div className={styles.imgLabel}>
                    <Lock size={20} /> {strings.locked}
                  </div>
                </AnimatedNFT>
              )}
            </div>
          </div>
          <div className={styles.gridItem}>
            <div className={styles.card}>
              <h2>{strings.info}</h2>
              <hr />
              <p>
                {file.description && file.description.length > 0 ? (
                  file.description
                ) : (
                  <i>{strings.noDescription}</i>
                )}
              </p>
            </div>
            {!file.isAvailable && (
              <div className={styles.card}>
                <h2>{strings.rules}</h2>
                <hr />
                <p>
                  {ruleset?.description ?? <i>{strings.noRulesDescription}</i>}
                </p>
              </div>
            )}
            {/* Ruleset Tree not ready yet - leave it hidden until then! */}
            {/* <div className={styles.requirements}>
              <hr />
              <p>{strings.rulesetTree}</p>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default FileUI;
