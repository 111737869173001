import style from "./style.module.scss";
import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { XCircle } from "react-feather";
import Button from "../Button";
import { useLanguage } from "lib/contexts/LanguageContext";
import { getStrings } from "config";
const strings = getStrings("Component", "Modal");
type Props = {
  clickOutsideToClose?: boolean;
  fullscreen?: boolean;
  isVisible?: boolean;
  className?: string;
  modalClassName?: string;
  backdropClassName?: string | null;
  contentClassName?: string;
  onClose?: () => void;
  onActionClick?: () => void;
  onCancelClick?: () => void;
  btnTxt?: string;
  cancelTxt?: string;
  btnLoading?: boolean;
  btnDisabled?: boolean;
  children: React.ReactNode;
};
const Modal: React.FunctionComponent<Props> = ({
  clickOutsideToClose = true,
  children,
  className,
  modalClassName,
  backdropClassName,
  contentClassName,
  onClose,
  onActionClick,
  onCancelClick,
  fullscreen = false,
  isVisible = false,
  btnTxt,
  cancelTxt,
  btnLoading = false,
  btnDisabled = false,
}) => {
  const lang = useLanguage();
  const containerRef = useRef<HTMLDivElement>(null);
  const [_isVisible, setIsVisible] = useState(isVisible);
  let overlayClass = style.modalOverlay;
  let contentClass = style.content;
  let modalClass = style.modal;
  let backdropClass = style.scrim;

  if (_isVisible) overlayClass += ` ${style.modalOverlay} ${style.active} `;
  if (!_isVisible) overlayClass += ` ${style.dNone} `;
  if (className) overlayClass += className;

  if (fullscreen) contentClass += ` ${style.fullscreen}`;
  if (contentClassName) contentClass += ` ${contentClassName}`;
  if (modalClassName) modalClass += ` ${modalClassName}`;
  if (backdropClassName) {
    backdropClass += ` ${backdropClassName}`;
  } else if (backdropClassName === null) {
    backdropClass = "";
  }

  useEffect(() => {
    setIsVisible(isVisible);

    const body = document.getElementById("body");

    if (!body) return;
    body.style.overflow = isVisible ? "hidden" : "auto";

    return () => {
      body.style.overflow = "auto";
    };
  }, [isVisible]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      )
        if (isVisible && onClose) {
          onClose();
          if (!btnLoading) {
            setIsVisible(false);
          }
        }
    }
    if (isVisible && clickOutsideToClose) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisible, containerRef, btnLoading]);
  const _onClose = () => {
    if (onClose) onClose();
    setIsVisible(false);
  };

  const _onActionClick = () => {
    if (onActionClick) onActionClick();
  };
  const _onCancelClick = () => {
    if (onCancelClick) onCancelClick();
    if (!cancelTxt) setIsVisible(false);
  };

  const component = (
    <div className={overlayClass}>
      <div className={backdropClass} />
      <div ref={containerRef} className={modalClass}>
        {!fullscreen && (
          <div className={style.actionContainer}>
            {onClose && !btnLoading && <XCircle size={36} onClick={_onClose} />}
          </div>
        )}
        <div className={contentClass}>
          {children}
          {onActionClick && (
            <Button
              loading={btnLoading}
              disabled={btnDisabled}
              onClick={_onActionClick}
              className="mb-0"
            >
              {btnTxt ?? strings[lang].mainActionBtnTxt}
            </Button>
          )}
          {onCancelClick && (
            <Button
              type="secondary"
              className={"mt-2 mb-0"}
              onClick={_onCancelClick}
              disabled={btnLoading}
            >
              {cancelTxt ?? strings[lang].cancelActionBtnTxt}
            </Button>
          )}
        </div>
      </div>
    </div>
  );

  const modalRoot = document.getElementById("modal_root");
  if (modalRoot != null) {
    return createPortal(component, modalRoot);
  } else {
    // Should not happen, but better than nothing
    return component;
  }
};

export default Modal;
