import { FC, ReactNode } from "react";
import { ArrowUpRight, XCircle } from "react-feather";
import LoaderCircle from "../LoaderCircle";
import styles from "./styles.module.scss";

type Props = {
  children?: ReactNode;
  type?: "primary" | "secondary" | "primaryButton" | "custom";
  onClick?: () => void;
  className?: string;
  squared?: boolean;
  loading?: boolean;
  disabled?: boolean;
  share?: boolean;
  onRemoveClick?: () => void;
};
const Chip: FC<Props> = ({
  children,
  type = "primary",
  onClick,
  onRemoveClick,
  className,
  squared = false,
  loading = false,
  disabled = false,
  share = false,
}) => {
  let rootClass = `${styles.chip} ${styles[type]}`;

  if (onClick && !disabled) rootClass += ` ${styles.clickable}`;
  if (className) rootClass += ` ${className}`;
  if (squared) rootClass += ` ${styles.squared}`;
  if (loading) rootClass += ` ${styles.loadingContainer}`;
  if (disabled) rootClass += ` ${styles.disabled}`;

  return (
    <span onClick={onClick} className={rootClass}>
      <span className={styles.chipText}>{children} </span>
      {loading && <LoaderCircle className={styles.loadingContainer} />}
      {!loading && onRemoveClick && (
        <XCircle className={styles.removableIcon} onClick={onRemoveClick} />
      )}
      {share && <ArrowUpRight size={"21"} />}
    </span>
  );
};

export default Chip;
