import LoginSrc from "../img/lufthaus-login.png";
import LogoSvg from "../img/lufthaus-logo-black.png";
const LogoPng = LogoSvg;

const HTML_TITLE = "Lufthaus Wallet"; //TBD
const TEAM_CITY = "Lufthaus Wallet"; //TBD
const TEAM_NAME = "";
const COPYRIGHT = "Lufthaus"; //TBD
const LOGIN_SRC = LoginSrc;
const LOGO_SVG_SRC = LogoSvg;
const LOGO_PNG_SRC = LogoPng;
const HIDE_TRANSFER_BUTTON = true;

export default {
  HTML_TITLE,
  TEAM_CITY,
  TEAM_NAME,
  COPYRIGHT,
  LOGIN_SRC,
  LOGO_SVG_SRC,
  LOGO_PNG_SRC,
  HIDE_TRANSFER_BUTTON,
};
