import { Blockchain, ScarcityType } from "config";

const hasMarketplace = true;
const challengeLink = "";
const scarcityType: ScarcityType = "unique"; // bundle or unique (bulls (bundle) vs misa (unique))
const canWithdrawFunds = false;
const redirectMarketplaceLink =
  Blockchain.NET === "Testnet"
    ? "https://fandom-dev.budlight.com"
    : "https://fandom.budlight.com";
const redirectUponListForSale = true;
const redirectUponPurchase = true;

const editionRestrictionName = "LVII";

export default {
  hasMarketplace,
  challengeLink,
  scarcityType,
  canWithdrawFunds,
  redirectMarketplaceLink,
  minPrice: 1,
  redirectUponListForSale,
  redirectUponPurchase,
  maxPrice: 10_000_000_000_000_000_000_000_000,
  editionRestrictionName,
};
