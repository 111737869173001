export const isAccountSetupFUSD = ` 
import FUSD from 0xFUSD
import FungibleToken from 0xFungibleToken

pub fun main(addr: Address): Bool {
    let recipient = getAccount(addr)
    let fusdVaultRef = recipient.getCapability(/public/fusdBalance).borrow<&FUSD.Vault{FungibleToken.Balance}>()

    return fusdVaultRef != nil
}
`;
