import { useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { useLanguage } from "lib/contexts/LanguageContext";
import Modal from "components/Widjets/Modal";
import styles from "../common.module.scss";
import bounceEffect from "./bounceEffect.module.scss";

type Props = { isVisible: boolean; onClose: () => void; link: string };
import { getStrings } from "config";
const strings = getStrings("Modals", "Sell");
export default function ShareModal({ isVisible, onClose, link }: Props) {
  const lang = useLanguage();
  const transitionRef = useRef(null);
  const [success, setSuccess] = useState<boolean>(false);

  async function copyTextToClipBoard() {
    await navigator.clipboard.writeText(link);
    setSuccess(!success);
    onClose();
    setTimeout(() => setSuccess(false), 1000);
  }

  return (
    <>
      <Modal
        modalClassName={styles.genericModal}
        isVisible={isVisible}
        onClose={onClose}
        btnTxt={strings[lang].copyLink}
        onActionClick={copyTextToClipBoard}
        onCancelClick={onClose}
      >
        <h3>{strings[lang].shareModalTitle}</h3>
        <h5 className="mt-2">{strings[lang].shareModalSubtitle}</h5>
      </Modal>
      <CSSTransition
        nodeRef={transitionRef}
        in={success}
        timeout={2000}
        classNames={bounceEffect}
        unmountOnExit
      >
        <div ref={transitionRef} className={`${styles.successMsg}`}>
          <p>{strings[lang].copiedToClipBoard}</p>
        </div>
      </CSSTransition>
    </>
  );
}
