import SmallNFTCard from "components/AppComponent/SmallNFTCard";
import Modal from "components/Widjets/Modal";
import { getStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import styles from "../common.module.scss";

const strings = getStrings("Modals", "Withdraw");

interface NftPreview {
  number?: number;
  thumbnail?: string | null;
  name?: string | null;
  editionNumber: string | number | null;
  printingLimit: number | null;
}

type Props = {
  onClose: () => void;
  onMainButtonClick: () => void;
  nft: NftPreview;
  queryResult: {
    isWithdrawLoading: boolean;
    isWithdrawSuccess: boolean;
    isWithdrawError: boolean;
    withdrawReset?: () => void;
  };
};

export default function WithdrawModal({
  queryResult,
  onClose,
  onMainButtonClick,
  nft,
}: Props) {
  const lang = useLanguage();

  const {
    isWithdrawLoading,
    isWithdrawSuccess,
    isWithdrawError,
    withdrawReset,
  } = queryResult;

  const handleClose = () => {
    onClose();
    withdrawReset?.();
  };

  if (isWithdrawLoading) {
    return (
      <Modal
        isVisible
        modalClassName={styles.genericModal}
        btnLoading={isWithdrawLoading}
      >
        <h3>{strings[lang].withdrawInProgressTitle}</h3>
        <h5 className="ma-1">{strings[lang].withdrawInProgressSubtitle}</h5>
        <h5>{strings[lang].pleaseWait}</h5>
        <SmallNFTCard nft={nft} loading={isWithdrawLoading} />
        <p className={styles.error}>{isWithdrawError}</p>
      </Modal>
    );
  } else {
    return (
      <Modal
        isVisible
        onClose={() => handleClose()}
        modalClassName={styles.genericModal}
        btnLoading={isWithdrawLoading}
        btnTxt={strings[lang].openWallet}
        onActionClick={() => onMainButtonClick()}
      >
        <h3>
          {isWithdrawError
            ? strings[lang].withdrawFailedTitle
            : strings[lang].congratulations}
        </h3>
        <h5 className="mt-2 mb-3">
          {isWithdrawError
            ? strings[lang].withdrawFailedSubtitle
            : strings[lang].successfulWithdraw}
        </h5>
        {isWithdrawSuccess && <h5>{strings[lang].itemInWallet}</h5>}
        <SmallNFTCard nft={nft} success={isWithdrawSuccess} />
        <p className={`${styles.error} ma-2`}>{isWithdrawError}</p>
      </Modal>
    );
  }
}
