import { Button, Dropdown, Field, Modal, Option } from "components";
import { ClientConfig, Config } from "config";
import { SALES_HISTORY_ROUTE } from "config/routes";
import { AvailableLanguage } from "lib/contexts/LanguageContext";
import { FunctionComponent, useState } from "react";
import { Search, XCircle } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";
import { Flow } from "types";
import styles from "../styles.module.scss";
import useIsMobile from "../../../hooks/useIsMobile";
import Breadcrumbs from "components/AppComponent/Breadcrumbs";

export type HeadingProps = {
  strings: ClientConfig["Strings"]["Pages"]["ArtistMarketplace"][AvailableLanguage];
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  onSearchClear: () => void;
  setSorting: (sorting: NftSortingOptions | null) => void;
  merchant?: Flow.Merchant | undefined;
};

const Heading: FunctionComponent<HeadingProps> = ({
  strings,
  searchTerm,
  setSearchTerm,
  onSearchClear,
  setSorting,
  merchant,
}) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [showSortModal, setShowSortModal] = useState<boolean>(false);

  const {
    smartContractName,
    smartContractAddress,
    id: merchantFID,
    profileName,
  } = useParams();

  return (
    <>
      {Config.Client.DISPLAY_BREADCRUMBS && (
        <Breadcrumbs
          smartContractAddress={smartContractAddress}
          smartContractName={smartContractName}
          merchantFID={merchantFID}
          profileName={profileName}
        />
      )}
      {!merchant && (
        <h1 className={styles.sectionHeading}>
          {Config.Client.TEAM_NAME}
          <br />
          {strings.merchantMarketPlaceTitle}
        </h1>
      )}
      {!!merchant && (
        <>
          <h1 className={styles.sectionHeading}>
            {!!merchant.profileImg && (
              <img
                className={styles.merchantProfileImg}
                src={merchant.profileImg}
                alt={merchant.profileName ?? ""}
              />
            )}
            {merchant.profileName}
            <br />
            {strings.merchantMarketPlaceTitle}
          </h1>
        </>
      )}
      <div className={styles.headerFields}>
        <Field
          type="text"
          prependIcon={<Search size={16} />}
          appendIcon={<XCircle />}
          onAppendIconClick={() => onSearchClear()}
          placeHolder={strings.searchPlaceholder}
          value={searchTerm ?? ""}
          className={styles.search}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        {!isMobile && (
          <Dropdown
            className={styles.sort}
            placeholder={strings.sortPlaceholder}
            onSelect={(e) =>
              setSorting((e?.value as NftSortingOptions) ?? null)
            }
          >
            <Option value={"price_asc"} display={strings.sortLowestPrice} />
            <Option value={"price_desc"} display={strings.sortHighestPrice} />
          </Dropdown>
        )}
      </div>
      <div className={styles.headerFields}>
        <Button type="secondary" onClick={() => navigate(SALES_HISTORY_ROUTE)}>
          {strings.recentMarketSales}
        </Button>
        {isMobile && (
          <>
            <Button
              type="secondary"
              onClick={() => setShowSortModal(true)}
              className={styles.headerFields}
            >
              {strings.sortPlaceholder}
            </Button>
            <Modal
              btnTxt={strings.done}
              modalClassName={styles.mobileSortModal}
              contentClassName={styles.mobileSortContentClass}
              isVisible={showSortModal}
              onActionClick={() => setShowSortModal(false)}
              onClose={() => setShowSortModal(false)}
            >
              <Dropdown
                className={styles.mobileSortTable}
                placeholder={strings.sortPlaceholder}
                onSelect={(e) =>
                  setSorting((e?.value as NftSortingOptions) ?? null)
                }
              >
                <Option
                  key={"1"}
                  value={"price_asc"}
                  display={strings.sortLowestPrice}
                />
                <Option
                  key={"2"}
                  value={"price_desc"}
                  display={strings.sortHighestPrice}
                />
              </Dropdown>
            </Modal>
          </>
        )}
      </div>
      <hr className={styles.separator} />
    </>
  );
};

export default Heading;
