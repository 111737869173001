import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { getStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import {
  useAppDispatch,
  useAppSelector,
  usePaginatedList,
} from "lib/store/hooks";
import { selectAppUser, selectEthUser } from "lib/store/slices/user-slice";
import { parseOnchainActionError } from "lib/store/utils";
import { useEffect, useState } from "react";
import { Eth } from "types";
import { ethAPI } from ".";
import { walletProvider } from "./web3";

// We cannot simply use a dynamic list of ethAPI.useGetClaimedNftListQuery, because of the laws of hooks.
// So instead I created this more complicated hook to serve a list of getClaimedNftList selectors,
// one for each wallet the user has. All item in the "lists" has its own "data", "isLoading", etc.
export function useGetClaimedNftLists({
  userId,
}: {
  userId: string | undefined;
}) {
  const { data: wallets } = ethAPI.useGetWalletListQuery({ userId });

  const dispatch = useAppDispatch();
  const lists = useAppSelector((state) =>
    (wallets ?? []).map((wallet) =>
      ethAPI.endpoints.getClaimedNftList.select({ wallet })(state)
    )
  );

  useEffect(() => {
    // Add one subscription for each wallet
    const subscriptions = wallets?.map((wallet) =>
      dispatch(ethAPI.endpoints.getClaimedNftList.initiate({ wallet }))
    );
    // Return the `unsubscribe` callback to be called in the `useEffect` cleanup step
    return () => subscriptions?.forEach((sub) => sub.unsubscribe());
  }, [dispatch, wallets]);

  return lists;
}

// This hook returns a flattened list of all NFTs of the current user.
export function useNftList(page = 0, limit?: number) {
  const userId = useAppSelector(selectAppUser)?.id?.toString();
  const purchased = usePaginatedList(page, (skip) =>
    ethAPI.useGetPaginatedPurchasedNftListQuery({ page, limit }, { skip })
  );
  const claimed = useGetClaimedNftLists({ userId });
  const claimedFlat = claimed.map(({ data }) => data ?? []).flat();

  let data = [...purchased.data, ...claimedFlat];

  if (limit) data = data.slice(0, limit);

  const isLoading = claimed.every((c) => c.isLoading) && claimed.length !== 0;

  const isError = purchased.isError || claimed.some((c) => c.isError);

  const totalUnclaimed = purchased.total ?? 0;

  const total = totalUnclaimed + claimedFlat.length;

  return { data, isLoading, isError, totalUnclaimed, total };
}

// The current wallet depends on the wallet-endpoints section of flowAPI and the user-slice.
export const useCurrentWallet = (): Eth.Wallet | null => {
  const appUser = useAppSelector(selectAppUser);
  const ethUser = useAppSelector(selectEthUser);

  const { data: wallets } = ethAPI.useGetWalletListQuery(
    {
      userId: appUser?.id?.toString() ?? "",
    },
    { skip: appUser == null }
  );

  if (appUser == null || ethUser == null || wallets == null) {
    return null;
  }

  return wallets.find((wallet) => wallet.address === ethUser.address) ?? null;
};

type UseOnchainActionStatus = {
  isLoading: boolean;
  isDeclined: boolean;
  isError: boolean;
  isSuccess: boolean;
  reset?: () => void;
  errorMessage?: string | null;
};

type UseTransfer = [(recipientAddress: string) => void, UseOnchainActionStatus];
export function useTransfer(
  itemEID: string,
  contractAddress: string
): UseTransfer {
  const localizedStrings = getStrings("Pages", "Details")[useLanguage()];
  const [mutation, { error, isError, reset }] = ethAPI.useTransferNftMutation();

  const sender = useCurrentWallet() as Eth.Wallet;

  const [waitForSuccess, { isLoading, isSuccess, isDeclined, errorMessage }] =
    usePollNftState(
      itemEID,
      contractAddress,
      ({ wallets }) => (sender ? !wallets[sender.address] : false),
      error
    );

  const transferNft = (recipientAddress: string) =>
    waitForSuccess(
      mutation({
        senderAddress: sender.address,
        itemEID,
        contractAddress,
        recipientAddress,
      })
    );

  useEffect(() => {
    if (isDeclined) {
      reset();
    }
  }, [isDeclined]);

  // Don't expose cadence runtime errors to users or system errors
  const transferErrorMessage =
    errorMessage?.includes("cadence runtime error") ||
    errorMessage?.includes("System Error")
      ? localizedStrings.transferFailed
      : errorMessage;

  return [
    transferNft,
    {
      isLoading,
      isDeclined,
      isError,
      errorMessage: transferErrorMessage,
      isSuccess,
      reset,
    },
  ];
}

type UseClaim = [
  (toAddress: string, id: number) => void,
  UseOnchainActionStatus
];
export function useClaim(itemEID: string, contractAddress: string): UseClaim {
  const [mutation, { error, isError, reset }] = ethAPI.useClaimNftMutation();

  const [waitForSuccess, { isLoading, isSuccess, errorMessage, isDeclined }] =
    usePollNftState(
      itemEID,
      contractAddress,
      ({ isClaimed }) => isClaimed,
      error
    );

  const claim = (toAddress: string, nftId: number) =>
    waitForSuccess(
      mutation({ toAddress, nft: { id: nftId, itemEID, contractAddress } })
    );

  useEffect(() => {
    if (isDeclined) {
      reset();
    }
  }, [isDeclined]);

  return [claim, { isLoading, isDeclined, isError, errorMessage, isSuccess }];
}

type UsePollNftState = [
  (mutationPromise: Promise<unknown>) => Promise<void>,
  {
    isLoading: boolean;
    isSuccess: boolean;
    isDeclined: boolean;
    errorMessage?: string | null;
  }
];
function usePollNftState(
  itemEID: string,
  contractAddress: string,
  stopCondition: (nftState: Eth.NftState) => boolean,
  error: FetchBaseQueryError | SerializedError | undefined,
  pollingInterval = 3000
): UsePollNftState {
  const { errorMessage, isDeclined } = parseOnchainActionError(error);
  const [isLoading, setLoading] = useState(false);
  const [isPolling, setPolling] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const dispatch = useAppDispatch();

  const user = useAppSelector(selectEthUser);
  const { data: nftState } = ethAPI.useGetNftStateQuery(
    { itemEID, contractAddress, user },
    { pollingInterval, skip: !isPolling }
  );

  useEffect(() => {
    if (isPolling && nftState != null && stopCondition(nftState)) {
      setLoading(false);
      setPolling(false);
      setIsSuccess(true);
      dispatch(
        ethAPI.util.invalidateTags([
          {
            type: "EthNft",
            id: `${contractAddress}-${itemEID}`,
          },
          "EthNft",
        ])
      );
    }
  }, [nftState, isPolling]);

  useEffect(() => {
    if (isDeclined || error != null) {
      setPolling(false);
      setLoading(false);
    }
  }, [isDeclined || error != null]);

  const waitForSuccess = async (mutationPromise: Promise<unknown>) => {
    setLoading(true);
    await mutationPromise;
    setPolling(true);
  };

  return [waitForSuccess, { isLoading, isSuccess, errorMessage, isDeclined }];
}

// This combines ethAPI.useLoginMutation and ethAPI.useRegisterWalletMutation
// to chain them one after the other.
export const useRegister = (): [
  () => void,
  { isLoading: boolean; isSuccess: boolean }
] => {
  const [onLogin, { isLoading: isLoginLoading }] = ethAPI.useLoginMutation();
  const [onRegister, { isLoading: isRegisterLoading, isSuccess }] =
    ethAPI.useRegisterWalletMutation();

  const user = useAppSelector(selectEthUser);

  //error and success handling
  useEffect(() => {
    if (user != null && walletProvider != null) {
      onRegister({ address: user.address, walletType: walletProvider });
    }
  }, [user]);

  // onLogin is the first step; When it is done, onRegister is triggered.
  return [
    onLogin,
    { isLoading: isLoginLoading || isRegisterLoading, isSuccess },
  ];
};

export function useMerchants(
  page = 0,
  query: { id?: string; profileName?: string }
) {
  return usePaginatedList(page, (skip) =>
    ethAPI.useGetPaginatedMerchantsQuery({ page, ...query }, { skip })
  );
}
