import { useState } from "react";
import { Config, getStrings } from "config";
import Modal from "components/Widjets/Modal";
import styles from "../common.module.scss";
import SmallNFTCard from "components/AppComponent/SmallNFTCard";
import { useLanguage } from "lib/contexts/LanguageContext";

interface NftPreview {
  id?: number;
  thumbnail?: string | null;
  name?: string | null;
  editionNumber: string | number | null;
  printingLimit: number | null;
}

type Props = {
  onClose: () => void;
  nft: NftPreview;
  onMainButtonClick: () => void;
  queryResult: { isPurchaseLoading: boolean; isPurchaseError: boolean };
};

export default function PurchaseModal({
  nft,
  queryResult,
  onClose,
  onMainButtonClick,
}: Props) {
  const lang = useLanguage();
  const [localizedStrings] = useState(getStrings("Modals", "Purchase")[lang]);

  const { isPurchaseLoading, isPurchaseError } = queryResult;

  const redirectUponPurchase = () => {
    const redirectLink = Config.MarketPlace.redirectMarketplaceLink;

    redirectLink && Config.MarketPlace.redirectUponPurchase
      ? (location.href = `${Config.MarketPlace.redirectMarketplaceLink}`)
      : onClose();
  };

  const onActionClick = () => {
    const redirectLink = Config.MarketPlace.redirectMarketplaceLink;

    redirectLink && Config.MarketPlace.redirectUponPurchase
      ? (location.href = `${Config.MarketPlace.redirectMarketplaceLink}`)
      : onMainButtonClick();
  };

  if (isPurchaseLoading) {
    return (
      <Modal
        isVisible
        modalClassName={styles.genericModal}
        btnLoading={isPurchaseLoading}
      >
        <h3>{localizedStrings.purchaseInProgressTitle}</h3>
        <h5 className="ma-1">{localizedStrings.purchaseInProgressSubtitle}</h5>
        <h5>{localizedStrings.pleaseWait}</h5>
        <SmallNFTCard nft={nft} loading={isPurchaseLoading} />
      </Modal>
    );
  } else {
    return (
      <Modal
        isVisible
        onClose={isPurchaseError ? onClose : redirectUponPurchase}
        modalClassName={styles.genericModal}
        btnLoading={isPurchaseLoading}
        btnTxt={localizedStrings.openWallet}
        onActionClick={() => onActionClick()}
      >
        <h3 className="mb-2">
          {isPurchaseError
            ? localizedStrings.purchasedFailedTitle
            : localizedStrings.congratulations}
        </h3>
        <h5 className={isPurchaseError ? "mb-2" : "mb-3"}>
          {isPurchaseError
            ? localizedStrings.purchasedFailedSubtitle
            : localizedStrings.itemInWallet}
        </h5>
        {isPurchaseError && <h5>{localizedStrings.tryAgainLater}</h5>}
        <SmallNFTCard nft={nft} success={!isPurchaseError} />
      </Modal>
    );
  }
}
