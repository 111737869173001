import React, { FunctionComponent, ReactNode } from "react";
import styles from "../styles.module.scss";
import { Check } from "react-feather";

type OptionProps = {
  value?: string | number;
  display?: string;
  data?: unknown;
  children?: ReactNode;
  active?: boolean;
  selected?: boolean;
  onClick?: () => void;
  onHover?: () => void;
};

const Option: FunctionComponent<OptionProps> = ({
  value,
  children,
  onClick,
  onHover,
  active = false,
  selected = false,
  display,
}: OptionProps) => {
  // Handlers

  const onMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
    if (onClick) {
      onClick();
    }
  };

  // Rendering

  let className = styles.option;
  if (active) className += ` ${styles.active}`;

  return (
    <div className={className} onMouseDown={onMouseDown} onMouseEnter={onHover}>
      <span>{children ? children : display ?? value}</span>
      {selected && <Check />}
    </div>
  );
};

export default Option;
