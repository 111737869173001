import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ethAuthenticationEndpoints } from "./authentication-endpoints";
import { ethNftEndpoints } from "./nft-endpoints";
import { ethOnchainActionsEndpoints } from "./onchain-actions.endpoints";
import { ethWalletEndpoints } from "./wallet-endpoints";

export const ethAPI = createApi({
  reducerPath: "ethAPI",
  baseQuery: fetchBaseQuery(),
  tagTypes: ["EthWallet", "EthNft", "EthNftState", "EthMerchant"],
  endpoints: (builder) => ({
    ...ethAuthenticationEndpoints(builder),
    ...ethWalletEndpoints(builder),
    ...ethNftEndpoints(builder),
    ...ethOnchainActionsEndpoints(builder),
  }),
});
