import { RoundImgCard } from "components";
import { UserPreview } from "pages/NFT/show/types";
import { useLanguage } from "lib/contexts/LanguageContext";
import { getStrings, Config } from "config";
import { NET } from "config/global/blockchain";
import { NftState } from "types";

interface Props {
  creator?: UserPreview | null;
  owner?: UserPreview | null;
  ownedByFlowAddress?: string;
  onCreatorClick?: () => void;
  nftState?: NftState;
}

const ownerAddressBlacklist: {
  Mainnet: string[];
  Testnet: string[];
} = {
  Mainnet: ["0x20187093790b9aef"],
  Testnet: ["0x985d410b577fd4a1"],
};

export const VisibleOwnerAndCreator = (props: Props) => {
  const { owner, creator, ownedByFlowAddress } = props;
  const localizedStrings = getStrings("Pages", "Details")[useLanguage()];
  const showOwner: boolean =
    owner != null &&
    !!owner.profileName &&
    !ownerAddressBlacklist[NET].includes(ownedByFlowAddress ?? "") &&
    (props.nftState?.isClaimed ?? true);

  return (
    <>
      {!!owner && showOwner && (
        <RoundImgCard
          src={owner.profileImg ?? undefined}
          label={localizedStrings.owner}
          name={owner.profileName ?? undefined}
          hasBorder={true}
        />
      )}
      {creator != null && (
        <RoundImgCard
          src={creator.profileImg ?? undefined}
          label={localizedStrings.creator}
          name={creator.profileName ?? undefined}
          hasBorder={true}
          onClick={props.onCreatorClick}
        />
      )}
    </>
  );
};

export const NftOwnerAndCreator = (props: Props) => {
  switch (Config.Client.HIDE_NFT_OWNER_AND_CREATOR) {
    case true:
      return null;
    default:
      return <VisibleOwnerAndCreator {...props} />;
  }
};
