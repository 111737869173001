// I used the code given here to make it so client-side routing auto-scrolls to the top:
// https://reactrouter.com/web/guides/scroll-restoration
import { FunctionComponent, useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop: FunctionComponent = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default ScrollToTop;
