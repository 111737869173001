import { AnimatedNFT, Modal } from "components";
import ShareModal from "components/AppComponent/ActionModals/Share/ShareModal";
import Breadcrumbs from "components/AppComponent/Breadcrumbs";
import NewLineText from "components/AppComponent/NewLineText";
import Loader from "components/Widjets/BigLoader";
import { CLIENT, Config, getStrings } from "config";
import { NET } from "config/global/blockchain";
import { useLanguage } from "lib/contexts/LanguageContext";
import NotFoundPage from "pages/NotFound/NotFoundPage";
import { Children, ReactNode, useState } from "react";
import { Maximize, Minimize } from "react-feather";
import { NftState } from "types";
import { Wallet as EthWallet } from "types/app-model/eth";
import { Wallet as FlowWallet } from "types/app-model/flow";
import { getFileType } from "utils/utils";
import { NftOwnerAndCreator } from "../components";
import { PfpTraits } from "../components/PfpTraits";
import { NftPreview } from "../types";
import { NftDisplayName } from "./NftDisplayName";
import styles from "./style.module.scss";
import { NftInfoChips } from "./VisibleInfoChips";

type Props = {
  nft?: NftPreview;
  isLoading: boolean;
  isError: boolean;
  children: ReactNode;
  nftState?: NftState;
  shareLink: string;
  displayUnclaimableMessage: string | null;
  setDisplayUnclaimableMessage: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  // The options are not shown to the user if these are undefined
  onSalesHistoryClick?: () => void;
  onMerchantClick?: () => void;
  wallet: FlowWallet | EthWallet | null;
  isCurrentWalletNftOwner: boolean;
  wallets?: Wallet[];
  restrictionBasedOnEditionName?: boolean;
};

export function NftShowUI(props: Props) {
  const [isNftFullscreen, setIsNftFullscreen] = useState<boolean>(false);
  const [shareModalVisible, setShareModalVisible] = useState<boolean>(false);
  const lang = useLanguage();
  const detailsPageStrings = getStrings("Pages", "Details")[lang];

  const thumbnail = props.nft?.thumbnail ?? undefined;
  const name = props.nft?.name ?? undefined;

  const restrictionBasedOnEditionName = !!props.restrictionBasedOnEditionName;

  const isNftClaimed = !!props.nft?.state?.isClaimed;

  const renderClaimStepList =
    // Config.Client.SHOW_CLAIMING_STEP_LIST &&
    !!props?.nft?.state?.isOwner && !isNftClaimed;

  //FYI videoURL is not necessarily a video, it can also be an image. Once the property name is changed to mediaURL on the backend, it will be updated here as well.
  const src = props.nft?.videoURL ?? "";

  if (props.isLoading) {
    return <Loader show={props.isLoading} />;
  }

  if (props.isError) {
    return <NotFoundPage />;
  }

  const { description, owner, creator, itemFID, itemEID } = props.nft ?? {};

  const itemID = itemFID || itemEID;

  const hasValidChildren = Children.toArray(props.children).length !== 0;

  const isUsersNft = Boolean(
    props.wallets?.find(
      (wallet) => wallet.address === props.nft?.currentAddress
    )
  );

  return (
    <section className={styles.editionPage}>
      <>
        <Modal
          modalClassName={styles.genericModal}
          isVisible={!!props.displayUnclaimableMessage}
          onClose={() => props.setDisplayUnclaimableMessage(null)}
        >
          <h3 className={`${styles.unclaimableMessage} my-5`}>
            {props.displayUnclaimableMessage}
          </h3>
        </Modal>
      </>
      <section className={styles.section_img}>
        <div className={styles.img_container}>
          {Config.Client.DISPLAY_BREADCRUMBS && !isUsersNft && (
            <Breadcrumbs
              itemName={props.nft?.name}
              itemFID={props.nft?.itemFID}
              merchantFID={props.nft?.merchantFID}
              smartContractName={props.nft?.smartContractName}
              smartContractAddress={props.nft?.smartContractAddress}
              profileName={props.nft?.creator?.profileName}
            />
          )}
          {src && (
            <AnimatedNFT
              src={src}
              thumbnail={thumbnail}
              name={name}
              fileType={getFileType(src) ?? undefined}
              icon={<Maximize />}
              onIconClick={() =>
                setIsNftFullscreen((fullscreen) => !fullscreen)
              }
            />
          )}
        </div>
      </section>
      <section className={styles.section_info}>
        <div className={styles.info_container}>
          {name && itemID && <NftDisplayName name={name} itemID={itemID} />}

          {renderClaimStepList && (
            <NftDisplayName
              name={detailsPageStrings.connect}
              className={styles.connect}
            />
          )}

          <NftInfoChips
            nft={props.nft}
            setShareModalVisible={setShareModalVisible}
          />
          <NewLineText text={description ?? ""} />

          {/* Sheba 3D Viewer link */}
          {/* This is going on dev & link is static for now */}
          {CLIENT === "sheba" && NET === "Testnet" && (
            <a
              href="https://embed.ogcrystals.com/app/crystal/741/embed"
              target="_blank"
              className={styles.secondaryBtn}
            >
              {detailsPageStrings.viewIn3D}
            </a>
          )}

          {hasValidChildren && (
            <div className={styles.action_container}>{props.children}</div>
          )}
          <div className={styles.owner_creator_wrapper}>
            <NftOwnerAndCreator
              owner={owner}
              creator={creator}
              ownedByFlowAddress={
                props.nft?.state?.ownedByFlowAddress ?? undefined
              }
              onCreatorClick={props.onMerchantClick}
              nftState={(props.nft?.state as NftState) ?? undefined}
            />
          </div>

          {props.onSalesHistoryClick != null && (
            <p
              className={styles.historyLink}
              onClick={props.onSalesHistoryClick}
            >
              {detailsPageStrings.salesHistory}
            </p>
          )}

          <PfpTraits
            nft={props.nft}
            restrictionBasedOnEditionName={restrictionBasedOnEditionName}
          />
        </div>
      </section>

      <Modal
        isVisible={isNftFullscreen}
        onClose={() => setIsNftFullscreen(false)}
        fullscreen
      >
        {src && (
          <AnimatedNFT
            src={src}
            thumbnail={thumbnail}
            name={name}
            fileType={getFileType(src) ?? undefined}
            icon={<Minimize />}
            onIconClick={() => setIsNftFullscreen((fullscreen) => !fullscreen)}
          />
        )}
      </Modal>
      <ShareModal
        isVisible={shareModalVisible}
        onClose={() => setShareModalVisible(false)}
        link={props.shareLink}
      />
    </section>
  );
}
