const PRIVACY_POLICY = `
<p>
15. PRIVACY POLICY
 
Our Privacy Policy describes the ways we collect, use, store and disclose your personal information, and is hereby incorporated by this reference into these Terms. You agree to the collection, use, storage, and disclosure of your data in accordance with our Privacy Policy.

</p>
<p>
16. DISPUTE RESOLUTION; BINDING ARBITRATION
 
YOU ARE AGREEING TO GIVE UP ANY RIGHTS TO LITIGATE CLAIMS IN A COURT. OTHER RIGHTS THAT YOU WOULD HAVE IF YOU WENT TO COURT MAY ALSO BE UNAVAILABLE OR MAY BE LIMITED IN ARBITRATION.
 
YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO HAVE A TRIAL BY JURY.
 
YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS IN ANY LAWSUIT, INCLUDING, BUT NOT LIMITED TO, CLASS ACTION LAWSUITS INVOLVING ANY SUCH DISPUTE.
 
(i) Binding Arbitration. All disputes arising out of or in connection with this contract, or in respect of any defined legal relationship associated therewith or derived therefrom, shall be referred to and finally resolved by arbitration under the International Commercial Arbitration Rules of Procedure of the Canadian Commercial Arbitration Centre.
 
The appointing authority shall be the Canadian Commercial Arbitration Centre.
 
The case shall be administered by the Canadian Commercial Arbitration Centre in accordance with its Rules.
 
The place of arbitration shall be Montreal, Quebec, Canada.
 
(ii) Arbitration Fees. Each party will cover its own fees and costs associated with the arbitration proceedings; however, if the arbitrator finds that you cannot afford to pay the fees and costs reasonably associated with the arbitration proceedings, we will pay them for you.
 
(iii) Award Enforcement. The award of the arbitrator will be final and binding, and any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction. The parties agree that they will not appeal any arbitration decision to any court.
 
(iv) Our Equitable Remedies. Notwithstanding the foregoing, we may seek and obtain injunctive relief in any jurisdiction in any court of competent jurisdiction, and you agree that these Terms are specifically enforceable by us through injunctive relief and other equitable remedies without proof of monetary damages.
 

</p>
<p>
17. GENERAL
 
(i) Entire Agreement.  These Terms and our Privacy Policy constitute the entire legal agreement between you and us and will be deemed to be the final and integrated agreement between you and us, and govern your access to and use of the App, and completely replace any prior or contemporaneous agreements between you and us related to your access to or use of the App, whether oral or written.
 
(ii) No Third-Party Beneficiaries. These Terms do not and are not intended to confer any rights or remedies upon any person or entity other than you
 
(iii) Interpretation. The language in these Terms will be interpreted as to its fair meaning, and not strictly for or against any party.
 
(iv) Severability. Should any part of these Terms be held invalid, illegal, void or unenforceable, that portion will deemed severed from these Terms and will not affect the validity or enforceability of the remaining provisions of these Terms.
 
(v) No Waivers. Our failure or delay to exercise or enforce any right or provision of these Terms will not constitute or be deemed a waiver of future exercise or enforcement of such right or provision. The waiver of any right or provision of these Terms will be effective only if in writing and signed for and on behalf of us by a duly authorized representative.
 
(vi) Governing Law. All matters arising out of or relating to these Terms will be governed by and construed in accordance with the laws of the Province of Quebec and the federal laws of Canada applicable therein without giving effect to any choice or conflict of law provision or rule (whether of the Province of British Columbia or any other jurisdiction).
 
(vii) Venue. Subject to Section 16 of these Terms, any legal action or proceeding arising under these Terms will be brought exclusively in the Supreme Court of the Province of Quebec, and we and you irrevocably consent and attorn to the personal jurisdiction and venue there.
 
(viii) Notices. We may provide you with any notices (including, without limitation those regarding changes to these Terms) by email or postings on the App. By providing us with your email address, you consent to our using the email address to send you any notices. Notices sent by email will be effective when we send the email, and notices we provide by posting will be effective upon posting. It is your responsibility to keep your email address current.
 
(ix) Assignment. You may not assign any of your rights or obligations under these Terms, whether by operation of law or otherwise, without our prior written consent. We may assign our rights and obligations under these Terms in our sole discretion to an affiliate, or in connection with an acquisition, sale or merger.

</p>
`;

const TERMS_OF_SERVICE = `
<p>Merchant.Mint.Store Terms of Use
Last Updated: July 12, 2021</p>

<p>
The site gleague.mint.store is an application that provides users with the opportunity to collect and showcase digital blockchain collectibles containing content solely for entertainment purposes from the NBA G League (“Merchant”).  Mint Blockchain Services ("Mint", "we", or "us") is making the App available to you. Before you use the App, however, you will need to agree to these Terms of Use and any terms and conditions incorporated herein by reference (collectively, these “Terms").
</p>
<p>
PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE APP. THESE TERMS GOVERN YOUR USE OF THE APP, UNLESS WE HAVE EXECUTED A SEPARATE WRITTEN AGREEMENT WITH YOU FOR THAT PURPOSE. WE ARE ONLY WILLING TO MAKE THE APP AVAILABLE TO YOU IF YOU ACCEPT ALL OF THESE TERMS. BY USING THE APP OR ANY PART OF IT, YOU ARE CONFIRMING THAT YOU UNDERSTAND AND AGREE TO BE BOUND BY ALL OF THESE TERMS. IF YOU ARE ACCEPTING THESE TERMS ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE LEGAL AUTHORITY TO ACCEPT THESE TERMS ON THAT ENTITY’S BEHALF, IN WHICH CASE “YOU” WILL MEAN THAT ENTITY. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT ACCEPT ALL OF THESE TERMS, THEN WE ARE UNWILLING TO MAKE THE APP AVAILABLE TO YOU. IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY NOT ACCESS OR USE THE APP.
</p>
<p>
THIS AGREEMENT CONTAINS AN ARBITRATION PROVISION (SEE SECTION 15).  PLEASE REVIEW THE ARBITRATION PROVISION CAREFULLY BECAUSE  IT AFFECTS YOUR RIGHTS.  BY USING THE APP OR ANY PART OF IT, YOU UNDERSTAND AND AGREE TO BE BOUND BY THE ARBITRATION PROVISION.
</p>
<p>
ANY PURCHASE OR SALE YOU MAKE, ACCEPT OR FACILITATE OUTSIDE OF THIS APP (AS DEFINED BELOW) OF A COLLECTIBLE (AS DEFINED BELOW) WILL BE ENTIRELY AT YOUR RISK. WE DO NOT CONTROL OR ENDORSE PURCHASES OR SALES OF COLLECTIBLES OUTSIDE OF THIS APP. WE EXPRESSLY DENY ANY OBLIGATION TO INDEMNIFY YOU OR HOLD YOU HARMLESS FOR ANY LOSSES YOU MAY INCUR BY TRANSACTING, OR FACILITATING TRANSACTIONS, IN COLLECTIBLES OUTSIDE OF THIS APP.
</p>
<p>
BY USING THE APP OR ANY PART OF IT, OR BY CLICKING “I ACCEPT” BELOW OR INDICATING YOUR ACCEPTANCE IN AN ADJOINING BOX, YOU ARE CONFIRMING THAT YOU UNDERSTAND AND AGREE TO BE BOUND BY ALL OF THESE TERMS.
</p>
<p>
BY USING THE APP OR ANY PART OF IT, OR BY CLICKING “I ACCEPT” BELOW OR INDICATING YOUR ACCEPTANCE IN AN ADJOINING BOX, YOU ARE CONFIRMING THAT YOU UNDERSTAND AND AGREE TO BE BOUND BY ALL OF THESE TERMS.
 
Any changes to these Terms will be in effect as of the “Last Updated Date” referred to at the top of this page. You should review these Terms before using the App or purchasing any product or using any services that are available through this App.
 
Your continued use of this App after the “Last Updated Date” will constitute your acceptance of and agreement to such changes.
 
By using this App, you affirm that you are of legal age to enter into these Terms, and you accept and are bound by these Terns. You affirm that if you are using this App on behalf of an organization or company, you have the legal authority to bind any such organization or company to these Terms.
 
You may not use this App if you: (i) do not agree to these Terms; (ii) are not of the age of majority in your jurisdiction of residence; or (iii) are prohibited from accessing or using this App or any of this App’s contents, products or services by applicable law.

</p>
<p>
1. USE OF THE APP; ACCOUNT SET-UP AND SECURITY
 
(i) Account and Wallet Set-Up. To most easily use the App, you should first install a web browser (such as the Google Chrome web browser).  You will also need register to establish an account affiliated with an electronic wallet, which will enable you to purchase and store collectibles that you collect or purchase via the App.  Each collectible is a Non-Fungible Token (an “NFT”) on the Flow™ blockchain network (the “Flow Network”).
 
(ii) Account Registration. You must provide accurate and complete registration information when you create an account for the App. By creating an account, you agree to provide accurate, current and complete account information about yourself, and to maintain and promptly update your account information as necessary.  We reserve the right reclaim usernames without liability to you.
 
(iii) Account Security. You are responsible for the security of your account for the App and for your electronic wallets. If you become aware of any unauthorized use of your password or of your account with us, you agree to notify us immediately at support@mint.store
 
(iv) Account Transactions.  You can use your electronic wallet to purchase, store, and engage in transactions using your credit card, or via one or more cryptocurrencies that we may elect to accept from time to time. Transactions that take place on the App are managed and confirmed via the Flow Network.  You understand that your Flow Network public address will be made publicly visible whenever you engage in a transaction on the App.

</p>
<p>
 2. PURCHASING AND EARNING YOUR COLLECTIBLES
 
(i) Acquiring Collectibles. The App allows you to collect and showcase Collectibles (each, a “Collectible”) from the Merchant.  Each Collectible is an NFT on the Flow Network.
 
(ii) Characteristics of Collectibles. Collectibles are comprised of an artistic design created by the in-house graphics design team of the Merchant.  Each Collectible has a defined set of attributes – including scarcity – which help determine the value of the Collectible.
 
(iii) Subjectivity of Collectibles. The value of each Collectible is inherently subjective, in the same way the value of other collectibles is inherently subjective. Each Collectible has no inherent or intrinsic value. Some collectors might prefer to have a Collectible featuring a certain Merchant game, while another might prefer an equivalent Collectible featuring a different Merchant match-up or game. 
 
(iv) Showcasing Collectibles. The “Showcase” feature of the App allows to you organize your Collectibles into collections, and show them to your friends.

</p>
<p>
3.  PAYMENT, GAS FEES, AND TAXES
 
(i) Financial Transactions on App. Any payments or financial transactions that you engage in via the App will be conducted solely through the Flow Network.  We have no control over these payments or transactions, nor do we have the ability to reverse any payments or transactions. We have no liability to you or to any third party for any claims or damages that may arise as a result of any payments or transactions that you engage in via the App, or any other payment or transactions that you conduct via the Flow Network. We do not provide refunds for any purchases that you might make on or through the App – whether for Collectibles, Packs, or anything else.
 
(ii) Gas Fees. Every transaction on the Flow Network requires the payment of a transaction fee (each, a “Gas Fee”). The Gas Fees fund the network of computers that run the decentralized Flow Network. This means that you will need to pay a Gas Fee for each transaction that you instigate via the App. Except as otherwise expressly set forth in these Terms, you will be solely responsible to pay any Gas Fee for any transaction that you instigate via the App.
 
(iii) Responsibility for Taxes. You will be solely responsible to pay any and all sales, use, value-added and other taxes, duties, and assessments (except taxes on our net income) now or hereafter claimed or imposed by any governmental authority (collectively, the “Taxes”) associated with your use of the App. Except for income taxes levied on us, you: (a) will pay or reimburse us for all national, federal, state, local or other taxes and assessments of any jurisdiction, including value added taxes and taxes as required by international tax treaties, customs or other import or export taxes, and amounts levied in lieu thereof based on charges set, services performed or payments made hereunder, as are now or hereafter may be imposed under the authority of any national, state, local or any other taxing jurisdiction; and (b) will not be entitled to deduct the amount of any such taxes, duties or assessments from payments (including Gas Fees) made to us pursuant to these Terms. To allow us to determine our tax obligations, unless you otherwise notify us in writing, you confirm that you are not a resident in Canada nor are you registered for Goods and services tax / Harmonized sales tax (GST / HST) or Provincial sales taxes (PST) in Canada, and will inform us if your status changes in the future.

</p>
<p>
4.  OWNERSHIP, LICENSE, AND OWNERSHIP RESTRICTIONS
 
YOUR OWNERSHIP OF COLLECTIBLES WILL ONLY BE RECOGNIZED BY US IF YOU HAVE PURCHASED OR OTHERWISE RIGHTFULLY ACQUIRED SUCH COLLECTIBLES FROM A LEGITIMATE SOURCE AND NOT THROUGH ANY OF THE CATEGORY B PROHIBITED ACTIVITIES (AS DEFINED BELOW).
 
For the purposes of this Section 4, the following capitalized terms will have the following meanings:
 
“Art” means any art, design, and drawings (in any form or media, including, without limitation, video or photographs) that may be associated with a Collectible that you Own.
 
“Own” means, with respect to a Collectible, a Collectible that you have purchased or otherwise rightfully acquired from a legitimate source (and not through any of the Category B Prohibited Activities (as defined below)), where proof of such purchase is recorded on the Flow Network.
 
“Purchased Collectible” means a Collectible that you Own. “Third Party IP” means any third party patent rights (including, without limitation, patent applications and disclosures), copyrights, trade secrets, trademarks, know-how or any other intellectual property rights recognized in any country or jurisdiction in the world.
 
(i) Ownership of Collectible.  Because each Collectible is an NFT on the Flow Network,  when you purchase a Collectible in accordance with these Terms (and not through any of the Category B Prohibited Activities), you own the underlying NFT completely.  This means that you have the right to swap your Collectible, sell it, or give it away. Ownership of the Collectible is mediated entirely by the Flow Network.
 
(ii) We Own the App.  You acknowledge and agree that we (or, as applicable, our licensors) owns all legal right, title and interest in and to all other elements of the App, and all intellectual property rights therein (including, without limitation, all Art, designs, systems, methods, information, computer code, software, services, “look and feel”, organization, compilation of the content, code, data, and all other elements of the App (collectively, the “App Materials”)).  You acknowledge that the App Materials are protected by copyright, trade dress, patent, and trademark laws, international conventions, other relevant intellectual property and proprietary rights, and applicable laws. All App Materials are the copyrighted property of us or our licensors, and all trademarks, service marks, and trade names associated with the App or otherwise contained in the App Materials are proprietary to us or our licensors.
 
(iii) No User License or Ownership of App Materials. Except as expressly set forth herein, your use of the App does not grant you ownership of or any other rights with respect to any content, code, data, or other App Materials that you may access on or through the App. We reserve all rights in and to the App Materials that are not expressly granted to you in these Terms.
 
(iv) Further User Ownership Acknowledgements. For the sake of clarity, you understand and agree: (a) that your purchase of a Collectible, whether via the App or otherwise, does not give you any rights or licenses in or to the App Materials (including, without limitation, our copyright in and to the associated Art) other than those expressly contained in these Terms; (b) that you do not have the right, except as otherwise set forth in these Terms, to reproduce, distribute, or otherwise commercialize any elements of the App Materials (including, without limitation, any Art) without our prior written consent in each case, which consent we may withhold in our sole and absolute discretion; and (c) that you will not apply for, register, or otherwise use or attempt to use any of our trademarks or service marks, or any confusingly similar marks, anywhere in the world without our prior written consent in each case, which consent we may withhold at our sole and absolute discretion.
 
(v)  User License to Art.  Subject to your continued compliance with these Terms, we grant you non-exclusive, non-transferable, royalty-free license to use and display the Art for your Purchased Collectibles, solely for your own personal, non-commercial use. 
 
(vi) Restrictions on Ownership.  You agree that you may not, nor permit any third party to do or attempt to do any of the foregoing without our (or, as applicable, our licensors’) express prior written consent in each case: (a) modify the Art for your Purchased Collectible in any way, including, without limitation, the shapes, designs, drawings, attributes, or color schemes; (b) use the Art for your Purchased Collectible to advertise, market, or sell any third party product or service; (c) use the Art for your Purchased Collectible in connection with images, videos, or other forms of media that depict hatred, intolerance, violence, cruelty, or anything else that could reasonably be found to constitute hate speech or otherwise infringe upon the rights of others; (d) use the Art for your Purchased Collectible in movies, videos, or any other forms of media, except to the limited extent that such use is expressly permitted in these Terms or solely for your own personal, non-commercial use; (e) sell, distribute for commercial gain (including, without limitation, giving away in the hopes of eventual commercial gain), or otherwise commercialize merchandise that includes, contains, or consists of the Art for your Purchased Collectible; (f) attempt to trademark, copyright, or otherwise acquire additional intellectual property rights in or to the Art for your Purchased Collectible; or (g) otherwise utilize the Art for your Purchased Collectible for your or any third party’s commercial benefit.
 
(vii) Third Party IP. If the Art associated with your Purchased Collectible contains Third Party IP (e.g., licensed intellectual property from the NBA or an NBA player), you understand and agree as follows: (a) that you will not have the right to use such Third Party IP in any way except as incorporated in the Art, and subject to the license and restrictions contained herein; (b) that, depending on the nature of the license granted from the owner of the Third Party IP, we may need to (and reserve every right to) pass through additional restrictions on your ability to use the Art; and (c) to the extent that we inform you of such additional restrictions in writing, you will be responsible for complying with all such restrictions from the date that you receive the notice, and that failure to do so will be deemed a breach of the license contained in this Section 4.
 
(viii) Other Terms of License.  The license granted in Section 4(v) above applies only to the extent that you continue to Own the applicable Purchased Collectible. If at any time you sell, swap, donate, give away, transfer, or otherwise dispose of your Purchased Collectible for any reason, the license granted in Section 4(v) will immediately expire with respect to that Collectible without the requirement of notice, and you will have no further rights in or to the Art for that Collectible. The restrictions in Sections 4(vii) and 4(viii) will survive the expiration or termination of these Terms.
 
(ix) User  Feedback.  You may choose to submit comments, bug reports, ideas or other feedback about the App, including without limitation about how to improve the App (collectively, “Feedback”). By submitting any Feedback, you agree that we are free to use such Feedback at our discretion and without additional compensation to you, and to disclose such Feedback to third parties (whether on a non-confidential basis, or otherwise). You hereby grant us a perpetual, irrevocable, nonexclusive, worldwide license under all rights necessary for us to incorporate and use your Feedback for any purpose.
</p>
<p>
5. CONDITIONS OF USE AND PROHIBITED ACTIVITIES
 
YOU AGREE THAT YOU ARE RESPONSIBLE FOR YOUR OWN CONDUCT WHILE ACCESSING OR USING THE APP, AND FOR ANY CONSEQUENCES THEREOF. YOU AGREE TO USE THE APP ONLY FOR PURPOSES THAT ARE LEGAL, PROPER AND IN ACCORDANCE WITH THESE TERMS AND ANY APPLICABLE LAWS OR REGULATIONS.
 
(i) User Warranties.  Without limiting the foregoing, you warrant and agree that your use of the App will not (and will not allow any third party to):(a) in any manner:
 
(1)  involve the sending, uploading, distributing or disseminating any unlawful, defamatory, harassing, abusive, fraudulent, obscene, or otherwise objectionable content;
 
(2)  involve the distribution of any viruses, worms, defects, Trojan horses, corrupted files, hoaxes, or any other items of a destructive or deceptive nature;
 
(3)  involve the uploading, posting, transmitting or otherwise making available through the App any content that infringes the intellectual proprietary rights of any party;
 
(4)  involve using the App to violate the legal rights (such as rights of privacy and publicity) of others;
(5)  involve engaging in, promoting, or encouraging illegal activity (including, without limitation, money laundering);
 
(6)  involve interfering with other users’ enjoyment of the App;
 
(7)  involve exploiting the App for any unauthorized commercial purpose;
 
(8)  involve modifying, adapting, translating, or reverse engineering any portion of the App;
 
(9) involve removing any copyright, trademark or other proprietary rights notices contained in or on the App or any part of it;
 
(10) involve reformatting or framing any portion of the App;
 
(11) involve displaying any content on the App that contains any hate-related or violent content or contains any other material, products or services that violate or encourage conduct that would violate any criminal laws, any other applicable laws, or any third party rights;
 
(12) involve using any spider, site search/retrieval application, or other device to retrieve or index any portion of the App or the content posted on the App, or to collect information about its users for any unauthorized purpose;
 
(13) involve accessing or using the App for the purpose of creating a product or service that is competitive with any of our products or services;
 
(14) involve abusing, harassing, or threatening another user of the App or any of our authorized representatives, customer service personnel, chat board moderators, or volunteers (including, without limitation, filing support tickets with false information, sending excessive emails or support tickets, obstructing our employees from doing their jobs, refusing to follow the instructions of our employees, or publicly disparaging us by implying favoritism by a our employees or otherwise); or
 
(15) involve using any abusive, defamatory, ethnically or racially offensive, harassing, harmful, hateful, obscene, offensive, sexually explicit, threatening or vulgar language when communicating with another user of the App or any of our authorized representatives, customer service personnel, chat board moderators, or volunteers
 
(each, a “Category A Prohibited Activity”); and/or
 
(b) in any manner:
 
(1)  involve creating user accounts by automated means or under false or fraudulent pretenses;
 
(2)  involve the impersonation of another person (via the use of an email address or otherwise);
 
(3)  involve using, employing, operating, or creating a computer program to simulate the human behavior of a user (“Bots”);
 
(4)  involve using, employing, or operating Bots or other similar forms of automation to engage in any activity or transaction on the App (including, without limitation, purchases of Packs, or of Collectibles on the Marketplace);
 
(5)  involve acquiring Collectibles through inappropriate or illegal means (including, among other things, using a stolen credit card, or a payment mechanism that you do not have the right to use, or purchasing a Collectible and then attempting to charge the cost back to your payment method while still maintaining ownership or control of the Collectible or selling, gifting or trading the Collectible to someone else); or
 
(6)  involve the purchasing, selling or facilitating the purchase and sale of any user’s account(s) to other users or third parties for cash or cryptocurrency consideration outside of the App; or
 
(7)  otherwise involve or result in the wrongful seizure or receipt of any Collectibles or other digital assets (each, a “Category B Prohibited Activity” and, together with Category A Prohibited Activity, the “Prohibited Activities”).
 
(ii) Effect of Your Breaches. If you engage in any of the Prohibited Activities, we may, at our sole and absolute discretion, without notice or liability to you, and without limiting any of our other rights or remedies at law or in equity, immediately suspend or terminate your user account and/or delete your Collectibles’ images and descriptions from the App. If we delete your Collectibles’ images and descriptions from the App, such deletion will not affect your ownership rights in any NFTs that you already Own, but you will not receive a refund of any amounts you paid for those Collectibles.
 
NOTWITHSTANDING THE FOREGOING, HOWEVER, IF WE REASONABLY BELIEVE THAT YOU ARE ENGAGED IN ANY OF THE CATEGORY B PROHIBITED ACTIVITIES, IN ADDITION TO OUR RIGHT TO IMMEDIATELY SUSPEND OR TERMINATE YOUR USER ACCOUNT AND/OR DELETE YOUR COLLECTIBLES’ IMAGES AND DESCRIPTIONS FROM THE APP, WE ALSO RESERVE THE RIGHT, AT OUR SOLE AND ABSOLUTE DISCRETION, WITHOUT NOTICE OR LIABILITY TO YOU, TO TAKE ANY OR ALL OF THE FOLLOWING ACTIONS: (A) TO DEEM ANY TRANSACTION THAT TOOK PLACE VIA OR AS THE RESULT OF SUCH ACTIVITIES TO BE VOID AB INITIO; AND/OR (B) TO IMMEDIATELY CONFISCATE ANY COLLECTIBLES (INCLUDING THEIR UNDERLYING NFTS) THAT WERE PURCHASED OR ACQUIRED AS THE RESULT OF SUCH ACTIVITIES.

</p>
<p>
6. TERMINATION
 
(i) You Terminate. You may terminate these Terms at any time by canceling your account on the App and discontinuing your access to and use of the App. If you cancel your account, or otherwise terminate these Terms, you will not receive any refunds for any purchases that you might make on or through the App – whether for Collectibles or anything else.
 
(ii) We Terminate. You agree that we, in our sole discretion and for any or no reason, may terminate these Terms and suspend and/or terminate your account(s) for the App without the provision of prior notice. You agree that any suspension or termination of your access to the App may be without prior notice, and that we will not be liable to you or to any third party for any such suspension or termination.
 
(iii)Other Remedies Available. If we terminate these Terms or suspend or terminate your access to or use of the App due to your breach of these Terms or any suspected fraudulent, abusive, or illegal activity (including, without limitation, if you engage in any of the Prohibited Activities), then termination of these Terms will be in addition to any other remedies we may have at law or in equity.
 
(iv) Referral to Governmental Authority. We have the right, without provision of prior notice, to take appropriate legal action, including, without limitation, referral to law enforcement or regulatory authority, or notifying the harmed party of any illegal or unauthorized use of the App. Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone using the App.
 
(v) Effect of Termination. Upon any termination or expiration of these Terms, whether by you or us, you may no longer have access to information that you have posted on the App or that is related to your account, and you acknowledge that we will have no obligation to maintain any such information in our databases or to forward any such information to you or to any third party. Sections 1 and 3 through 17 will survive the termination or expiration of these Terms for any reason.
 
YOU WAIVE AND HOLD US AND THE NBA AND EACH OF OUR RESPECTIVE PARENTS, SUBSIDIARIES, AFFILIATES AND OUR AND THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, AND SUCCESSORS HARMLESS FROM ANY AND ALL CLAIMS RESULTING FROM ANY ACTION TAKEN BY US AND ANY OF THE FOREGOING PARTIES RELATING TO ANY INVESTIGATIONS BY EITHER US OR SUCH PARTIES OR BY LAW ENFORCEMENT AUTHORITIES.

</p>
<p>
7. DISCLAIMERS
 
YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR ACCESS TO AND USE OF THE APP IS AT YOUR SOLE RISK, AND THAT THE APP IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, WE, THE NBA, AND OUR RESPECTIVE PARENTS, SUBSIDIARIES, AFFILIATES, AND LICENSORS MAKE NO EXPRESS WARRANTIES AND HEREBY DISCLAIM ALL IMPLIED WARRANTIES REGARDING THE APP AND ANY PART OF IT, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, CORRECTNESS, ACCURACY, OR RELIABILITY. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE, THE NBA, AND OUR PARENTS, SUBSIDIARIES, AFFILIATES, AND LICENSORS DO NOT REPRESENT OR WARRANT TO YOU THAT: (I) YOUR ACCESS TO OR USE OF THE APP WILL MEET YOUR REQUIREMENTS; (II) YOUR ACCESS TO OR USE OF THE APP WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR; (III) USAGE DATA PROVIDED THROUGH THE APP WILL BE ACCURATE; (IV) THE APP OR ANY CONTENT, SERVICES, OR FEATURES MADE AVAILABLE ON OR THROUGH THE APP ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR (V) THAT ANY DATA THAT YOU DISCLOSE WHEN YOU USE THE APP WILL BE SECURE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES IN CONTRACTS WITH CONSUMERS, SO SOME OR ALL OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
 
YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET, AND AGREE THAT WE HAVE NO LIABILITY OR RESPONSIBILITY FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO OUR GROSS NEGLIGENCE.
 
WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSSES YOU INCUR AS THE RESULT OF YOUR USE OF THE FLOW NETWORK, OR YOUR ELECTRONIC WALLET, INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES OR CLAIMS ARISING FROM: (I) USER ERROR, SUCH AS FORGOTTEN PASSWORDS OR INCORRECTLY CONSTRUED SMART CONTRACTS OR OTHER TRANSACTIONS; (II) SERVER FAILURE OR DATA LOSS; (III) CORRUPTED WALLET FILES; OR (IV) UNAUTHORIZED ACCESS OR ACTIVITIES BY THIRD PARTIES, INCLUDING, BUT NOT LIMITED TO, THE USE OF VIRUSES, PHISHING, BRUTE-FORCING OR OTHER MEANS OF ATTACK AGAINST THE APP, THE FLOW NETWORK, OR ANY ELECTRONIC WALLET.
 
COLLECTIBLES ARE INTANGIBLE DIGITAL ASSETS THAT EXIST ONLY BY VIRTUE OF THE OWNERSHIP RECORD MAINTAINED IN THE FLOW NETWORK. ALL SMART CONTRACTS ARE CONDUCTED AND OCCUR ON THE DECENTRALIZED LEDGER WITHIN THE FLOW NETWORK. WE HAVE NO CONTROL OVER AND MAKE NO GUARANTEES OR PROMISES WITH RESPECT TO SMART CONTRACTS.
 
WE ARE NOT RESPONSIBLE FOR LOSSES DUE TO BLOCKCHAINS OR ANY OTHER FEATURES OF THE FLOW NETWORK, OR ANY ELECTRONIC WALLET, INCLUDING BUT NOT LIMITED TO LATE REPORT BY DEVELOPERS OR REPRESENTATIVES (OR NO REPORT AT ALL) OF ANY ISSUES WITH THE BLOCKCHAIN SUPPORTING THE FLOW NETWORK, INCLUDING FORKS, TECHNICAL NODE ISSUES, OR ANY OTHER ISSUES HAVING FUND LOSSES AS A RESULT.

</p>
<p>
8. LIMITATION OF LIABILITY
 
YOU UNDERSTAND AND AGREE THAT WE, THE NBA, AND EACH OF OUR PARENTS, SUBSIDIARIES, AFFILIATES AND LICENSORS WILL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES WHICH YOU MAY INCUR, HOWSOEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, INCLUDING, WITHOUT LIMITATION, ANY LOSS OF PROFITS (WHETHER INCURRED DIRECTLY OR INDIRECTLY), LOSS OF GOODWILL OR BUSINESS REPUTATION, LOSS OF DATA, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, DIMINUTION OF VALUE OR ANY OTHER INTANGIBLE LOSS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
 
YOU AGREE THAT THE TOTAL, AGGREGATE LIABILITY TO YOU FOR ANY AND ALL CLAIMS ARISING OUT OF OR RELATING TO THESE TERMS OR YOUR ACCESS TO OR USE OF (OR YOUR INABILITY TO ACCESS OR USE) ANY PORTION OF THE APP, WHETHER IN CONTRACT, TORT, STRICT LIABILITY, OR ANY OTHER LEGAL THEORY, IS LIMITED TO THE GREATER OF THE AMOUNTS YOU HAVE ACTUALLY AND LAWFULLY PAID US UNDER THESE TERMS IN THE TWO (2) MONTH PERIOD PRECEDING THE DATE THE CLAIM AROSE.
 
YOU ACKNOWLEDGE AND AGREE THAT WE HAVE MADE THE APP AVAILABLE TO YOU AND ENTERED INTO THESE TERMS IN RELIANCE UPON THE REPRESENTATIONS AND WARRANTIES,  DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH HEREIN, WHICH REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN US AND YOU AND FORM AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN US AND YOU. WE WOULD NOT BE ABLE TO PROVIDE THE APP TO YOU WITHOUT THESE LIMITATIONS.

</p>
<p>
9. ASSUMPTION OF RISK
 
(i) Value and Volatility.  The rights provided to you are for entertainment purposes only.   Without limiting the foregoing, the prices of collectible blockchain assets are extremely volatile and subjective and collectible blockchain assets have no inherent or intrinsic value. Fluctuations in the price of other digital assets could materially and adversely affect the value of your Collectibles, which may also be subject to significant price volatility. Each Collectible has no inherent or intrinsic value. We cannot (and do not) guarantee that any Collectibles purchased will retain their original value, as the value of collectibles is inherently subjective and, among other things, factors occurring outside of the Merchant NFT ecosystem may materially impact the value and desirability of any particular Collectible.
 
(ii) Tax Calculations. You are solely responsible for determining what, if any, taxes apply to your Collectible-related transactions. We are not responsible for determining the taxes that apply to your transactions on the App.
 
(iii) Use of Blockchain. The App does not store, send, or receive Collectibles. This is because Collectibles exist only by virtue of the ownership record maintained on the App’s supporting blockchain in the Flow network. Any transfer of Collectibles occurs within the supporting blockchain in the Flow network, and not on the App.
 
(iv) Inherent Risks with Internet Currency. There are risks associated with using an Internet-based currency, including, but not limited to, the risk of hardware, software and Internet connections, the risk of malicious software introduction, and the risk that third parties may obtain unauthorized access to information stored within your electronic wallet. You accept and acknowledge that we will not be responsible for any communication failures, disruptions, errors, distortions or delays you may experience when using the Flow Network, however caused.
 
(v) Regulatory Uncertainty. The regulatory regime governing blockchain technologies, cryptocurrencies and tokens is uncertain, and new regulations or policies may materially adversely affect the development of the Merchant NFT ecosystem, and therefore the potential utility or value of your Collectibles.
 
(vi) Software Risks. Upgrades to the Flow Network, a hard fork in the Flow Network, or a change in how transactions are confirmed on the Flow Network may have unintended, adverse effects on all blockchains using the Flow Network’s NFT standard, including the Merchant NFT ecosystem. 

</p>
<p>
10. INDEMNIFICATION
 
You agree to hold harmless and indemnify us, the NBA and each of our parents, subsidiaries, affiliates, officers, agents, employees, advertisers, licensors, suppliers or partners from and against any claim, liability, loss, damage (actual and consequential) of any kind or nature, suit, judgment, litigation cost and attorneys' fees arising out of or in any way related to: (i) your breach of these Terms; (ii) your misuse of the App; or (iii) your violation of applicable laws, rules or regulations in connection with your access to or use of the App. You agree that we will have control of the defense or settlement of any such claims.

</p>
<p>
11. EXTERNAL SITES
 
The App may include hyperlinks to other websites or resources (collectively, the “External Sites”), which are provided solely as a convenience to our users. We have no control over any External Sites. You acknowledge and agree that we are not responsible for the availability of any External Sites, and that we do not endorse any advertising, products or other materials on or made available from or through any External Sites. Furthermore, you acknowledge and agree that we are not liable for any loss or damage which may be incurred as a result of the availability or unavailability of the External Sites, or as a result of any reliance placed by you upon the completeness, accuracy or existence of any advertising, products or other materials on, or made available from, any External Sites.

</p>
<p>
 
12. FORCE MAJEURE
 
(i) Force Majeure Events. We will not be liable or responsible to the you, nor be deemed to have defaulted under or breached these Terms, for any failure or delay in fulfilling or performing any of these Terms, when and to the extent such failure or delay is caused by or results from the following force majeure events (“Force Majeure Event(s)”): (a) acts of God; (b) flood, fire, earthquake, epidemics, pandemics, including the 2019 novel coronavirus pandemic (COVID-19), tsunami, explosion; (c) war, invasion, hostilities (whether war is declared or not), terrorist threats or acts, riot or other civil unrest; (d) government order, law, or action; (e) embargoes or blockades in effect on or after the date of this agreement; (f) strikes, labor stoppages or slowdowns or other industrial disturbances; (g) shortage of adequate or suitable Internet connectivity, telecommunication breakdown or shortage of adequate power or electricity; and (h) other similar events beyond our control.
 
(ii) Performance During Force Majeure Events. If we suffer a Force Majeure Event, we will use reasonable efforts to promptly notify you of the Force Majeure Event, stating the period of time the occurrence is expected to continue. We will use diligent efforts to end the failure or delay and ensure the effects of such Force Majeure Event are minimized. We will resume the performance of our obligations as soon as reasonably practicable after the removal of the cause. In the event that our failure or delay remains uncured for a period of forty-five (45) consecutive days following written notice given by us under this Section 12, we may thereafter terminate these Terms upon fifteen (15) days' written notice.

</p>
<p>
13. CHANGES TO THE APP
 
We are constantly innovating the App to help provide the best possible experience. You acknowledge and agree that the form and nature of the App, and any part of it, may change from time to time without prior notice to you, and that we may add new features and change any part of the App at any time without notice.
 
</p>
<p>
14. CHILDREN
 
You affirm that you are over the age of 18.  The App is not intended for children under 18.  If you are under the age of 18, you may not use the App.  We do not knowingly collect information from or direct any of our content specifically to children under the age of 18.  If we learn or have reason to suspect that you are a user who is under the age of 18, we will unfortunately have to close your account.  Other countries may have different minimum age limits, and if you are below the minimum age for providing consent for data collection in your country, you may not use the App.

</p>
`;

export default { PRIVACY_POLICY, TERMS_OF_SERVICE };
