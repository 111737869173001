import { flowAPI } from "lib/store/api/flow";
import { useCurrentWallet } from "lib/store/api/flow/hooks";
import { ModalVisible } from "../NftShowPage";
import { WithdrawButtonUI } from "../ui/WithdrawButtonUI";

export function WithdrawButtonFlowConnector({
  itemFID,
  contractAddress,
  contractName,
  setModalVisible,
  withdraw,
}: {
  itemFID: string;
  contractAddress: string;
  contractName: string;
  setModalVisible: React.Dispatch<React.SetStateAction<ModalVisible | null>>;
  withdraw: (smartContractAddress?: string, smartContractName?: string) => void;
}) {
  const { data: nft } = flowAPI.useGetFullNftQuery({
    itemFID,
    contractAddress,
    contractName,
  });
  const { data: status } = flowAPI.useGetBlockchainStatusQuery();
  const wallet = useCurrentWallet();
  const isUnderMaintenance =
    status?.isBlockchainUnderMaintenance ||
    status?.isMarketplaceUnderMaintenance ||
    false;

  const canWithdraw =
    nft?.state != null &&
    nft?.state.isOwner &&
    nft?.state.isClaimed &&
    nft?.state.isForSale;

  if (!canWithdraw || nft == null) {
    return null;
  }

  return (
    <WithdrawButtonUI
      disabled={wallet?.address !== nft.currentAddress || isUnderMaintenance}
      nft={nft}
      onClick={() => withdraw(nft.smartContractAddress, nft.smartContractName)}
      setModalVisible={setModalVisible}
    />
  );
}
