import { getStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import { FunctionComponent, ReactNode, useEffect, useState } from "react";
import styles from "./styles.module.scss";

type Props = {
  children: ReactNode;
  title?: string;
  titleLink?: ReactNode;
  description?: string;
  searchTerm?: string;
  total?: number;
  defaultShowAll?: boolean;
  displayShowAll?: boolean;
  showAllText?: string;
  onShowAll?: () => void;
};

const CollectiblesSection: FunctionComponent<Props> = ({
  children,
  title,
  titleLink,
  description,
  searchTerm,
  total = 0,
  defaultShowAll = false,
  displayShowAll = false,
  showAllText,
  onShowAll,
}) => {
  const strings = getStrings("Component", "Collectibles")[useLanguage()];
  const [showAll, setShowAll] = useState<boolean>(defaultShowAll);
  const [totalArray, setTotalArray] = useState<number[]>([]);

  // When the total is updated, update totalArray to be [1, 2, 3...]
  // Each number has to be in the array, with a limit of 20
  // This is used in the stylesheet to identify whether or not there is more items than the displayed limit
  useEffect(() => {
    const numbers = new Array(Math.max(20, total));
    for (let i = 0; i < numbers.length; i++) {
      numbers[i] = i + 1;
    }
    setTotalArray(numbers);
  }, [total]);

  return (
    <>
      {title && (
        <header
          className={styles.sectionHeading}
          data-total={totalArray.join(" ")}
        >
          <h3 className={styles.sectionHeading}>
            {searchTerm
              ? `${strings.creatorsHeadingSearch} "${searchTerm}"`
              : title}
            {titleLink && titleLink}
          </h3>
          {displayShowAll && (
            <p
              className={styles.showAllBtn}
              onClick={() => {
                onShowAll ? onShowAll() : setShowAll((x) => !x);
              }}
            >
              {showAllText ?? (showAll ? strings.showLess : strings.showAll)}
            </p>
          )}
        </header>
      )}
      {description && <p className={styles.description}>{description}</p>}
      <section className={styles.sectionCollectibles} data-show-all={showAll}>
        <div className={styles.collectiblesList}>{children}</div>
      </section>
    </>
  );
};

export default CollectiblesSection;
