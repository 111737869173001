import { getStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import { FC, useState } from "react";
import MarketplaceUI from "./ui";

const _strings = getStrings("Pages", "ArtistMarketplace");

const MarketplaceWrapper: FC = () => {
  const [searchTerm, setSearchTermState] = useState<string>("");
  const [sorting, setSortingState] = useState<NftSortingOptions | null>(null);
  const strings = _strings[useLanguage()];

  const onSearchClear = () => {
    if (searchTerm) {
      setSearchTerm("");
    }
  };

  const setSearchTerm = (searchTerm: string) => {
    setSearchTermState(searchTerm);
  };

  const setSorting = (sorting: NftSortingOptions | null) => {
    setSortingState(sorting);
  };

  return (
    <MarketplaceUI
      strings={strings}
      sorting={sorting}
      searchTerm={searchTerm}
      onSearchClear={onSearchClear}
    >
      <MarketplaceUI.Header
        strings={strings}
        searchTerm={searchTerm}
        setSearchTerm={(searchTerm: string) => setSearchTerm(searchTerm)}
        onSearchClear={onSearchClear}
        setSorting={(sorting: NftSortingOptions | null) => setSorting(sorting)}
      />
    </MarketplaceUI>
  );
};

export default MarketplaceWrapper;
