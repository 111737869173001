const MERCHANT_LIST = {
  Testnet: [
    { id: "1", address: "0x28492e97618732eb" },
    { id: "3", address: "0x28492e97618732eb" },
    { id: "7", address: "0x28492e97618732eb" },
    { id: "9", address: "0x28492e97618732eb" },
  ],
  Mainnet: [{ id: "3", address: "0xdac587e8f257a6f2" }],
};

const MINT_STORE_MARKET = {
  Testnet: { id: 2, address: "0x8c0c210d007efa25" },
  Mainnet: { id: 1, address: "0xdac587e8f257a6f2" },
};

const WALLET_VIEWER_ID = {
  Testnet: 1,
  Mainnet: 10,
};
const KYC_MERCHANT_ID = {
  Testnet: 1,
  Mainnet: 1,
};

export { MERCHANT_LIST, MINT_STORE_MARKET, WALLET_VIEWER_ID, KYC_MERCHANT_ID };
