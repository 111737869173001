import feathersClient from "lib/feathers";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { Utility } from "types";
import { Builder } from "./builder";
import { Blockchain, Project } from "config";
import {
  PaginatedShopifyContent,
  PaginatedWithRulesets,
} from "lib/store/hooks";

export const utilityAPI = createApi({
  reducerPath: "utilityAPI",
  baseQuery: fetchBaseQuery(),
  endpoints: (builder: Builder) => ({
    getUtility: builder.query<
      Utility.UtilityResponse,
      {
        shop?: string;
        collection_id?: number | null;
      }
    >({
      async queryFn({ shop, collection_id }) {
        const data = await feathersClient.service("utility").find({
          query: { shop, collection_id },
        });

        return { data };
      },
    }),

    getGatedProductByHandle: builder.query<
      Utility.Product,
      {
        shop: string;
        handle: string;
      }
    >({
      async queryFn({ handle, shop }) {
        const data = await feathersClient
          .service("gated-products")
          .get(handle, {
            query: {
              shop,
            },
          });

        return { data };
      },
    }),

    createDraftOrder: builder.mutation<
      Utility.DraftOrder,
      {
        shop: string;
        cart: { [key: string]: number };
        collection_id: string;
      }
    >({
      async queryFn({ shop, cart, collection_id }) {
        const data = await feathersClient
          .service("create-draft-order")
          .create({ shop, cart, collection_id });

        return { data };
      },
    }),

    /**
     * Get a paginated list of products from Shopify
     * @param shop The Shopify store URL.
     * @param collectionId Shouldn't be null, make sure the client has a alid collection ID.
     * @param cursor The cursor returned by this same query. If there is no cursor, leave undefined or an empty string (both work).
     * @param limit Amount of products to fetch. Default is `10`.
     */
    getProducts: builder.query<
      PaginatedShopifyContent<Utility.Product>,
      {
        shop: string;
        collectionId: number | null;
        cursor?: string;
        limit?: number;
      }
    >({
      async queryFn({ shop, collectionId, cursor, limit }) {
        const data = await feathersClient.service("gated-products").find({
          query: {
            shop,
            collection_id: collectionId,
            cursor: cursor ?? "",
            limit: limit ?? 10,
          },
        });
        return {
          data: {
            list: data.records ?? [],
            cursor: data.cursor,
            hasNextPage: data.hasNextPage,
            rulesetIds: data.rulesetIds,
          },
        };
      },
    }),

    getPlaylists: builder.query<
      PaginatedWithRulesets<Utility.Playlist>,
      { page: number }
    >({
      async queryFn({ page }) {
        const data = await feathersClient.service("gated-playlists").find({
          query: {
            $skip: page * 10,
            $limit: 10,
            walletViewerId: Project.WALLET_VIEWER_ID[Blockchain.NET],
          },
        });
        return {
          data: {
            list: data.records,
            total: data.total,
            rulesetIds: data.rulesetIds,
          },
        };
      },
    }),

    getVideos: builder.query<
      PaginatedWithRulesets<Utility.Video>,
      { page: number }
    >({
      async queryFn({ page }) {
        const data = await feathersClient.service("gated-videos").find({
          query: {
            $skip: page * 10,
            $limit: 10,
            walletViewerId: Project.WALLET_VIEWER_ID[Blockchain.NET],
          },
        });
        return {
          data: {
            list: data.records,
            total: data.total,
            rulesetIds: data.rulesetIds,
          },
        };
      },
    }),

    getFiles: builder.query<
      PaginatedWithRulesets<Utility.File>,
      { page: number }
    >({
      async queryFn({ page }) {
        const data = await feathersClient.service("gated-files").find({
          query: {
            $skip: page * 10,
            $limit: 10,
            walletViewerId: Project.WALLET_VIEWER_ID[Blockchain.NET],
          },
        });
        return {
          data: {
            list: data.records,
            total: data.total,
            rulesetIds: data.rulesetIds,
          },
        };
      },
    }),

    getPlaylist: builder.query<
      Utility.Playlist,
      {
        id: string;
      }
    >({
      async queryFn({ id }) {
        const data = await feathersClient.service("gated-playlists").get(id);

        return { data };
      },
    }),

    getVideo: builder.query<Utility.Video, { uuid: string }>({
      async queryFn({ uuid }) {
        const data = await feathersClient.service("gated-videos").get(uuid);
        return { data };
      },
    }),

    getFile: builder.query<Utility.File, { uuid: string }>({
      async queryFn({ uuid }) {
        const data = await feathersClient.service("gated-files").get(uuid);
        return { data };
      },
    }),
  }),
});
