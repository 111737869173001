import style from "./style.module.scss";
import { FunctionComponent } from "react";

type Props = {
  className?: string;
  size?: number;
};
const LoaderCircle: FunctionComponent<Props> = ({ className, size }: Props) => {
  let rootClass = style.loader;
  let rootStyle;

  if (size)
    rootStyle = {
      height: `${size}px`,
      width: `${size}px`,
      borderWidth: `${size / 8}px`,
    };

  if (className) rootClass += ` ${className}`;
  return (
    <div className={style.container}>
      <div className={rootClass} style={rootStyle} />
    </div>
  );
};
export default LoaderCircle;
