import { flowAPI } from "lib/store/api/flow";
import { useCurrentWallet } from "lib/store/api/flow/hooks";
import { TransferButtonUI } from "../ui/TransferButtonUI";
import { ModalVisible } from "../NftShowPage";
import { canTransfer } from "../util";

export function TransferButtonFlowConnector({
  itemFID,
  contractAddress,
  contractName,
  setModalVisible,
}: {
  itemFID: string;
  contractAddress: string;
  contractName: string;
  setModalVisible: React.Dispatch<React.SetStateAction<ModalVisible | null>>;
}) {
  const { data: nft } = flowAPI.useGetFullNftQuery({
    itemFID,
    contractAddress,
    contractName,
  });
  const { data: status } = flowAPI.useGetBlockchainStatusQuery();
  const wallet = useCurrentWallet();

  if (!canTransfer(nft) || nft == null) {
    return null;
  }

  return (
    <TransferButtonUI
      disabled={
        wallet?.address !== nft.currentAddress ||
        status?.isBlockchainUnderMaintenance ||
        false
      }
      nft={nft}
      setModalVisible={setModalVisible}
    />
  );
}
