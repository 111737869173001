import { SmartContract } from "../../../../../types";

export const NftResultStruct = (contract: SmartContract): string => `  
  pub struct NftResult {
      pub(set) var id: UInt64
      pub(set) var name: String
      pub(set) var description: String
      pub(set) var thumbnail: AnyStruct{MetadataViews.File}
      pub(set) var price: UFix64?
      pub(set) var printingLimit: UInt64?
      pub(set) var editionName: String?
      pub(set) var editionNumber: UInt64
      pub(set) var merchantID: UInt32
      pub(set) var traits: [MetadataViews.Trait]
      pub(set) var smartContractName: String
      pub(set) var smartContractAddress: String

      
      init(id: UInt64, price: UFix64?, merchantID: UInt32, displayView: MetadataViews.Display, editionsView: MetadataViews.Editions, traits: [MetadataViews.Trait]) {
          self.id = id
          self.name = displayView.name
          self.description = displayView.description
          self.thumbnail = displayView.thumbnail
          self.price = price
          self.printingLimit = editionsView.infoList[0].max
          self.editionNumber = editionsView.infoList[0].number
          self.editionName = editionsView.infoList[0].name
          self.merchantID = merchantID
          self.traits = traits
          self.smartContractName = "${contract.name}"
          self.smartContractAddress = "${contract.address}";
      }
  }
`;
