const purchaseScript = `

import FUSD from 0xFUSD
import FungibleToken from 0xFungibleToken
import MintStoreItem from 0xMintStoreItem
import MintStoreMarketFactory from 0xMintStoreMarketFactory

transaction(sellerAddress: Address, marketAddress: Address, tokenID: UInt64) {
    prepare(acct: AuthAccount) {

        ///////////
        // Seller
        ///////////

        let marketRef = getAccount(marketAddress).getCapability(MintStoreMarketFactory.MarketPublicPath)
            .borrow<&{MintStoreMarketFactory.MarketPublic}>()
            ?? panic("Cannot borrow a reference to the account's Market")

        // borrow a public reference to the seller's sale collection
        let saleCollection = getAccount(sellerAddress).getCapability(marketRef.SaleCollectionPublicPath)
            .borrow<&{MintStoreMarketFactory.SalePublic}>()
            ?? panic("Could not borrow public sale reference")

        let price = saleCollection.getPrice(tokenID: tokenID)
            ?? panic("No token matching this ID for sale!")

        ///////////
        // Buyer
        ///////////

        // borrow a reference to the signer's collection
        let buyerCollection = acct.borrow<&MintStoreItem.Collection>(from: MintStoreItem.CollectionStoragePath)
            ?? panic("Could not borrow reference to the MintStoreItem Collection")
        // borrow a reference to the signer's fungible token Vault
        let buyer = acct.borrow<&FUSD.Vault{FungibleToken.Provider}>(from: /storage/fusdVault)
            ?? panic("Could not borrow reference to the buyer's FUSD Vault")
        
        ///////////
        // Buyer purchases from Seller
        ///////////

        // withdraw tokens from the signer's vault
        let tokens <- buyer.withdraw(amount: price) as! @FUSD.Vault
        // purchase the item
        let purchasedToken <- saleCollection.purchase(tokenID: tokenID, buyTokens: <-tokens)
        // deposit the purchased item into the signer's collection
        buyerCollection.deposit(token: <-purchasedToken)

    }
}
`;
export default purchaseScript;
