import { SmartContract } from "types";
import * as Blockchain from "../../../global-blockchain-config";

const SERVER_API_URL =
  Blockchain.NET === "Testnet"
    ? "https://dev-testnet-api.mint.store"
    : "https://v2.api-wallet.mint.store";

const MERCHANT_LIST = {
  Testnet: [
    { id: "1", address: "0x28492e97618732eb" },
    { id: "9", address: "0x28492e97618732eb" },
    { id: "19", address: "0x28492e97618732eb" },
  ],
  Mainnet: [
    { id: "73", address: "0x3744f2f82b3c0a79" },
    { id: "75", address: "0x3744f2f82b3c0a79" },
    { id: "76", address: "0x3744f2f82b3c0a79" },
    { id: "80", address: "0x3744f2f82b3c0a79" },
    { id: "82", address: "0x3744f2f82b3c0a79" },
    { id: "86", address: "0x3744f2f82b3c0a79" },
    { id: "87", address: "0x3744f2f82b3c0a79" },
    { id: "88", address: "0x3744f2f82b3c0a79" },
    { id: "90", address: "0x3744f2f82b3c0a79" },
    { id: "91", address: "0x3744f2f82b3c0a79" },
    { id: "92", address: "0x3744f2f82b3c0a79" },
    { id: "93", address: "0x3744f2f82b3c0a79" },
    { id: "94", address: "0x3744f2f82b3c0a79" },
  ],
};

const MINT_STORE_MARKET = {
  Testnet: { id: 1, address: "0x28492e97618732eb" },
  Mainnet: { id: 12, address: "0x3744f2f82b3c0a79" },
};

const WALLET_VIEWER_ID = {
  Testnet: 11,
  Mainnet: 25,
};
const KYC_MERCHANT_ID = {
  Testnet: 1,
  Mainnet: 3,
};
const DISCOVERY_WALLET = {
  Testnet: "https://staging.accounts.meetdapper.com/fcl/authn-restricted",
  Mainnet: "https://accounts.meetdapper.com/fcl/authn-restricted",
};

const SMART_CONTRACTS: SmartContract[] = [
  {
    id:
      Blockchain.NET === "Testnet"
        ? "786aa074-64d0-493a-823b-e7d36fa53bff"
        : "7764d1a0-bf2e-4da1-9e57-ba7c208cd935",
    name: "MintStoreItem",
    type: "Edition",
    address:
      Blockchain.NET === "Testnet"
        ? "0x985d410b577fd4a1"
        : "0x20187093790b9aef",
    publicCollectionStoragePath: "MintStoreItemCollectionPublic",
    providerPath: "MintStoreItemCollection",
  },
  {
    id:
      Blockchain.NET === "Testnet"
        ? "411a6139-d29a-4cbe-b1fc-759ef9de3be1"
        : "3d745114-e74d-4f9c-8e77-75ab5f7f8df5",
    name: "OpenLockerInc",
    type: "Edition",
    address:
      Blockchain.NET === "Testnet"
        ? "0x985d410b577fd4a1"
        : "0x20187093790b9aef",
    publicCollectionStoragePath: "OpenLockerIncCollectionPublic",
    providerPath: "OpenLockerIncCollection",
  },
  {
    id:
      Blockchain.NET === "Testnet"
        ? "b22fff06-4cf4-4964-848d-42cc3d38fc36"
        : "948565ad-f560-47a2-8bab-38e337df3a3c",
    name: "OpenLockerIncBoneYardHuskyzClub",
    type: "PFP",
    scarcity: null,
    address:
      Blockchain.NET === "Testnet"
        ? "0x985d410b577fd4a1"
        : "0x20187093790b9aef",
    publicCollectionStoragePath:
      "OpenLockerIncBoneYardHuskyzClubCollectionPublic",
    providerPath: "OpenLockerIncBoneYardHuskyzClubCollection",
    attributes: [],
  },
];

export {
  MERCHANT_LIST,
  MINT_STORE_MARKET,
  WALLET_VIEWER_ID,
  KYC_MERCHANT_ID,
  SERVER_API_URL,
  SMART_CONTRACTS,
  DISCOVERY_WALLET,
};
