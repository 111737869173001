import SmallNFTCard from "components/AppComponent/SmallNFTCard";
import Field from "components/Widjets/FieldInput";
import Modal from "components/Widjets/Modal";
import { Config, getStrings } from "config";
import { MARKETPLACE_ROUTE } from "config/routes";
import useMoney from "hooks/useMoney";
import { useLanguage } from "lib/contexts/LanguageContext";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatUsdWithSymbol } from "utils/utils";
import styles from "../common.module.scss";
import customStyles from "./custom.module.scss";

const modalStrings = getStrings("Modals", "Sell");

interface NftPreview {
  number?: number;
  thumbnail?: string | null;
  name?: string | null;
  editionNumber: string | number | null;
  printingLimit: number | null;
}

type Props = {
  onClose: () => void;
  onMainButtonClick: (price: number) => void;
  nft: NftPreview;
  lowestPrice?: number | null;
  fees?: number | null;
  queryResult: {
    isListForSaleLoading: boolean;
    isListForSaleSuccess: boolean;
    listForSaleErrorMessage?: string | null;
    listForSaleReset?: () => void;
  };
};

export default function SellModal(props: Props) {
  const lang = useLanguage();
  const strings = modalStrings[lang];
  const [error, setError] = useState<string | null>(null);
  const {
    display: price,
    update: updatePrice,
    value: floatPrice,
  } = useMoney({ useFormatting: false });

  const navigate = useNavigate();

  async function listForSale() {
    if (price == null || !price.length) return setError(strings.noPriceError);
    if (floatPrice >= 1e21) return setError(strings.biggerThan1e21);
    setError(null);

    return props.onMainButtonClick(floatPrice);
  }
  const {
    isListForSaleLoading,
    isListForSaleSuccess,
    listForSaleErrorMessage,
    listForSaleReset,
  } = props.queryResult;

  function cancel() {
    updatePrice("");
    listForSaleReset?.();
    props.onClose();
  }

  const isMissingPrice = price == null || !price.length;
  const isPriceToHigh = floatPrice >= Config.MarketPlace.maxPrice;
  const isPriceToLow = floatPrice < Config.MarketPlace.minPrice;

  const canListForSale = !isMissingPrice && !isPriceToHigh && !isPriceToLow;
  const dynamicSubtitleClasses = ["ma-1", "mb-3", customStyles.dynamicSubtitle];

  const redirectToMarketplaceLink = () => {
    Config.MarketPlace.redirectMarketplaceLink
      ? (location.href = `${Config.MarketPlace.redirectMarketplaceLink}/marketplace`)
      : navigate(MARKETPLACE_ROUTE);
  };

  const redirectUponListForSale = () => {
    const redirectLink = Config.MarketPlace.redirectMarketplaceLink;

    redirectLink && Config.MarketPlace.redirectUponListForSale
      ? (location.href = `${Config.MarketPlace.redirectMarketplaceLink}`)
      : cancel();
  };

  if (isPriceToHigh) {
    dynamicSubtitleClasses.push(customStyles.priceToHigh);
  } else if (!isMissingPrice && isPriceToLow) {
    dynamicSubtitleClasses.push(customStyles.priceToLow);
  }

  if (isListForSaleLoading) {
    return (
      <Modal
        isVisible
        modalClassName={styles.genericModal}
        btnLoading={isListForSaleLoading}
        btnDisabled={Number.isNaN(floatPrice) || floatPrice <= 0}
        btnTxt={strings.placeForSale}
      >
        <h3>{strings.placeForSaleInProgress}</h3>
        <h5 className="ma-1">{strings.placeForSaleInProgressSubtitle}</h5>
        <h5>{strings.pleaseWait}</h5>
        <SmallNFTCard nft={props.nft} loading={isListForSaleLoading} />
      </Modal>
    );
  } else {
    return (
      <Modal
        isVisible
        onClose={
          props.queryResult.isListForSaleSuccess
            ? redirectUponListForSale
            : cancel
        }
        modalClassName={styles.genericModal}
        btnTxt={
          isListForSaleSuccess
            ? strings.showOnMarketplace
            : strings.placeForSale
        }
        onActionClick={() =>
          props.queryResult.isListForSaleSuccess
            ? redirectToMarketplaceLink()
            : listForSale()
        }
        btnDisabled={!canListForSale}
      >
        <h3>
          {isListForSaleSuccess ? strings.successTitle : strings.placeForSale}
        </h3>
        <h5
          className={dynamicSubtitleClasses.join(" ")}
          dangerouslySetInnerHTML={{
            __html: isListForSaleSuccess
              ? strings.successSubtitle
              : strings.subtitle,
          }}
        ></h5>
        <SmallNFTCard nft={props.nft} success={isListForSaleSuccess} />
        {!isListForSaleSuccess && (
          <div className={customStyles.inputField}>
            <Field
              id="sell-price"
              className={"pl-0 mt-2"}
              placeHolder={strings.price}
              value={price ?? ""}
              useValue
              errorMsg={error ?? listForSaleErrorMessage ?? undefined}
              type="text"
              inputMode={"decimal"}
              onChange={(e) => {
                setError(null);
                updatePrice(e.target.value.replace(/[^\d.-]/g, ""));
                listForSaleReset?.();
              }}
            />
            <span className={customStyles.dollarSign}>$</span>
            <section className={customStyles.details}>
              {props.lowestPrice != null && (
                <p>
                  {`${strings.currentLowestPrice}: ${formatUsdWithSymbol(
                    props.lowestPrice
                  )}`}
                </p>
              )}
              {props.fees != null && (
                <p>
                  {strings.marketplaceFee}:{" "}
                  {Math.round(props.fees * 10000) / 100}%
                </p>
              )}
            </section>
          </div>
        )}
        {isListForSaleSuccess && price && (
          <p className="ma-2">{formatUsdWithSymbol(Number(price))}</p>
        )}
      </Modal>
    );
  }
}
