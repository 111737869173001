import { useEffect, useState } from "react";
import { Config, getStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import { Button, CodeInput, Field, Modal } from "components";
import styles from "./styles.module.scss";

type ModalStep = "email" | "code";

type Props = {
  onSubmit: (email: string, code?: string) => Promise<void>;
  onCancel: () => void;
  isEmailDataLoading: boolean;
  emailError?: string | null;
  resetEmailData: () => void;
  isLoginDataLoading: boolean;
  loginError: boolean;
  isOnRedemptionPage: boolean;
  lastEmailAndCode?: { email: string; code: string };
};

export function LoginModalUI({
  onSubmit,
  onCancel,
  isEmailDataLoading,
  emailError,
  isLoginDataLoading,
  resetEmailData,
  loginError,
  isOnRedemptionPage,
  lastEmailAndCode,
}: Props) {
  const [step, setStep] = useState<ModalStep>("email");
  const [email, setEmail] = useState<string>("");
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const joinedCode = code.join("");
  const localizedStrings = getStrings("Modals", "Login")[useLanguage()];

  const emailErrorMsg = emailError
    ? localizedStrings.errors.invalidEmailAddress
    : null;

  const validEmailAddress = emailError === null;

  const isLastTryInvalid =
    loginError &&
    lastEmailAndCode?.code === joinedCode &&
    lastEmailAndCode?.email === email;

  useEffect(() => {
    if (validEmailAddress) setStep("code");
  }, [validEmailAddress]);

  const onClose = () => {
    setStep("email");
    onCancel();
  };

  const handleReturnToEmailStepClick = () => {
    setEmail("");
    setCode(["", "", "", "", "", ""]);
    setStep("email");
  };

  const loginSubtitle = Array.isArray(localizedStrings.email.subtitle)
    ? localizedStrings.email.subtitle.map((string) => (
        <span className={styles.subtitle}>{string}</span>
      ))
    : isOnRedemptionPage
    ? localizedStrings.email.redeemSubtitle
    : localizedStrings.email.subtitle;

  return (
    <>
      <div className={styles.scrim} />
      <Modal
        onClose={onClose}
        isVisible={step === "email"}
        onActionClick={() => onSubmit(email)}
        modalClassName={styles.modal}
        btnTxt={localizedStrings.email.btnTxt}
        btnLoading={isEmailDataLoading}
        backdropClassName={null}
        btnDisabled={!email || !!emailErrorMsg}
      >
        <div className={styles.titleContainer}>
          <h1 className="mb-3">
            {isOnRedemptionPage
              ? localizedStrings.email.redeemTitle
              : localizedStrings.email.title}
          </h1>
          <h3>{loginSubtitle}</h3>
        </div>
        <Field
          onKeyDown={(e) => e.key === "Enter" && email && onSubmit(email)}
          required
          autoComplete={"on"}
          onChange={(e) => {
            if (emailErrorMsg) resetEmailData();
            setEmail(e.target.value);
          }}
          value={email}
          type={"email"}
          errorMsg={emailErrorMsg ?? ""}
          placeHolder={localizedStrings.email.inputPlaceholder}
        />
      </Modal>
      <Modal
        isVisible={step === "code"}
        onClose={onClose}
        modalClassName={styles.modal}
        backdropClassName={null}
      >
        <div className={styles.titleContainer}>
          <h2>{localizedStrings.code.title}</h2>
          <h3>{localizedStrings.code.subtitle}</h3>
        </div>
        <div className={styles.actionContainer}>
          <CodeInput
            errorMsg={
              isLastTryInvalid ? localizedStrings.errors.incorrectCode : ""
            }
            code={code}
            setCode={setCode}
            onKeyDown={(e) => e.key === "Enter" && onSubmit(email, joinedCode)}
          />
          <Button
            className="ma-0 mb-2"
            onClick={() => onSubmit(email, joinedCode)}
            loading={isLoginDataLoading}
            disabled={
              joinedCode.length !== Config.Email.LOGIN_CODE_LENGTH ||
              !!isLastTryInvalid
            }
          >
            {localizedStrings.code.btnTxt}
          </Button>
          <a
            className="backLinkText"
            onClick={() => handleReturnToEmailStepClick()}
          >
            {localizedStrings.code.backLinkText}
          </a>
        </div>
      </Modal>
    </>
  );
}
