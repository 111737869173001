import { useAppSelector } from "lib/store/hooks";
import { selectAppUser } from "lib/store/slices/user-slice";
import { FunctionComponent } from "react";
import { ErrorPageUI } from "./ui/ErrorPageUI";

const ErrorPage: FunctionComponent = () => {
  const user = useAppSelector(selectAppUser);
  return <ErrorPageUI isLoggedIn={user != null} />;
};

export default ErrorPage;
