import { SmartContract } from "types";

const dapperSetupAccountScript = (contract: SmartContract): string => `
import NonFungibleToken from 0xNonFungibleToken
import ${contract.name} from ${contract.address}
import MetadataViews from 0xMetadataViews

transaction {
    prepare(acct: AuthAccount) {
        if acct.borrow<&${contract.name}.Collection>(from: ${contract.name}.CollectionStoragePath) == nil {
        // create a new empty collection
        let collection <- ${contract.name}.createEmptyCollection()
        // save it to the account
        acct.save(<-collection, to: ${contract.name}.CollectionStoragePath)
        // create a public capability for the collection
        acct.link<&${contract.name}.Collection{NonFungibleToken.CollectionPublic, ${contract.name}.${contract.name}CollectionPublic, MetadataViews.ResolverCollection}>(
                ${contract.name}.CollectionPublicPath,
                target: ${contract.name}.CollectionStoragePath,
            )
        }
    }
    execute {}
}
`;

export default dapperSetupAccountScript;
