import LoginSrc from "../img/SISI-NFT-mint-front-image@2x.jpg";
import Logo from "../img/Sisi-Desktop-Hero-Logo.png";

const HTML_TITLE = "Sisi NFT-Kollektion";
const TEAM_CITY = "Sisi NFT-Kollektion";
const TEAM_NAME = "Sisi NFT-Kollektion";
const COPYRIGHT = "Sisi";
const LOGIN_SRC = LoginSrc;
const LOGO_SVG_SRC = Logo;
const LOGO_PNG_SRC = Logo;

export default {
  HTML_TITLE,
  TEAM_CITY,
  TEAM_NAME,
  COPYRIGHT,
  LOGIN_SRC,
  LOGO_SVG_SRC,
  LOGO_PNG_SRC,
};
