function parseNet(net: string | undefined): "Testnet" | "Mainnet" {
  switch (net?.toLocaleLowerCase()) {
    case "testnet":
      return "Testnet";
    case "mainnet":
      return "Mainnet";
    default:
      return "Testnet";
  }
}

const NET: "Testnet" | "Mainnet" = parseNet(
  process.env.REACT_APP_BLOCKCHAIN_NETWORK
);

export { NET };
