import LoginSrc from "../img/ownmine-login-photo.jpeg";
import LogoSvg from "../img/ownmine-logo.png";
const LogoPng = LogoSvg;

const HTML_TITLE = "OWNMINE Wallet Viewer";
const TEAM_CITY = "OWNMINE";
const TEAM_NAME = "OWNMINE";
const COPYRIGHT = "OWNMINE";
const LOGIN_SRC = LoginSrc;
const LOGO_SVG_SRC = LogoSvg;
const LOGO_PNG_SRC = LogoPng;

export default {
  HTML_TITLE,
  TEAM_CITY,
  TEAM_NAME,
  COPYRIGHT,
  LOGIN_SRC,
  LOGO_SVG_SRC,
  LOGO_PNG_SRC,
};
