import LoginSrc from "../img/bogus-rect.png";
import LogoSvg from "../img/logoipsum-logo-10.svg";
const LogoPng = LogoSvg;

const HTML_TITLE = "Bogus Polygon Wallet Viewer";
const TEAM_CITY = "BOGUS";
const TEAM_NAME = "BOGUS";
const COPYRIGHT = "MINT";
const LOGIN_SRC = LoginSrc;
const LOGO_SVG_SRC = LogoSvg;
const LOGO_PNG_SRC = LogoPng;

export default {
  HTML_TITLE,
  TEAM_CITY,
  TEAM_NAME,
  COPYRIGHT,
  LOGIN_SRC,
  LOGO_SVG_SRC,
  LOGO_PNG_SRC,
};
