import { FC, useState } from "react";
import { useNavigate, generatePath } from "react-router-dom";
import { getStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import { useMerchants } from "../../../lib/store/api/flow/hooks";
import UtilitySubpageUI, { Item } from "pages/Utility/pages/ui";
import { MARKETPLACE_MERCHANT_ROUTE } from "config/routes";
const MerchantsPage: FC = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(0);
  const { data, total, isFetching, isError } = useMerchants(page, {
    profileName: "",
  });
  const strings = getStrings("Pages", "ArtistMarketplace")[useLanguage()];

  const items: Item[] = data.map((creator) => {
    const isAvailable = true;

    return {
      title: creator.name ?? "",
      description: "",
      imageSrc: creator.profileImg ?? undefined,
      onClick: () => {
        navigate(
          generatePath(MARKETPLACE_MERCHANT_ROUTE, { id: creator.merchantFID })
        );
      },
      isAvailable,
    };
  });
  return (
    <UtilitySubpageUI
      title={strings.creatorsHeading}
      description={""}
      isFetching={isFetching}
      isError={isError}
      data={items}
      dataLength={data.length}
      hasMore={total > data.length}
      onLoadMore={() => setPage((p) => p + 1)}
    />
  );
};

export default MerchantsPage;
