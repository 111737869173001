import { useEditions } from "lib/store/api/flow/hooks";
import { FunctionComponent, useState } from "react";
import { useParams } from "react-router-dom";
import MerchantUI from "./ui";
import { useMarketplaceContext } from "../../ui";

const MerchantWrapper: FunctionComponent = () => {
  const { searchTerm, sorting, strings } = useMarketplaceContext();
  const { id } = useParams();
  const [editionsPage, setEditionsPage] = useState<number>(0);

  const {
    data: editions,
    total: totalEditions,
    isLoading,
  } = useEditions(editionsPage, {
    name: `${searchTerm ?? ""}`,
    merchantFID: id,
    sort: sorting,
  });

  const onLoadMore = () => {
    setEditionsPage((p) => p + 1);
  };

  return (
    <MerchantUI
      loading={isLoading}
      onSearchChange={() => setEditionsPage(0)}
      strings={strings}
      editions={editions}
      total={totalEditions}
      onLoadMore={onLoadMore}
      searchTerm={searchTerm}
    />
  );
};

export default MerchantWrapper;
