import LoginSrc from "../img/login-photo-white.png";
import LogoSvg from "../img/koenig-logo.png";
const LogoPng = LogoSvg;

const HTML_TITLE = "König Wallet Viewer";
const TEAM_CITY = "König Galerie";
const TEAM_NAME = "König";
const COPYRIGHT = "König Galerie";
const LOGIN_SRC = LoginSrc;
const LOGO_SVG_SRC = LogoSvg;
const LOGO_PNG_SRC = LogoPng;

export default {
  HTML_TITLE,
  TEAM_CITY,
  TEAM_NAME,
  COPYRIGHT,
  LOGIN_SRC,
  LOGO_SVG_SRC,
  LOGO_PNG_SRC,
};
