import React from "react";
import { Helmet } from "react-helmet";

type Props = {
  title: string;
  description: string;
};
const MetaTags: React.FunctionComponent<Props> = ({
  title,
  description,
}: Props) => {
  return (
    <Helmet>
      <title data-react-helmet="true">{title}</title>
      <meta name="description" content={description} data-react-helmet="true" />
    </Helmet>
  );
};

export default MetaTags;
