import LoginSrc from "../img/nbacleveland3.png";
import LogoSvg from "../img/AS22_Cleveland_Primary.png";
const LogoPng = LogoSvg;

const HTML_TITLE = "NBA ALL-STAR Wallet Viewer";
const TEAM_CITY = "NBA ALL-STAR";
const TEAM_NAME = "NBA ALL-STAR";
const COPYRIGHT = "NBA ALL-STAR";
const LOGIN_SRC = LoginSrc;
const LOGO_SVG_SRC = LogoSvg;
const LOGO_PNG_SRC = LogoPng;

export default {
  HTML_TITLE,
  TEAM_CITY,
  TEAM_NAME,
  COPYRIGHT,
  LOGIN_SRC,
  LOGO_SVG_SRC,
  LOGO_PNG_SRC,
};
