const HAS_UTILITY_PAGE = true;

// We will need those images!
const UTILITY_BANNER =
  "https://dkuxa1i6sgo8h.cloudfront.net/sisi/sisi_utility_banner.png";
const UTILITY_BANNER_FILE =
  "https://dkuxa1i6sgo8h.cloudfront.net/sisi/sisi_utility_banner.png";

const UTILITY_SECTIONS: UtilitySection[] = [
  "marketplace",
  "collection",
  "videos",
  "archives",
];

export default {
  HAS_UTILITY_PAGE,
  UTILITY_SECTIONS,
  UTILITY_BANNER,
  UTILITY_BANNER_FILE,
  HIDE_FILE_DESCRIPTION: true,
};
