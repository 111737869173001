import { getStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import { FunctionComponent, ReactNode } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import LoaderCircle from "../LoaderCircle";
import styles from "./styles.module.scss";

type Props = {
  children: ReactNode;
  hasMore: boolean;
  dataLength: number;
  loading: boolean;
  title?: string;
  titleLink?: ReactNode;
  description?: string;
  searchTerm?: string;
  onLoadMore: () => void;
};

const CollectiblesInfiniteSection: FunctionComponent<Props> = ({
  children,
  hasMore,
  dataLength,
  title,
  titleLink,
  description,
  searchTerm,
  onLoadMore,
  loading,
}) => {
  const strings = getStrings("Component", "Collectibles")[useLanguage()];

  return (
    <>
      {title && (
        <header className={styles.sectionHeading}>
          <h3 className={styles.sectionHeading}>
            {searchTerm
              ? `${strings.collectiblesHeadingSearch} "${searchTerm}"`
              : title}
            {titleLink && titleLink}
          </h3>
        </header>
      )}
      {description && <p className={styles.description}>{description}</p>}

      {!dataLength && !loading && <p>{strings.noResult}</p>}
      <section className={styles.sectionCollectibles} data-show-all={true}>
        <InfiniteScroll
          next={() => onLoadMore()}
          hasMore={!loading && hasMore}
          dataLength={dataLength}
          loader={<LoaderCircle size={48} />}
          scrollableTarget="#body"
        >
          <div className={styles.collectiblesList}>{children}</div>
        </InfiniteScroll>
      </section>
      {loading && <LoaderCircle size={48} />}
    </>
  );
};

export default CollectiblesInfiniteSection;
