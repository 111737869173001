import { SmartContract } from "types";

export const isAccountSetup = (contract: SmartContract): string => `
 import ${contract.name} from ${contract.address}
        
 pub fun main(addr: Address): Bool {
  let recipient = getAccount(addr)
  let accountRef = recipient
  .getCapability(${contract.name}.CollectionPublicPath)
  .borrow<&{${contract.name}.${contract.publicCollectionStoragePath}}>()
  return accountRef != nil
          
 }
`;
