const MERCHANT_LIST = {
  Testnet: [{ id: "9", address: "0x8c0c210d007efa25" }],
  Mainnet: [{ id: "3", address: "0xdac587e8f257a6f2" }],
};

const MINT_STORE_MARKET = {
  Testnet: { id: 0, address: "0x8c0c210d007efa25" },
  Mainnet: { id: 0, address: "0xdac587e8f257a6f2" },
};

const WALLET_VIEWER_ID = {
  Testnet: 1,
  Mainnet: 10,
};
const KYC_MERCHANT_ID = {
  Testnet: 1,
  Mainnet: 1,
};

const DISCOVERY_WALLET = {
  Testnet: "https://staging.accounts.meetdapper.com/fcl/authn-restricted",
  Mainnet: "https://accounts.meetdapper.com/fcl/authn-restricted",
};

export {
  MERCHANT_LIST,
  MINT_STORE_MARKET,
  WALLET_VIEWER_ID,
  KYC_MERCHANT_ID,
  DISCOVERY_WALLET,
};
