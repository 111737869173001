import { Config } from "config";
import {
  usePaginatedListWithRulesets,
  usePaginatedShopify,
} from "lib/store/hooks";
import { utilityAPI } from ".";

export function useUtility() {
  return utilityAPI.useGetUtilityQuery({
    shop: Config.Utility.UTILITY_SHOPIFY_SHOP,
    collection_id: Config.Utility.UTILITY_SHOPIFY_COLLECTION_ID,
  });
}

export function useUtilityProducts(cursor: string) {
  return usePaginatedShopify(() => {
    return utilityAPI.useGetProductsQuery({
      shop: Config.Utility.UTILITY_SHOPIFY_SHOP,
      collectionId: Config.Utility.UTILITY_SHOPIFY_COLLECTION_ID,
      cursor,
    });
  });
}

export function useUtilityPlaylists(page = 0) {
  return usePaginatedListWithRulesets(page, (skip) => {
    return utilityAPI.useGetPlaylistsQuery({ page }, { skip });
  });
}

export function useUtilityVideos(page = 0) {
  return usePaginatedListWithRulesets(page, (skip) => {
    return utilityAPI.useGetVideosQuery({ page }, { skip });
  });
}

export function useUtilityFiles(page = 0) {
  return usePaginatedListWithRulesets(page, (skip) => {
    return utilityAPI.useGetFilesQuery({ page }, { skip });
  });
}

export function useGatedProductByHandle(handle: string) {
  return utilityAPI.useGetGatedProductByHandleQuery({
    handle,
    shop: Config.Utility.UTILITY_SHOPIFY_SHOP,
  });
}

export function useGatedPlaylist(id: string) {
  return utilityAPI.useGetPlaylistQuery({ id });
}

export function useGatedVideo(uuid: string) {
  return utilityAPI.useGetVideoQuery({ uuid });
}

export function useGatedFile(uuid: string) {
  return utilityAPI.useGetFileQuery({ uuid });
}
