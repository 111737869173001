import { getStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";

type Props = {
  shouldRedirect: boolean;
};

export default function ClaimSuccessfulSubtitle({ shouldRedirect }: Props) {
  const strings = getStrings("Modals", "Claim");
  const lang = useLanguage();
  return (
    <div>
      <h5 className="mt-2">{strings[lang].itemInWallet}</h5>
      {shouldRedirect && (
        <h5 className="mt-1">{strings[lang].redirectMessage}</h5>
      )}
    </div>
  );
}
