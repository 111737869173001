import { FC, useState } from "react";
import PfpUI from "./ui";
import { useLanguage } from "../../../../lib/contexts/LanguageContext";
import { useItemForSale } from "../../../../lib/store/api/flow/hooks";
import { getStrings } from "../../../../config";
import { useMarketplaceContext } from "../../ui";
import { ItemOption } from "../../../../components/Widjets/ExpansionPanel";
import { usePfpFilter } from "./hooks/useFilter";
import { useParams } from "react-router-dom";
import { sansPrefix } from "@onflow/fcl";
const _strings = getStrings("Pages", "ArtistMarketplace");

const PfpWrapper: FC = () => {
  const { searchTerm, onSearchClear, sorting } = useMarketplaceContext();
  const { smartContractName, smartContractAddress } = useParams();
  const [page, setPage] = useState<number>(0);
  const {
    headers,
    selectedFilter,
    handleSelectedFilter,
    removeSelectedFilter,
  } = usePfpFilter(smartContractAddress, smartContractName);
  const strings = _strings[useLanguage()];

  const _onSearchClear = () => {
    setPage(0);
    onSearchClear();
  };

  const _onSelectedFilter = (items: ItemOption[]) => {
    setPage(0);
    handleSelectedFilter(items);
  };
  const {
    data: items,
    total,
    isLoading,
  } = useItemForSale(
    page,
    {
      nft: { name: { $iLike: `%${searchTerm}%` } },
      smartContractName,
      smartContractAddress: sansPrefix(smartContractAddress),
    },
    selectedFilter,
    sorting
  );

  return (
    <PfpUI
      onSearchChange={() => setPage(0)}
      selectedFilter={Object.values(selectedFilter).flat()}
      setSelectedFilter={_onSelectedFilter}
      loading={isLoading}
      headers={headers}
      strings={strings}
      items={items}
      onRemoveFilter={removeSelectedFilter}
      total={total}
      onLoadMore={() => setPage((p) => p + 1)}
      searchTerm={searchTerm}
      onSearchClear={_onSearchClear}
    />
  );
};

export default PfpWrapper;
