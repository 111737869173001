import { AnimatedNFT, Button, Chip, Modal, RoundImgCard } from "components";
import NewLineText from "components/AppComponent/NewLineText";
import Page from "components/Widjets/Page";
import { Config, getStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import styles from "pages/Editions/show/ui/styles.module.scss";
import { useState } from "react";
import { AlertCircle, Maximize, Minimize } from "react-feather";
import {
  RedemptionCodeData,
  RedemptionCodeEdition,
  UserRedemptionStatusData,
} from "types";
import { LoginModal } from "../../../components/AppComponent";
import SmallNFTCard from "../../../components/AppComponent/SmallNFTCard";
import { useAppNavigation } from "../../../hooks/useNavigation";

export type EditionMerchant = {
  name: string;
  image: string;
};

type Props = {
  edition: RedemptionCodeEdition;
  nft?:
    | UserRedemptionStatusData["mintedNft"]
    | UserRedemptionStatusData["ethNft"]
    | null;
  merchant: EditionMerchant | null;
  isLoggedIn: boolean;
  userLimitReached: boolean;
  globalLimitReached: boolean;
  submitRedemptionRequest?: () => Promise<unknown>;
  editionsLeft: number | null;
  maxRedemptions?: number | null;
  editionsRedeemed: number | null;
  error: boolean;
  isRedemptionDone: boolean;
  isLoadingModalVisible: boolean;
  redemptionCodeData: RedemptionCodeData;
};

export function RedemptionNftUI({
  nft,
  edition,
  merchant,
  isLoggedIn,
  userLimitReached,
  globalLimitReached,
  submitRedemptionRequest,
  isLoadingModalVisible,
  editionsLeft,
  maxRedemptions,
  editionsRedeemed,
  error,
  isRedemptionDone,
  redemptionCodeData,
}: Props) {
  const lang = useLanguage();
  const detailsPageStrings = getStrings("Pages", "Details")[lang];
  const redeemModalStrings = getStrings("Modals", "Redeem")[lang];
  const [isNftFullscreen, setIsNftFullscreen] = useState<boolean>(false);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const navigator = useAppNavigation();
  const actionContainerStyles = `${styles.action_container} ${styles.redeemButtonContainer}`;

  //FYI videoURL is not necessarily a video, it can also be an image. Once the property name is changed to mediaURL on the backend, it will be updated here as well.
  const src = edition?.videoURL;
  let redemptionTitle = edition.name;
  if (edition.name === "LVII") {
    redemptionTitle += " Playoff Pick 'Em";
  }

  const itemID = (nft as any)?.itemFID ?? (nft as any)?.itemEID ?? null;

  const smartContractAddress =
    edition.smart_contract?.address ?? edition.contractAddress;

  const renderEditionsLeftOnTotal = () => {
    if (maxRedemptions) {
      return (
        <>
          {editionsLeft} / {maxRedemptions}
        </>
      );
    } else {
      return <>{editionsLeft}</>;
    }
  };

  return (
    <Page className={styles.editionPage}>
      <section className={styles.section_img}>
        <div className={styles.img_container}>
          {src && (
            <AnimatedNFT
              src={edition.videoURL || edition.thumbnail || ""}
              thumbnail={edition.thumbnail || undefined}
              name={edition.name || undefined}
              fileType={
                edition.videoURL
                  ? "Video"
                  : edition.thumbnail
                  ? "Image"
                  : undefined
              }
              icon={<Maximize />}
              onIconClick={() => setIsNftFullscreen(!isNftFullscreen)}
            />
          )}
        </div>
      </section>
      <section className={styles.section_info}>
        <div className={styles.info_container}>
          <h1 className={styles.redemption_title}>{redemptionTitle}</h1>
          <div className={styles.info_chips}>
            {Config.Client.REDEMPTION_SHOW_REMAINING && (
              <Chip type="secondary">
                {renderEditionsLeftOnTotal()}
                {detailsPageStrings.editionsRemaining}
              </Chip>
            )}
            {Config.Client.REDEMPTION_SHOW_REDEEMED && (
              <Chip type="secondary">
                {editionsRedeemed}
                {detailsPageStrings.editionsRedeemed}
              </Chip>
            )}
          </div>
          <NewLineText
            className={styles.edition_description}
            text={edition.description}
          />
          <hr className={styles.seperator} />
          {merchant && (
            <RoundImgCard
              src={merchant.image}
              label={detailsPageStrings.creator}
              name={merchant.name}
              hasBorder={true}
              className={styles.merchant_card}
            />
          )}
          <div className={actionContainerStyles}>
            <Button
              disabled={userLimitReached || globalLimitReached || error}
              type="primary"
              onClick={() => {
                if (isLoggedIn) {
                  submitRedemptionRequest?.();
                } else {
                  setIsLoginModalVisible(true);
                }
              }}
            >
              {detailsPageStrings.redeemNFT}
            </Button>
            {userLimitReached && (
              <div className={`${styles.errorContainer} d-flex align-center`}>
                <AlertCircle className={"mr-2"} />
                <p className="my-3">
                  {detailsPageStrings.redemptionLimitReached}
                </p>
              </div>
            )}
            {globalLimitReached && (
              <div className={`${styles.errorContainer} d-flex align-center`}>
                <AlertCircle className={"mr-2"} />
                <p className="my-3">{detailsPageStrings.globalLimitReached}</p>
              </div>
            )}
            {error && (
              <div className={`${styles.errorContainer} d-flex align-center`}>
                <AlertCircle className={"mr-2"} />
                <p className="my-3">
                  <span>Unable to redeem NFT at this time.</span>
                  <br />
                  <span>Please try again later</span>
                </p>
              </div>
            )}
          </div>
          {detailsPageStrings.psNote && (
            <p className="my-3">{detailsPageStrings.psNote}</p>
          )}
        </div>
      </section>
      <Modal
        isVisible={isNftFullscreen}
        onClose={() => setIsNftFullscreen(false)}
        fullscreen
      >
        <AnimatedNFT
          src={edition.videoURL || edition.thumbnail || ""}
          thumbnail={edition.thumbnail || undefined}
          name={edition.name || undefined}
          fileType={
            edition.videoURL ? "Video" : edition.thumbnail ? "Image" : undefined
          }
          icon={<Minimize />}
          onIconClick={() => setIsNftFullscreen(!isNftFullscreen)}
        />
      </Modal>
      {isLoginModalVisible && (
        <LoginModal
          onCancel={() => setIsLoginModalVisible(false)}
          onComplete={() => {
            submitRedemptionRequest?.();
            setIsLoginModalVisible(false);
          }}
        />
      )}
      {isLoadingModalVisible && !nft && (
        <Modal isVisible modalClassName={styles.genericModal} btnLoading>
          <h3 className="ma-2">{redeemModalStrings.loadingTitle}</h3>
          <h5 className="ma-1">{redeemModalStrings.loadingSubtitle}</h5>
          <h5 className="mt-1 mb-3">{redeemModalStrings.pleaseWait}</h5>
          <SmallNFTCard redemptionCodeData={redemptionCodeData} loading />
          <Button disabled={true}>{redeemModalStrings.viewCollectible}</Button>
        </Modal>
      )}
      {isRedemptionDone && nft && (
        <Modal
          isVisible
          modalClassName={styles.genericModal}
          onClose={() =>
            navigator.goToFlowNftPage({
              itemFID: itemID,
              smartContractName: edition.smart_contract?.name ?? null,
              smartContractAddress: smartContractAddress,
            })
          }
        >
          <h3 className="ma-2">{redeemModalStrings.successfulTitle}</h3>
          <h5 className="ma-1">{redeemModalStrings.successfulSubtitle}</h5>
          <h5 className="mt-1 mb-3">{redeemModalStrings.itemNowInWallet}</h5>
          <SmallNFTCard nft={nft} success />
          <Button
            onClick={() =>
              navigator.goToFlowNftPage({
                itemFID: itemID,
                smartContractName: edition.smart_contract?.name ?? null,
                smartContractAddress: smartContractAddress,
              })
            }
          >
            {redeemModalStrings.viewCollectible}
          </Button>
        </Modal>
      )}
    </Page>
  );
}
