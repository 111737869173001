import BadWordsFilter from "bad-words";
import Field from "components/Widjets/FieldInput";
import Modal from "components/Widjets/Modal";
import { getStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import { useEffect, useState } from "react";
import styles from "../styles.module.scss";

const Filter = new BadWordsFilter();

type Props = {
  isLoading?: boolean;
  onMainButtonClick: (username: string) => void;
  onClose: () => void;
  isError?: boolean;
};

export function ProfileStep(props: Props) {
  const localizedStrings = getStrings("Modals", "Wizard")[useLanguage()];
  const localizedErrorStrings = getStrings("Component", "EditUser")[
    useLanguage()
  ];
  const [username, setUsername] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>();

  useEffect(() => {
    if (Filter.isProfane(username) && username.length !== 0) {
      setErrorMsg(localizedErrorStrings.badWordsError);
    } else {
      setErrorMsg("");
    }
  }, [username]);

  useEffect(() => {
    if (props.isError && username.length !== 0) {
      setErrorMsg(localizedErrorStrings.usernameErrorMsg);
    } else {
      setErrorMsg("");
    }
  }, [props.isError]);

  return (
    <Modal
      clickOutsideToClose={false}
      isVisible
      onClose={props.onClose}
      modalClassName={styles.modal}
      btnTxt={localizedStrings.profile.actionBtnTxt}
      onActionClick={() => {
        if (username != null) {
          props.onMainButtonClick(username);
        }
      }}
      btnLoading={props.isLoading}
    >
      <h1>{localizedStrings.profile.title}</h1>
      <p>{localizedStrings.profile.subtitle}</p>
      <Field
        onChange={(e) => setUsername(e.target.value)}
        placeHolder={localizedStrings.profile.usernamePlaceholder}
        className="my-5"
        errorMsg={errorMsg}
      />
    </Modal>
  );
}
