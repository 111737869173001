import { ScarcityType, Blockchain } from "config";

const hasMarketplace = true;
const challengeLink = "";
const scarcityType: ScarcityType = "unique"; // bundle or unique (bulls (bundle) vs misa (unique))
const canWithdrawFunds = false;
const redirectMarketplaceLink =
  Blockchain.NET === "Testnet"
    ? "https://fandom-dev.budlight.com"
    : "https://fandom.budlight.com";

export default {
  hasMarketplace,
  challengeLink,
  scarcityType,
  canWithdrawFunds,
  redirectMarketplaceLink,
  minPrice: 1,
  maxPrice: 1_000,
};
