import { ScarcityType } from "config";

const hasMarketplace = true;
const challengeLink = "";
const scarcityType: ScarcityType = "unique"; // bundle or unique (bulls (bundle) vs misa (unique))
const canWithdrawFunds = false;
const redirectUponListForSale = false;
const redirectUponPurchase = false;

export default {
  hasMarketplace,
  challengeLink,
  scarcityType,
  canWithdrawFunds,
  redirectMarketplaceLink: null as string | null,
  minPrice: 0,
  maxPrice: 1e21,
  redirectUponListForSale,
  redirectUponPurchase,
  editionRestrictionName: "",
};
