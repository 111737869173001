import { FC, useCallback } from "react";
import { ClaimModalProps } from "./ClaimModal";

export const CustomizedClaimModal: FC<ClaimModalProps> = (props) => {
  const ClaimModal: FC<ClaimModalProps> | null = useCallback(() => {
    try {
      const CustomClaimModal: FC<ClaimModalProps> | null =
        require(`config/clients/${process.env.REACT_APP_CLIENT_NAME}/customizable/components/claim-modal.tsx`)?.default(
          props
        ) ?? null;

      return CustomClaimModal;
    } catch (e) {
      return require("components/AppComponent/ActionModals/Claim/ClaimModal").ClaimModal(
        props
      );
    }
  }, [props]);

  return <ClaimModal {...props}></ClaimModal>;
};
