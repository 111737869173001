import React from "react";
import { Config, getStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import { NavLink } from "react-router-dom";
import style from "./default.module.scss";
import {
  PRIVACY_POLICY_ROUTE,
  TERMS_ROUTE,
  FAQ_ZENDESK,
} from "../../../config/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebookF,
  faTwitter,
  faYoutube,
  faTiktok,
  faTelegram,
} from "@fortawesome/free-brands-svg-icons";
import { useAppSelector } from "lib/store/hooks";
import { selectAppUser } from "lib/store/slices/user-slice";

const strings = getStrings("Component", "AppFooter");

export const DefaultFooter = React.memo((props: { logout: () => void }) => {
  const user = useAppSelector(selectAppUser);
  const lang = useLanguage();

  const hasSocialMedia = Object.values(Config.SocialMedia).some(
    (source) => source.length > 0
  );

  return (
    <footer className={style.footer}>
      <div className={style.leftContainer}>
        {hasSocialMedia && (
          <div className={style.socialMediaContainer}>
            <nav>
              {!!Config.SocialMedia.FACEBOOK_MEDIA_URL && (
                <a href={Config.SocialMedia.FACEBOOK_MEDIA_URL} target="_blank">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
              )}
              {!!Config.SocialMedia.INSTAGRAM_MEDIA_URL && (
                <a
                  href={Config.SocialMedia.INSTAGRAM_MEDIA_URL}
                  target={"_blank"}
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              )}
              {!!Config.SocialMedia.TWITTER_MEDIA_URL && (
                <a href={Config.SocialMedia.TWITTER_MEDIA_URL} target="_blank">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              )}
              {!!Config.SocialMedia.YOUTUBE_MEDIA_URL && (
                <a href={Config.SocialMedia.YOUTUBE_MEDIA_URL} target="_blank">
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
              )}
              {!!Config.SocialMedia.TIKTOK_MEDIA_URL && (
                <a href={Config.SocialMedia.TIKTOK_MEDIA_URL} target="_blank">
                  <FontAwesomeIcon icon={faTiktok} />
                </a>
              )}
              {!!Config.SocialMedia.TELEGRAM_MEDIA_URL && (
                <a href={Config.SocialMedia.TELEGRAM_MEDIA_URL} target="_blank">
                  <FontAwesomeIcon icon={faTelegram} />
                </a>
              )}
            </nav>
          </div>
        )}
        <p>
          &copy; {Config.Client.COPYRIGHT_YEAR} {Config.Client.COPYRIGHT}
        </p>
        {strings[lang].showPoweredBy && (
          <p>
            {strings[lang].poweredBy}{" "}
            <a href="https://mint.store" target="_blank">
              mint.store
            </a>{" "}
            {strings[lang].onFlow}
          </p>
        )}
      </div>
      <nav>
        {user != null && (
          <a onClick={() => props.logout()}>{strings[lang].logout}</a>
        )}
        <NavLink
          className={({ isActive }) => (isActive ? "active" : "")}
          to={TERMS_ROUTE}
        >
          {strings[lang].termsOfService}
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "active" : "")}
          to={PRIVACY_POLICY_ROUTE}
        >
          {strings[lang].privacyPolicy}
        </NavLink>
        <a href={FAQ_ZENDESK} target="_blank">
          {strings[lang].faq}
        </a>
        <a href={`mailto:${Config.Client.CONTACT_US}`}>
          {strings[lang].contactUs}
        </a>
      </nav>
    </footer>
  );
});
