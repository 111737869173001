import { useLocation } from "react-router-dom";
import { LoginModalConnector } from "./connectors/AppConnector";

type Props = {
  onCancel: () => void;
  onComplete: () => void;
};

export function LoginModal({ onCancel, onComplete }: Props) {
  const { pathname } = useLocation();

  const isOnRedemptionPage = pathname.includes("redemption");

  return (
    <LoginModalConnector
      onCancel={onCancel}
      onComplete={onComplete}
      isOnRedemptionPage={isOnRedemptionPage}
    />
  );
}
