import { FC, useState } from "react";
import MarketplaceUI, { useMarketplaceContext } from "../../ui";
import {
  useEditions,
  useMerchants,
} from "../../../../lib/store/api/flow/hooks";

const EditionsView: FC = () => {
  const { searchTerm, strings, sorting } = useMarketplaceContext();
  const [editionPage, setEditionsPage] = useState<number>(0);
  const [merchantPage, setMerchantPage] = useState<number>(0);

  const {
    data: editions,
    isLoading: editionsLoading,
    total: totalEditions,
  } = useEditions(editionPage, {
    name: searchTerm,
    sort: sorting,
  });

  const {
    data: merchants,
    isLoading: merchantLoading,
    total: totalMerchant,
  } = useMerchants(merchantPage, {
    profileName: searchTerm,
  });
  const onSearchChange = () => {
    setEditionsPage(0);
    setMerchantPage(0);
  };
  return (
    <>
      <MarketplaceUI.Merchants
        total={totalMerchant}
        onSearchChange={onSearchChange}
        loading={merchantLoading}
        items={merchants}
        strings={strings}
        searchTerm={searchTerm}
        onLoadMore={() => setMerchantPage((p) => p + 1)}
      />
      <MarketplaceUI.Editions
        loading={editionsLoading}
        onSearchChange={onSearchChange}
        strings={strings}
        searchTerm={searchTerm}
        onLoadMore={() => setEditionsPage((p) => p + 1)}
        total={totalEditions}
        items={editions}
      />
    </>
  );
};

export default EditionsView;
