import Button from "components/Widjets/Button";
import { getStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import { ModalVisible } from "../NftShowPage";

interface NftPreview {
  thumbnail?: string | null;
  name?: string | null;
  editionNumber: string | number | null;
  printingLimit: number | null;
  address?: string;
}

type Props = {
  disabled: boolean;
  nft: NftPreview;
  setModalVisible: React.Dispatch<React.SetStateAction<ModalVisible | null>>;
};

export function TransferButtonUI(props: Props) {
  const localizedStrings = getStrings("Pages", "Details")[useLanguage()];

  return (
    <>
      <Button
        type="secondary"
        disabled={props.disabled}
        onClick={() => props.setModalVisible("transfer")}
      >
        {localizedStrings.transferNft}
      </Button>
    </>
  );
}
