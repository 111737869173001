import style from "./style.module.scss";
import { FunctionComponent } from "react";
import { useLanguage } from "lib/contexts/LanguageContext";
import { getStrings } from "config";
const strings = getStrings("Component", "NftList");
type Props = {
  src: string;
  name: string;
  description: string | JSX.Element;
  isClaimed?: boolean;
  isConnected?: boolean;
  showStatus?: boolean;
};

const NftThumbnail: FunctionComponent<Props> = ({
  src,
  name,
  description,
  isClaimed = false,
  isConnected,
  showStatus = false,
}: Props) => {
  const lang = useLanguage();

  return (
    <div className={style.figure}>
      <span
        className={style.imageContainer}
        style={{
          backgroundImage: `${
            showStatus && !isClaimed
              ? "linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)),"
              : ""
          } url(${src})`,
        }}
      >
        {showStatus && !isClaimed && (
          <span className={style.unclaimedButton}>
            {strings[lang].clickToClaim}
          </span>
        )}
      </span>
      <h3 className={style.nftName}>
        {showStatus && isClaimed && isConnected && (
          <span className={`${style.bullet} ${style.active}`}></span>
        )}
        {showStatus && (!isClaimed || !isConnected) && (
          <span className={style.bullet}></span>
        )}
        {name}
      </h3>

      {typeof description === "string" ? <h4>{description}</h4> : description}
    </div>
  );
};
export default NftThumbnail;
