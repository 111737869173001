const PRIVACY_POLICY = `
<p>
15. PRIVACY POLICY
Our Privacy Policy describes the ways we collect, use, store and disclose your personal information, and is hereby incorporated by this reference into these Terms. You agree to the collection, use, storage, and disclosure of your data in accordance with our Privacy Policy.
</p>
<p>
16. DISPUTE RESOLUTION; BINDING ARBITRATION
YOU ARE AGREEING TO GIVE UP ANY RIGHTS TO LITIGATE CLAIMS IN A COURT. OTHER RIGHTS THAT YOU WOULD HAVE IF YOU WENT TO COURT MAY ALSO BE UNAVAILABLE OR MAY BE LIMITED IN ARBITRATION.
YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO HAVE A TRIAL BY JURY.
YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS IN ANY LAWSUIT, INCLUDING, BUT NOT LIMITED TO, CLASS ACTION LAWSUITS INVOLVING ANY SUCH DISPUTE.
(i) Binding Arbitration. All disputes arising out of or in connection with this contract, or in respect of any defined legal relationship associated therewith or derived therefrom, shall be referred to and finally resolved by arbitration under the Commercial Arbitration Rules of the American Arbitration Association (“AAA”).
The case shall be heard by one arbitrator, who shall be an executive with a company in the entertainment event production industry agreed upon by the parties, and will be conducted in English. The arbitrator will decide the substance of all claims in accordance with applicable law and will honor all claims of privilege recognized by law. The arbitrator shall not be bound by rulings in prior arbitrations involving different users but is bound by rulings in prior arbitrations involving the same user to the extent required by applicable law. The arbitrator’s award shall be final and binding and judgment on the award rendered by the arbitrator may be entered in any court having jurisdiction thereof.
As part of the arbitration, both you and the applicable Collectible Parties will have the opportunity for reasonable discovery of non-privileged information that is relevant to the claim. The arbitrator may award any individualized remedies that would be available in court. 
(ii) Arbitration Fees. Each party will cover its own fees and costs associated with the arbitration proceedings; however, if the arbitrator finds that you cannot afford to pay the fees and costs reasonably associated with the arbitration proceedings, we will pay them for you.
(iii) Award Enforcement. The award of the arbitrator will be final and binding, and any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction. The parties agree that they will not appeal any arbitration decision to any court.
(iv) Our Equitable Remedies. Notwithstanding the foregoing, we may seek and obtain injunctive relief in any jurisdiction in any court of competent jurisdiction, and you agree that these Terms are specifically enforceable by us through injunctive relief and other equitable remedies without proof of monetary damages.
</p>
<p>
17. GENERAL
(i) Entire Agreement.  These Terms and our Privacy Policy constitute the entire legal agreement between you and us and will be deemed to be the final and integrated agreement between you and us and govern your access to and use of the App, and completely replace any prior or contemporaneous agreements between you and us related to your access to or use of the App, whether oral or written.
(ii) No Third-Party Beneficiaries. These Terms do not and are not intended to confer any rights or remedies upon any person or entity other than you
(iii) Interpretation. The language in these Terms will be interpreted as to its fair meaning, and not strictly for or against any party.
(iv) Severability. Should any part of these Terms be held invalid, illegal, void or unenforceable, that portion will deemed severed from these Terms and will not affect the validity or enforceability of the remaining provisions of these Terms.
(v) No Waivers. Our failure or delay to exercise or enforce any right or provision of these Terms will not constitute or be deemed a waiver of future exercise or enforcement of such right or provision. The waiver of any right or provision of these Terms will be effective only if in writing and signed for and on behalf of us by a duly authorized representative.
(vi) Governing Law. All matters arising out of or relating to these Terms will be governed by and construed in accordance with the laws of the State of New York without giving effect to any choice or conflict of law provision or rule.
(vii) Venue. Subject to Section 16 of these Terms, any legal action or proceeding arising under these Terms will be brought exclusively in Chicago, Illinois, and we and you irrevocably consent and attorn to the personal jurisdiction and venue there.
(viii) Notices. We may provide you with any notices (including, without limitation those regarding changes to these Terms) by email or postings on the App. By providing us with your email address, you consent to our using the email address to send you any notices. Notices sent by email will be effective when we send the email, and notices we provide by posting will be effective upon posting. It is your responsibility to keep your email address current.
(ix) Assignment. You may not assign any of your rights or obligations under these Terms, whether by operation of law or otherwise, without our prior written consent. We may assign our rights and obligations under these Terms in our sole discretion to an affiliate, or in connection with an acquisition, sale or merger.
</p>
`;

const TERMS_OF_SERVICE = `
<p>
TODO
</p>


`;

export default { PRIVACY_POLICY, TERMS_OF_SERVICE };
