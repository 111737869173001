import { getStrings } from "config";
import { AppContext } from "context/AppContext";
import { useLanguage } from "lib/contexts/LanguageContext";
import { useContext, useEffect, useState } from "react";
import { SmartContractWithSetup } from "types";
import { SetupStatus } from "../connectors/SetupWizardFlowConnector";
import { Alert } from "./Alert";
import { FundsStep, Source } from "./FundsStep";
import { ProfileStep } from "./ProfileStep";
import { RegisterStep } from "./RegisterStep";
import { SmartContractStep } from "./SmartContractStep";

export type Step = "register" | "smartContracts" | "funds" | "profile";

export interface WalletPreview {
  balance: string | null;
  contracts?: {
    [contract: string]: {
      isSetup: boolean;
      address: string;
    };
  };
}

type Props = {
  steps: Step[];
  completedSteps: Step[];
  showAlert?: boolean;
  wallet: WalletPreview | null;
  isLoading?: boolean;
  onRegister?: () => void;
  onSetup?: (smartContract: SmartContractWithSetup) => void;
  onAddFunds?: (source: Source) => void;
  onSkipAddingFunds?: () => void;
  onCompleteProfile?: (username: string) => void;
  walletType: WalletType | null;
  setupStatus?: SetupStatus;
  requiredSmartContracts?: SmartContractWithSetup[];
  isError?: boolean;
};

export function SetupWizardUI(props: Props) {
  const appContext = useContext(AppContext) as AppContext;
  const localizedStrings = getStrings("Modals", "Wizard")[useLanguage()];
  const [currentStep, setCurrentStep] = useState<Step | null>(null);

  const StepProgress = () => {
    const incompleteSteps = props.steps.filter(
      (step) => !props.completedSteps.includes(step)
    );
    // We don't actually want to show the real index. For example, if there are 4 steps, and step 1
    // and 4 are completed, we don't want to open up on step "2 of 4" and close after step "3 of 4".
    // It makes more sense that it opens up on step "3 of 4" and closes after step "4 of 4", even
    // if they are technically #2 and #3.
    const index =
      currentStep != null
        ? incompleteSteps.indexOf(currentStep) + props.completedSteps.length
        : null;

    const total = props.steps.length;
    if (total > 1 && index != null) {
      return (
        <h3>
          {localizedStrings.step} {index + 1} {localizedStrings.of} {total}
        </h3>
      );
    } else {
      return null;
    }
  };

  const onNext = () => {
    let nextIndex = currentStep ? props.steps.indexOf(currentStep) + 1 : 0;
    let nextStep =
      nextIndex < props.steps.length ? props.steps[nextIndex] : null;

    while (nextStep != null && props.completedSteps.includes(nextStep)) {
      nextIndex = nextIndex + 1;
      nextStep = nextIndex < props.steps.length ? props.steps[nextIndex] : null;
    }

    setCurrentStep(nextStep);
  };

  useEffect(() => {
    if (appContext.wizard.display.isVisible) {
      onNext();
    } else {
      setCurrentStep(null);
    }
  }, [appContext.wizard.display.isVisible]);

  if (currentStep != null && props.completedSteps.includes(currentStep)) {
    onNext();
  }

  const handleClose = () => {
    setCurrentStep(null);
    appContext.wizard.display.setIsVisible(false);
  };

  return (
    <>
      {props.showAlert && props.completedSteps.length < props.steps.length && (
        <Alert
          steps={props.steps}
          completedSteps={props.completedSteps}
          onClick={onNext}
        />
      )}

      {currentStep === "register" && (
        <RegisterStep
          isLoading={props.isLoading}
          onMainButtonClick={() => props.onRegister?.()}
          onClose={() => handleClose()}
          walletType={props.walletType}
        />
      )}

      {currentStep === "smartContracts" && (
        <SmartContractStep
          onSetup={(smartContract) => props.onSetup?.(smartContract)}
          onNextStepButtonClick={onNext}
          onClose={() => handleClose()}
          walletType={props.walletType}
          insideSetupModal
          setupStatus={props.setupStatus}
          requiredSmartContracts={props.requiredSmartContracts}
        />
      )}

      {currentStep === "funds" && props.wallet != null && (
        <FundsStep
          onMainButtonClick={(source) => props.onAddFunds?.(source)}
          onClose={() => handleClose()}
          onSkip={() => props.onSkipAddingFunds?.()}
          wallet={props.wallet}
        />
      )}

      {currentStep === "profile" && (
        <ProfileStep
          isError={props.isError}
          isLoading={props.isLoading}
          onMainButtonClick={(username) => props.onCompleteProfile?.(username)}
          onClose={() => handleClose()}
        />
      )}
    </>
  );
}
