import Button from "components/Widjets/Button";
import { getStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import { ModalVisible } from "../NftShowPage";

type Props = {
  disabled: boolean;
  customDisabledReason?: string | null;
  setModalVisible: React.Dispatch<React.SetStateAction<ModalVisible | null>>;
};

export function PlaceForSaleButtonUI(props: Props) {
  const localizedStrings = getStrings("Pages", "Details")[useLanguage()];

  return (
    <>
      <Button
        disabled={props.disabled}
        onClick={() => props.setModalVisible("sell")}
      >
        {localizedStrings.placeForSale}
      </Button>
      {props.customDisabledReason && <p>{props.customDisabledReason}</p>}
    </>
  );
}
