const listForSaleScript = `
import FungibleToken from 0xFungibleToken
      import FUSD from 0xFUSD
      import NonFungibleToken from 0xNonFungibleToken
      import MintStoreItem from 0xMintStoreItem
      import MintStoreMarketFactory from 0xMintStoreMarketFactory
      
      transaction(marketAddress: Address, nftId: UInt64, price: UFix64) {
          prepare(acct: AuthAccount) {
              // Check if market address is accessible
              let marketAcct = getAccount(marketAddress)
              // borrow a reference to the Admin resource in storage
              let marketCapability = marketAcct.getCapability<&{MintStoreMarketFactory.MarketPublic}>(MintStoreMarketFactory.MarketPublicPath)
              let marketRef = marketCapability.borrow() ?? panic("Cannot borrow a reference to the account's Market")
              if acct.borrow<&MintStoreMarketFactory.SaleCollection>(from: marketRef.SaleCollectionStoragePath) == nil {
                  let ownerCapability = acct.getCapability<&FUSD.Vault{FungibleToken.Receiver}>(/public/fusdReceiver)
                  
                  var ownerCollection = acct.link<&MintStoreItem.Collection>(/private/MintStoreItemCollection, target: /storage/MintStoreItemCollection)
      
                  // The capability creation fails if an item was already listed in another Market before
                  // When that happens, simply get a reference to the capability instead of creating it
                  if (ownerCollection == nil) {
                      ownerCollection = acct.getCapability<&MintStoreItem.Collection>(/private/MintStoreItemCollection)
                  }
            
                  let collection <- marketRef.createSaleCollection(ownerCollection: ownerCollection!, ownerCapability: ownerCapability, marketCapability: marketCapability)
                  acct.save(<-collection, to: marketRef.SaleCollectionStoragePath)
                  acct.link<&MintStoreMarketFactory.SaleCollection{MintStoreMarketFactory.SalePublic}>(marketRef.SaleCollectionPublicPath, target: marketRef.SaleCollectionStoragePath)
              }
              // borrow a reference to the sale
              let collection = acct.borrow<&MintStoreMarketFactory.SaleCollection>(from:  marketRef.SaleCollectionStoragePath)
                  ?? panic("Could not borrow from sale in storage")
              
              // put the nft up for sale
              collection.listForSale(tokenID: nftId, price: price)
          }
      }
`;
export default listForSaleScript;
