import LoginSrc from "../img/NFTViewerImage.jpg";
import LogoSvg from "../img/Gamisodes-Logo.svg";
const LogoPng = LogoSvg;

const HTML_TITLE = "Gamisodes";
const TEAM_CITY = "Gamisodes";
const TEAM_NAME = "";
const COPYRIGHT = "Gamisodes";
const LOGIN_SRC = LoginSrc;
const LOGO_SVG_SRC = LogoSvg;
const LOGO_PNG_SRC = LogoPng;

export default {
  HTML_TITLE,
  TEAM_CITY,
  TEAM_NAME,
  COPYRIGHT,
  LOGIN_SRC,
  LOGO_SVG_SRC,
  LOGO_PNG_SRC,
  HIDE_TRANSFER_BUTTON: true,
};
