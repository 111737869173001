import { ClientConfig, Config } from "config";
import {
  LOGIN_ROUTE,
  MARKETPLACE_ROUTE,
  NFT_LIST_ROUTE,
  UTILITY_ROUTE,
} from "config/routes";
import useIsMobile from "hooks/useIsMobile";
import { AvailableLanguage } from "lib/contexts/LanguageContext";
import { authenticationAPI } from "lib/store/api/authentication-api";
import { ethAPI } from "lib/store/api/eth";
import { flowAPI } from "lib/store/api/flow";
import { AppUser } from "lib/store/slices/user-slice";
import React, { useEffect, useState } from "react";
import { Menu, X } from "react-feather";
import { useNavigate } from "react-router-dom";
import { LoginModal } from "../ActionModals/Login";
import RoundImg from "../RoundImg";
import style from "./style.module.scss";

type Props = {
  strings: ClientConfig["Strings"]["Component"]["AppHeader"][AvailableLanguage];
  user?: AppUser | null;
};

type NavLink = {
  name: string;
  route: string;
  isExternalLink: boolean;
};

export default function UserLinks({ strings, user }: Props) {
  const navigate = useNavigate();

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isLoginModalVisible, setIsLoginModalVisible] =
    useState<boolean>(false);

  const [authLogout] = authenticationAPI.useLogoutMutation();
  const [flowLogout] = flowAPI.useLogoutMutation();
  const [ethLogout] = ethAPI.useLogoutMutation();

  const logout = React.useCallback(() => {
    authLogout();
    flowLogout();
    ethLogout({ isDisconnecting: true });
    navigate(LOGIN_ROUTE);
  }, [authLogout, flowLogout, ethLogout, navigate]);

  const navLinks: NavLink[] = [];

  const isMobile = useIsMobile();

  useEffect(() => {
    const hideDropdown = () => {
      setIsDropdownVisible(false);
    };

    if (isDropdownVisible) {
      window.addEventListener("click", hideDropdown);
    }

    return () => {
      window.removeEventListener("click", hideDropdown);
    };
  });

  if (
    Config.MarketPlace.hasMarketplace &&
    Config.MarketPlace.redirectMarketplaceLink
  ) {
    navLinks.push(
      {
        name: strings.marketplaceLink2,
        route: `${Config.MarketPlace.redirectMarketplaceLink}/marketplace`,
        isExternalLink: true,
      },
      {
        name: strings.marketplaceLink,
        route: `${Config.MarketPlace.redirectMarketplaceLink}/collection`,
        isExternalLink: true,
      }
    );
  }

  // This is for external link but without marketplace. We should definitely do a custom component for pickem
  if (
    !Config.MarketPlace.hasMarketplace &&
    Config.MarketPlace.redirectMarketplaceLink
  ) {
    navLinks.push({
      name: strings.marketplaceLink,
      route: `${Config.MarketPlace.redirectMarketplaceLink}/collection`,
      isExternalLink: true,
    });
  }
  if (
    Config.MarketPlace.hasMarketplace &&
    !Config.MarketPlace.redirectMarketplaceLink
  ) {
    navLinks.push({
      name: strings.marketplaceLink,
      route: MARKETPLACE_ROUTE,
      isExternalLink: false,
    });
  }

  if (Config.Utility.HAS_UTILITY_PAGE) {
    navLinks.push({
      name: strings.utility,
      route: UTILITY_ROUTE,
      isExternalLink: false,
    });
  }

  const handleUserConnection = () => {
    if (!user) {
      setIsLoginModalVisible(true);
    } else {
      logout();
    }
  };

  return (
    <div className={style.userInfo}>
      {isMobile ? (
        <div className={style.dropdownMenu}>
          {!isDropdownVisible && (
            <div>
              <Menu onClick={() => setIsDropdownVisible(true)} />
            </div>
          )}
          {isDropdownVisible && (
            <>
              <div>
                <X onClick={() => setIsDropdownVisible(false)} />
              </div>
              <ul>
                <li>
                  <a onClick={() => handleUserConnection()}>
                    {!user ? strings.login : strings.logout}
                  </a>
                </li>

                {user && (
                  <li onClick={() => navigate(NFT_LIST_ROUTE)}>
                    <p className={style.profileName}>
                      {user?.profileName ?? "Profile"}
                    </p>
                  </li>
                )}
                {navLinks.map((navLink) => (
                  <li>
                    {navLink.route.includes("http") ? (
                      <a href={navLink.route} target="_blank">
                        {navLink.name}
                      </a>
                    ) : (
                      <a
                        onClick={() => {
                          navigate(navLink.route);
                        }}
                      >
                        {navLink.name}
                      </a>
                    )}
                  </li>
                ))}
              </ul>
            </>
          )}
          {isLoginModalVisible && (
            <LoginModal
              onCancel={() => setIsLoginModalVisible(false)}
              onComplete={() => setIsLoginModalVisible(false)}
            />
          )}
        </div>
      ) : (
        <>
          {navLinks.map((link, idx) => (
            <a
              key={`nav-link-${idx}`}
              className={style.navLink}
              href={link.isExternalLink ? link.route : undefined}
              onClick={
                !link.isExternalLink ? () => navigate(link.route) : undefined
              }
            >
              {link.name}
            </a>
          ))}
          <span onClick={() => navigate(NFT_LIST_ROUTE)}>
            <p className={style.profileName}>{user?.profileName}</p>
            <RoundImg
              src={user?.profileImg ?? ""}
              name={user?.profileName ?? "Anonymous"}
            />
          </span>
        </>
      )}
    </div>
  );
}
