import { UtilityItem } from "pages/Utility";

const HAS_UTILITY_PAGE = false;

// Utility banner button link
// Leave null unless client has external link to add
const UTILITY_BANNER_BUTTON_LINK = null;

// Shopify info
const UTILITY_SHOPIFY_SHOP = "";
const UTILITY_SHOPIFY_COLLECTION_ID = null;

// Banner image links
const UTILITY_BANNER = "https://source.unsplash.com/random/1280x720/?gate";
const UTILITY_BANNER_PLAYLIST =
  "https://source.unsplash.com/random/1280x720/?music";
const UTILITY_BANNER_VIDEO =
  "https://source.unsplash.com/random/1280x720/?cinema";
const UTILITY_BANNER_FILE =
  "https://source.unsplash.com/random/1280x720/?folders";

const UTILITY_SECTIONS: UtilitySection[] = [
  "collection",
  "marketplace",
  "merchandise",
  "playlists",
  "videos",
  "archives",
  "discord",
];

const UTILITY_DISCORD: UtilityItem[] = [];

export default {
  HAS_UTILITY_PAGE,
  UTILITY_BANNER_BUTTON_LINK,
  UTILITY_SHOPIFY_SHOP,
  UTILITY_SHOPIFY_COLLECTION_ID,
  UTILITY_BANNER,
  UTILITY_BANNER_PLAYLIST,
  UTILITY_BANNER_VIDEO,
  UTILITY_BANNER_FILE,
  UTILITY_SECTIONS,
  UTILITY_DISCORD,
  HIDE_FILE_DESCRIPTION: false,
};
