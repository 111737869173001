import { SmartContract } from "types";
import * as Blockchain from "../../../global-blockchain-config";

const MERCHANT_LIST = {
  Testnet: [
    { id: "20", address: "0x28492e97618732eb" },
    { id: "9", address: "0x28492e97618732eb" },
  ],
  Mainnet: [{ id: "36", address: "0xfc44215cea2f5f04" }],
};

const MINT_STORE_MARKET = {
  Testnet: { id: 0, address: "0x28492e97618732eb" },
  Mainnet: { id: 6, address: "0xfc44215cea2f5f04" },
};

const WALLET_VIEWER_ID = {
  Testnet: 5,
  Mainnet: 11,
};
const KYC_MERCHANT_ID = {
  Testnet: 1,
  Mainnet: 1,
};

const DISCOVERY_WALLET = {
  Testnet: "https://staging.accounts.meetdapper.com/fcl/authn-restricted",
  Mainnet: "https://accounts.meetdapper.com/fcl/authn-restricted",
};

const SMART_CONTRACTS: SmartContract[] = [
  {
    id:
      Blockchain.NET === "Testnet"
        ? "786aa074-64d0-493a-823b-e7d36fa53bff"
        : "7764d1a0-bf2e-4da1-9e57-ba7c208cd935",
    name: "MintStoreItem",
    type: "Edition",
    address:
      Blockchain.NET === "Testnet"
        ? "0x985d410b577fd4a1"
        : "0x20187093790b9aef",
    publicCollectionStoragePath: "MintStoreItemCollectionPublic",
    providerPath: "MintStoreItemCollection",
  },
  // {
  //   id:
  //     Blockchain.NET === "Testnet"
  //       ? "18486217-2738-4c16-a2cd-f7c1e4f18968"
  //       : "74794c08-c063-40f2-8918-d761d14ef3d5",
  //   name: "YoungBoysBern",
  //   type: "Edition",
  //   address:
  //     Blockchain.NET === "Testnet"
  //       ? "0x985d410b577fd4a1"
  //       : "0x20187093790b9aef",
  //   publicCollectionStoragePath: "YoungBoysBernCollectionPublic",
  //   providerPath: "YoungBoysBernCollection",
  // },
  // {
  //   id:
  //     Blockchain.NET === "Testnet"
  //       ? "934c4b4f-e971-465d-8637-48bfc39c5ecd"
  //       : "6f185b26-4f8d-475a-b5a5-6302e137959b",
  //   name: "YBees",
  //   type: "PFP",
  //   address:
  //     Blockchain.NET === "Testnet"
  //       ? "0x985d410b577fd4a1"
  //       : "0x20187093790b9aef",
  //   publicCollectionStoragePath: "YBeesCollectionPublic",
  //   providerPath: "YBeesCollection",
  // },
];

export {
  MERCHANT_LIST,
  MINT_STORE_MARKET,
  WALLET_VIEWER_ID,
  KYC_MERCHANT_ID,
  SMART_CONTRACTS,
  DISCOVERY_WALLET,
};
