import { FunctionComponent, useState } from "react";
import styles from "../styles.module.scss";
import { Props } from "..";
import { AnimatedNFT } from "components";
import { Utility } from "types";
import { Headphones, Lock } from "react-feather";
import LockedAlert from "components/AppComponent/LockedAlert";

type PlaylistProps = {
  playlist: Utility.Playlist;
} & Props;

const PlaylistUI: FunctionComponent<PlaylistProps> = ({
  playlist,
  ruleset,
  strings,
}) => {
  const [activeFile, setActiveFile] = useState<Utility.PlaylistFile | null>(
    null
  );

  if (!activeFile && (playlist.fileURLs?.length ?? 0) > 0) {
    setActiveFile(playlist.fileURLs?.[0] ?? null);
  }

  return (
    <>
      {!playlist.isAvailable && <LockedAlert message={ruleset?.description} />}
      <div className={styles.container}>
        <div className={styles.headingImage}>
          <div className={styles.background}>
            <img src={playlist.previewImage} />
          </div>
          <div className={styles.content}>
            <h1>{playlist.name}</h1>
          </div>
        </div>
        <div className={styles.grid}>
          <div className={styles.gridItem}>
            <div className={styles.card}>
              {!!activeFile?.url && (
                <audio
                  controls
                  src={activeFile.url}
                  className={styles.tempAudioPlayer}
                />
              )}
              <div className={styles.playlistTracks}>
                {playlist.fileURLs
                  ?.filter((f) => f.url)
                  .map((file, i) => (
                    <div
                      key={i}
                      className={styles.track}
                      onClick={() => setActiveFile(file)}
                    >
                      <div className={styles.trackContent}>
                        <div className={styles.sectionImage}>
                          <img
                            src={file.coverURL}
                            className={styles.trackImage}
                          />
                        </div>
                        <div className={styles.sectionInfo}>
                          <h6>{file.name}</h6>
                          <p>
                            {file.artist} &mdash; {file.album}
                          </p>
                        </div>
                        {!!activeFile && file.url === activeFile?.url && (
                          <div className={styles.sectionActions}>
                            <Headphones size={28} />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
              {!activeFile?.url && (
                <AnimatedNFT
                  src={playlist?.previewImage}
                  thumbnail={playlist?.previewImage}
                  name={playlist?.name}
                  fileType={"Image"}
                  className={styles.imageLocked}
                >
                  <div className={styles.imgScrim}></div>
                  <div className={styles.imgLabel}>
                    <Lock size={20} /> {strings.locked}
                  </div>
                </AnimatedNFT>
              )}
            </div>
          </div>
          <div className={styles.gridItem}>
            <div className={styles.card}>
              <h2>{strings.info}</h2>
              <hr />
              <p>{playlist.description ?? <i>{strings.noDescription}</i>}</p>
            </div>
            {!playlist.isAvailable && (
              <div className={styles.card}>
                <h2>{strings.rules}</h2>
                <hr />
                <p>
                  {ruleset?.description ?? <i>{strings.noRulesDescription}</i>}
                </p>
              </div>
            )}
            {/* Ruleset Tree not ready yet - leave it hidden until then! */}
            {/* <div className={styles.requirements}>
            <hr />
            <p>{strings.rulesetTree}</p>
          </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PlaylistUI;
