import SmallNFTCard from "components/AppComponent/SmallNFTCard";
import Modal from "components/Widjets/Modal";
import { ClientConfig } from "config";
import { AvailableLanguage } from "lib/contexts/LanguageContext";
import { NftPreview } from ".";
import styles from "../common.module.scss";
import customStyles from "./custom.module.scss";
import { Recipient } from "./RecipientStep";

type Props = {
  recipient?: Recipient;
  nft: NftPreview;
  strings: ClientConfig["Strings"]["Modals"]["Sell"][AvailableLanguage];
};

export default function TransferStepLoading(props: Props) {
  const localizedStrings = props.strings.transferModal.transferStep;
  return (
    <Modal
      clickOutsideToClose={false}
      isVisible
      modalClassName={`${styles.genericModal} ${customStyles.transferModal}`}
    >
      <h3 className="mb-3">{localizedStrings.loadingTitle}</h3>
      {props.recipient?.name && (
        <div className={customStyles.confimUserInfo}>
          <span className="mr-2">{localizedStrings.profileName}:</span>
          <span>{props.recipient.name}</span>
        </div>
      )}
      {props.recipient?.address && (
        <div className={customStyles.confimUserInfo}>
          <span className="mr-2">{localizedStrings.address}:</span>
          <span>{props.recipient.address}</span>
        </div>
      )}
      <h5>{localizedStrings.loadingDisclaimer}</h5>
      <SmallNFTCard nft={props.nft as NftPreview} loading />
    </Modal>
  );
}
