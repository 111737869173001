import LogoSvg from "../img/budlight-header.svg";
import LoginSrc from "../img/DESKTOP_MOBILE_POST_PURCHASE_ASSET.png";
import {
  ArizonaCardinals,
  AtlantaFalcons,
  BaltimoreRavens,
  BuffaloBills,
  CarolinaPanthers,
  ChicagoBears,
  CincinnatiBengals,
  ClevelandBrowns,
  DallasCowboys,
  DenverBroncos,
  DetroitLions,
  GreenBayPackers,
  HoustonTexans,
  IndianapolisColts,
  JacksonvilleJaguars,
  KansasCityChiefs,
  LasVegasRaiders,
  LosAngelesChargers,
  LosAngelesRams,
  MiamiDolphins,
  MinnesotaVikings,
  NewEnglandPatriots,
  NewOrleansSaints,
  NewYorkGiants,
  NewYorkJets,
  PhiladelphiaEagles,
  PittsburghSteelers,
  SanFrancisco49ers,
  SeattleSeahawks,
  TampaBayBuccaneers,
  TennesseeTitans,
  WashingtonCommanders,
} from "../img/nfl-team-logos";

const LogoPng = LogoSvg;

const HTML_TITLE = "NFL AB";
const TEAM_CITY = "Wallet";
const TEAM_NAME = "NFL AB";
const COPYRIGHT = "Bud Light all rights reserved.";
const LOGIN_SRC = LoginSrc;
const LOGO_SVG_SRC = LogoSvg;
const LOGO_PNG_SRC = LogoPng;
const LOGO_HEIGHT_PX = 36;
const DISPLAY_INITIAL_LOGIN_PAGE = false;
const CUSTOM_FOOTER = "pickem";
const CUSTOM_NFT_NAME = "pickem";
const USE_LIVE_CHAT_WIDGET = false;

type NflTeam = {
  city: string;
  name: string;
  logoFileName: string;
  url: string;
  abbr: string;
};

export const nflTeams: NflTeam[] = [
  {
    city: "Buffalo",
    name: "Bills",
    logoFileName: BuffaloBills,
    url: "",
    abbr: "BUF",
  },
  {
    city: "Miami",
    name: "Dolphins",
    logoFileName: MiamiDolphins,
    url: "",
    abbr: "MIA",
  },
  {
    city: "New England",
    name: "Patriots",
    logoFileName: NewEnglandPatriots,
    url: "",
    abbr: "NE",
  },
  {
    city: "New York",
    name: "Jets",
    logoFileName: NewYorkJets,
    url: "",
    abbr: "NYJ",
  },
  {
    city: "Baltimore",
    name: "Ravens",
    logoFileName: BaltimoreRavens,
    url: "",
    abbr: "BLT",
  },
  {
    city: "Cleveland",
    name: "Browns",
    logoFileName: ClevelandBrowns,
    url: "",
    abbr: "CLV",
  },
  {
    city: "Cincinnati",
    name: "Bengals",
    logoFileName: CincinnatiBengals,
    url: "",
    abbr: "CIN",
  },
  {
    city: "Pittsburgh",
    name: "Steelers",
    logoFileName: PittsburghSteelers,
    url: "",
    abbr: "PIT",
  },
  {
    city: "Houston",
    name: "Texans",
    logoFileName: HoustonTexans,
    url: "",
    abbr: "HST",
  },
  {
    city: "Indianapolis",
    name: "Colts",
    logoFileName: IndianapolisColts,
    url: "",
    abbr: "IND",
  },
  {
    city: "Jacksonville",
    name: "Jaguars",
    logoFileName: JacksonvilleJaguars,
    url: "",
    abbr: "JAX",
  },
  {
    city: "Tennessee",
    name: "Titans",
    logoFileName: TennesseeTitans,
    url: "",
    abbr: "TEN",
  },
  {
    city: "Denver",
    name: "Broncos",
    logoFileName: DenverBroncos,
    url: "",
    abbr: "DEN",
  },
  {
    city: "Kansas City",
    name: "Chiefs",
    logoFileName: KansasCityChiefs,
    url: "",
    abbr: "KC",
  },
  {
    city: "Las Vegas",
    name: "Raiders",
    logoFileName: LasVegasRaiders,
    url: "",
    abbr: "LV",
  },
  {
    city: "Los Angeles",
    name: "Chargers",
    logoFileName: LosAngelesChargers,
    url: "",
    abbr: "LAC",
  },
  {
    city: "Dallas",
    name: "Cowboys",
    logoFileName: DallasCowboys,
    url: "",
    abbr: "DAL",
  },
  {
    city: "New York",
    name: "Giants",
    logoFileName: NewYorkGiants,
    url: "",
    abbr: "NYG",
  },
  {
    city: "Philadelphia",
    name: "Eagles",
    logoFileName: PhiladelphiaEagles,
    url: "",
    abbr: "PHI",
  },
  {
    city: "Washington",
    name: "Commanders",
    logoFileName: WashingtonCommanders,
    url: "",
    abbr: "WAS",
  },
  {
    city: "Chicago",
    name: "Bears",
    logoFileName: ChicagoBears,
    url: "",
    abbr: "CHI",
  },
  {
    city: "Detroit",
    name: "Lions",
    logoFileName: DetroitLions,
    url: "",
    abbr: "DET",
  },
  {
    city: "Green Bay",
    name: "Packers",
    logoFileName: GreenBayPackers,
    url: "",
    abbr: "GB",
  },
  {
    city: "Minnesota",
    name: "Vikings",
    logoFileName: MinnesotaVikings,
    url: "",
    abbr: "MIN",
  },
  {
    city: "Atlanta",
    name: "Falcons",
    logoFileName: AtlantaFalcons,
    url: "",
    abbr: "ATL",
  },
  {
    city: "New Orleans",
    name: "Saints",
    logoFileName: NewOrleansSaints,
    url: "",
    abbr: "NO",
  },
  {
    city: "Carolina",
    name: "Panthers",
    logoFileName: CarolinaPanthers,
    url: "",
    abbr: "CAR",
  },
  {
    city: "Tampa Bay",
    name: "Buccaneers",
    logoFileName: TampaBayBuccaneers,
    url: "",
    abbr: "TB",
  },
  {
    city: "Arizona",
    name: "Cardinals",
    logoFileName: ArizonaCardinals,
    url: "",
    abbr: "ARZ",
  },
  {
    city: "Los Angeles",
    name: "Rams",
    logoFileName: LosAngelesRams,
    url: "",
    abbr: "LAR",
  },
  {
    city: "San Francisco",
    name: "49ers",
    logoFileName: SanFrancisco49ers,
    url: "",
    abbr: "SF",
  },
  {
    city: "Seattle",
    name: "Seahawks",
    logoFileName: SeattleSeahawks,
    url: "",
    abbr: "SEA",
  },
];

export default {
  HTML_TITLE,
  TEAM_CITY,
  TEAM_NAME,
  COPYRIGHT,
  LOGIN_SRC,
  LOGO_SVG_SRC,
  LOGO_PNG_SRC,
  LOGO_HEIGHT_PX,
  DISPLAY_INITIAL_LOGIN_PAGE,
  HIDE_NFT_OWNER_AND_CREATOR: true,
  HIDE_NFT_INFO_CHIPS: true,
  HIDE_PFP_TRAITS: true,
  CUSTOM_FOOTER,
  CUSTOM_NFT_NAME,
  HIDE_EDITION_LOWEST_PRICE: true,
  HIDE_NFT_LIST_TABS: true,
  USE_LIVE_CHAT_WIDGET,
  HIDE_TRANSFER_BUTTON: false,
  DISABLE_PURCHASE_IF_STATUS_TRAIT_ACTIVE: true,
  REQUIRE_CONSENT_BEFORE_PURCHASE: true,
  PERSIST_FLOW_USER_IN_LOCAL_STORAGE: true,
  SHOW_CLAIMING_STEP_LIST: true,
  REDEMPTION_SHOW_REMAINING: false,
  REDEMPTION_SHOW_REDEEMED: false,
};
