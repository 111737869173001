import { flowAPI } from "lib/store/api/flow";
import { BlockchainMaintenanceBannerUI } from "../ui/BlockchainMaintenanceBannerUI";

export function BlockchainMaintenanceBannerFlowConnector() {
  const { data } = flowAPI.useGetBlockchainStatusQuery();

  return (
    <BlockchainMaintenanceBannerUI
      isUnderMaintenance={
        (data?.isBlockchainUnderMaintenance ||
          data?.isMarketplaceUnderMaintenance) ??
        false
      }
      message={data?.maintenanceMessage}
    />
  );
}
