import React, { CSSProperties, FunctionComponent } from "react";
import LoaderCircle from "../LoaderCircle";
import styles from "./style.module.scss";

type Props = {
  children?: React.ReactNode;
  className?: string;
  icon?: React.ReactNode;
  iconPosition?: "before" | "after";
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  style?: CSSProperties;
  type?: "primary" | "secondary" | "transparent";
  title?: string;
};

const Button: FunctionComponent<Props> = ({
  children,
  icon,
  iconPosition = "before",
  className,
  disabled = false,
  loading = false,
  onClick,
  style,
  type = "primary",
  title,
}: Props) => {
  const btnType: "button" | "submit" = onClick ? "button" : "submit";
  const rootClasses = [styles.button];
  rootClasses.push(styles[type]);
  if (icon) rootClasses.push(styles[iconPosition]);
  if (className) rootClasses.push(className);

  const renderChildren = () => {
    if (loading)
      return (
        <div className={styles.loadingContainer}>
          {children}
          <span className={styles.loaderCircle}>
            <LoaderCircle />
          </span>
        </div>
      );

    if (icon) {
      switch (iconPosition) {
        case "before":
          return (
            <>
              <span>{icon}</span>
              <span>{children}</span>
            </>
          );

        case "after":
        default:
          return (
            <>
              <span>{children}</span>
              <span>{icon}</span>
            </>
          );
      }
    }

    return (
      <>
        <span>{children}</span>
      </>
    );
  };

  return (
    <button
      style={style}
      onClick={onClick}
      type={btnType}
      disabled={disabled || loading}
      className={rootClasses.join(" ")}
      title={title}
    >
      {renderChildren()}
    </button>
  );
};

export default Button;
