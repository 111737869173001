import Modal from "components/Widjets/Modal";
import { getStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import { FunctionComponent, useState } from "react";
import { Lock } from "react-feather";
import styles from "./styles.module.scss";

type Props = {
  message?: string;
};

const LockedAlert: FunctionComponent<Props> = ({ message }) => {
  const strings = getStrings("Component", "LockedAlert")[useLanguage()];
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  return (
    <>
      <div className={styles.lockedAlert}>
        <span>
          <Lock size={13} /> {strings.locked}
        </span>
        <p onClick={() => setIsModalVisible(true)}>{strings.howToUnlock}</p>
      </div>

      <Modal
        isVisible={isModalVisible}
        btnTxt={strings.modalBtnTxt}
        onActionClick={() => setIsModalVisible(false)}
        onClose={() => setIsModalVisible(false)}
      >
        <h2>{strings.howToUnlock}</h2>
        <p className={styles.modalText}>{message ?? strings.defaultMessage}</p>
      </Modal>
    </>
  );
};

export default LockedAlert;
