import BloctoSDK from "@blocto/sdk";
import Web3 from "web3";
import { Blockchain } from "config";
import { BlockchainConfig } from "types/blockchain";
import MintStoreAbi from "lib/blockchain/polygon/abi/mint-store-item";
import detectEthereumProvider from "@metamask/detect-provider";
import { MetaMaskInpageProvider } from "@metamask/providers";

let walletProvider: EthWalletType | null;
let connectingWalletProvider: EthWalletType | null;

// FIXME: Change the way blockchain config works, so we don't have to resort to `as`
const PolygonConfig = Blockchain.NET_CONFIG as BlockchainConfig<"polygon">;

let bloctoSDK: BloctoSDK | undefined = initializeBloctoSDK();
let bloctoWeb3: Web3 | undefined = initializeBloctoWeb3();
let metamaskProvider: MetaMaskInpageProvider | undefined;
let metamaskWeb3: Web3 | undefined;

const setWalletProvider = (_walletProvider: EthWalletType | null) => {
  walletProvider = _walletProvider;
};

const setEthConnectingWalletProvider = (
  _connectingWalletProvider: EthWalletType | null
) => {
  connectingWalletProvider = _connectingWalletProvider;
};

//update bloctoSDK and blocto references when reinitializing
function reinitBlocto() {
  bloctoSDK = initializeBloctoSDK();
  bloctoWeb3 = initializeBloctoWeb3();
}

function initializeBloctoSDK(): BloctoSDK | undefined {
  if (
    Blockchain.BLOCKCHAIN_NAME === "polygon" &&
    PolygonConfig.CHAIN_ID &&
    PolygonConfig.WEB3_PROVIDER_URL
  ) {
    return new BloctoSDK({
      ethereum: {
        chainId: PolygonConfig.CHAIN_ID,
        rpc: PolygonConfig.WEB3_PROVIDER_URL,
      },
    });
  } else {
    return undefined;
  }
}

function initializeBloctoWeb3(): Web3 | undefined {
  if (Blockchain.BLOCKCHAIN_NAME === "polygon" && bloctoSDK != undefined) {
    // @ts-ignore This line follows the example shown here: https://docs.blocto.app/blocto-sdk/javascript-sdk/evm-sdk/web3.js
    // I suspect the types don't work because "Blocto SDK for Ethereum-like chains is still in Beta. APIs are subject to breaking changes."
    // But this version works for what we do with it.
    return new Web3(bloctoSDK.ethereum);
  } else {
    return undefined;
  }
}

const getMetaMask = async (): Promise<
  | {
      metamaskProvider: MetaMaskInpageProvider | undefined;
      metamaskWeb3: Web3 | undefined;
    }
  | {
      metamaskProvider: MetaMaskInpageProvider | undefined;
      metamaskWeb3: Web3 | undefined;
    }
> => {
  if (metamaskProvider && metamaskWeb3) {
    return { metamaskProvider, metamaskWeb3 };
  } else {
    try {
      const metamaskProvider = (await detectEthereumProvider({
        silent: true,
      })) as MetaMaskInpageProvider;
      const metamaskWeb3: Web3 = new Web3(metamaskProvider as any);
      return { metamaskProvider, metamaskWeb3 };
    } catch (error) {
      return { metamaskProvider, metamaskWeb3 };
    }
  }
};

const anonymous = new Web3(
  new Web3.providers.HttpProvider(PolygonConfig.WEB3_PROVIDER_URL)
);

const getMintStoreContract = (
  contractAddress: string,
  { needAuth }: { needAuth: boolean }
) =>
  new (needAuth && bloctoWeb3 ? bloctoWeb3 : anonymous).eth.Contract(
    MintStoreAbi,
    contractAddress
  );

const getMintStoreContractMetamask = async (contractAddress: string) => {
  if (metamaskWeb3 === undefined) {
    const { metamaskWeb3 } = await getMetaMask();
    if (metamaskWeb3 === undefined) {
      return new anonymous.eth.Contract(MintStoreAbi, contractAddress);
    }
    return new metamaskWeb3.eth.Contract(MintStoreAbi, contractAddress);
  } else {
    return new metamaskWeb3.eth.Contract(MintStoreAbi, contractAddress);
  }
};

export {
  walletProvider,
  connectingWalletProvider,
  bloctoSDK,
  bloctoWeb3,
  reinitBlocto,
  getMintStoreContract,
  getMintStoreContractMetamask,
  getMetaMask,
  setWalletProvider,
  setEthConnectingWalletProvider,
};
