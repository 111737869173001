import Modal from "components/Widjets/Modal";
import styles from "../common.module.scss";
import SmallNFTCard from "components/AppComponent/SmallNFTCard";
import { useLanguage } from "lib/contexts/LanguageContext";
import { getStrings } from "config";
import { PropsWithChildren } from "react";
import ClaimSuccessfulSubtitle from "./ClaimSuccessfulSubtitle";
import { Eth, Flow } from "types";

const strings = getStrings("Modals", "Claim");

interface NftPreview {
  id?: number;
  thumbnail?: string | null;
  name?: string | null;
  editionNumber: string | number | null;
  printingLimit: number | null;
}

export type ClaimModalProps = {
  onClose: () => void;
  nft: NftPreview;
  queryResult: {
    isClaimSuccess: boolean;
    isClaimError: boolean;
  };
  shouldRedirect?: boolean;
};

export function ClaimModal({
  onClose,
  nft,
  queryResult,
  children,
  shouldRedirect = false,
}: PropsWithChildren<ClaimModalProps>) {
  const lang = useLanguage();

  const { isClaimSuccess, isClaimError } = queryResult;

  const isLoading =
    !isClaimSuccess &&
    !isClaimError &&
    !(nft as Flow.FullNft | Eth.FullNft).state.isClaimed;

  if (isLoading) {
    return (
      <Modal
        isVisible
        modalClassName={styles.genericModal}
        btnLoading={isLoading}
      >
        <h3>{strings[lang].claimInProgressTitle}</h3>
        <h5 className="ma-1">{strings[lang].claimInProgressSubtitle}</h5>
        <h5>{strings[lang].pleaseWait}</h5>
        <SmallNFTCard nft={nft} loading={isLoading} />
      </Modal>
    );
  } else {
    return (
      <Modal
        isVisible
        onClose={() => onClose()}
        modalClassName={styles.genericModal}
        btnLoading={isLoading}
        btnTxt={strings[lang].openWallet}
      >
        <h3>
          {isClaimError
            ? strings[lang].unsuccessfulTitle
            : strings[lang].congratulations}
        </h3>
        {isClaimError ? (
          <h5 className="mt-2">{strings[lang].unsuccessfulSubtitle}</h5>
        ) : (
          <ClaimSuccessfulSubtitle shouldRedirect={shouldRedirect} />
        )}
        <SmallNFTCard nft={nft} success={!isClaimError} />
        {children}
      </Modal>
    );
  }
}
