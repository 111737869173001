const getPriceScript = `
import MintStoreMarketFactory from 0xMintStoreMarketFactory

// This is a script to get the listed price of an account's MintStoreItem.NFT at a specified Market.
// Returns nil if the NFT is not listed at that market
//
// Parameters:
//
// tokenID: The ID of the MintStoreItem.NFT that is listed for sale
// account: The Flow Address of the account whose item is listed for sale
// market: The Flow Address of the market at which the item is listed for sale

// Returns: UFix64?
// gives the price of a MintStoreItem.NFT as it is listed on the specified Market

pub fun main(tokenID: UInt64, account: Address, market: Address): UFix64? {
    let marketRef = getAccount(market).getCapability(MintStoreMarketFactory.MarketPublicPath)
                                      .borrow<&{MintStoreMarketFactory.MarketPublic}>()
        ?? panic("Cannot borrow a reference to the account's Market")

    let collectionRef = getAccount(account).getCapability(marketRef.SaleCollectionPublicPath)
                                           .borrow<&{MintStoreMarketFactory.SalePublic}>()
        ?? panic("Cannot borrow a reference to the account's SaleCollection")

    return collectionRef!.getPrice(tokenID: tokenID)
}
`;
export default getPriceScript;
