const MINT_STORE_ITEM_ADDRESS = {
  Testnet: "0x985d410b577fd4a1",
  Mainnet: "0x20187093790b9aef",
};

const MINT_STORE_MARKET_FACTORY_ADDRESS = {
  Testnet: "0x985d410b577fd4a1",
  Mainnet: "0x49b8e5d4d66ae880",
};

const METADATA_VIEWS_ADDRESS = {
  Testnet: "0x631e88ae7f1d7c20",
  Mainnet: "0x1d7e57aa55817448",
};

const NON_FUNGIBLE_TOKEN_ADDRESS = {
  Testnet: "0x631e88ae7f1d7c20",
  Mainnet: "0x1d7e57aa55817448",
};

const FUNGIBLE_TOKEN_ADDRESS = {
  Testnet: "0x9a0766d93b6608b7",
  Mainnet: "0xf233dcee88fe0abe",
};

const DUC_ADDRESS = {
  Testnet: "0x82ec283f88a62e65",
  Mainnet: "0xead892083b3e2c6c",
};

const NFT_STORE_FRONT_ADDRESS = {
  Testnet: "0x94b06cfca1d8a476",
  Mainnet: "0x4eb8a10cb9f87357",
};

const FUSD_ADDRESS = {
  Testnet: "0xe223d8a629e49c68",
  Mainnet: "0x3c5959b568896393",
};

const ACCESS_NODE = {
  Testnet: "https://rest-testnet.onflow.org",
  Mainnet: "https://rest-mainnet.onflow.org",
};

const WALLET = {
  Testnet: "https://flow-wallet-testnet.blocto.app/api/flow/authn",
  Mainnet: "https://flow-wallet.blocto.app/api/flow/authn",
};

const FLOW_MAX_GAS_LIMIT = {
  Testnet: 9999,
  Mainnet: 9999,
};

export {
  MINT_STORE_ITEM_ADDRESS,
  NON_FUNGIBLE_TOKEN_ADDRESS,
  ACCESS_NODE,
  WALLET,
  FUNGIBLE_TOKEN_ADDRESS,
  FUSD_ADDRESS,
  DUC_ADDRESS,
  NFT_STORE_FRONT_ADDRESS,
  MINT_STORE_MARKET_FACTORY_ADDRESS,
  METADATA_VIEWS_ADDRESS,
  FLOW_MAX_GAS_LIMIT,
};
