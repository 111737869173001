import styles from "../styles.module.scss";
import AlertComponent from "components/Widjets/Alert";
import Button from "components/Widjets/Button";
import { getStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import { Step } from "./SetupWizardUI";
import { Portal } from "react-portal";
import { useGetDomElement } from "../../../../../hooks/useGetDomElement";

type Props = {
  steps: Step[];
  completedSteps: Step[];
  onClick: () => void;
};

export function Alert(props: Props) {
  const localizedStrings = getStrings("Modals", "Wizard")[useLanguage()];
  const nftListPage = useGetDomElement("nftListPage");

  return (
    <>
      {nftListPage && (
        <Portal node={nftListPage}>
          <AlertComponent type={"blank"} className={styles.alert}>
            <div className={styles.profileAlertContent}>
              <p>
                {`${props.completedSteps.length} ${localizedStrings.alert.of} ${
                  props.steps.length
                } ${
                  props.steps.length > 1
                    ? localizedStrings.alert.steps
                    : localizedStrings.alert.step
                } ${localizedStrings.alert.completed}`}
              </p>
              <Button
                className={styles.custom}
                onClick={() => props.onClick()}
                type="secondary"
              >
                {localizedStrings.alert.button}
              </Button>
            </div>
          </AlertComponent>
        </Portal>
      )}
    </>
  );
}
