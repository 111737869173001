import { createContext, ReactNode, useContext } from "react";

export type AvailableLanguage = "en";

const defaultLanguage = "en";

const LanguageContext = createContext<AvailableLanguage>(defaultLanguage);

function LanguageProvider({ children }: { children: ReactNode }) {
  return (
    <LanguageContext.Provider value="en">{children}</LanguageContext.Provider>
  );
}

const useLanguage = () => useContext(LanguageContext);

export { LanguageProvider, useLanguage };
