import { Project } from "config";
import { flowAPI } from "lib/store/api/flow";
import { useCurrentWallet } from "lib/store/api/flow/hooks";
import { useAppSelector } from "lib/store/hooks";
import { selectAppUser } from "lib/store/slices/user-slice";
import { FullNft } from "types/app-model/flow";
import { ModalVisible } from "../NftShowPage";
import { PurchaseButtonUI } from "../ui/PurchaseButtonUI";
import { canPurchase } from "../util";

export function PurchaseButtonFlowConnector({
  itemFID,
  contractAddress,
  contractName,
  setModalVisible,
  purchase,
}: {
  itemFID: string;
  contractAddress: string;
  contractName: string;
  setModalVisible: React.Dispatch<React.SetStateAction<ModalVisible | null>>;
  purchase: (nft: FullNft) => void;
}) {
  const isLoggedOut = useAppSelector(selectAppUser) == null;

  const { data: nft } = flowAPI.useGetFullNftQuery({
    itemFID,
    contractAddress,
    contractName,
  });
  const { data: status } = flowAPI.useGetBlockchainStatusQuery();
  const isUnderMaintenance =
    status?.isBlockchainUnderMaintenance ||
    status?.isMarketplaceUnderMaintenance ||
    false;
  const wallet = useCurrentWallet();

  const hasInsufficientFunds =
    nft?.saleInfo?.price != null &&
    Number.parseFloat(wallet?.balance ?? "0") < nft.saleInfo.price;

  const onPurchaseClick = (nft: FullNft) => {
    setModalVisible("purchase");
    purchase(nft);
  };

  if (!canPurchase(nft) || nft == null) {
    return null;
  }

  return (
    <PurchaseButtonUI
      hasInsufficientFunds={
        Project.WALLET_TYPE.length === 1 && Project.WALLET_TYPE[0] === "Blocto"
          ? hasInsufficientFunds
          : false
      }
      disabled={isUnderMaintenance || (!isLoggedOut && !wallet)}
      loggedIn={!isLoggedOut}
      nft={nft}
      wallet={wallet}
      onClick={() =>
        isLoggedOut ? setModalVisible("login") : onPurchaseClick(nft)
      }
    />
  );
}
