const FACEBOOK_MEDIA_URL = "";
const INSTAGRAM_MEDIA_URL = "";
const TWITTER_MEDIA_URL = "https://twitter.com/MINTdotstore";
const YOUTUBE_MEDIA_URL = "";
const TIKTOK_MEDIA_URL = "";

export default {
  FACEBOOK_MEDIA_URL,
  INSTAGRAM_MEDIA_URL,
  TWITTER_MEDIA_URL,
  YOUTUBE_MEDIA_URL,
  TIKTOK_MEDIA_URL,
};
