import { FullNft } from "../../../types/app-model/flow";
import { Config } from "../../../config";
const { hasMarketplace } = Config.MarketPlace;

export const canClaim = (nft?: FullNft): boolean =>
  !!nft &&
  ((nft.state.isOwner && !nft.state.isClaimed) || nft.state.isClaimProcessing);

export const canWithdraw = (nft?: FullNft): boolean =>
  !!nft && nft.state.isOwner && nft.state.isClaimed && nft.state.isForSale;

export const canSell = (nft?: FullNft): boolean =>
  !!nft &&
  hasMarketplace &&
  nft.state.isOwner &&
  nft.state.isClaimed &&
  !nft.state.isForSale &&
  nft.edition.isForSale;

export const canTransfer = (nft?: FullNft): boolean =>
  !!nft &&
  nft.state.isClaimed &&
  !nft.state.isForSale &&
  nft.state.isOwner &&
  !Config.Client.HIDE_TRANSFER_BUTTON &&
  !(
    nft.scarcity &&
    Config.Client.HIDE_TRANSFER_BUTTON_FOR_NFT_SCARCITIES.includes(nft.scarcity)
  );

export const canPurchase = (nft?: FullNft): boolean =>
  !!nft &&
  hasMarketplace &&
  !nft.state.isOwner &&
  nft.state.isForSale &&
  nft.edition.isForSale;
