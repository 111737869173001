import { FC } from "react";
import { useLanguage } from "lib/contexts/LanguageContext";
import styles from "./styles.module.scss";
import { getStrings } from "config";

const strings = getStrings("Component", "ActiveSwitch");
type Props = {
  isActive?: boolean;
  onClick?: () => void;
};

const Switch: FC<Props> = ({ isActive = false, onClick }: Props) => {
  const lang = useLanguage();

  return (
    <>
      <div className={styles.activeSwitch}>
        {isActive && <p className={styles.label}>{strings[lang].active}</p>}
        <div
          className={styles.slider}
          data-active={isActive}
          onClick={onClick}
        />
      </div>
    </>
  );
};

export default Switch;
