import { withPrefix } from "@onflow/fcl";
import { flowAPI } from "lib/store/api/flow";
import {
  useCurrentWallet,
  useWalletSetupStatus,
} from "lib/store/api/flow/hooks";
import { getStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import { useState } from "react";
import { SmartContractStep } from "components/AppComponent/ActionModals/SetupWizard/ui/SmartContractStep";
import { ModalVisible } from "../NftShowPage";
import { canClaim } from "../util";
import { ClaimButtonUI } from "../ui/claim-button";

export function ClaimButtonFlowConnector({
  itemFID,
  contractAddress,
  contractName,
  setModalVisible,
  claim,
  setDisplayUnclaimableMessage,
  step,
  noModalClaim = false,
}: {
  itemFID: string;
  contractAddress: string;
  contractName: string;
  setModalVisible: React.Dispatch<React.SetStateAction<ModalVisible | null>>;
  claim: (walletAddress: string, nftID: number) => void;
  setDisplayUnclaimableMessage?: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  step?: string;
  noModalClaim?: boolean;
}) {
  const localizedStrings = getStrings("Pages", "Details")[useLanguage()];

  const { data: nft } = flowAPI.useGetFullNftQuery({
    itemFID,
    contractAddress,
    contractName,
  });

  const { data: status } = flowAPI.useGetBlockchainStatusQuery();
  const wallet = useCurrentWallet();
  const { data: smartContractsWithSetup } = useWalletSetupStatus();

  const [isSmartContractModalVisible, setisSmartContractModalVisible] =
    useState<boolean>(false);

  // The only reason why we keep this is if we eventually want to withdraw from another page,
  if (!nft || !canClaim(nft)) {
    return null;
  }

  const requiredSmartContract = smartContractsWithSetup?.find(
    (walletContract) =>
      walletContract.name === nft.smartContractName &&
      withPrefix(walletContract.address) ===
        withPrefix(nft.smartContractAddress)
  );

  let disabledReason: string | undefined;

  if (nft.state.isClaimProcessing) {
    disabledReason = localizedStrings.isClaimProcessing;
  } else if (wallet == null) {
    disabledReason = localizedStrings.noWalletDetected;
  } else if (status?.isBlockchainUnderMaintenance) {
    disabledReason = localizedStrings.blockchainMaintenance;
  }

  let enabledButtonWithUnclaimableMessage: string | undefined;

  if (!requiredSmartContract) {
    enabledButtonWithUnclaimableMessage = localizedStrings.unclaimable;
  }

  const isClaimable = requiredSmartContract?.isSetup === true;

  const onClaimClick = () => {
    if (wallet != null && nft.id != null && !noModalClaim) {
      if (isClaimable) {
        claim(wallet.address, nft.id);
        setModalVisible("claim");
      } else {
        setisSmartContractModalVisible(true);
      }
    } else {
      handleNoModalClaim();
    }
  };

  const [onSetup, setupStatus] =
    flowAPI.useSetupAccountForSmartContractMutation();

  const { reset: resetSetupStatus } = setupStatus;

  if (setupStatus.status === "rejected") resetSetupStatus();

  const handleNoModalClaim = () => {
    wallet &&
      requiredSmartContract &&
      onSetup({ wallet: wallet, smartContract: requiredSmartContract });
  };

  return (
    <>
      <ClaimButtonUI
        step={step}
        disabledReason={disabledReason}
        enabledButtonWithUnclaimableMessage={
          enabledButtonWithUnclaimableMessage
        }
        nft={nft}
        onClaimClick={onClaimClick}
        isClaimable={isClaimable}
        setDisplayUnclaimableMessage={setDisplayUnclaimableMessage}
        loading={setupStatus.isLoading}
      />
      {wallet && isSmartContractModalVisible && (
        <SmartContractStep
          onSetup={(smartContract) => {
            onSetup({ wallet, smartContract });
          }}
          onClose={() => setisSmartContractModalVisible(false)}
          walletType={wallet.walletType}
          insideSetupModal={false}
          setupStatus={setupStatus}
          requiredSmartContracts={
            requiredSmartContract && [requiredSmartContract]
          }
        />
      )}
    </>
  );
}
