import { SmartContract } from "types";
import * as Blockchain from "../../../global-blockchain-config";

const MERCHANT_LIST = {
  Testnet: [{ id: "22", address: "0x28492e97618732eb" }],
  Mainnet: [{ id: "78", address: "0xfc4b78e1076d4764" }],
};

const MINT_STORE_MARKET = {
  Testnet: { id: 0, address: "0x28492e97618732eb" },
  Mainnet: { id: 0, address: "0x8e4e3f4b2085762c" },
};

const WALLET_VIEWER_ID = {
  Testnet: 7,
  Mainnet: 20,
};
const KYC_MERCHANT_ID = {
  Testnet: 1,
  Mainnet: 1,
};
const SMART_CONTRACTS: SmartContract[] = [
  {
    id:
      Blockchain.NET === "Testnet"
        ? "66198374-7738-4af4-b285-6591143fe9f4"
        : "b38e464a-d8b4-49c2-bd53-942e28627257",
    name: "RTLStoreItem",
    type: "Edition",
    address:
      Blockchain.NET === "Testnet"
        ? "0x985d410b577fd4a1"
        : "0x9d1d0d0c82bf1c59",
    publicCollectionStoragePath: "RTLStoreItemCollectionPublic",
    providerPath: "RTLStoreItemCollection",
  },
];

export {
  MERCHANT_LIST,
  MINT_STORE_MARKET,
  WALLET_VIEWER_ID,
  KYC_MERCHANT_ID,
  SMART_CONTRACTS,
};
