import { Blockchain } from "config";
import { BlockchainMaintenanceBannerFlowConnector } from "./connectors/BlockchainMaintenanceBannerFlowConnector";

export function BlockchainMaintenanceBanner() {
  switch (Blockchain.BLOCKCHAIN_NAME) {
    case "flow":
      return <BlockchainMaintenanceBannerFlowConnector />;
    case "polygon":
      // Polygon platforms currently does not support this
      return null;
  }
}
