const FACEBOOK_MEDIA_URL = "https://www.facebook.com/nbagleague";
const INSTAGRAM_MEDIA_URL = "https://www.instagram.com/nbagleague/";
const TWITTER_MEDIA_URL = "https://twitter.com/nbagleague";
const YOUTUBE_MEDIA_URL =
  "https://www.youtube.com/channel/UCpGimyrbwRtrcJ-CIiRDXbA";
const TIKTOK_MEDIA_URL = "https://www.tiktok.com/@nbagleague";

export default {
  FACEBOOK_MEDIA_URL,
  INSTAGRAM_MEDIA_URL,
  TWITTER_MEDIA_URL,
  YOUTUBE_MEDIA_URL,
  TIKTOK_MEDIA_URL,
};
