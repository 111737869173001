import styles from "./style.module.scss";
import { Config, getStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import MetaTags from "components/Widjets/MetaTags";
import { LOGIN_ROUTE, NFT_LIST_ROUTE } from "config/routes";

type Props = {
  isLoggedIn: boolean;
};

export function ErrorPageUI({ isLoggedIn }: Props) {
  const localizedStrings = getStrings("Pages", "ErrorPage")[useLanguage()];
  return (
    <div className={styles.content}>
      <MetaTags
        title={Config.Metatags.NOT_FOUND_META_TAG.TITLE}
        description={Config.Metatags.NOT_FOUND_META_TAG.DESCRIPTION}
      />
      <div className={styles.logo}>
        <img src={Config.Client.LOGO_SVG_SRC} alt={Config.Client.TEAM_NAME} />
      </div>
      <h1>{localizedStrings.title}</h1>
      <h2>{localizedStrings.description}</h2>
      <div>
        <a href={isLoggedIn ? NFT_LIST_ROUTE : LOGIN_ROUTE}>
          {localizedStrings.linkTxt}
        </a>
      </div>
    </div>
  );
}
