import { FC } from "react";
import CollectiblesSection from "../../../components/Widjets/Collectibles/CollectiblesSection";
import Collectible from "../../../components/Widjets/Collectibles/Collectible";
import {
  MARKETPLACE_MERCHANT_ROUTE,
  MARKETPLACE_ROUTES,
} from "../../../config/routes";
import { Flow } from "../../../types";
import { Project } from "../../../config";
import { useNavigate } from "react-router-dom";
import { NET } from "../../../config/global-blockchain-config";
import { UIProps } from "./types";
import useIsMobile from "hooks/useIsMobile";

const Merchants: FC<UIProps<Flow.Merchant>> = ({
  strings,
  searchTerm,
  items,
}) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  return Project.MERCHANT_LIST[NET].length > 1 ? (
    <CollectiblesSection
      title={strings.creatorsHeading}
      searchTerm={searchTerm}
      total={items.length}
      displayShowAll={isMobile || items.length > 10}
      onShowAll={() => navigate(MARKETPLACE_ROUTES.CREATORS)}
    >
      {!items.length && <p>{strings.noResult}</p>}
      {items?.map((merchant, i) => (
        <Collectible
          key={i}
          title={merchant.profileName ?? ""}
          imageSrc={merchant.profileImg ?? undefined}
          onClick={() =>
            navigate(
              MARKETPLACE_MERCHANT_ROUTE.replace(
                ":id",
                merchant.merchantFID ?? ""
              ).replace(":profileName", merchant.profileName ?? "")
            )
          }
        />
      ))}
    </CollectiblesSection>
  ) : null;
};

export default Merchants;
