import { ScarcityType } from "config";

const hasMarketplace = true;
const challengeLink = "";
const scarcityType: ScarcityType = "unique"; // bundle or unique (bulls (bundle) vs misa (unique))
const canWithdrawFunds = false;
export default {
  hasMarketplace,
  challengeLink,
  scarcityType,
  canWithdrawFunds,
};
