import { SmartContract } from "types";
// prettier-ignore
export const listForSaleStoreFront = (contract: SmartContract): string => `
import ${contract.name} from ${contract.address}
import NonFungibleToken from 0xNonFungibleToken
import DapperUtilityCoin from 0xDapperUtilityCoin
import NFTStorefront from 0xNFTStorefront
import FungibleToken from 0xFungibleToken
import MetadataViews from 0xMetadataViews


transaction(saleItemID: UInt64, saleItemPrice: UFix64) {
  let ducReceiver: Capability<&{FungibleToken.Receiver}>
  let ${contract.name}Provider: Capability<&${contract.name}.Collection{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
  let storefront: &NFTStorefront.Storefront
  var saleCutsArray: [NFTStorefront.SaleCut];


  prepare(acct: AuthAccount) {
		self.saleCutsArray = []

    // If the account doesn't already have a Storefront
    if acct.borrow<&NFTStorefront.Storefront>(from: NFTStorefront.StorefrontStoragePath) == nil {

      // Create a new empty .Storefront
      let storefront <- NFTStorefront.createStorefront() as! @NFTStorefront.Storefront

      // save it to the account
      acct.save(<-storefront, to: NFTStorefront.StorefrontStoragePath)

      // create a public capability for the .Storefront
      acct.link<&NFTStorefront.Storefront{NFTStorefront.StorefrontPublic}>(NFTStorefront.StorefrontPublicPath, target: NFTStorefront.StorefrontStoragePath)
       }

      self.ducReceiver = acct.getCapability<&{FungibleToken.Receiver}>(/public/dapperUtilityCoinReceiver)
		  assert(self.ducReceiver.borrow() != nil, message: "Missing or mis-typed DUC receiver")

      if !acct.getCapability<&${contract.name}.Collection{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(/private/${contract.name}Collection)!.check() {
        acct.link<&${contract.name}.Collection{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(/private/${contract.name}Collection, target: /storage/${contract.name}Collection)
      }

      self.${contract.name}Provider = acct.getCapability<&${contract.name}.Collection{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(/private/${contract.name}Collection)!
      assert(self.${contract.name}Provider.borrow() != nil, message: "Missing or mis-typed ${contract.name}.Collection provider")

 		  let collection = acct
            .getCapability(${contract.name}.CollectionPublicPath)
            .borrow<&{${contract.name}.${contract.name}CollectionPublic}>()
            ?? panic("Could not borrow a reference to the collection")

      var totalRoyalties = 0.0
      let nft = collection.borrow${contract.name}(id: saleItemID) ?? panic("Could not borrow nft reference with that ID")
      ${contract.txRejectionCondition?.listForSale ?? ""}
      // Check whether the NFT implements the MetadataResolver or not.
      if nft.getViews().contains(Type<MetadataViews.Royalties>()) {
        let royaltiesRef = nft.resolveView(Type<MetadataViews.Royalties>())?? panic("Unable to retrieve the royalties")
        let royalties = (royaltiesRef as! MetadataViews.Royalties).getRoyalties()
        for royalty in royalties {
        if !royalty.receiver!.check() {
          panic("Royalty receiver capability is not valid")
        }
        var amount = UFix64(royalty.cut * saleItemPrice);
        totalRoyalties = totalRoyalties + amount;
        self.saleCutsArray.append(NFTStorefront.SaleCut(receiver: royalty.receiver, amount: amount))				
        }
      }
      
      // Append the cut for the seller.
      self.saleCutsArray.append(NFTStorefront.SaleCut(
        receiver: self.ducReceiver,
        amount: saleItemPrice - totalRoyalties
        ))
		
      self.storefront = acct.borrow<&NFTStorefront.Storefront>(from: NFTStorefront.StorefrontStoragePath)
      ?? panic("Missing or mis-typed NFTStorefront Storefront")

      let existingOffers = self.storefront.getListingIDs()
      if existingOffers.length > 0 {
        for listingResourceID in existingOffers {
          let listing: &NFTStorefront.Listing{NFTStorefront.ListingPublic}? = self.storefront.borrowListing(listingResourceID: listingResourceID)
          if listing != nil && listing!.getDetails().nftID == saleItemID && listing!.getDetails().nftType == Type<@${contract.name}.NFT>(){
            self.storefront.removeListing(listingResourceID: listingResourceID)
          }
        }
      }
    }

    pre {}

    execute {

        self.storefront.createListing(
            nftProviderCapability: self.${contract.name}Provider,
            nftType: Type<@${contract.name}.NFT>(),
            nftID: saleItemID,
            salePaymentVaultType: Type<@DapperUtilityCoin.Vault>(),
            saleCuts: self.saleCutsArray
        )
    }
}`;
