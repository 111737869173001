import { useEffect, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "./index";
import _ from "lodash";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type Paginated<T> = {
  list: T[];
  total: number;
};

export type PaginatedWithRulesets<T> = {
  list: T[];
  rulesetIds: string[];
  total: number;
};

export type PaginatedShopifyContent<T> = {
  list: T[];
  cursor: string;
  hasNextPage: boolean;
  rulesetIds: string[];
};

export function usePaginatedList<T>(
  page = 0,
  useGetPageData: (skip: boolean) => {
    data?: Paginated<T>;
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
  }
) {
  const [noMoreResults, setNoMoreResults] = useState(false);

  const {
    data: pageData,
    isLoading: isPageLoading,
    ...rest
  } = useGetPageData(noMoreResults);
  const [completeList, setCompleteList] = useState<T[]>([]);

  const isLoading = isPageLoading || (rest.isFetching && page === 0);

  useEffect(() => {
    const newList = pageData?.list;
    if (newList != null) {
      if (page === 0) {
        // Page was set back to 0: remove old daa
        setCompleteList(newList);
      } else {
        // New page: aggregate data
        setCompleteList((oldList) => [...oldList, ...newList]);
      }
    } else if (page > 1) {
      setNoMoreResults(true);
    }
  }, [pageData]);

  return {
    // This makes sure that reseting the page does not show the stale data while refetching
    data: isLoading ? [] : completeList,
    total: pageData?.total ?? 0,
    isLoading,
    ...rest,
  };
}

export function usePaginatedListWithRulesets<T>(
  page = 0,
  useGetPageData: (skip: boolean) => {
    data?: PaginatedWithRulesets<T>;
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
  }
) {
  const [noMoreResults, setNoMoreResults] = useState(false);
  const {
    data: pageData,
    isLoading: isPageLoading,
    ...rest
  } = useGetPageData(noMoreResults);
  const [completeDataList, setCompleteDataList] = useState<T[]>([]);
  const [completeRulesetList, setCompleteRulesetList] = useState<string[]>([]);

  const isLoading = isPageLoading || (rest.isFetching && page === 0);

  useEffect(() => {
    const newDataList = pageData?.list;
    const newRulesetList = pageData?.rulesetIds;

    if (newDataList != null) {
      if (page === 0) {
        setCompleteDataList(newDataList);
      } else {
        setCompleteDataList((oldList) => [...oldList, ...newDataList]);
      }
    } else if (page > 1) {
      setNoMoreResults(true);
    }

    if (newRulesetList != null) {
      if (page === 0) {
        setCompleteRulesetList(newRulesetList);
      } else {
        setCompleteRulesetList((oldList) =>
          _.uniq([...oldList, ...newRulesetList])
        );
      }
    }
  }, [pageData]);

  return {
    data: isLoading ? [] : completeDataList,
    rulesetIds: isLoading ? [] : completeRulesetList,
    total: pageData?.total ?? 0,
    isLoading,
    ...rest,
  };
}

export function usePaginatedShopify<T>(
  useGetPageData: (skip: boolean) => {
    data?: PaginatedShopifyContent<T>;
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
  }
) {
  const {
    data: pageData,
    isLoading: isPageLoading,
    ...rest
  } = useGetPageData(false);
  const [completeDataList, setCompleteDataList] = useState<T[]>([]);
  const [completeRulesetList, setCompleteRulesetList] = useState<string[]>([]);

  const isLoading =
    isPageLoading || (rest.isFetching && !completeDataList.length);

  useEffect(() => {
    const newDataList = pageData?.list;
    const newRulesetList = pageData?.rulesetIds;
    if (newDataList != null) {
      setCompleteDataList((oldList) => [...oldList, ...newDataList]);
    }

    if (newRulesetList != null) {
      setCompleteRulesetList((oldList) =>
        _.uniq([...oldList, ...newRulesetList])
      );
    }
  }, [pageData]);

  return {
    data: isLoading ? [] : completeDataList,
    rulesetIds: isLoading ? [] : completeRulesetList,
    cursor: pageData?.cursor ?? "",
    hasNextPage: pageData?.hasNextPage,
    isLoading,
    ...rest,
  };
}
