import background from "../img/login-photo.png";
import logo from "../img/logo.svg";

const BACKGROUND = background;
const LOGO = logo;

const TITLE = "We Need to Check Your ID";
const SUBTITLE = "You must be of legal drinking age to enter this site";
const TAGLINE = "Enjoy Responsibly";
const COPYRIGHT = "© 2022 A-B, BUD LIGHT ® BEER, ST. LOUIS, MO.";
const TERMS =
  "By submitting this form, you agree to be bound by the Terms of Service and Privacy Policy. Do Not Sell My Personal Information";
const WARNING = "Sorry, you are not old enough to view this site";

const VERIFICATION_URL = "https://webshop.anheuser-busch.com/lda/AgeCheck.aspx";

export default {
  BACKGROUND,
  LOGO,
  TITLE,
  SUBTITLE,
  TAGLINE,
  COPYRIGHT,
  TERMS,
  WARNING,
  VERIFICATION_URL,
};
