import { Project } from "config";
import { AppContext } from "context/AppContext";
import { authenticationAPI } from "lib/store/api/authentication-api";
import { useCurrentWallet, useRegister } from "lib/store/api/eth/hooks";
import { useAppSelector } from "lib/store/hooks";
import { selectAppUser } from "lib/store/slices/user-slice";
import { useContext } from "react";
import { SetupWizardUI, Step } from "../ui/SetupWizardUI";

type Props = {
  showAlert?: boolean;
};

export function SetupWizardEthConnector({ showAlert }: Props) {
  const completedSteps: Step[] = [];

  const appContext = useContext(AppContext) as AppContext;
  const user = useAppSelector(selectAppUser);
  const wallet = useCurrentWallet();

  const walletType = Project.WALLET_TYPE[0]; //default to first if multiple wallets are supported

  if (user?.primaryEthWalletId != null) {
    completedSteps.push("register");
  }
  if (user?.profileName != null) {
    completedSteps.push("profile");
  }

  const [onLogin, registerStatus] = useRegister();
  const [onCompleteProfile, profileStatus] =
    authenticationAPI.useSetUsernameMutation();

  const isLoading = registerStatus.isLoading || profileStatus.isLoading;

  const isProfileStatusError = profileStatus.isError;

  const onRegister = () => {
    if (Project.WALLET_TYPE?.length > 1) {
      appContext.wizard.display.setIsVisible(false);

      appContext.selectWallet.callbacks.setOnClose(() => () => {
        appContext.selectWallet.display.setIsVisible(false);
        appContext.wizard.display.setIsVisible(true);
      });

      appContext.selectWallet.callbacks.setOnSuccess(() => () => {
        appContext.selectWallet.display.setIsVisible(false);
        appContext.wizard.display.setIsVisible(true);
      });

      appContext.selectWallet.display.setIsVisible(true);
    } else {
      onLogin();
    }
  };

  return (
    <SetupWizardUI
      showAlert={
        (user?.primaryEthWalletId == null || wallet != null) && showAlert
      }
      steps={["register", "profile"]}
      completedSteps={completedSteps}
      wallet={wallet}
      isLoading={isLoading}
      walletType={walletType}
      onRegister={onRegister}
      isError={isProfileStatusError}
      onCompleteProfile={(profileName) =>
        // The profile step will not render if the user is null anyway
        user != null && onCompleteProfile({ id: user.id, profileName })
      }
    />
  );
}
