const getTotalRoyaltyRateScript = `
    import MintStoreMarketFactory from 0xMintStoreMarketFactory

    // This script reads the total amount of royalty taken on purchase (the sum of all royalties)
    // Returns: UFix64
    // The total royalty amount
    pub fun main(marketAddress: Address): UFix64{
        let mrkt = getAccount(marketAddress)
        let marketRef = mrkt.getCapability(MintStoreMarketFactory.MarketPublicPath).borrow<&{MintStoreMarketFactory.MarketPublic}>()
            ?? panic("Cannot borrow a reference to the account's Market")
        return marketRef.getTotalRoyaltyRate()
    }
`;
export default getTotalRoyaltyRateScript;
