import { Fragment, ReactNode, useState } from "react";
import styles from "./style.module.scss";
import { ChevronDown, ChevronUp } from "react-feather";
import Panel from "./panel";

export type PanelHeader<T> = {
  text: string;
  value: string;
  isOpen?: boolean;
  multiple?: boolean; // if multiple option can be selected
  options: ItemOption[];
  display?: (value: T) => ReactNode | JSX.Element;
  collapsable?: boolean;
  onSelect?: (item: ItemOption) => void;
};

export type ItemOption = {
  key: string;
  value: string;
  inverted?: boolean;
  isSelected?: boolean;
};

type Props<T> = {
  headers: PanelHeader<T>[];
  selectedItems?: ItemOption[];
  setSelectedItems?: (items: ItemOption[]) => void;
  onRemove?: (item: ItemOption) => void;
  multipleTabActive?: boolean;
  className?: string;
};

function defaultActiveTabIdxArr<T>(headers: PanelHeader<T>[]) {
  return headers.filter((x) => x.isOpen).map((_, i) => i);
}
export const ExpansionPanels = <T extends unknown>({
  headers,
  setSelectedItems,
  selectedItems = [],
  multipleTabActive = false,
  className,
}: Props<T>) => {
  /**
   * ┌────────┐
   * │ State  │
   * └────────┘
   */
  const [_activeTabs, _setActiveTab] = useState<number[]>(
    defaultActiveTabIdxArr(headers)
  );

  /**
   * ┌─────────┐
   * │ Handler │
   * └─────────┘
   */
  const onPanelHeaderClick = (idx: number) => {
    const newActiveTab = [..._activeTabs];
    const activeTabIdx = newActiveTab.findIndex((_idx) => _idx === idx);
    if (activeTabIdx === -1) {
      multipleTabActive ? newActiveTab.push(idx) : (newActiveTab[0] = idx);
    } else {
      newActiveTab.splice(activeTabIdx, 1);
    }

    _setActiveTab(newActiveTab);
  };

  const onOptionSelection = (item: ItemOption, header?: PanelHeader<T>) => {
    if (header?.onSelect) header.onSelect(item);

    let newSelectedOption = [...selectedItems];

    const idx = newSelectedOption.findIndex(
      (i) => i.value === item.value && i.key === item.key
    );
    if (!header?.multiple) {
      newSelectedOption = newSelectedOption.filter((x) => x.key !== item.key);
      if (idx === -1) newSelectedOption.push(item);
      return setSelectedItems?.(newSelectedOption);
    }

    if (item.isSelected && idx === -1) {
      newSelectedOption.push(item);
    } else {
      newSelectedOption.splice(idx, 1);
    }

    setSelectedItems?.(newSelectedOption);
  };

  return (
    <div className={className}>
      {headers.map((header, idx) => (
        <Fragment key={`header_${header.value}`}>
          <button
            onClick={() => onPanelHeaderClick(idx)}
            className={styles.extensionPanel}
          >
            <span>{header.text}</span>
            <div className={styles.panelExtraInfo}>
              <span>{`${header.options.length}`}</span>
              {_activeTabs.includes(idx) ? <ChevronUp /> : <ChevronDown />}
            </div>
          </button>
          <div>
            <Panel
              {...header}
              isOpen={_activeTabs.includes(idx)}
              onSelect={(item) => onOptionSelection(item, header)}
              selectedItems={selectedItems}
            />
          </div>
        </Fragment>
      ))}
    </div>
  );
};
