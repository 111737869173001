import { FunctionComponent } from "react";
import { ClaimButtonProps, DefaultClaimButtonUI } from "./default";
import { flowAPI } from "lib/store/api/flow";
import { useCurrentWallet } from "lib/store/api/flow/hooks";
import customizedString from "config/clients/pickem/strings/Pages/CustomClaimButton.json";
import { useLanguage } from "../../../../../lib/contexts/LanguageContext";

type NFTLimitPerClient = {
  editionFID: string; // must be number in string;
  limit: number;
};

const RESTRICTION_BASED_ON_EDITION: NFTLimitPerClient[] = [
  {
    editionFID: "33",
    limit: 1,
  },
];
export const PickemClaimButtonUI: FunctionComponent<ClaimButtonProps> = (
  props
) => {
  const wallet = useCurrentWallet();
  const _customizedString = customizedString[useLanguage()];
  const restriction = RESTRICTION_BASED_ON_EDITION.find(
    (rules) => rules.editionFID === props.nft.editionFID
  );
  const { data } = flowAPI.useGetNftsQuery(
    {
      skip: 0,
      limit: 1, // we want to limit it at one because the data is not usefully,
      query: {
        type: "claimed",
        editionFID: restriction?.editionFID,
        walletAddresses: wallet?.address,
      },
    },

    { skip: !props.nft || !wallet }
  );

  const disableReason =
    restriction && data && data.total > restriction.limit - 1
      ? data.list[0].mintingStatus === "CLAIMED"
        ? `${_customizedString.limitClaimingErrorMsgPrefix} ${restriction.limit} ${_customizedString.limitClaimingErrorMsgSuffix}`
        : _customizedString.limitClaimProcessing
      : props.disabledReason;

  return (
    <>
      {disableReason && <p>{disableReason}</p>}
      <DefaultClaimButtonUI {...props} disabledReason={disableReason} />
    </>
  );
};
