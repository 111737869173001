import { ItemOption } from "./index";
import { FC } from "react";
import { Checkbox } from "../../index";
import styles from "./style.module.scss";

type Props = {
  option: ItemOption;
  onSelect?: (item: ItemOption) => void;
  selectedItems?: ItemOption[];
};
const PanelOption: FC<Props> = ({
  option: { key, value, inverted = false },
  onSelect,
  selectedItems = [],
}) => {
  const _isSelected =
    selectedItems.findIndex((x) => x.key === key && x.value === value) !== -1;

  /**
   * ┌─────────┐
   * │ Handler │
   * └─────────┘
   */
  const _onSelect = (selected: boolean) => {
    onSelect?.({ key, value, isSelected: selected, inverted });
  };

  return (
    <div className={styles.option}>
      {value}
      <Checkbox
        selected={_isSelected}
        onChange={(selected) => _onSelect(selected)}
      />
    </div>
  );
};
export default PanelOption;
