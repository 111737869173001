import React, { FunctionComponent } from "react";

import { DefaultFooter } from "./DefaultFooter";

import {
  FOOTER_COPYRIGHT,
  FOOTER_MARS_BRANDING,
  FOOTER_MARS_BRANDING_TEXT,
  FOOTER_LINKS,
} from "config/clients/sheba/customizable/client";

import styles from "./sheba.module.scss";

export const ShebaFooter: FunctionComponent<{ onLogout: () => void }> =
  React.memo(({ onLogout }) => {
    return (
      <>
        <DefaultFooter logout={onLogout} />
        <footer className={styles.shebaFooter}>
          <div className={styles.container}>
            <div className={styles.brandingSection}>
              <img
                src={FOOTER_MARS_BRANDING}
                alt={FOOTER_MARS_BRANDING_TEXT}
                className={styles.brandingImg}
              />
            </div>

            <div className={styles.linkSection}>
              <ul>
                {FOOTER_LINKS.map((navItem, i) => (
                  <li key={i}>
                    <a href={navItem.link} target={"_blank"}>
                      {navItem.title}
                    </a>
                    {navItem.icon && (
                      <img alt={navItem.iconAlt} src={navItem.icon} />
                    )}
                  </li>
                ))}
              </ul>
            </div>

            <div className={styles.copyrightSection}>
              <p>{FOOTER_COPYRIGHT}</p>
            </div>
          </div>
        </footer>
      </>
    );
  });
