import React, {
  CSSProperties,
  FunctionComponent,
  HTMLAttributeAnchorTarget,
} from "react";
import styles from "./styles.module.scss";

type Props = {
  children?: React.ReactNode;
  className?: string;
  href: string;
  target?: HTMLAttributeAnchorTarget;
  style?: CSSProperties;
  type?: "primary" | "secondary" | "transparent";
};

const ButtonHref: FunctionComponent<Props> = ({
  children,
  className,
  href,
  target,
  style,
  type = "primary",
}) => {
  const rootClasses = [styles.button];
  rootClasses.push(styles[type]);
  if (className) rootClasses.push(className);

  return (
    <>
      <a
        href={href}
        target={target}
        className={rootClasses.join(" ")}
        style={style}
      >
        <span>{children}</span>
      </a>
    </>
  );
};

export default ButtonHref;
