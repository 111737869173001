// To get typescript type of file
import * as Default from "./default";
import * as Global from "./global";
import { BlockchainConfig, BlockchainConfigList } from "types/blockchain";

const CLIENT = require("./client").CLIENT;

type ClientConfig = {
  Blockchain: typeof Default.Blockchain;
};

const Client: ClientConfig = {
  Blockchain: require(`./clients/${CLIENT}/blockchain`),
};

//This merges merge Blockchain context of client and default with client value
// overriding default if provided
const Blockchain = {
  ...Global.Blockchain,
  ...Default.Blockchain,
  ...Client.Blockchain,
};

const {
  NET,
  BLOCKCHAIN_NAME,
}: {
  NET: "Mainnet" | "Testnet";
  BLOCKCHAIN_NAME: SupportedBlockchain;
} = Blockchain;

const RAW_CONFIG: BlockchainConfig<SupportedBlockchain> = require(`./global/blockchain/${BLOCKCHAIN_NAME}`);

// Get the blockchain config
const BLOCKCHAIN_CONFIG: BlockchainConfigList = {
  polygon: {
    CURRENCY: "MATIC",
    HAS_WALLETS_FUNDS_ACTION: false,
    HAS_WALLET_SETUP_ACTION: false,
  },
  flow: {
    CURRENCY: "$",
    HAS_WALLETS_FUNDS_ACTION: false,
    HAS_WALLET_SETUP_ACTION: true,
  },
};

const BLOCKCHAIN_WALLET_HAS_WALLET_SETUP_ACTION =
  BLOCKCHAIN_CONFIG[BLOCKCHAIN_NAME].HAS_WALLET_SETUP_ACTION;

const BLOCKCHAIN_WALLET_HAS_WALLETS_FUNDS_ACTION =
  BLOCKCHAIN_CONFIG[BLOCKCHAIN_NAME].HAS_WALLETS_FUNDS_ACTION;

const BLOCKCHAIN_WALLET_CURRENCY = BLOCKCHAIN_CONFIG[BLOCKCHAIN_NAME].CURRENCY;

const NET_CONFIG = Object.entries(RAW_CONFIG).reduce(
  (acc, value) => ({
    ...acc,
    ...{ [value[0]]: value[1][NET] },
  }),
  {}
);

export {
  NET_CONFIG,
  BLOCKCHAIN_WALLET_CURRENCY,
  BLOCKCHAIN_WALLET_HAS_WALLETS_FUNDS_ACTION,
  BLOCKCHAIN_WALLET_HAS_WALLET_SETUP_ACTION,
  NET,
  BLOCKCHAIN_NAME,
};
