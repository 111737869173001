import LoginSrc from "../img/bogus-rect.png";
import LogoSvg from "../img/logoipsum-logo-10.svg";
const LogoPng = LogoSvg;

const HTML_TITLE = "Dapper Wallet POC Viewer";
const TEAM_CITY = "Dapper Wallet POC";
const TEAM_NAME = "Dapper Wallet POC";
const COPYRIGHT = "MINT";
const LOGIN_SRC = LoginSrc;
const LOGO_SVG_SRC = LogoSvg;
const LOGO_PNG_SRC = LogoPng;

export default {
  HTML_TITLE,
  TEAM_CITY,
  TEAM_NAME,
  COPYRIGHT,
  LOGIN_SRC,
  LOGO_SVG_SRC,
  LOGO_PNG_SRC,
};
