import { SmartContract } from "../../../../../../types";

export const purchaseStoreFrontScript = (contract: SmartContract): string => `
import FungibleToken from 0xFungibleToken
import NonFungibleToken from 0xNonFungibleToken
import DapperUtilityCoin from 0xDapperUtilityCoin
import NFTStorefront from 0xNFTStorefront
import ${contract.name} from ${contract.address}
import MetadataViews from 0xMetadataViews

transaction(storefrontAddress: Address, listingResourceID: UInt64, expectedPrice: UFix64) {
    let paymentVault: @FungibleToken.Vault
    let buyerMintStoreCollection: &{${contract.name}.${contract.name}CollectionPublic}
    let storefront: &NFTStorefront.Storefront{NFTStorefront.StorefrontPublic}
    let listing: &NFTStorefront.Listing{NFTStorefront.ListingPublic}
    let balanceBeforeTransfer: UFix64
    let mainDucVault: &DapperUtilityCoin.Vault

    prepare(dapper: AuthAccount, buyer: AuthAccount) {
        // Initialize the buyer's account if it is not already initialized
        if buyer.borrow<&${contract.name}.Collection>(from: ${contract.name}.CollectionStoragePath) == nil {
            buyer.save(<-${contract.name}.createEmptyCollection(), to: ${contract.name}.CollectionStoragePath)

            buyer.link<&${contract.name}.Collection{${contract.name}.${contract.name}CollectionPublic, NonFungibleToken.CollectionPublic, MetadataViews.ResolverCollection}>(
                ${contract.name}.CollectionPublicPath,
                target: ${contract.name}.CollectionStoragePath
            )
        }

        // Fetch the storefront where the listing exists
        self.storefront = getAccount(storefrontAddress)
            .getCapability<&NFTStorefront.Storefront{NFTStorefront.StorefrontPublic}>(
                NFTStorefront.StorefrontPublicPath
            )!
            .borrow()
            ?? panic("Could not borrow Storefront from provided address")

        // Fetch the listing from the storefront by ID
        self.listing = self.storefront.borrowListing(listingResourceID: listingResourceID)
            ?? panic("No Offer with that ID in Storefront")

        // Get access to Dapper's DUC vault
        let salePrice = self.listing.getDetails().salePrice
        self.mainDucVault = dapper.borrow<&DapperUtilityCoin.Vault>(from: /storage/dapperUtilityCoinVault)
            ?? panic("Cannot borrow DapperUtilityCoin vault from dapper storage")

        // Withdraw the appropriate amount of DUC from the vault
        self.balanceBeforeTransfer = self.mainDucVault.balance
        self.paymentVault <- self.mainDucVault.withdraw(amount: salePrice)

        // Check that the price is what we expect
        if (expectedPrice != salePrice) {
            panic("Expected price does not match sale price")
        }

        self.buyerMintStoreCollection = buyer
            .getCapability<&{${contract.name}.${contract.name}CollectionPublic}>(${contract.name}.CollectionPublicPath)
            .borrow()
            ?? panic("Could not borrow ${contract.name} Collection from provided address")
    }

    execute {
        let item <- self.listing.purchase(
            payment: <-self.paymentVault
        )

        self.buyerMintStoreCollection.deposit(token: <-item)
    }

    post {
        // Ensure there is no DUC leakage
        self.mainDucVault.balance == self.balanceBeforeTransfer: "transaction would leak DUC"
    }
}`;
