const MERCHANT_LIST = {
  Testnet: [{ id: "1", address: "0x28492e97618732eb" }],
  Mainnet: [{ id: "27", address: "0x9ac2d8a3277a8b31" }],
};

const MINT_STORE_MARKET = {
  Testnet: { id: 1, address: "0x28492e97618732eb" },
  Mainnet: { id: 3, address: "0x9ac2d8a3277a8b31" },
};

const WALLET_VIEWER_ID = {
  Testnet: 1,
  Mainnet: 22,
};
const KYC_MERCHANT_ID = {
  Testnet: 1,
  Mainnet: 1,
};

export { MERCHANT_LIST, MINT_STORE_MARKET, WALLET_VIEWER_ID, KYC_MERCHANT_ID };
