const HAS_UTILITY_PAGE = true;

const UTILITY_SHOPIFY_SHOP = "valtech-nft.myshopify.com";
const UTILITY_SHOPIFY_COLLECTION_ID = 412446687459;

const UTILITY_BANNER =
  "https://mint-store-metadata.s3.us-east-2.amazonaws.com/Valtech/valtech-utility-banner.png";
const UTILITY_BANNER_FILE =
  "https://mint-store-metadata.s3.us-east-2.amazonaws.com/Valtech/valtech-utility-banner.png";

const UTILITY_SECTIONS: UtilitySection[] = [
  "collection",
  "merchandise",
  "archives",
  "playlists",
  "videos",
];

export default {
  HAS_UTILITY_PAGE,
  UTILITY_SHOPIFY_SHOP,
  UTILITY_SHOPIFY_COLLECTION_ID,
  UTILITY_BANNER,
  UTILITY_BANNER_FILE,
  UTILITY_SECTIONS,
};
