const LOGIN_META_TAGS = {
  TITLE: `${process.env.REACT_APP_WALLET_VIEWER_NAME} - Login`,
  DESCRIPTION: `This is the login page description`,
};

const MARKET_META_TAGS = {
  TITLE: `${process.env.REACT_APP_WALLET_VIEWER_NAME} - Market`,
  DESCRIPTION: `This is the market page description`,
};

const MERCHANTS_META_TAGS = {
  TITLE: `${process.env.REACT_APP_WALLET_VIEWER_NAME} - Merchants`,
  DESCRIPTION: `This is the merchants page description`,
};

const NFT_DETAILS_META_TAGS = {
  TITLE: `${process.env.REACT_APP_WALLET_VIEWER_NAME} - NFT Details`,
  DESCRIPTION: `This is a description for the nft details page`,
};

const NFT_LIST_META_TAGS = {
  TITLE: `${process.env.REACT_APP_WALLET_VIEWER_NAME} - NFT List`,
  DESCRIPTION: `This is a description for the nft list page`,
};

const EDITION_DETAILS_META_TAGS = {
  TITLE: `${process.env.REACT_APP_WALLET_VIEWER_NAME} - Edition Details`,
  DESCRIPTION: `This is a description for the edition details page`,
};

const PRIVACY_POLICY_META_TAG = {
  TITLE: `${process.env.REACT_APP_WALLET_VIEWER_NAME} - Privacy Policy`,
  DESCRIPTION: `This is a description for privacy policy page`,
};

const TERMS_META_TAG = {
  TITLE: `${process.env.REACT_APP_WALLET_VIEWER_NAME} - Terms of service`,
  DESCRIPTION: `This is a description for privacy policy page`,
};

const NOT_FOUND_META_TAG = {
  TITLE: `${process.env.REACT_APP_WALLET_VIEWER_NAME} - Page not Found`,
  DESCRIPTION: `The page requested doesn't exist.`,
};

const FAQ_META_TAG = {
  TITLE: `${process.env.REACT_APP_WALLET_VIEWER_NAME} - Frequently Asked Questions`,
  DESCRIPTION: "This is a description for the FAW page",
};

const SALES_HISTORY_META_TAGS = {
  TITLE: `${process.env.REACT_APP_WALLET_VIEWER_NAME} - Sales History`,
  DESCRIPTION: "This is the Sales History page description",
};

export default {
  FAQ_META_TAG,
  LOGIN_META_TAGS,
  MARKET_META_TAGS,
  MERCHANTS_META_TAGS,
  NFT_DETAILS_META_TAGS,
  NFT_LIST_META_TAGS,
  NOT_FOUND_META_TAG,
  TERMS_META_TAG,
  PRIVACY_POLICY_META_TAG,
  EDITION_DETAILS_META_TAGS,
  SALES_HISTORY_META_TAGS,
};
